
export interface Position {
    value: string;
    translationKey: string;
  }
  
  export const POSITIONS: Position[] = [
    { value: 'Principals, Assistant Principals, and Center’s Director', translationKey: 'position1' },
    { value: 'Early Childhood Educators and Assistants', translationKey: 'position2' },
    { value: 'Parent Coordinators', translationKey: 'position3' },
    { value: 'Budget/bookkeeping Managers', translationKey: 'position4' },
    { value: '3K, UPK, Kindergarten Classroom Teachers, Teaching Assistants and Paraprofessionals', translationKey: 'position5' },
    { value: '3K, UPK, Kindergarten Art Teachers, Cluster Teachers and SEITs', translationKey: 'position6' },
    { value: '3K, UPK, Kindergarten Social Workers and Guidance Counselors', translationKey: 'position7' },
    { value: 'Other', translationKey: 'other' }
  ];



//   { value: 'Principals Assistant Principals and Centers Director', translationKey: 'position1' },
//   { value: 'Early Childhood Educators and Assistants', translationKey: 'position2' },
//   { value: 'Parent Coordinators', translationKey: 'position3' },
//   { value: 'Budget bookkeeping Managers', translationKey: 'position4' },
//   { value: '3K UPK Kindergarten Classroom Teachers Teaching Assistants and Paraprofessionals', translationKey: 'position5' },
//   { value: '3K UPK Kindergarten Art Teachers Cluster Teachers and SEITs', translationKey: 'position6' },
//   { value: '3K UPK Kindergarten Social Workers and Guidance Counselors', translationKey: 'position7' },
//   { value: 'Other', translationKey: 'other' }
  