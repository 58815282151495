<div class="container main-container">
    <h2 mat-dialog-title class="title">{{ 'CheckInfo' | translate }}</h2>
    <mat-dialog-content class="mat-typography">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12">

            <ng-container *ngFor="let adult of formData.family.adults; let i = index">
              <!-- <p>Adult {{ i + 1 }} ID: {{ adult.id }}</p> -->
              <ng-container *ngIf="i === 0">
                <p>{{ "areYouStaffMember" | translate}}: <strong>{{ adult.isMemberAtSchoolCenter ? getTranslation('yes') : getTranslation('no') }}</strong></p>
                <ng-container *ngIf="adult.isMemberAtSchoolCenter === true">
                <p>{{ "doYouHaveChildren" | translate}}: <strong>{{ adult.haveChildren !== null ? (adult.haveChildren ? getTranslation('yes') : getTranslation('no')) : 'N/A' }}</strong> </p>
                <p>{{ "assignedLiaison" | translate}}: <strong>{{ adult.assignedCoolCultureCulturalLiaison !==null ? (adult.assignedCoolCultureCulturalLiaison ? getTranslation('yes') : getTranslation('no')): 'N/A' }}</strong></p>
                <p>{{ "yourPositionOrTitle" | translate}} <strong>
                  {{ !adult.positionTitle ? 'N/A' : adult.positionTitle === 'Other' ? adult.stuffPositionOther : getPositionTranslation(adult.positionTitle) | translate }}
                </strong> </p>
              </ng-container>
                <hr>
            </ng-container>

            <ng-container *ngIf="i === 0">
              <h2>{{ "adult" | translate}} {{i===0? 1 : 2}}</h2>
              <!-- <p>{{ "familySize" | translate}}: <strong>{{ formData.familySize }}</strong></p> -->
              <p>{{ "FirstName" | translate}}: <strong>{{ adult.firstName }}</strong> </p>
              <p>{{ "LastName" | translate}}: <strong>{{ adult.lastName }}</strong> </p>
              <p>{{ "birthdate" | translate}}: <strong>{{ adult.birthdate | date:'yyyy' }}</strong> </p>
              <p>{{ "gender" | translate}}: <strong> {{ adult.gender === 'Other Gender Identities' ? adult.genderCustom : getGenderTranslation(adult.gender) | translate }}</strong></p>
              <p>{{ "EmailAddress" | translate}}: <strong>{{ adult.email }}</strong> </p>
              <p>{{ "boroughResidence" | translate}}: <strong>{{ getBoroughTranslation(adult.boroughOfResidence) | translate }}</strong> </p>
              <p>{{ "mobilePhone" | translate}}: <strong>{{ adult.mobilePhone }}</strong> </p>
              <hr>
            </ng-container>

            <ng-container *ngIf="i === 1 && data.optionalAdult2 === true">
              <h2>{{ "adult" | translate}} {{i===0? 1 : 2}}</h2>
              <!-- <p>{{ "familySize" | translate}}: <strong>{{ formData.familySize }}</strong></p> -->
              <p>{{ "FirstName" | translate}}: <strong>{{ adult.firstName }}</strong> </p>
              <p>{{ "LastName" | translate}}: <strong>{{ adult.lastName }}</strong> </p>
              <p>{{ "birthdate" | translate}}: <strong>{{ adult.birthdate | date:'yyyy' }}</strong> </p>
              <p>{{ "gender" | translate}}: <strong> {{ adult.gender === 'Other Gender Identities' ? adult.genderCustom : getGenderTranslation(adult.gender) | translate }}</strong></p>
              <p>{{ "EmailAddress" | translate}}: <strong>{{ adult.email }}</strong> </p>
              <p>{{ "boroughResidence" | translate}}: <strong>{{ getBoroughTranslation(adult.boroughOfResidence) | translate }}</strong> </p>
              <p>{{ "mobilePhone" | translate}}: <strong>{{ adult.mobilePhone }}</strong> </p>
              <hr>
            </ng-container>

          </ng-container>
            <ng-container *ngFor="let child of formData.family.children; let i = index">
                <!-- <p>Child {{ i + 1 }} ID: {{ child.id }}</p> -->
                <!-- <ng-container *ngIf="formData.family.adults.haveChildren === true"> -->
                <ng-container *ngIf="child.firstName !== null && child.lastName  !== null">
                  <h2>{{ "child" | translate}}</h2>
                  <p>{{ "FirstName" | translate}}: <strong>{{ child.firstName }}</strong> </p>
                  <p>{{ "LastName" | translate}}: <strong>{{ child.lastName }}</strong> </p>
                  <p>{{ "childGradeProgram" | translate}}: <strong>{{ child.gradeProgram }}</strong> </p>
                  <p>{{ "childClassroom" | translate}}: <strong>{{ child.classroom }}</strong> </p>
                  <p>{{ "childTeacher" | translate}}: <strong>{{ child.teacher }}</strong> </p>
                </ng-container> 
              </ng-container>
              <!-- </ng-container> -->
                
                <p>{{ "numberChildren" | translate}}: <strong>{{ formData.family.numOfChildren }}</strong> </p>
                <p>{{ "printFamilyName" | translate}}: <strong>{{ formData.family.familyName }}</strong> </p>
                <p>{{ "printFamilyNameText4" | translate}}: <strong>{{ getisInterestedInDigitalCardTranslation(formData.family.isInterestedInDigitalCard) | translate }}</strong> </p>
                <p>{{ "museumExperience1" | translate}}: <strong>{{  formData.family.isVisitedEvent !==null ? (formData.family.isVisitedEvent ? getTranslation('yes') : getTranslation('no')): 'N/A'  }}</strong> </p>
                <p>{{ "museumExperience2" | translate}}: <strong>{{ getTimesVisitedEventTranslation(formData.family.timesVisitedEvent) | translate }}</strong> </p>
                <p>{{ "museumExperience3" | translate}}: </p>
                <ul>
                  <li *ngIf="formData.family.visitedYear2017"><strong>2017</strong></li>
                  <li *ngIf="formData.family.visitedYear2018"><strong>2018</strong></li>
                  <li *ngIf="formData.family.visitedYear2019"><strong>2019</strong></li>
                  <li *ngIf="formData.family.visitedYear2020"><strong>2020</strong></li>
                  <li *ngIf="formData.family.visitedYear2021"><strong>2021</strong></li>
                  <li *ngIf="formData.family.visitedYear2022"><strong>2022</strong></li>
                  <li *ngIf="formData.family.visitedYear2023"><strong>2023</strong></li>
                </ul>
                <ng-container *ngFor="let child of formData.family.children; let i = index">
                  <p>{{ "mainLanguageSpokenAtHome" | translate}}: <strong>{{ getPrimaryLanguageTranslation(child.primaryLanguageSpokenByParent) | translate}}</strong> </p>
                  <p>{{ "otherLanguageSpokenAtHome" | translate}}: </p>
                    <ul>
                      <li *ngFor="let language of getSelectedLanguages()"><strong>{{ language }}</strong></li>
                    </ul>
                <hr>
                <p>{{ "ethnicBackgroundChild" | translate}}:</p>
                <ul>
                  <li *ngFor="let ethnicity of getSelectedEthnicities()"> <strong>{{ ethnicity }}</strong></li>
                </ul>
                </ng-container>
            <p>{{ "highestEducation" | translate}}: <strong>{{ getHighestLevelEducationTranslation(formData.family.highestLevelEducation) | translate }}</strong></p>
            <p>{{ "currentHouseholdIncome" | translate}}: <strong>{{ getCurrentHouseholdIncomeRange(formData.family.currentHouseholdIncomeRange) | translate }}</strong></p>

            <br>

          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog()">{{ 'GoBack' | translate }}</button>
      <button mat-flat-button color="warn" (click)="saveDialog()">{{ 'Confirm' | translate }}</button>
    </mat-dialog-actions>
  </div>
  
