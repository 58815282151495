<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container main-container">
    <h2 mat-dialog-title class="title">{{ 'Details' | translate }}</h2>
    <mat-dialog-content class="mat-typography">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12">
            <form [formGroup]="detailsForm">

              <p>Organization name: {{data.element.organisationName}}</p>
              <p>Status: {{ data.element.status }}</p>
              <p>Language: {{data.element.language}}</p>
              <hr>
  
              <ng-container *ngFor="let adult of data.element.family.adults; let i = index">
                <div formGroupName="family">

                <div formArrayName="adults">
                  <div [formGroupName]="i">
                      <ng-container *ngIf="i === 0">
                        <p class="mb-0">{{ "areYouStaffMember" | translate}}:</p>
                        <mat-radio-group color="warn" aria-label="Select an option" formControlName="isMemberAtSchoolCenter">
                          <mat-radio-button [value]="true">{{ 'yes' | translate }}</mat-radio-button>
                          <mat-radio-button [value]="false">{{ 'no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                        <ng-container *ngIf="detailsForm.get('family.adults.0.isMemberAtSchoolCenter').value === true">
                        <p class="mb-0 pt-3">{{ "doYouHaveChildren" | translate}}:</p>
                        <mat-radio-group color="warn" aria-label="Select an option" formControlName="haveChildren">
                          <mat-radio-button [value]="true">{{'yes' | translate}}</mat-radio-button>
                          <mat-radio-button [value]="false">{{'no' | translate}}</mat-radio-button>
                        </mat-radio-group>
                        <p class="mb-0 pt-3">{{ "assignedLiaison" | translate}}:</p>
                        <mat-radio-group color="warn" aria-label="Select an option" formControlName="assignedCoolCultureCulturalLiaison">
                          <mat-radio-button [value]="true">{{'yes' | translate}}</mat-radio-button>
                          <mat-radio-button [value]="false">{{'no' | translate}}</mat-radio-button>
                        </mat-radio-group>
                        <p class="mb-0 pt-3">{{ "yourPositionOrTitle" | translate}}</p>
                        <mat-radio-group color="warn" aria-label="Select an option" class="d-flex flex-column" formControlName="positionTitle">
                          <mat-radio-button *ngFor="let position of positions" [value]="position.value">{{ position.translationKey | translate }}</mat-radio-button>
                          <!-- <mat-radio-button *ngFor="let position of positionsOld" [value]="position.value">{{ position.translationKey | translate }}</mat-radio-button> -->
                          </mat-radio-group>
                          <div *ngIf="detailsForm.get('family.adults.0.positionTitle').value === 'Other'">
                            <p class="pt-4">{{'OtherPosition' | translate}}</p>
                            <mat-form-field class="non-resizable col-12" appearance="outline">
                            <input matInput formControlName="stuffPositionOther">
                          </mat-form-field>
                          </div>
                        </ng-container>
                        <hr>
                    </ng-container>

                    <ng-container *ngIf="i === 0">
                      <p class="mb-1">{{ "FirstName" | translate}}:</p>
                      <mat-form-field class="col-12" appearance="outline">
                        <input matInput formControlName="firstName">
                      </mat-form-field>
                      <p class="mb-1 pt-1">{{ "LastName" | translate}}:</p>
                      <mat-form-field class="col-12" appearance="outline">
                        <input matInput formControlName="lastName">
                      </mat-form-field>
                      <p class="mb-1 pt-1">{{ "birthdate" | translate}}:</p>
                      <mat-form-field class="col-12" appearance="outline">
                        <mat-label>{{ 'select' | translate }}</mat-label>
                        <mat-select formControlName="birthdate" placeholder="{{ 'select' | translate }}">
                          <mat-option *ngFor="let year of birthYears" [value]="year">{{ year | slice:0:4  }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <p class="mb-1 pt-1">{{ "gender" | translate}}:</p>
                      <mat-form-field class="col-12" appearance="outline">
                        <mat-label>{{'select' | translate}}</mat-label>
                        <mat-select formControlName="gender">
                        <mat-option *ngFor="let gender of genders" [value]="gender.value">
                            {{gender.value}}
                        </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <ng-container *ngIf="detailsForm.get('family.adults.0.gender').value === 'Other Gender Identities'">
                      <p class="mb-1 pt-1">{{'GenderInfo' | translate}}</p>
                      <mat-form-field class="non-resizable col-12" appearance="outline">
                        <input matInput formControlName="genderCustom">
                      </mat-form-field>
                      </ng-container>
                      <p class="mb-1 pt-1">{{ "EmailAddress" | translate}}:</p>
                      <mat-form-field class="field col-12" appearance="outline">
                        <input matInput formControlName="email" placeholder="email@example.com">
                        <!-- <mat-error *ngIf="detailsForm.get('email').hasError('required')">
                          {{ 'EnterEmailError' | translate }}
                        </mat-error>
                        <mat-error *ngIf="detailsForm.get('email').hasError('email') && !detailsForm.get('email').hasError('required')">
                          {{ 'EmailRequiredError' | translate }}
                        </mat-error> -->
                      </mat-form-field>
                      <p class="mb-1 pt-1">{{ "boroughResidence" | translate}}:</p>
                      <mat-form-field class="col-12" appearance="outline">
                        <mat-label>{{'select' | translate}}</mat-label>
                        <mat-select formControlName="boroughOfResidence">
                          <mat-option *ngFor="let borough of boroughs" [value]="borough.value">
                            {{borough.viewValue | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <p class="mb-1 pt-1">{{ "mobilePhone" | translate}}:</p>
                      <mat-form-field class="col-12" appearance="outline">
                        <mat-label></mat-label>
                        <input matInput formControlName="mobilePhone">
                      </mat-form-field>
                      <hr>
                  </ng-container>

                  <ng-container *ngIf="i === 1">
                    <p class="mb-1">{{ "FirstName" | translate}}:</p>
                    <mat-form-field class="col-12" appearance="outline">
                      <input matInput formControlName="firstName">
                    </mat-form-field>
                    <p class="mb-1 pt-1">{{ "LastName" | translate}}:</p>
                    <mat-form-field class="col-12" appearance="outline">
                      <input matInput formControlName="lastName">
                    </mat-form-field>
                    <p class="mb-1 pt-1">{{ "birthdate" | translate}}:</p>
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>{{ 'select' | translate }}</mat-label>
                      <mat-select formControlName="birthdate" placeholder="{{ 'select' | translate }}">
                        <mat-option *ngFor="let year of birthYears" [value]="year">{{ year | slice:0:4  }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <p class="mb-1 pt-1">{{ "gender" | translate}}:</p>
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>{{'select' | translate}}</mat-label>
                      <mat-select formControlName="gender">
                      <mat-option *ngFor="let gender of genders" [value]="gender.value">
                          {{gender.value}}
                      </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="detailsForm.get('family.adults.1.gender').value === 'Other Gender Identities'">
                    <p class="mb-1 pt-1">{{'GenderInfo' | translate}}</p>
                    <mat-form-field class="non-resizable col-12" appearance="outline">
                      <input matInput formControlName="genderCustom">
                    </mat-form-field>
                    </ng-container>
                    <p class="mb-1 pt-1">{{ "EmailAddress" | translate}}:</p>
                    <mat-form-field class="field col-12" appearance="outline">
                      <input matInput formControlName="email" placeholder="email@example.com">
                      <!-- <mat-error *ngIf="detailsForm.get('email').hasError('required')">
                        {{ 'EnterEmailError' | translate }}
                      </mat-error>
                      <mat-error *ngIf="detailsForm.get('email').hasError('email') && !detailsForm.get('email').hasError('required')">
                        {{ 'EmailRequiredError' | translate }}
                      </mat-error> -->
                    </mat-form-field>
                    <p class="mb-1 pt-1">{{ "boroughResidence" | translate}}:</p>
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>{{'select' | translate}}</mat-label>
                      <mat-select formControlName="boroughOfResidence">
                        <mat-option *ngFor="let borough of boroughs" [value]="borough.value">
                          {{borough.viewValue | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <p class="mb-1 pt-1">{{ "mobilePhone" | translate}}:</p>
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label></mat-label>
                      <input matInput formControlName="mobilePhone">
                    </mat-form-field>
                    <hr>
                </ng-container>

                    </div>
              </div>
            </div>
            </ng-container>
            <div formGroupName="family">

            <div formArrayName="children">
              <ng-container *ngFor="let childrenForm of childrenForms.controls; let i = index" [formGroupName]="i">   
                <ng-container *ngIf="this.detailsForm.get('family.adults.0.haveChildren').value === true || detailsForm.get('family.adults.0.haveChildren').value === null">                
                  <p class="mb-1">{{ "ChildFirstName" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <input matInput formControlName="firstName">
                  </mat-form-field>
                  <p class="mb-1 pt-1">{{ "ChildLastName" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <input matInput formControlName="lastName">
                  </mat-form-field>
                  <p class="mb-1 pt-1">{{ "childGradeProgram" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <input matInput formControlName="gradeProgram">
                  </mat-form-field>
                  <p class="mb-1 pt-1">{{ "childClassroom" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <input matInput formControlName="classroom">
                  </mat-form-field>
                  <p class="mb-1 pt-1">{{ "childTeacher" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <input matInput formControlName="teacher">
                  </mat-form-field>
                  </ng-container> 
              </ng-container>
            </div>
              <ng-container  *ngIf="detailsForm.get('family.adults.0.haveChildren').value === true || detailsForm.get('family.adults.0.haveChildren').value === null">
                  <p class="mb-1 pt-1">{{ "numberChildren" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>{{'select' | translate}}</mat-label>
                    <mat-select formControlName="numOfChildren">
                      <mat-option *ngFor="let number of numbers" [value]="number.value">
                        {{number.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  </ng-container>
                  <p class="mb-1 pt-1">{{ "printFamilyName" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <!-- <mat-label>{{'FirstName' | translate}}</mat-label> -->
                    <input matInput formControlName="familyName">
                  </mat-form-field>
                  <p class="mb-1 pt-1">{{ "printFamilyNameText4" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>{{'select' | translate}}</mat-label>
                    <mat-select formControlName="isInterestedInDigitalCard">
                      <mat-option *ngFor="let choice of choices" [value]="choice.value">
                        {{choice.viewValue | translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <p class="mb-0 pt-1">{{ "museumExperience1" | translate}}:</p>
                  <mat-radio-group color="warn" aria-label="Select an option" formControlName="isVisitedEvent">
                    <!-- <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.isVisitedEvent').hasError('required')">
                      {{ 'MustSelectOption' | translate }}
                    </mat-error> -->
                    <mat-radio-button [value]="true">{{'yes' | translate}}</mat-radio-button>
                    <mat-radio-button [value]="false">{{'no' | translate}}</mat-radio-button>
                  </mat-radio-group>


                  <ng-container *ngIf="detailsForm.get('family.isVisitedEvent').value === true">
                  <p class="mb-1 pt-1">{{ "museumExperience2" | translate}}:</p>
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>{{'select' | translate}}</mat-label>
                    <mat-select formControlName="timesVisitedEvent">
                      <mat-option *ngFor="let number of museumVisits" [value]="number.value">
                        {{number.viewValue | translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <p class="mb-1 pt-1">{{ "museumExperience3" | translate}}: </p>
                  <section class="d-flex flex-column">
                    <mat-checkbox color="warn" formControlName="visitedYear2017">2017</mat-checkbox>
                    <mat-checkbox color="warn" formControlName="visitedYear2018">2018</mat-checkbox>
                    <mat-checkbox color="warn" formControlName="visitedYear2019">2019</mat-checkbox>
                    <mat-checkbox color="warn" formControlName="visitedYear2020">2020</mat-checkbox>
                    <mat-checkbox color="warn" formControlName="visitedYear2021">2021</mat-checkbox>
                    <mat-checkbox color="warn" formControlName="visitedYear2022">2022</mat-checkbox>
                    <mat-checkbox color="warn" formControlName="visitedYear2023">2023</mat-checkbox>
                    <mat-checkbox hidden color="warn" formControlName="visitedYear2024">2024</mat-checkbox>
                    <mat-checkbox hidden color="warn" formControlName="visitedYear2025">2025</mat-checkbox>
                  </section>
                </ng-container>
                </div>

                    <div formGroupName="family">
                    <div formArrayName="children">
                    <ng-container *ngFor="let childrenForm of childrenForms.controls; let i = index" [formGroupName]="i">

                    <p class="mb-1 pt-2">{{ "mainLanguageSpokenAtHome" | translate}}:</p>
                    <mat-form-field class="col-12" appearance="outline">
                      <mat-label>{{'select' | translate}}</mat-label>
                      <mat-select formControlName="primaryLanguageSpokenByParent">
                        <mat-option *ngFor="let language of languages" [value]="language.value">
                          {{language.viewValue | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <p class="mb-1 pt-1">{{ "otherLanguageSpokenAtHome" | translate}}: </p>
                      <!-- <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.otherLanguagesSpokenByParent').hasError('required')">
                        {{ 'MustSelectOption' | translate }}
                      </mat-error> -->
                      <section class="d-flex flex-column">
                        <mat-checkbox formControlName="otherLanguageArabic" color="warn">{{ 'language1' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageBengali" color="warn">{{ 'language2' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageCantonese" color="warn">{{ 'language3' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageEnglish" color="warn">{{ 'language4' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageFrench" color="warn">{{ 'language5' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageHaitianCreole" color="warn">{{ 'language6' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageHebrew" color="warn">{{ 'language7' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageKorean" color="warn">{{ 'language8' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageMandarin" color="warn">{{ 'language9' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageSpanish" color="warn">{{ 'language10' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageRussian" color="warn">{{ 'language11' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageUrdu" color="warn">{{ 'language12' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageYiddish" color="warn">{{ 'language13' | translate }}</mat-checkbox>
                        <mat-checkbox formControlName="otherLanguageOther" color="warn" >{{ 'other' | translate }}</mat-checkbox>
                      </section>
                  <hr>
                  </ng-container>

                    </div>

                    <ng-container *ngIf="detailsForm.get('family.adults.0.haveChildren').value !== false">
                    <p class="mb-1">{{ "ethnicBackgroundChild" | translate}}:</p>
                      <!-- <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.racialEthnicOfChild').hasError('required')">
                        {{ 'MustSelectOption' | translate }}
                      </mat-error> -->
                      <section class="d-flex flex-column">
                        <mat-checkbox color="warn" formControlName="racialAmericanIndianOrAlaskanNative">{{'ethnicity1' | translate}}</mat-checkbox>
                        <mat-checkbox color="warn" formControlName="racialAsian">{{'ethnicity2' | translate}}</mat-checkbox>
                        <mat-checkbox color="warn" formControlName="racialBlackOrAfricanAmerican">{{'ethnicity3' | translate}}</mat-checkbox>
                        <mat-checkbox color="warn" formControlName="racialLatinoOrLatinx">{{'ethnicity4' | translate}}</mat-checkbox>
                        <mat-checkbox color="warn" formControlName="racialNativeAmericanOrPacificIslander">{{'ethnicity5' | translate}}</mat-checkbox>
                        <mat-checkbox color="warn" formControlName="racialWhite">{{'ethnicity6' | translate}}</mat-checkbox>
                        <mat-checkbox color="warn" formControlName="racialPreferNotToAnswer">{{'ethnicity7' | translate}}</mat-checkbox>
                      </section>
                    </ng-container>
                      <p class="mb-1 pt-3">{{ "highestEducation" | translate}}:</p>
                      <mat-radio-group color="warn" aria-label="Select an option" class="d-flex flex-column" formControlName="highestLevelEducation">
                        <!-- <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.highestLevelEducation').hasError('required')">
                          {{ 'MustSelectOption' | translate }}
                        </mat-error> -->
                        <mat-radio-button value="Less than 9th grade">{{'education1' | translate}}</mat-radio-button>
                        <mat-radio-button value="9th to 12th grade, no diploma">{{'education2' | translate}}</mat-radio-button>
                        <mat-radio-button value="High school graduate (incl. GED or alternative credential)">{{'education3' | translate}}</mat-radio-button>
                        <mat-radio-button value="Some college">{{'education4' | translate}}</mat-radio-button>
                        <mat-radio-button value="Associate's degree (i.e AA, AS)">{{'education5' | translate}}</mat-radio-button>
                        <mat-radio-button value="Bachelor's Degree (i.e BA, BS)">{{'education6' | translate}}</mat-radio-button>
                        <mat-radio-button value="Graduate or professional degree (i.e MA, MS, Ph.D, JD)">{{'education7' | translate}}</mat-radio-button>
                        <mat-radio-button value="Other">{{'other' | translate}}</mat-radio-button>
                      </mat-radio-group>
                      <p class="mb-1 pt-3">{{ "currentHouseholdIncome" | translate}}:</p>
                      <mat-form-field class="col-12" appearance="outline">
                        <mat-label>{{'select' | translate}}</mat-label>
                        <mat-select formControlName="currentHouseholdIncomeRange">
                          <mat-option *ngFor="let income of incomes" [value]="income.value">
                            {{income.viewValue | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
                
  
              <br>
            </form>
            <mat-error *ngIf="detailsForm.invalid">Please fill out the required fields correctly.</mat-error>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog()">{{ 'Cancel' | translate }}</button>
      <button mat-flat-button color="warn" [disabled]="!this.canSubmit" (click)="saveDialog()">{{ 'confirm' | translate }}</button>
    </mat-dialog-actions>
  </div>
  
