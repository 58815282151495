import { Component, OnDestroy } from '@angular/core';
import { LoadingService } from '../loading-spinner/loading.service';
import { MatTableDataSource } from '@angular/material/table';
import { PaymentAdminControllerService, SalesforceNotPaidResponse } from 'projects/swagger-api/src';
import { ContextDataService } from '../services/context-data.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-unpaid-invoices',
  templateUrl: './unpaid-invoices.component.html',
  styleUrls: ['./unpaid-invoices.component.css']
})
export class UnpaidInvoicesComponent implements OnDestroy {
	private onDestroy: Subject<any> = new Subject();

  loading:boolean = false;

  dataSource: MatTableDataSource<SalesforceNotPaidResponse> = new MatTableDataSource<SalesforceNotPaidResponse>();
  displayedColumns: string[] = ['name' , 'totalAmount', 'paidAmount', 'link'];


  constructor(
    private loadingService: LoadingService,
    private paymentService: PaymentAdminControllerService,
    private contextDataService: ContextDataService
  ){}


  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
			next: response => {
        if (response) {
          this.getUnpaidPayments();
        }
			}
		});
  }

  ngOnDestroy(): void {
		this.onDestroy.next('onDestroy');
	}

  getUnpaidPayments(){
    this.paymentService.notPaidOrganisations(this.contextDataService.contextData.activeCampaign.campaignId,).subscribe(
      (response)=>{
        // console.log(response);
        this.dataSource = new MatTableDataSource(response);

    })
  }


  onPageChange(event: any) {
  }
}
