import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LangService } from 'src/app/lang.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent {

  selectedLanguage: string;
  targetLink!: string;

  constructor(private router: Router, private cookieService: CookieService, private langService: LangService,) {}

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.setTargetLink();
  }

  ngOnInit() {
    this.setTargetLink();
    this.selectedLanguage = this.langService.getSelectedLanguage();
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    }
  }

  selectLang(lang: string) {
    this.langService.selectLang(lang);
    this.selectedLanguage = lang;
}

  setTargetLink() {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/registration/first-timer/terms-and-conditions')) {
      this.targetLink = '/registration/first-timer/enter-code';
    } else if (currentUrl.includes('/registration/coming-back-user/terms-and-conditions')) {
      this.targetLink = '/registration/coming-back-user/welcome-back';
    } else {
      this.targetLink = '/';
    }
  }
}