import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private pendingRequests = 0;
  private isLoading = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoading.asObservable();

  showLoading() {
    this.pendingRequests++;
    this.isLoading.next(true);
  }

  hideLoading() {
    this.pendingRequests--;
    if (this.pendingRequests <= 0) {
      this.isLoading.next(false);
    }
  }
}
