<div class="col-lg-10 col-md-9 col-sm-8 p-0">

  </div>
  <div class="logo-image">
    <img src="https://www.coolculture.org/wp-content/uploads/2022/01/logo.png" alt="Logo" width="198" height="76" />
  </div>
  
  <div class="conainer">
    <div class="d-flex justify-content-center image-container">
      <img class="image-style" src="https://www.coolculture.org/wp-content/uploads/2022/03/48086683432_b342f8fe06_o.jpg" alt="Logo" width="100%" height="630" style="object-fit: cover;"/>
        <div class="gradient-overlay"></div>
          <!-- <div class="text-container col-lg-4"> -->
          <div class="text-container col-lg-4">
            <div class="big-text">Cool Culture Privacy Policy</div>
            <!-- <div class="small-text">{{ 'familyPassRegistrationText' | translate}}</div> -->
      </div>
    </div>
  </div>
  
  <div class="container-fluid d-flex justify-content-center pt-4 pb-4 px-lg-5">
    <div class="col-lg-8 col-md-8 col-sm-8">
      <mat-card class="card-size">
        <mat-card-content>
                <div class="container bg-white p-5 pt-1 rounded">


                    <h2 class="fw-bold mb-4 d-flex justify-content-center">Cool Culture Website Privacy Policy</h2>
                    <p>Cool Culture is committed to protecting your privacy when you access our website and the online services 
                        we offer. Consistent with New York State’s Internet Security and Privacy Act (NYS ISPA), Freedom of 
                        Information Law and Personal Privacy Protection Law, this policy explains the use of information which you 
                        provide to us and information which may be available to us when you access this website.</p>
                    <p>At Cool Culture, accessible from www.coolculture.org, one of our main priorities is the privacy of our 
                        visitors. This Privacy Policy document contains types of information that is collected and recorded by Cool 
                        Culture and how we use it.</p> 
                    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to 
                        contact us.</p> 
                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to 
                        the information that they shared and/or collect in Cool Culture. This policy is not applicable to any 
                        information collected offline or via channels other than this website. Our Privacy Policy was created with the 
                        help of the Free Privacy Policy Generator.</p>
        
                    <h3 class="fw-bold mt-4">Consent</h3>
                    
                    <ul>
                        <li>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</li>
                    </ul>
        
                    <p>Information we collect:</p>
                    <ul>
                        <li>The personal information that you are asked to provide, and the reasons why you are asked to provide 
                            it, will be made clear to you at the point we ask you to provide your personal information. </li>
                        <li>If you contact us directly, we may receive additional information about you such as your name, email 
                            address, phone number, the contents of the message and/or attachments you may send us, and any 
                            other information you may choose to provide.</li>
                        <li>When you register for an Account, we may ask for your contact information, including items such as 
                            name, company name, address, email address, and telephone number</li>
                    </ul>
        
                    <h3 class="fw-bold mt-4">How we use your information</h3>

                    <p>This website collects and stores information each time it is accessed to enable us to measure the total 
                        number of visitors, and certain non-personal statistical information on types of users, to the different 
                        sections of our site. This helps us make our website more accessible and useful to visitors. We use the 
                        information we collect in various ways, including to: 
                        </p>
                    <ul>
                        <li>Provide, operate, and maintain our websites and mobile applications </li>
                        <li>Improve, personalize, and expand our website </li>
                        <li>Understand and analyze how you use our website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>Communicate with you, either directly or through one of our partners, including for customer service, to 
                            provide you with updates and other information relating to the website, and for marketing and 
                            promotional purposes</li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>
                        <li>Log Files</li>
                    </ul>

                    <p>Cool Culture follows a standard procedure of using log files. These files log visitors when they visit 
                        websites. All hosting companies do this and are a part of hosting services’ analytics. The information 
                        collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), 
                        date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any 
                        information that is personally identifiable. The purpose of the information is for analyzing trends, 
                        administering the site, tracking users’ movement on the website, and gathering demographic information.</p>
                        <br>
                    <h3 class="fw-bold mt-4">Interactive Applications</h3>
                    
                    <p>If you use one of our online interactive applications that requires you to enter personal information, such as 
                        registration for admission, the information you enter is collected and stored as part of processing your 
                        request. This information is used only in connection with processing your request and will be made 
                        available to others only as necessary to complete the processing. Information that you provide online in 
                        connection with any interactive application may be stored by us pursuant to the retention period applicable 
                        to the unit for which the interactive application was submitted. This occurs when the information you 
                        provide is a necessary part of the record of the transaction. We do not resell this information. We do not 
                        disclose this personal information to any other parties except as necessary to process your specific 
                        request, or as provided or required by law. Information concerning applicable retention periods may be 
                        obtained through the Internet privacy policy contact listed in this policy.</p>
                        <br>
                    <h3 class="fw-bold mt-4">Cookies and Web Beacons</h3>
            
                    <p>Like any other website, Cool Culture uses cookies. These cookies are used to store information including 
                        visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is 
                        used to optimize the users’ experience by customizing our web page content based on visitors’ browser 
                        type and/or other information. </p>
                        <br>
                    <h3 class="fw-bold mt-4">Advertising Partners Privacy Policies</h3>
            
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Cool Culture. 
                        Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are 
                        used in their respective advertisements and links that appear on Cool Culture, which are sent directly to 
                        users’ browser. They automatically receive your IP address when this occurs. These technologies are used 
                        to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content 
                        that you see on websites that you visit.</p>

                    <p>Note that Cool Culture has no access to or control over these cookies that are used by third-party 
                        advertisers.</p>    
            
                        <br>

                    <h3 class="fw-bold mt-4">Third Party Privacy Policies </h3>
            
                    <p>Cool Culture’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to 
                        consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may 
                        include their practices and instructions about how to opt out of certain options. 
                         
                        You can choose to disable cookies through your individual browser options. To know more detailed 
                        information about cookie management with specific web browsers, it can be found at the browsers’ 
                        respective websites.</p>
                        <br>
                    <h3 class="fw-bold mt-4">NYS ISPA Act Privacy Rights (Do Not Sell My Personal Information) </h3>
            
                    <p>Under the NYS ISPA, among other rights, New York consumers have the right to:</p>

                    <ul>
                        <li>Request that a business that collects a consumer’s personal data disclose the categories and specific 
                            pieces of personal data that a business has collected about consumers.</li>
                        <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                        <li>Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.</li>
                    </ul>

                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these 
                        rights, please contact us.</p>

                    <br>

                    <h3 class="fw-bold mt-4">General Data Protection Regulation (GDPR) Data Protection Rights</h3>
            
                    <p>We would like to make sure you are fully aware of all your data protection rights. Every user is entitled to 
                        the following:</p>

                        <ul>
                            <li>The right to access:  You have the right to request copies of your personal data. We may charge you a 
                                small fee for this service</li>
                            <li>The right to rectification:  You have the right to request that we correct any information you believe is 
                                inaccurate. You also have the right to request that we complete the information you believe is 
                                incomplete.</li>
                            <li>The right to erasure:  You have the right to request that we erase your personal data, under certain 
                                conditions.</li>
                            <li>The right to restrict processing: You have the right to request that we restrict the processing of your 
                                personal data under certain conditions.</li>
                            <li>The right to object to processing:  you have the right to object to our processing of your personal data 
                                under certain conditions.</li>
                            <li>The right to data portability:  You have the right to request that we transfer the data that we have 
                                collected to another organization, or directly to you, under certain conditions. </li>            
                        </ul>

                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these 
                        rights, please contact us.</p>   
                        
                    <br>

                    <h3 class="fw-bold mt-4">Links</h3>
            
                    <p>The Office of the State Comptroller's website contains links to other websites for your information and 
                        convenience. The Office of the State Comptroller has no control over the privacy practices or the content of 
                        such other websites. Please review the privacy information provided by these sites.</p> 

                       <p>The responsibility for the content and accuracy of information on sites accessed by linking from our website 
                        rests with the entities providing the information. This includes any responsibility for updating information 
                        upon which visitors may rely. </p>

                        <p>The inclusion of links from this site to others does not imply any endorsement by the Office of the State 
                        Comptroller of any product, service, or vendor. Any mention of products, services or vendors is for 
                        informational purposes only. </p>

                        <p>If another website provides a link from that website to our site, it must link to our home page. Such links 
                        may not capture our pages within "frames" from other sites or otherwise present our content as content of 
                        another website.</p>
                    
                        <br>
                    <h3 class="fw-bold mt-4">Children’s Information</h3>

                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents 
                        and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                    <br>

                    <h3 class="fw-bold mt-4">Disclaimer</h3>

                    <p>The information provided in this privacy policy should not be construed as giving business, legal, or other 
                        advice, or warranting as fail proof the security of information provided through this website.</p>   
                    <br>
                    <h3 class="fw-bold mt-4">Contact Us</h3>
                    
                    <p>If you have any questions about this privacy policy, security, or use of information you provide online, 
                        please contact <a href="mailto:webmaster@coolculture.org">webmaster@coolculture.org</a>. This privacy policy will be updated from time to time. Please 
                        review this page frequently.</p>
                </div>
            
        </mat-card-content>
      </mat-card>  
    </div>
  </div>
  
  <footer class="row row-cols-1 py-5  border-top">
  
    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/families/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Families</a></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/museum-lists/" class="nav-link p-0 custom-color">Museum Lists</a></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/family-activities/" class="nav-link p-0 custom-color">Family Activities</a></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/family-pass-registration/" class="nav-link p-0 custom-color">Family Pass Enrollment</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/faqs-for-families/" class="nav-link p-0 custom-color">FAQs for Families</a></li>
        <br>
        <li class="nav-item mb-2"><a href="https://coolculture.org/centers-schools/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Centers & Schools</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/enrollment-for-centers/" class="nav-link p-0 custom-color">Enrollment for Centers</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/enrollment-for-schools/" class="nav-link p-0 custom-color">Enrollment for Schools</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/resources-activities/" class="nav-link p-0 custom-color">Resources & Activities</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/faqs-for-educators/" class="nav-link p-0 custom-color">FAQs for Educators</a></li>
      </ul>
    </div>
  
    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <!-- <h5>Section</h5> -->
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><a href="https://coolculture.org/programs-impact/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Programs & Impact</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/health-wellbeing/" class="nav-link p-0 custom-color">Health + Wellbeing</a></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/cultural-partners/" class="nav-link p-0 custom-color">Cultural Partners</a></li>
        <br>
        <li class="nav-item mb-2"><a href="https://coolculture.org/about-us/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">About Us</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/our-team/" class="nav-link p-0 custom-color">Our Team</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/careers/" class="nav-link p-0 custom-color">Careers</a></li>
        <!-- <li class="nav-item mb-2"><a routerLink="/privacy-policy" class="nav-link p-0 custom-color">Privacy policy</a></li> -->
  
        <br>
        <li class="nav-item mb-2"><a href="https://coolculture.org/get-involved/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Get Involved</a></li>
      </ul>
    </div>
  
    <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><a class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Cool Culture Mission</a></li>
        <br><br>
        <li class="nav-item mb-2"><p class="nav-link p-0 custom-color" style="box-sizing: border-box;">To amplify the voices of families and strengthen the power of historically marginalized communities through engagement with art and culture, both within cultural institutions and beyond.</p></li>
        <li class="nav-item mb-2">
        <a href="https://www.coolculture.org/donate" class="mat-flat-button mat-primary"><button mat-flat-button color="warn" class="donate-button">Donate</button></a>
        </li>
      </ul>
    </div> -->
  
    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <!-- <h5>Section</h5> -->
      <ul class="nav flex-column col-10">
        <li class="nav-item mb-2"><a class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Cool Culture Mission</a></li>
        <br>
        <li class="nav-item mb-2"><p class="nav-link p-0 custom-color" style="box-sizing: border-box;">To amplify the voices of families and strengthen the power of historically marginalized communities through engagement with art and culture, both within cultural institutions and beyond.</p></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/donate" class="mat-flat-button mat-primary"><button mat-flat-button color="warn" class="donate-button">Donate</button></a></li>
      </ul>
    </div>
  
    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <!-- <h5>Section</h5> -->
      <ul class="nav flex-column col">
        <li class="nav-item mb-2"><a href="mailto:info@coolculture.org" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">info@coolculture.org</a></li>
        <li class="nav-item mb-2"><a href="tel:718.230.4186" class="nav-link p-0 text-white">718.230.4186</a></li>
        <li class="nav-item mb-2"><a class="nav-link p-0 text-white">80 Hanson Place, Suite 605</a></li>
        <li class="nav-item mb-2"><a href="https://www.google.com/maps/place/Professional+building,+80+Hanson+Pl+%23606,+Brooklyn,+NY+11217,+USA/@40.6852617,-73.9766057,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25bb1d27ad1f9:0xbbfec9431b6d2d25!8m2!3d40.6852577!4d-73.974417" class="nav-link p-0 text-white">Brooklyn NY 11217</a></li>
        <br>
        <li class="nav-item mb-2 d-flex gap-2">
          <button mat-mini-fab color="primary" class="fab-button custom-fab-size" (click)="openFacebookPage()">
            <span class="fab fa-facebook d-flex justify-content-center"></span>
          </button>        
          <button mat-mini-fab color="accent" class="fab-button custom-fab-size" (click)="openInstagramPage()">
            <i class="fab fa-instagram d-flex justify-content-center"></i>
          </button>
          <button mat-mini-fab color="primary" class="fab-button custom-fab-size" (click)="openTwitterPage()">
            <i class="fab fa-twitter d-flex justify-content-center"></i>
          </button>
          <button mat-mini-fab color="warn" class="fab-button custom-fab-size" (click)="openYoutubePage()">
            <i class="fab fa-youtube d-flex justify-content-center"></i>
          </button>
        </li>
        <br>
        <!-- <p class="nav-item mb-2 d-flex text-white">Website by<a href="https://inellipse.com/" class="nav-link p-0"><img src="https://inellipse.com/wp-content/uploads/2019/01/Inellipse-vector-1.png" alt="" width="80%" height="80%"></a></p> -->
      </ul>
    </div>
  
    <div class="col-1 p-0"></div>
   <div class="col-10 p-1">
      <p class="text-white">Supported in part by public funds from the National Endowment for the Arts (NEA), the New York State Council on the Arts, the New York City Department of Cultural Affairs in partnership with The City Council, and through generous gifts from Corporations, foundations and individuals.</p>
    </div> 
    <div class="col-1 p-0"></div>
  
    
    <div class="col-1 p-0"></div>
   <div class="col-10 p-1">
      <p class="text-white">2023 © Cool Culture  <a style="color: red; text-decoration: none;" href="https://www.coolculture.org/privacy-policy/">Privacy Policy</a></p>
    </div> 
    <div class="col-1 p-0"></div>
  </footer>
  
  
  
