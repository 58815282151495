<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container main-container">
    <h2 mat-dialog-title class="title">{{ 'selectStatus' | translate }}</h2>
    <mat-dialog-content class="mat-typography pb-0">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12">
            <mat-form-field class="col-12 pt-2" appearance="outline">
              <mat-label>{{ 'select' | translate }}</mat-label>
              <mat-select [(ngModel)]="selectedStatus">
                <mat-option *ngFor="let status of filteredStatusOptions" [value]="status">{{ status }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog()">{{ 'Cancel' | translate }}</button>
      <button mat-flat-button color="warn" (click)="saveDialog()">{{ 'apply' | translate }}</button>
    </mat-dialog-actions>
  </div>
  