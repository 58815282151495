<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="flex-container">
	<div class="container-xs px-3">
		<div class="row justify-content-end">
			<div class="col col-auto">
				<button mat-raised-button color="warn" (click)="openDialog('CREATE')">{{ 'BUTTON.ADD' | translate }} {{ 'CAMPAIGNS.CAMPAIGN' | translate }}</button>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col pt-4">
				<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
					<ng-container matColumnDef="name">
						<mat-header-cell *matHeaderCellDef> {{ 'CAMPAIGNS.NAME' | translate }} </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
					</ng-container>

					<ng-container matColumnDef="id">
						<mat-header-cell *matHeaderCellDef> {{ 'CAMPAIGNS.ID' | translate }} </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{ element.campaignId }} </mat-cell>
					</ng-container>

					<ng-container matColumnDef="active">
						<mat-header-cell *matHeaderCellDef> {{ 'CAMPAIGNS.ACTIVE' | translate }} </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{ (element.active ? 'COMMON.ACTIVE' : 'COMMON.INACTIVE') | translate }} </mat-cell>
					</ng-container>

					<ng-container matColumnDef="actions">
						<mat-header-cell *matHeaderCellDef class="d-flex justify-content-center col-1 p-0"> {{ 'COMMON.ACTIONS' | translate }} </mat-header-cell>
						<mat-cell *matCellDef="let element" class="p-3 justify-content-center col-1">
							<button mat-raised-button class="full-width-button" color="warn" [matMenuTriggerFor]="menu">
								<div class="d-flex justify-content-center">
									<mat-icon>more_vert</mat-icon>
								</div>
							</button>
							<mat-menu #menu="matMenu">
								<div class="d-flex justify-content-center py-1">
									<button
										matTooltip="{{ 'BUTTON.UPDATE' | translate }}"
										matTooltipPosition="left"
										mat-raised-button
										color="warn"
										class="w-50"
										(click)="openDialog('UPDATE', element)">
										<mat-icon class="m-0">edit</mat-icon>
									</button>
								</div>

								<div class="d-flex justify-content-center py-1">
									<button
										matTooltip="{{ 'BUTTON.REMOVE' | translate }}"
										matTooltipPosition="left"
										mat-raised-button
										color="warn"
										class="w-50"
										(click)="openDialog('REMOVE', element)">
										<mat-icon class="m-0">delete</mat-icon>
									</button>
								</div>
							</mat-menu>
						</mat-cell>
					</ng-container>

					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
				</mat-table>
			</div>
		</div>
	</div>
</div>
