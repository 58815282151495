import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, catchError, combineLatest, map, mergeMap, of, switchMap } from 'rxjs';
import { ApplicationControllerService, OrganisationControllerService } from 'projects/swagger-api/src';

@Injectable({
  providedIn: 'root'
})
export class CodeGuard implements CanActivate {
  constructor(
    private router: Router,
    private organisationService: OrganisationControllerService,
    private applicationController: ApplicationControllerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const enteredCode = next.queryParams.code;
    const enteredId = next.queryParams.id;

    // Check if either code or id is provided
    if (enteredCode || enteredId) {
      // Create an observable for checking code validity
      const codeValidation$ = enteredCode
        ? this.organisationService.getOrganisationByCode(enteredCode)
        : of(null); // If no code provided, return null

      // Create an observable for checking id validity
      const idValidation$ = enteredId
        ? this.applicationController.showApplication(enteredId)
        : of(null); // If no id provided, return null

      // Combine the observables to check both code and id validity
      return combineLatest([codeValidation$, idValidation$]).pipe(
        switchMap(([codeResult, idResult]) => {
          // Check if either code or id is valid
          if (codeResult || idResult) {
            return of(true); // Either code or id is valid
          } else {
            // console.error('Invalid organization details');
            this.router.navigate(['/enter-code']);
            return of(false);
          }
        }),
        catchError((error) => {
          // console.error('Error fetching organization details:', error);
          this.router.navigate(['/enter-code']);
          return of(false);
        })
      );
    } else {
      this.router.navigate(['/enter-code']);
      return of(false);
    }
  }
}
