import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {

  @Input() formData: any;
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  selectedPrimaryLanguage: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,

  ){
    this.formData = this.data.formData;

    const currentLanguage = this.formData.language; // Replace with your actual way of getting the language
    this.translateService.use(currentLanguage.toLocaleLowerCase());    
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }

  ngOnInit(): void {
  }

  saveDialog(): void {
    // Emit the 'confirm' event to indicate the user's confirmation
    this.confirm.emit();
  }

  closeDialog(): void {
    // Emit the 'cancel' event to indicate the user canceled the action
    this.cancel.emit();
  }

  getLanguageDisplayName(key: string): string {
    const languageOrder = [
      'otherLanguageArabic',
      'otherLanguageBengali',
      'otherLanguageCantonese',
      'otherLanguageEnglish',
      'otherLanguageFrench',
      'otherLanguageHaitianCreole',
      'otherLanguageHebrew',
      'otherLanguageKorean',
      'otherLanguageMandarin',
      'otherLanguageSpanish',
      'otherLanguageRussian',
      'otherLanguageUrdu',
      'otherLanguageYiddish',
      'otherLanguageOther'
    ];
  
    const index = languageOrder.indexOf(key);
    if (index !== -1) {
      if (index === languageOrder.length - 1) {
        // Check if any "otherLanguage" option is selected
        const isOtherLanguageSelected = languageOrder.some((languageKey) =>
          this.formData.family.children[0][languageKey]
        );
        if (isOtherLanguageSelected) {
          return this.translateService.instant('other'); // Fetch the translated name for "other"
        } else {
          return null; // Return an empty string if no "otherLanguage" option is selected
        }
      } else {
        const displayKey = 'language' + (index + 1);
        return this.translateService.instant(displayKey); // Fetch the translated name
      }
    } else {
      return null;
    }
  }

  getSelectedLanguages(): string[] {
    const otherLanguagesSpokenByParent = this.formData.family.children[0];
    const selectedLanguages = Object.keys(otherLanguagesSpokenByParent)
      .filter((key) => key !== 'primaryLanguageSpokenByParent' && otherLanguagesSpokenByParent[key])
      .map((key) => this.getLanguageDisplayName(key))
      .filter((language) => language !== null);
    return selectedLanguages;
  }

  getEthnicityDisplayName(key: string): string {
    const ethnicityOrder = [
      'racialAmericanIndianOrAlaskanNative',
      'racialAsian',
      'racialBlackOrAfricanAmerican',
      'racialLatinoOrLatinx',
      'racialNativeAmericanOrPacificIslander',
      'racialWhite',
      'racialPreferNotToAnswer'
    ];

    const index = ethnicityOrder.indexOf(key);
    if (index !== -1) {
      const displayKey = 'ethnicity' + (index + 1);
      return this.translateService.instant(displayKey); // Fetch the translated name
    } else {
      return '';
    }
  }
  


  getSelectedEthnicities(): string[] {
    const ethnicities = this.formData.family;
    const selectedEthnicities = [];

    const ethnicityTranslations = {
      racialAmericanIndianOrAlaskanNative: 'ethnicity1',
      racialAsian: 'ethnicity2',
      racialBlackOrAfricanAmerican: 'ethnicity3',
      racialLatinoOrLatinx: 'ethnicity4',
      racialNativeAmericanOrPacificIslander: 'ethnicity5',
      racialWhite: 'ethnicity6',
      racialPreferNotToAnswer: 'ethnicity7',
    };
  
    for (const key in ethnicities) {
      if (ethnicities[key] && ethnicityTranslations[key]) {
        selectedEthnicities.push(this.translateService.instant(ethnicityTranslations[key]));
      }
    }
  
    return selectedEthnicities;
  }
  

  getPositionTranslation(positionTitle: string): string {
    const positionTitleTranslations = {
      "Principals Assistant Principals and Centers Director": "position1",
      "Early Childhood Educators and Assistants": "position2",
      "Parent Coordinators": "position3",
      "Budget/bookkeeping Managers": "position4",
      "3K UPK Kindergarten Classroom Teachers Teaching Assistants and Paraprofessionals": "position5",
      "3K UPK Kindergarten Art Teachers Cluster Teachers and SEITs": "position6",
      "3K UPK Kindergarten Social Workers and Guidance Counselors": "position7",
      "Other": "other"
    };
    return positionTitleTranslations[positionTitle] || positionTitle; // Return the translation key or the original value
  }

  getGenderTranslation(gender: string): string {
    const genderTranslations = {
      "Female": "Female",
      "Male": "Male",
      "Non-Binary": "Non-Binary",
      "Nonconforming": "Nonconforming",
      "Prefer not to answer": "PreferNotToAnswer",
      "Other Gender Identities": "OtherGenderIdentities"
    };
    return genderTranslations[gender] || gender; // Return the translation key or the original value
  }


  getBoroughTranslation(boroughOfResidence: string): string {
    const boroughTranslations = {
      "Bronx": "Bronx",
      "Manhattan": "Manhattan",
      "Brooklyn": "Brooklyn",
      "Queens": "Queens",
      "Staten Island": "StatenIsland"
    };
    return boroughTranslations[boroughOfResidence] || boroughOfResidence; // Return the translation key or the original value
  }

  getisInterestedInDigitalCardTranslation(isInterestedInDigitalCard: string): string {
    const isInterestedTranslations = {
      "YES": "Yes",
      "NO": "No",
      "MAYBE": "Maybe"
    };
    return isInterestedTranslations[isInterestedInDigitalCard] || isInterestedInDigitalCard; // Return the translation key or the original value
  }

  getTimesVisitedEventTranslation(timesVisitedEvent: string): string {
    const TimesVisitedTranslations = {
      "0": "TimesVisitedMuseum0",
      "1 time": "TimesVisitedMuseum1",
      "2 times": "TimesVisitedMuseum2",
      "3 or more times": "TimesVisitedMuseum3"
    };
    return TimesVisitedTranslations[timesVisitedEvent] || timesVisitedEvent; // Return the translation key or the original value
  }

  getPrimaryLanguageTranslation(primaryLanguageSpokenByParentTranslation: string): string {
    const primaryLanguageTranslations = {
      "Arabic": "language1",
      "Bengali": "language2",
      "Cantonese": "language3",
      "English": "language4",
      "French": "language5",
      "Haitian Creole": "language6",
      "Hebrew": "language7",
      "Korean": "language8",
      "Mandarin": "language9",
      "Spanish": "language10",
      "Russian": "language11",
      "Urdu": "language12",
      "Yiddish": "language13",
      "Other": "other"
    };
    return primaryLanguageTranslations[primaryLanguageSpokenByParentTranslation] || primaryLanguageSpokenByParentTranslation; // Return the translation key or the original value
  }

  getHighestLevelEducationTranslation(highestLevelEducation: string): string {
    const highestLevelEducationTranslations = {
      "Less than 9th grade": "education1",
      "9th to 12th grade, no diploma": "education2",
      "High school graduate (incl. GED or alternative credential)": "education3",
      "Some college": "education4",
      "Associate's degree (i.e AA, AS)": "education5",
      "Bachelor's Degree (i.e BA, BS)": "education6",
      "Graduate or professional degree (i.e MA, MS, Ph.D, JD)": "education7",
      "Other": "other"
    };
    return highestLevelEducationTranslations[highestLevelEducation] || highestLevelEducation; // Return the translation key or the original value
  }

  getCurrentHouseholdIncomeRange(currentHouseholdIncomeRange: string): string {
    const highestLevelEducationTranslations = {
      "Less than $40,000": "LessThan40000",
      "$40,000 -$49,999": "$40,000-$49,000",
      "$50,000-$59,999": "$50,000-$59,000",
      "$60,000-$69,999": "$60,000-$69,000",
      "$70,000 -$79,000": "$70,000-$79,000",
      "$80,000 - $89,000": "$80,000-$89,000",
      "$90,000 - $99,000": "$90,000-$99,000",
      "$100,000-$150,000": "$100,000-$150,000",
      "$150,000 or more": "150000orMore"
    };
    return highestLevelEducationTranslations[currentHouseholdIncomeRange] || currentHouseholdIncomeRange; // Return the translation key or the original value
  }

}
