<div class="flex-container">
    <div class="container-xs px-3">
      <app-loading-spinner *ngIf="loading"></app-loading-spinner>



      <div class="row justify-content-center">
        <div class="col pt-4 pb-2">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef class="justify-content-start col-2 col-lg-2">Name</mat-header-cell>
              <mat-cell *matCellDef="let element" class="justify-content-start col-2 col-lg-2">{{element.Title}}</mat-cell>
            </ng-container> -->

              <ng-container matColumnDef="accountPayableEmail">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-2 col-lg-3"> Account Payable Email </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-2 col-lg-3"> {{element.PO_Account_Payable_Email__c}} </mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="sentDate">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-1 col-lg-2"> Sent Date </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-1 col-lg-2"> {{element.poPackageSentDate}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-2 col-lg-2"> Number </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-2 col-lg-2"> {{element.PO_Number__c}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="opportunity">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-2"> Link </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-2"> 
                    <a [href]="'https://coolculture.lightning.force.com/lightning/r/Opportunity/' + element.npe01__Opportunity__c + '/view'" target="_blank"
                        style="display: inline-block; word-wrap: break-word; max-width: 100%;">
                            {{element.npe01__Opportunity__c}}
                    </a>
                     </mat-cell>
              </ng-container>


              <ng-container matColumnDef="button">
                <mat-header-cell *matHeaderCellDef class="justify-content-end col-3"></mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-end col-3 gap-2"> 
                    <button mat-raised-button color="warn" (click)="generatePackage(element.npe01__Opportunity__c, element.PO_Number__c)">Generate PO Package</button>
                    <button mat-raised-button color="warn" (click)="sendEmailDialog(element)">Send Email</button>

                </mat-cell>
              </ng-container>

              
        
          
            <!-- Header row -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          
            <!-- Table rows -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <!-- <mat-paginator class="mat-elevation-z8" #paginator [pageSize]="[50]" [pageSizeOptions]="[25, 50, 100]" [pageSize]="paginator.pageSize" [pageIndex]="paginator.pageIndex" [length]="paginator.length" (page)="onPageChange($event)"></mat-paginator> -->

        </div>
      </div>

  </div>
  


  