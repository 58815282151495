import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationAdminControllerService, SalesforceAdminControllerService, SalesforcePayment } from 'projects/swagger-api/src';
import { LoadingService } from '../loading-spinner/loading.service';
import { CustomApplicationAdminControllerService } from 'custom-api/customAdminController.service';
import { PurchaseOrdersDialogComponent } from './purchase-orders-dialog/purchase-orders-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SendMailComponent } from './send-mail/send-mail.component';
import { ContextDataService } from '../services/context-data.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.css']
})
export class PurchaseOrdersComponent implements OnDestroy {
	private onDestroy: Subject<any> = new Subject();

  loading:boolean = false;

  dataSource: MatTableDataSource<SalesforcePayment> = new MatTableDataSource<SalesforcePayment>();
  displayedColumns: string[] = ['accountPayableEmail', 'sentDate', 'number', 'opportunity', 'button'];

  constructor(
    private loadingService: LoadingService,
    private salesForceAdminController: SalesforceAdminControllerService,
    private customApplicationAdminService: CustomApplicationAdminControllerService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private contextDataService: ContextDataService
    ){}

  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
			next: response => {
        if (response) {
          this.getContentVersion();
        }
			}
		});
  }

  ngOnDestroy(): void {
		this.onDestroy.next('onDestroy');
	}

  getContentVersion(){
      this.salesForceAdminController.getOpportunitiesWithPayableEmailAndPONumber(this.contextDataService.contextData.activeCampaign.campaignId).subscribe(
      (response)=>{
        // console.log(response);
        this.dataSource = new MatTableDataSource(response);

    })
  }

  generatePackage(opportunity: string, fileName: string) {
    this.customApplicationAdminService.mergePOPackageDocuments(opportunity).subscribe(
      (blob: any) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'PO-' + fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error => {
        if (error.status === 409) {

          this.readBlobAsText(error.error).then((errorMessage: string) => {
            this.openErrorDialog(errorMessage);
          });

        } else {
          console.error(error);
        }
      },
      () => {
      }
    );
  }

  openErrorDialog(errorMessage: string): void {
    const dialogRef = this.dialog.open(PurchaseOrdersDialogComponent, {
      width: '400px',
      data: { message: errorMessage }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  readBlobAsText(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }

  sendEmailDialog(userData: any) {
    const dialogRef = this.dialog.open(SendMailComponent, {
      width: '400px',
      data: { data: userData }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('this is the result',result);

      if (result?.clicked === 'yes') {
        // console.log('User said yes');
        this.sendEmailAPI(result.data);
      } else if (result === 'no'|| result === undefined) {
        // console.log('User said no');
      } else {
        // console.log('Dialog closed without a clear choice');
      }
    });

  }


  sendEmailAPI(userData: any) {
    this.customApplicationAdminService.sendPOPackageEmail(userData.npe01__Opportunity__c, userData.PO_Account_Payable_Email__c)
      .subscribe(
    (res) => {
    },
    error => {
      if (error.status === 409) {

        this.sendEmailDialog(error);

      } else if(error.statusText === 'OK'){
        this.getContentVersion();
      } else {
        // console.error(error);
      }
    },
    () => {
      this.cdr.detectChanges();
    }
  );
}

  // sendEmailAPI(userData: any) {
  //   this.customApplicationAdminService.sendPOPackageEmail('0065f00000M67nhAAB', 'daniel.vretovski@inellipse.com')
  //     .subscribe(
  //       (res) => {
  //       },
  //       error => {
  //         if (error.status === 409) {

  //          this.sendEmailDialog(error);

  //         } else if(error.statusText === 'OK'){
  //           this.getContentVersion();
  //         } else {
  //           // console.error(error);
  //         }
  //       },
  //       () => {
  //         this.cdr.detectChanges();
  //       }
  //     );
  // }





}
