import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../lang.service';
import { NgForm } from '@angular/forms';
import { OrganisationControllerService, OrganisationDTO, SalesforceOrganisationDTO } from 'projects/swagger-api/src';

@Component({
  selector: 'app-invalid-code',
  templateUrl: './invalid-code.component.html',
  styleUrls: ['./invalid-code.component.css']
})
export class InvalidCodeComponent implements OnInit {
  loading: boolean = false;
  organizations: OrganisationDTO[] = [];
  selectedLanguage: string;
  submitClicked = false;
  loginError:boolean = false;


  constructor(
    private organizationService: OrganisationControllerService,
    private router: Router,
    private translateService: TranslateService,
    private langService: LangService,
  ) {}


  getTranslatedText(): string {
    return this.translateService.instant('errorCode');
  }

  ngOnInit(){
    this.getTranslatedText();
    // this.getOrganizations();
  }

  selectLang(lang: string) {
    this.langService.selectLang(lang);
    this.selectedLanguage = lang;
  }

  
  // getOrganizations() {
  //   this.organizationService.getAllOrganisations().subscribe(
  //     (organizations) => {
  //       console.log(organizations);
        
  //       this.organizations = organizations;
  //     },
  //     (error) => {
  //       console.error('Error retrieving organizations:', error);
  //     }
  //   );
  // }

updateUrl(form: NgForm): void {
  this.submitClicked = true;
  if (form.valid) {
    this.loading = true;
    const enteredCode = form.value.code;

    this.organizationService.getOrganisationByCode(enteredCode).subscribe(
      (matchingOrganization: SalesforceOrganisationDTO) => {
        const fullUrl = `/?code=${enteredCode}`; // Construct the full URL

        this.router.navigateByUrl(fullUrl)
          .then(() => {
            // console.log('Navigation successful');
          })
          .catch(error => {
            // console.error('Navigation failed:', error);
          });
      },
      (error: any) => {
        // console.error('Invalid code entered', error);
        this.loginError = true;
        this.loading = false;
      }
    );
  }
}


  openFacebookPage(): void {
    window.open('https://www.facebook.com/CoolCulture/', '_blank');
  }
  openInstagramPage(): void {
    window.open('https://www.instagram.com/coolculture/', '_blank');
  }
  openTwitterPage(): void {
    window.open('https://twitter.com/coolculture', '_blank');
  }
  openYoutubePage(): void {
    window.open('https://www.youtube.com/channel/UCMFLyEf4U6-ARLff5kQkaqw', '_blank');
  }

}
