import { ChangeDetectorRef, Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationAdminControllerService, ApplicationRacialDto, DashboardAdminControllerService, DashboardApplication, DashboardApplicationStatus, DashboardOrganisationsWithNoApplications, DashboardStaffApplication, OrganisationDTO, PageDashboardApplication, PageDashboardOrganisationsWithNoApplications } from 'projects/swagger-api/src';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexPlotOptions,
  AnnotationLabel,
  ApexAnnotations,
  ApexOptions,
  ApexNoData
} from "ng-apexcharts";
import { Observable, Subject, forkJoin, takeUntil } from 'rxjs';
import { LoadingService } from '../loading-spinner/loading.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ContextDataService } from '../services/context-data.service';







@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnDestroy {
  private onDestroy: Subject<any> = new Subject();

  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatPaginator) paginator2: MatPaginator;

  @ViewChild("chart") chart: ChartComponent;



  loading:boolean = false;
  displayedColumns1: string[] = ['organization','approved', 'declined', 'underReview', 'totalRegistrations'];
  displayedColumns2: string[] = ['organization', 'emailPrincipal', 'emailCulturalLiaison', 'emailBookKeeper', 'emailSubmitter', 'link'];

  selectedValues: OrganisationDTO = { organisationId: null, opportunityId: null , organisationName: 'All'};
  originalOrganizations: OrganisationDTO[] = [{ organisationName: 'All', organisationId: null, opportunityId: null }];
  dataSource1: MatTableDataSource<DashboardApplication> = new MatTableDataSource<DashboardApplication>();
  dataSource2: MatTableDataSource<DashboardOrganisationsWithNoApplications> = new MatTableDataSource<DashboardOrganisationsWithNoApplications>();

  retrievedOrganizations: OrganisationDTO[];
  startDate: Date | null = null;
  endDate: Date | null = null;
  sortOrder: 'ASC' | 'DESC' = 'DESC';
  totalRegistrations: number = 0;
  totalChildren: number = 0;
  globalValues;
  initialOrder:boolean = true;
  updatedOganizationName: string = '';
  InputCopyValue: OrganisationDTO[];
  displayLoanerPassesNumber: number = 0;
  isXsScreen: boolean = false;

paginator1PageIndex: number = 0;
paginator1PageSize: number = 25;
paginator1TotalLength: number;

// For the second table
paginator2PageIndex: number = 0;
paginator2PageSize: number = 25;
paginator2TotalLength: number;

  chartOptions1: Partial<ApexOptions>;
  chartOptions2: Partial<ApexOptions>;
  chartOptions3: Partial<ApexOptions>;
  chartOptions4: Partial<ApexOptions>;

  private noDataOptions: ApexNoData = {
    text: "No data to display",
    align: "left",
    verticalAlign: 'middle',
    style: {
      color: "#008ffb",
      fontSize: '13px',
    }
  };

  constructor(private getUniqueOrganizations: ApplicationAdminControllerService,
    private dashboardAdminController: DashboardAdminControllerService,
    private applicationAdminController: ApplicationAdminControllerService,
    private changeDetectorRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private breakpointObserver: BreakpointObserver,
    private contextDataService: ContextDataService
    ){

      this.chartOptions1 = {
        series: [],
        chart: {
          type: "pie",
          width: 455,
          height: 455
        },
        noData: this.noDataOptions,
        labels: [],
        dataLabels: {
          enabled: true,
          formatter: function (val:number, opts) {
            // console.log('this is value',val, 'this is opts', opts);
            var percentage = parseFloat(opts.w.globals.seriesPercent[opts.seriesIndex]);

            if (!isNaN(percentage)) {
              var wholeNumber = opts.w.config.series[opts.seriesIndex];
              return wholeNumber + ' (' + percentage.toFixed(1) + '%)';
            } else {
              return 'Invalid Percentage';
            }
              },
          // offsetX: 300,
          // offsetY: 300,
          textAnchor: "middle",
          style:{
            fontSize:"16px"
          }
        },
        // annotations:
        //   {
        //     texts:[{
        //         x: 50,
        //         y: 50,
        //         text: "Staff members vs. Parents/Guardians"
        //       }]
        //   },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 295,
                height: 295
              },
              legend: {
                position: "bottom"
              },
            }
          }
        ]
      };


      this.chartOptions2 = {
        series: [],

        chart: {
          type: "pie",
          width: 430,
          height: 430
         },
        noData: this.noDataOptions,
        labels: [],
        dataLabels: {
          enabled: true,
          formatter: function (val:number, opts) {
            // console.log('this is value',val, 'this is opts', opts);
            var percentage = parseFloat(opts.w.globals.seriesPercent[opts.seriesIndex]);

            if (!isNaN(percentage)) {
              var wholeNumber = opts.w.config.series[opts.seriesIndex];
              return wholeNumber + ' (' + percentage.toFixed(1) + '%)';
            } else {
              return 'Invalid Percentage';
            }
              },
          // offsetX: 300,
          // offsetY: 300,
          textAnchor: "middle",
          style:{
            fontSize:"16px"
          }
        },
        // plotOptions:{
        //   pie:{
        //     donut:{
        //       labels:{
        //         show: true,
        //         total: {
        //           show: true,
        //           showAlways:true,
        //           fontSize:'18px'
        //         }
        //       }
        //     }
        //   }
        // },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
                height: 300
              },
              legend: {
                position: "bottom"
              },
            }
          }
        ]
      };

      this.chartOptions3 = {
        series: [],
        chart: {
          type: "pie",
          width: 470,
          height: 470
         },
        noData: this.noDataOptions,
        labels: [],
        dataLabels: {
          enabled: true,
          formatter: function (val:number, opts) {
            // console.log('this is value',val, 'this is opts', opts);
            var percentage = parseFloat(opts.w.globals.seriesPercent[opts.seriesIndex]);

            if (!isNaN(percentage)) {
              var wholeNumber = opts.w.config.series[opts.seriesIndex];
              return wholeNumber + ' (' + percentage.toFixed(1) + '%)';
            } else {
              return 'Invalid Percentage';
            }
              },
          // offsetX: 300,
          // offsetY: 300,
          textAnchor: "middle",
          style:{
            fontSize:"16px"
          }
        },
        // plotOptions:{
        //   pie:{
        //     donut:{
        //       labels:{
        //         show: true,
        //         total: {
        //           show: true,
        //           showAlways:true,
        //           fontSize:'18px'
        //         }
        //       }
        //     }
        //   }
        // },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 359,
                height: 359
              },

              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };

      this.chartOptions4 = {
        series: [],
        chart: {
          type: "pie",
          width: 485,
          height: 485
         },
        noData: this.noDataOptions,
        labels: [],
        dataLabels: {
          enabled: true,
          formatter: function (val:number, opts) {
            // console.log('this is value',val, 'this is opts', opts);
            var percentage = parseFloat(opts.w.globals.seriesPercent[opts.seriesIndex]);

            if (!isNaN(percentage)) {
              var wholeNumber = opts.w.config.series[opts.seriesIndex];
              return wholeNumber + ' (' + percentage.toFixed(1) + '%)';
            } else {
              return 'Invalid Percentage';
            }
              },
          // offsetX: 300,
          // offsetY: 300,
          textAnchor: "middle",
          style:{
            fontSize:"16px"
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 340,
                height: 340
              },

              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };

    }

  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe(result => {
      this.isXsScreen = result.matches;
    });

    this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
			next: response => {
        if (response) {
          setTimeout(() => {
            this.getAllOrganizations();
            this.getDashboardTableInfo();
            this.getOrganizationStaffNumberPie();
            this.getApplicationStatusPie();
            this.getAllChildrenNumber();
            this.getIncomeRangeForPie();
            this.getRacialInfoPie();
            this.getOrganizationsWithoutApplications();
            this.getLoanerPassesNumber();
            }, 1000);
        }
			}
		});
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
		this.onDestroy.next('onDestroy');
	}

  @HostListener('document:click')
  clickOnDocument() {
    this.loading = false;
  }

  public displayProperty(organisation: OrganisationDTO): string {
    if (organisation) {
        return organisation.organisationName;
    }
    return '';
}

onPageChangeFirstTable(event: PageEvent) {
  this.paginator1PageIndex = event.pageIndex;
  this.paginator1PageSize = event.pageSize;
  this.getDashboardTableInfo(); // Load data for the first table
  this.getOrganizationsWithoutApplications(); // Load data for the second table

}

onPageChangeSecondTable(event: PageEvent) {
  this.paginator2PageIndex = event.pageIndex;
  this.paginator2PageSize = event.pageSize;
  this.getDashboardTableInfo(); // Load data for the first table
  this.getOrganizationsWithoutApplications(); // Load data for the second table
}



  toggleSortOrder() {
    this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    this.paginator1PageIndex = 0;
    this.initialOrder = false;
    this.getDashboardTableInfo();
  }

  onOrganizationChange(newValue: any){

    this.selectedValues = newValue;
    // console.log('Selected organization:', newValue);
    // console.log('Selected organization:', this.selectedValues);


    if (typeof newValue === "string") {
      let filterValue = newValue.toLowerCase();
      this.InputCopyValue = this.originalOrganizations.filter(item => item.organisationName.toLowerCase().includes(filterValue));
    }

    else {

      this.paginator1PageIndex = 0;
      this.paginator2PageIndex = 0;
      this.getDashboardTableInfo();
      this.getOrganizationStaffNumberPie();
      this.getApplicationStatusPie();
      this.getAllChildrenNumber();
      this.getIncomeRangeForPie();
      this.getRacialInfoPie();
      this.getLoanerPassesNumber();
    }
  }

  getOrganizationsWithoutApplications(){
    this.dashboardAdminController.getDashboardOrganisationsWithoutApplications(
      this.contextDataService.contextData.activeCampaign.campaignId,
        this.selectedValues.organisationId,
        this.paginator2PageIndex,
        this.paginator2PageSize,
        "ASC").subscribe(
          (response: PageDashboardOrganisationsWithNoApplications) => {
            // console.log('dashboard data with 0 registrations',response);

            if (response && response.content) {
              this.dataSource2.data = response.content;
              this.paginator2TotalLength = response.totalElements;
            }
          },
          error => {
            // console.error('Error fetching dashboard data:', error);
          },
          () => {
          }
        );
    }
  getDashboardTableInfo(){
    this.dashboardAdminController.getDashboardApplicationsInDateRangeAndOrgId(
      this.contextDataService.contextData.activeCampaign.campaignId,
      this.startDate,
      this.endDate,
      this.selectedValues.organisationId,
      this.paginator1PageIndex,
      this.paginator1PageSize,
      this.sortOrder
      ).subscribe(
      (response: PageDashboardApplication) => {
      // console.log('dashboard data',response);

      if (response && response.content) {
        this.dataSource1.data = response.content;
        this.paginator1TotalLength = response.totalElements;

        // this.totalRegistrations = response.content
        // .map(contentItem => contentItem.totalRegistrations)
        // .reduce((accumulator, totalRegistration) => accumulator + totalRegistration, 0);

      // console.log('Total Registrations:', this.totalRegistrations);
      }
    },
    error => {
      // console.error('Error fetching dashboard data:', error);
    },
    () => {
    }
  );
  }

  getRacialInfoPie() {
    this.dashboardAdminController.getRacialRegistrations(
      this.contextDataService.contextData.activeCampaign.campaignId,
      this.selectedValues.organisationId, this.startDate, this.endDate).subscribe(
      (racialData: ApplicationRacialDto) => {
        // console.log('racial data',racialData);

        const labelMapping = {
          'nativeAmerican': 'Native American',
          'asian': 'Asian',
          'africanAmerican': 'African American',
          'latino': 'Latino',
          'nativeAmericanPacific': 'Native American Pacific',
          'white': 'White',
          'preferNotToSay': 'Prefer not to say'
        };

        let chartData;
        let chartLabels;

        if (racialData && Object.keys(racialData).every(key => racialData[key] === null)) {
          chartData = [];
          chartLabels = ['No Race/Ethnicity in that range'];
        } else {
          chartData = Object.values(racialData);
          chartLabels = Object.keys(racialData).map(key => labelMapping[key]);
        }

        this.chartOptions4 = {
          ...this.chartOptions4,
          series: chartData,
          labels: chartLabels
        };
      },
      error => {
        console.error('Error fetching racial registrations data:', error);
      },
      () => {
      }
    );
  }


  getIncomeRangeForPie(){
    this.dashboardAdminController.countFamiliesByIncomeRange(
      this.contextDataService.contextData.activeCampaign.campaignId,
      this.startDate, this.endDate, this.selectedValues.organisationId).subscribe(
      (income)=>{
        // console.log(income);

        let chartData = [];
        let chartLabels = [];


        if (Object.keys(income).length === 0) {
          chartData = [];
          chartLabels = ['No Incomes in that range'];
        } else {
          const desiredOrder = [
            "Less than $40,000",
            "$40,000 -$49,999",
            "$50,000-$59,000",
            "$50,000-$59,999",
            "$60,000-$69,000",
            "$60,000-$69,999",
            "$70,000 -$79,000",
            "$70,000-$79,000",
            "$80,000-$89,000",
            "$90,000 - $99,000",
            "$90,000-$99,000",
            "$100,000 - $150,000",
            "$150,000 or more"
          ];

          chartLabels = desiredOrder.filter(label => income[label] !== undefined);

          chartData = chartLabels.map(label => income[label]);
        }

        this.chartOptions3 = {
          ...this.chartOptions3,
          series: chartData,
          labels: chartLabels,
        };

    })
  }


  getOrganizationStaffNumberPie() {
    this.dashboardAdminController.getDashboardStaffApplicationsNumber(
      this.contextDataService.contextData.activeCampaign.campaignId,
      this.startDate, this.endDate, this.selectedValues.opportunityId).subscribe(
      (response: DashboardStaffApplication) => {
        // console.log('staff members yes/no',response);

        this.globalValues = response;
        // console.log(this.globalValues,'this is global');

        let chartData;
        let chartLabels;

        if (response && response.yes === 0 && response.no === 0) {
          chartData = [];
          chartLabels = ['No members in that range'];
        } else {
          chartData = [response.yes, response.no];
          chartLabels = ['Staff members', 'Parents/Guardians'];
        }

        this.chartOptions1 = {
          ...this.chartOptions1,
          series: chartData,
          labels: chartLabels
        };
      },
      error => {
        console.error('Error fetching staff applications data:', error);
      },
      () => {
      }
    );
  }

  getApplicationStatusPie(){
    this.dashboardAdminController.getDashboardStaffApplicationsStatuses(
      this.contextDataService.contextData.activeCampaign.campaignId,
      this.startDate, this.endDate, this.selectedValues.opportunityId).subscribe(
      (response: DashboardApplicationStatus)=>{
        // console.log('application statuses',response);

        let chartData;
        let chartLabels;

        if (Object.keys(response).length === 0) {
          this.totalRegistrations = 0;
          chartData = [];
          chartLabels = ['No Status in that range'];
        } else {
          this.totalRegistrations = response.isStatusApproved + response.isStatusDeclined + response.isStatusInUnderReview;
          chartData = [response.isStatusApproved, response.isStatusDeclined, response.isStatusInUnderReview];
          chartLabels = ['Approved', 'Declined', 'Under Review'];
        }

        this.chartOptions2 = {
          ...this.chartOptions2,
          series: chartData,
          labels: chartLabels
        };
      },
      error => {
        console.error('Error fetching staff applications data:', error);
      },
      () => {
      }
    );
  }


  getAllChildrenNumber(){
    this.dashboardAdminController.childrenNumberInDateRangeByOrgId(
      this.contextDataService.contextData.activeCampaign.campaignId,
      this.startDate, this.endDate, this.selectedValues.organisationId).subscribe(
      (childrenData: number)=>{
        // console.log('children data',childrenData);
        this.totalChildren = childrenData;
      })
  }


  getAllOrganizations(){
    this.getUniqueOrganizations.getUniqueOrganisationIds(this.contextDataService.contextData.activeCampaign.campaignId,).subscribe(
      (response: OrganisationDTO[]) => {
        // console.log(response, "this is the organizations get");

          this.retrievedOrganizations = response;
          this.originalOrganizations = [{ organisationName: 'All', organisationId: null, opportunityId: null }, ...response];

          this.originalOrganizations.sort((a, b) => a.organisationName.localeCompare(b.organisationName));
          this.InputCopyValue = this.originalOrganizations;
        },
      error => {
        // console.error('Error fetching organizations:', error);
      },
      () => {
      }
    );
  }

  getLoanerPassesNumber(){
    this.dashboardAdminController.getLoanerPassesNumberInDateRangeAndOrgId(
      this.contextDataService.contextData.activeCampaign.campaignId,
      this.startDate, this.endDate, this.selectedValues.organisationId).subscribe(
      (response: number)=>{
        // console.log(response);

        this.displayLoanerPassesNumber = response;

    })
  }

  onSearch() {
    this.paginator1PageIndex = 0;
    this.paginator2PageIndex = 0;

    this.getDashboardTableInfo();
    this.getOrganizationStaffNumberPie();
    this.getApplicationStatusPie();
    this.getAllChildrenNumber();
    this.getIncomeRangeForPie();
    this.getRacialInfoPie();
    this.getLoanerPassesNumber();
  }

}
