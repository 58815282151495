<div class="col-lg-10 col-md-9 col-sm-8 p-0" hidden>
    <header class="p-3 mb-3 border-bottom">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-3">
        <!-- Breadcrumb navigation -->
        <!-- Dropdown menu -->
        <div class="dropdown text-end">
          <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <img [src]="selectedLanguage === 'en' ? 'assets/flags/united-kingdom.png' : 'assets/flags/republic-of-macedonia.png'" alt="mdo" width="32" height="32" class="rounded-circle" />
          </a>
          <ul class="dropdown-menu text-small">
            <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" (click)="selectLang('mk')">MK</a></li>
            <li>
              <hr class="dropdown-divider"/>
            </li>
            <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" (click)="selectLang('en')">EN</a></li>
          </ul>
        </div>
      </div>
    </header>
  </div>
<div class="container">
    <h1>{{'TermsAndConditionsTitle' | translate}}</h1>
    <br>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet proin fermentum leo vel. Nibh nisl condimentum id venenatis a condimentum vitae. Molestie at elementum eu facilisis. Ullamcorper sit amet risus nullam. Sem nulla pharetra diam sit amet. Nisl vel pretium lectus quam id leo. Porttitor rhoncus dolor purus non enim praesent. Massa enim nec dui nunc mattis enim ut. Mi bibendum neque egestas congue quisque egestas. Non consectetur a erat nam at lectus urna duis convallis.

        Interdum posuere lorem ipsum dolor sit. Pulvinar elementum integer enim neque volutpat ac tincidunt vitae. At elementum eu facilisis sed odio morbi quis. Tellus elementum sagittis vitae et leo duis. A erat nam at lectus. Vel quam elementum pulvinar etiam. Elit ullamcorper dignissim cras tincidunt. Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at. Viverra aliquet eget sit amet tellus cras. Eu turpis egestas pretium aenean. At tellus at urna condimentum mattis pellentesque id nibh tortor. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum. Nisl pretium fusce id velit. Ac ut consequat semper viverra nam libero justo laoreet sit. Consectetur a erat nam at. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Faucibus purus in massa tempor nec feugiat nisl. Egestas diam in arcu cursus euismod quis.
        
        Erat nam at lectus urna duis. Id volutpat lacus laoreet non curabitur gravida. Ac tortor dignissim convallis aenean et. A condimentum vitae sapien pellentesque habitant. Cras tincidunt lobortis feugiat vivamus at. At auctor urna nunc id cursus metus aliquam. Lacus vestibulum sed arcu non odio. Augue mauris augue neque gravida in fermentum. Tristique senectus et netus et malesuada fames ac turpis. Non enim praesent elementum facilisis leo vel. Egestas congue quisque egestas diam in. Pretium nibh ipsum consequat nisl. Felis eget velit aliquet sagittis. Vitae congue eu consequat ac felis donec et odio pellentesque. Cras semper auctor neque vitae tempus. Risus commodo viverra maecenas accumsan lacus vel facilisis volutpat. Nibh sed pulvinar proin gravida hendrerit lectus a. Tellus in metus vulputate eu scelerisque felis imperdiet. Tincidunt vitae semper quis lectus nulla at volutpat. Nibh tellus molestie nunc non.
        
        In nibh mauris cursus mattis molestie a iaculis at. At ultrices mi tempus imperdiet nulla. Egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Viverra mauris in aliquam sem fringilla ut morbi tincidunt augue. Nisl nisi scelerisque eu ultrices vitae auctor eu. Facilisis leo vel fringilla est ullamcorper eget nulla. Vulputate enim nulla aliquet porttitor. Tempor id eu nisl nunc mi. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Vitae aliquet nec ullamcorper sit amet risus nullam eget felis. Non pulvinar neque laoreet suspendisse interdum consectetur libero. Justo nec ultrices dui sapien eget. Eget gravida cum sociis natoque penatibus et magnis. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare.
        
        Venenatis cras sed felis eget velit aliquet sagittis id. Dui vivamus arcu felis bibendum ut tristique et egestas quis. Tincidunt praesent semper feugiat nibh. Lectus quam id leo in vitae turpis massa sed. Porta non pulvinar neque laoreet. Eget arcu dictum varius duis at. A diam maecenas sed enim ut sem viverra aliquet. Sed enim ut sem viverra aliquet eget sit. Amet cursus sit amet dictum sit amet. Eu consequat ac felis donec et odio pellentesque diam volutpat.</p>

        <div class="checkbox">
            <mat-checkbox>{{'TermsAndConditionsAgree' | translate}}</mat-checkbox>
        </div> 
        
        <div class="button-row">
            <button mat-raised-button class="full-width-button" [routerLink]="targetLink">{{'Next' | translate}}</button>
        </div>

</div>
   

