<div class="flex-container">
    <div class="container-xs px-3">
      <app-loading-spinner *ngIf="loading"></app-loading-spinner>



      <div class="row justify-content-center">
        <div class="col pt-4 pb-2">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef class="justify-content-start col-4 col-lg-4">Title</mat-header-cell>
              <mat-cell *matCellDef="let element" class="justify-content-start col-4 col-lg-4">{{element.Title}}</mat-cell>
            </ng-container>
              
              <ng-container matColumnDef="uploadDate">
                <mat-header-cell *matHeaderCellDef class="justify-content-center col-4 col-lg-4 text-center"> Upload Date </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-center col-4 col-lg-4"> {{element.CreatedDate | date:'dd-MM-yyyy HH:mm'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="link">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-4 col-lg-4"> Link </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-4 col-lg-4"> 
                    <a [href]="'https://coolculture.lightning.force.com/lightning/r/ContentDocument/' + element.ContentDocumentId + '/view'" target="_blank"
                        style="display: inline-block; word-wrap: break-word; max-width: 100%;">
                            {{element.ContentDocumentId}}
                    </a>
                     </mat-cell>
              </ng-container>

              
        
          
            <!-- Header row -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          
            <!-- Table rows -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <!-- <mat-paginator class="mat-elevation-z8" #paginator [pageSize]="[50]" [pageSizeOptions]="[25, 50, 100]" [pageSize]="paginator.pageSize" [pageIndex]="paginator.pageIndex" [length]="paginator.length" (page)="onPageChange($event)"></mat-paginator> -->

        </div>
      </div>

  </div>
  


  