import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-export-confirmation-dialog',
  templateUrl: './export-confirmation-dialog.component.html',
  styleUrls: ['./export-confirmation-dialog.component.css']
})
export class ExportConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ExportConfirmationDialogComponent>,
  ) { }
}
