import { CookieService } from 'ngx-cookie-service';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LangService } from 'src/app/lang.service';

@Component({
  selector: 'app-welcome-back',
  templateUrl: './welcome-back.component.html',
  styleUrls: ['./welcome-back.component.css']
})
export class WelcomeBackComponent {

  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  selectedLanguage: string;

  constructor(private langService: LangService, private cookieService: CookieService) {}

  
  ngOnInit() {
    this.selectedLanguage = this.langService.getSelectedLanguage();
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    }
  }

  selectLang(lang: string) {
    this.langService.selectLang(lang);
    this.selectedLanguage = lang;
}
}
