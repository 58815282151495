import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  envName = environment.name;
  title = 'coolculture';
  currentApplicationVersion = environment.appVersion;

  constructor() {
    // console.log(this.currentApplicationVersion);

  }
}
