<div class="container main-container">
  <h2 mat-dialog-title class="title">Confirm delete</h2>
  <mat-dialog-content class="mat-typography pb-0">
    <div class="container">
      <div class="row gy-3">
        <div class="col-12">
          <p>  Are you sure you want to delete this item?
          </p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">No</button>
    <button mat-flat-button color="warn" (click)="onYesClick()">Yes</button>
  </mat-dialog-actions>
</div>

