<div class="flex-container">
    <div class="container-xs px-3 d-flex justify-content-center">
      <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  
      <ng-container *ngIf="userRole === 'ORGANISATION_ADMIN'">
        <div class="row d-flex justify-content-between">
          <div class="col-12 d-flex flex-column-reverse align-items-start gap-3">
            <ng-container *ngIf="deliveryResponseFiltered">
              <div *ngFor="let trackingItem of deliveryResponseFiltered; let i = index" class="col-12">
                <mat-card *ngIf="trackingItem">
                  <mat-card-content>
                    <div class="d-flex flex-wrap">
                      <label for="" class="d-flex">Status: <p class="d-flex justify-content-end align-items-end ps-3 mb-0 custom-font">{{ trackingItem.statusSummary }}</p></label>
                      <!-- <label for="" class="ps-3 pt-1 custom-font">{{ trackingItem.statusSummary }}</label> -->
                    </div>
    
                    <div class="pt-3">
                      <label for="" class="">Tracking Number:</label>
                      <label for="" class="ps-3 custom-font">{{ trackingItem.trackingNumber }}</label>
                    </div>
    
                    <div class="d-flex justify-content-start align-items-center">
                      <label for="" class="">Check details</label>
                      <button mat-icon-button (click)="toggleAdditionalDetails(i)">
                        <mat-icon>{{ showAdditionalDetails[i] ? 'expand_less' : 'expand_more' }}</mat-icon>
                      </button>
                    </div>
    
                    <div class="" *ngIf="showAdditionalDetails[i]">
                      <div *ngFor="let trackingEvent of trackingItem.trackingEvents; let j = index">
                        <div>
                          <!-- <label for="" class="custom-font">Location:</label> -->
                          <label for="" class="ps-3">{{ trackingEvent.eventType }}</label>
                        </div>
                        <div>
                          <!-- <label for="" class="custom-font">Time:</label> -->
                          <label for="" class="ps-3">{{ formatTimestamp(trackingEvent.eventTimestamp) }}</label>
                      </div>
                        <br>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="userRole === 'ADMIN'">
        <div class="col col-md-6 col-lg-5 d-flex align-items-center">
          <mat-card class="card-size col-12">
              <mat-card-content>

                <!-- <ng-container *ngIf="!uploadedFile"> -->
                  <div class="row">

                    <div class="col pb-0">
                      <div class="d-flex justify-content-center">
                        <h1 for="" class="fs-3">Upload tracking number document</h1>
                      </div>
                      <input type="file" class="file-input pt-2" (change)="onFileSelected($event)" #fileUpload hidden>

                      <div class="file-upload pt-2 d-flex justify-content-center align-items-center">
          
                        <div>
                          <button mat-mini-fab color="primary" class="upload-btn"
                          (click)="fileUpload.click()">
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
          
                        <div class="d-flex justify-content-center ps-2">
                          {{this.fileName || "No file uploaded yet."}}
                        </div>
          
                      </div>
                    </div>

                </div>


                <!-- </ng-container> -->

                <ng-container *ngIf="uploadedFile">
                  <div class="d-flex justify-content-evenly align-items-center">
                    <label class="text-center fs-4">You have successfully uploaded the file </label>
                    <!-- <button class="custom-button-size" (click)="this.resetPage()" mat-raised-button color="warn"><i class="fa-solid fa-arrows-rotate"></i></button> -->
                  </div>
                </ng-container>

              </mat-card-content>
          </mat-card>

      </div>
      </ng-container>
    </div>
  </div>
  