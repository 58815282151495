import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-orders-dialog',
  templateUrl: './purchase-orders-dialog.component.html',
  styleUrls: ['./purchase-orders-dialog.component.css']
})
export class PurchaseOrdersDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
    // console.log(data);
    
  }

}
