import { Component, ViewChild } from '@angular/core';
import { LoadingService } from '../loading-spinner/loading.service';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationAdminControllerService, NotificationDTO, PageNotificationDTO, PlaceAdminControllerService, PlaceDTO } from 'projects/swagger-api/src';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent {


  notificationForm: FormGroup;
  displayedColumns: string[] = ['notificationType', 'title', 'message','link'];
  dataSource: MatTableDataSource<NotificationDTO> = new MatTableDataSource<NotificationDTO>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  loading:boolean = false;
  selectedType: string | null = null;
  places: PlaceDTO[];

  options = [
    { name: 'Info', value: 'INFO' },
    { name: 'Place', value: 'PLACE' },
    { name: 'Link', value: 'LINK' }
  ];
  

  constructor(
    private loadingService: LoadingService, 
    private placesService: PlaceAdminControllerService,
    private notificationsService: NotificationAdminControllerService,
    private formBuilder: FormBuilder,
    ){}


  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.initForm();
    this.getPlaces();
    this.getNotifications();
  }

  initForm(){
    this.notificationForm = this.formBuilder.group({
      dataId: [''],
      notificationType: ['', Validators.required  ],
      topic: ['cool-culture-general'],
      title: ['', Validators.required],
      message: ['', Validators.required],
      link: ['']
    })}

  getPlaces(){
    this.placesService.getPlaces1().subscribe(
      (result)=>{
        // console.log(result);
        this.places = result;
    })
  }

  getNotifications(){
    this.notificationsService.getAllNotifications1(
      this.paginator.pageIndex,
      this.paginator.pageSize).subscribe(
      (page: PageNotificationDTO)=>{
        // console.log('notifications log', page);
        
        if (page && page.content) {
          this.dataSource.data = page.content;
          this.paginator.length = page.totalElements;
        }
    })
  }


  onPageChange() {
    this.getNotifications();
  }

  onSend(){
    if (this.notificationForm.valid) {
      const formData = this.notificationForm.value;

      Object.keys(formData).forEach((key) => {
        if (typeof formData[key] === 'string') {
          formData[key] = formData[key].trim();
        }
      });
  
      this.notificationsService.createNotification(formData).subscribe(
        (response) => {
          // console.log('Notification created successfully:', response);
          this.paginator.pageIndex = 0;
          this.notificationForm.reset();
          this.getNotifications();
        },
        (error) => {
          console.error('Error creating notification:', error);
        }
      );
    } else {
      // console.log('Form is invalid');
    }
  }


}
