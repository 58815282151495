<div class="container main-container">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12 pt-4 pb-4">
            <div class="error-dialog-content">
                <h1>Error</h1>
                <p>{{ data.message }}</p>
              </div>
          </div>
        </div>
      </div>
  </div>
  