import { Component } from '@angular/core';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent {

  loading:boolean = true;

  onIframeLoad(): void {
    this.loading = false; // Set loading to false when the iframe is fully loaded
  }
}
