import { TranslateService } from '@ngx-translate/core';
import { LangService } from './../lang.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreadcrumbService } from './breadcrumb.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { ApplicationAdminControllerService, OrganisationControllerService, UserAdminControllerService, UserDTO } from 'projects/swagger-api/src';
import { ContextDataService } from '../services/context-data.service';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-admin-panel',
	templateUrl: './admin-panel.component.html',
	styleUrls: ['./admin-panel.component.css'],
})
export class AdminPanelComponent implements OnInit, OnDestroy {
	private onDestroy: Subject<any> = new Subject();

	breadcrumbData: string[] = [];
	selectedLanguage: string = 'en';
	isOrgAdmin: boolean = false;
	userRole: string;
	campaignFormControl: FormControl = new FormControl({});

	constructor(
		private router: Router,
		private breadcrumbService: BreadcrumbService,
		private langService: LangService,
		private cookieService: CookieService,
		private translate: TranslateService,
		private httpClient: HttpClient,
		private adminService: ApplicationAdminControllerService,
		private userAdminController: UserAdminControllerService,
		public contextDataService: ContextDataService
	) {}

	ngOnInit(): void {
		this.contextDataService.getAllCampaigns();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.updateBreadcrumb();
			}
		});

		if (this.router.url === '/admin-panel') {
			this.breadcrumbData = ['Dashboard'];
		} else {
			this.updateBreadcrumb();
		}

		const storedLanguage = localStorage.getItem('selectedLanguage');
		if (storedLanguage) {
			this.selectedLanguage = storedLanguage;
		}

		this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
			next: response => {
        if (response === 'data loaded') {
          this.campaignFormControl.setValue(this.contextDataService.contextData.activeCampaign);
          this.userAdminController.getLoggedInUser1().subscribe(
				(user: UserDTO) => {
					this.userRole = user.role;
					if (user.role === 'USER') {
						this.showUserView();
					} else if (user.role === 'ADMIN') {
						this.showAdminView();
					} else if (user.role === 'ORGANISATION_ADMIN') {
						this.showOrgAdminView();
					}
				},
				error => {
					// console.error('Error fetching user information:', error);
				});
        }
			},
		});
	}

	ngOnDestroy(): void {
		this.onDestroy.next('onDestroy');
	}

	showUserView(): void {}

	showAdminView(): void {
		this.isOrgAdmin = true;
	}

	showOrgAdminView(): void {}

	updateBreadcrumb(): void {
		const currentRoute = this.router.url;
		this.breadcrumbService.updateBreadcrumb(currentRoute);
		const breadcrumbItems = this.breadcrumbService.getBreadcrumbData();

		// Create an array of promises for the breadcrumb translations
		const translationPromises = breadcrumbItems.map((item: string) => this.translate.get(item).toPromise());

		// Wait for all the translations to complete using Promise.all
		Promise.all(translationPromises).then((translatedItems: string[]) => {
			this.breadcrumbData = translatedItems;
		});
	}

	selectLang(lang: string) {
		this.langService.selectLang(lang);
		this.selectedLanguage = lang;
		localStorage.setItem('selectedLanguage', lang);
		this.updateBreadcrumb();
	}

	logout(): void {
		// Clear access token and refresh token cookies and language cookie
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('selectedLanguage');

		this.router.navigate(['/login']);
	}

	openSupportForm() {
		const iframe = document.createElement('iframe');
		iframe.src = 'https://forms.monday.com/forms/embed/1cab9600076eac89a25f292dcbe8d05e?r=use1';
		iframe.width = '650';
		iframe.height = '500';
		iframe.style.border = '0';
		iframe.style.boxShadow = '5px 5px 56px 0px rgba(0,0,0,0.25)';

		// Append the iframe to the body
		document.body.appendChild(iframe);
	}
}
