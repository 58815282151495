<div class="container main-container">
    <h2 mat-dialog-title class="title px-4">Error deleting item</h2>
    <mat-dialog-content class="mat-typography p-0">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12 px-4">
            <p>  {{data.message}}
            </p>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>