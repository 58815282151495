import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationAdminControllerService, ApplicationDTO, PageApplicationDTO, SalesforceAdminControllerService, SalesforceOrganisationDTO, UserAdminControllerService, UserDTO, SalesforceOrganization, OrganisationDTO, OpportunityStatusResponse, Body2 } from 'projects/swagger-api/src';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApplicationsEditDialogComponent } from './applications-edit-dialog/applications-edit-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplicationsDetailsDialogComponent } from './applications-details-dialog/applications-details-dialog.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { LoadingService } from '../loading-spinner/loading.service';
import { CustomApplicationAdminControllerService } from 'custom-api/customAdminController.service';
import { ContextDataService } from '../services/context-data.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';



@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit, OnDestroy {
	private onDestroy: Subject<any> = new Subject();

  // status: string= 'UNDER_REVIEW';
  displayedColumns: string[] = ['adult','childName', 'grade', 'teacherName', 'email', 'organization','staffMember','printedFamilyName', 'createdDate', 'status', 'actions'];
  dataSource: MatTableDataSource<ApplicationDTO> = new MatTableDataSource<ApplicationDTO>();
  retrievedOrganizations: OrganisationDTO[];
  originalOrganizations: OrganisationDTO[] = [{ organisationName: 'All', organisationId: null, opportunityId: null }];
  selectedValues: OrganisationDTO = { organisationId: null, opportunityId: null , organisationName: 'All'};
  statusOptions: string[] = ['All', 'UNDER_REVIEW', 'APPROVED', 'DECLINED'];
  selectedStatus: string = 'All';
  selectedOpportunityId: string = null;
  selectedOrganizationId: string = null;
  // userRole: string = '';
  userRole: string | undefined;

  loading: boolean = false;
  searchText: string = '';
  selectedElement: ApplicationDTO;
  isXsScreen: boolean = false;
  InputCopyValue: any[];
  sortOrder: 'ASC' | 'DESC' = 'DESC';
  selectedLabelDate: boolean;
  selectedLabelName: boolean;
  selectedLabelClass: boolean;
  initialOrderName: boolean = true;
  initialOrderClass: boolean = true;
  initialOrderDate: boolean = true;
  stepperOrientation: 'horizontal' | 'vertical' = 'horizontal';
  fileNamePartnershipAgreement: string;
  fileNamePurchaseOrder: string;
  // sortOrderByName: 'ASC' | 'DESC' = 'DESC';
  // sortOrderByDate: 'ASC' | 'DESC' = 'DESC';
  modifiedFileName: string;
  sortBy: string = 'createdDate';
  opportunityStatus: OpportunityStatusResponse | undefined;
  selectedIndex = 0;
  completedSteps: boolean[] = [false, false, false]; // Assuming you have 3 steps




  @ViewChild('copyLinkButton') copyLinkButton: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminService: ApplicationAdminControllerService,
    // private organization: OrganisationAdminControllerService,
    public dialog: MatDialog,
    private salesForceOrganization: SalesforceAdminControllerService,
    private userAdminController: UserAdminControllerService,
    private getUniqueOrganizations: ApplicationAdminControllerService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
    private applicationAdminService: ApplicationAdminControllerService,
    private loadingService: LoadingService,
    private customApplicationAdminService: CustomApplicationAdminControllerService,
    private contextDataService: ContextDataService
  ) {}

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .subscribe(result => {
      if (result.matches) {
        // Screen size is small or extra small
        this.stepperOrientation = 'vertical';
      } else {
        // Screen size is medium, large, or extra large
        this.stepperOrientation = 'horizontal';
      }
    });

    this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
			next: response => {
        if (response) {
          this.getUserInfo();
          this.getAllOrganizations();

          // if(this.userRole === "ORGANISATION_ADMIN"){
            // this.getOpportunityStatus();
          // }
        }
			}
		});

    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe(result => {
      this.isXsScreen = result.matches;
    });
  }

  ngAfterViewInit() {

    this.changeDetectorRef.detectChanges();

  }

  ngOnDestroy(): void {
		this.onDestroy.next('onDestroy');
	}

  public displayProperty(organisation: OrganisationDTO): string {
    if (organisation) {
        return organisation.organisationName;
    }
    return '';
}

getOpportunityStatus(){
  // console.log('this user',this.userRole);

  this.applicationAdminService.opportunityStatus('body').subscribe(response => {
    // console.log('opportunity status',response);


    this.opportunityStatus = response;



    // const mockupData: any = {
    //   "enrolmentApplicationDecision": "Eligible",
    //   "partnershipAgreementAccepted": true,
    //   "invoicePaymentStatus": {
    //     "paidAmount": 150,
    //     "totalAmount": 100
    //   },
    //   "familyPassDistributionProjectedDate": "2023-12-31"
    // };

    // this.opportunityStatus = mockupData;

    this.updateStepperIndex(response);

  });
}


updateStepperIndex(response: any) {
  // if (response.enrolmentApplicationDecision === 'Eligible') {
  //   this.completedSteps[0] = true; // Mark Step 1 as completed
  // }

  // if (response.partnershipAgreementAccepted === true) {
  //   this.completedSteps[1] = true; // Mark Step 2 as completed
  // }

  // if (response.invoicePaymentStatus.paidAmount >= response.invoicePaymentStatus.totalAmount) {
  //   this.completedSteps[2] = true; // Mark Step 3 as completed
  //   // Mark all previous steps as completed
  //   this.completedSteps[0] = true;
  //   this.completedSteps[1] = true;
  // }

  // // Determine the next step based on the conditions
  // if (this.completedSteps[2]) {
  //   this.selectedIndex = 2; // Move to Step 3 if Step 3 is completed
  // } else if (this.completedSteps[1]) {
  //   this.selectedIndex = 1; // Move to Step 2 if Step 2 is completed
  // } else {
  //   this.selectedIndex = 0; // Default to Step 1
  // }
}





  copyLink() {
    const linkToCopy = `https://registration.coolculture.org?code=${this.selectedOpportunityId}`;
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.setAttribute('value', linkToCopy);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    alert('Link copied to clipboard');
  }

  openLink(event: Event): void {
    // Prevent the default behavior of the Enter key press
    event.preventDefault();

    // Get the URL from the selectedOpportunityId variable and open it in a new tab
    window.open(this.selectedOpportunityId, '_blank');
  }

  getUserInfo(){
    this.userAdminController.getLoggedInUser1().subscribe(
      (user: UserDTO) => {
        // console.log(user.role);
        this.userRole = user.role;
        // console.log(this.userRole);

        if(user.role === "ORGANISATION_ADMIN"){
          this.selectedOpportunityId = user.opportunityId;
          this.selectedOrganizationId = user.orgId;

          // this.InputCopyValue = [...this.originalOpportinities, user];
          // this.originalOpportinities = [...this.originalOpportinities, user];
          this.getOpportunityStatus();


          // console.log(this.selectedOpportunityId);

          this.getAllApplications();
        } else{
          this.getAllApplications();
        }
      },
      (error) => {
        // console.error('Error fetching user information:', error);
      }
    );
  }

  getAllApplications() {
    const statusToSend = this.selectedStatus === 'All' ? '' : this.selectedStatus;

    // if (this.paginator) {
      this.adminService.getApplicationsByStatusAndSearch(
        this.contextDataService.contextData.activeCampaign.campaignId,
        statusToSend,
        this.searchText,
        this.selectedOpportunityId,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.sortBy,
        this.sortOrder,
        'Family'
      ).subscribe(
        (page: PageApplicationDTO) => {
          // console.log("this is the page log", page);

          if (page && page.content) {
            this.dataSource.data = page.content;
            this.paginator.length = page.totalElements;
          }
        },
        error => {
          // console.error('Error fetching applications:', error);
        },
        () => {
        }
      );
    // }
  }

  onSearch() {
    this.paginator.pageIndex = 0,
    this.getAllApplications();
  }

  onPageChange(event: any) {
    this.getAllApplications();
  }

  onStatusChange() {
    this.paginator.pageIndex = 0;
    this.getAllApplications();
    this.getAllOrganizations();
  }

  onOrganizationChange(newValue: any) {

    this.selectedOpportunityId = newValue.opportunityId;

    if (typeof newValue === "string") {
      let filterValue = newValue.toLowerCase();
      this.InputCopyValue = this.originalOrganizations.filter(item => item.organisationName.toLowerCase().includes(filterValue));
    }

    else{
      // console.log('Selected organization:', newValue);

    this.paginator.pageIndex = 0;
    this.getAllApplications();
    }
  }


  getAllOrganizations(){
    this.getUniqueOrganizations.getUniqueOrganisationIds(this.contextDataService.contextData.activeCampaign.campaignId).subscribe(
      (response: OrganisationDTO[]) => {
        // console.log(response, "this is the organizations get");

          this.retrievedOrganizations = response;
          this.originalOrganizations = [{ organisationName: 'All', organisationId: null, opportunityId: null }, ...response];

          // console.log(this.originalOrganizations);

          this.originalOrganizations.sort((a, b) => a.organisationName.localeCompare(b.organisationName));
          this.InputCopyValue = this.originalOrganizations;

        // console.log(this.retrievedOrganizations, "this is the organizations array");
      },
      error => {
        // console.error('Error fetching organizations:', error);
      },
      () => {
        // This is the complete callback, it runs after all data has been fetched.
      }
    );
  }




  editApplicationStatus(applicationId: string, currentStatus: string) {
    const dialogRef = this.dialog.open(ApplicationsEditDialogComponent, {
      width: '400px',
      data: { applicationId, currentStatus, statusOptions: this.statusOptions },
    });

    dialogRef.afterClosed().subscribe((newStatus: string) => {
      if (newStatus !== undefined && newStatus.trim() !== '') {
        if (newStatus === 'DECLINED') {
          const confirmDialogRef = this.dialog.open(ConfirmDeleteComponent, {
            width: '400px',
            data: 'Are you sure you want to decline this application?',
          });

          confirmDialogRef.afterClosed().subscribe((confirmationResult: boolean) => {
            if (confirmationResult) {
              this.updateApplicationStatus(applicationId, newStatus);
            } else {
            }
          });
        } else {
          this.updateApplicationStatus(applicationId, newStatus);
        }
      } else {
      }
    });
  }

  updateApplicationStatus(applicationId: string, newStatus: string) {
    this.adminService.updateApplicationStatus(applicationId, newStatus).subscribe({
      next: (result: any) => {
        // Successfully updated application status, now refresh the list of applications
        this.getAllApplications();
      },
      error: (error: any) => {
        if (error.status === 409) {
          this.openErrorDialog('You cannot approve this application because another application with the same email already exists');
        }
        // console.error('Error updating application status:', error);
      },
    });
  }

  openErrorDialog(message: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '500px',
      // height: '200px',
      data: { message: message },
    });
  }



  details(element: ApplicationDTO) {
    // console.log(element);

    const dialogRef = this.dialog.open(ApplicationsDetailsDialogComponent, {
      data: { element: element },
    });

    dialogRef.afterClosed().subscribe(changesMade => {
      if (changesMade) {
        this.getAllApplications();
      }
    });
  }

  toggleSortByDate() {
    this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    this.selectedLabelDate = true;
    this.selectedLabelName = false;
    this.selectedLabelClass = false;
    this.initialOrderName = true;
    this.initialOrderDate = false;
    this.initialOrderClass = true;
    this.sortBy = 'createdDate'
    this.paginator.pageIndex = 0,
    this.getAllApplications();
  }

  toggleSortByName() {
    this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    this.selectedLabelDate = false;
    this.selectedLabelName = true;
    this.selectedLabelClass = false;
    this.initialOrderName = false;
    this.initialOrderDate = true;
    this.initialOrderClass = true;
    this.sortBy = 'name'
    this.paginator.pageIndex = 0,

    this.getAllApplications();
  }

  toggleSortByClass() {
    this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    this.selectedLabelDate = false;
    this.selectedLabelName = false;
    this.selectedLabelClass = true;
    this.initialOrderName = true;
    this.initialOrderDate = true;
    this.initialOrderClass = false;
    this.sortBy = 'class'
    this.paginator.pageIndex = 0,

    this.getAllApplications();
  }



  isNullOrEmpty(value: string | null | undefined): boolean {
    return value === null || value === undefined || value.trim() === '';
  }


  onFileSelectedPurchaseOrder(event: any): void {
    // console.log('hey');

    const file = event.target.files[0];
    // console.log('this is the file', file);

    this.fileNamePurchaseOrder = file.name;

    if (file) {
      // console.log('this is the file', file);

      // let formData: FormData = new FormData();
      // console.log('Before appending:', formData);

      // formData.append('file', file, file.name);

      // console.log('After appending:', formData);


      // You can now send the formData to your server using HttpClient
      this.applicationAdminService.getOpenAIDataForm(this.opportunityStatus.paymentId, file)
        .subscribe(response => {
          // Handle the response from the server
          // console.log('File uploaded successfully:', response);
        }, error => {
          // Handle errors
          console.error('Error uploading file:', error);
        });
    }
  }

  onFileSelectedPartnershipAgreement(event: any): void {
    const file = event.target.files[0]; // Assuming you allow selecting only one file

    this.fileNamePartnershipAgreement = file.name
      // console.log(file.name);

      this.modifiedFileName = file.name.replace('.pdf', '');
      // const opportunityId: string = 'yourOpportunityId'; // Set your opportunityId

      this.applicationAdminService.createContentFileForm(this.selectedOpportunityId,this.modifiedFileName, file)
        .subscribe(
          (response) => {
            // console.log('File uploaded successfully:', response);
            // Add any logic you want to perform after successful upload
            this.getOpportunityStatus();
          },
          (error) => {
            console.error('Error uploading file:', error);
            // Handle the error, show a message, etc.
          }
        );

  }


 downloadFile(event: any){
  // console.log('hel',event);
  const id = event.id;
  const fileName = event.title;

  // console.log(id, fileName);


  this.customApplicationAdminService.downloadFile3(id,fileName)
  .subscribe(
    (response:any) => {
      // console.log('File downloaded successfully:', response);
      // Add any logic you want to perform after successful upload
      const blobUrl = URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = fileName;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
    },
    (error) => {
      console.error('Error downloading file:', error);
      // Handle the error, show a message, etc.
    }
  );

 }


}



