<div class="col-lg-10 col-md-9 col-sm-8 p-0" hidden>
  <header class="p-3 mb-3 border-bottom">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-3">
      <!-- Breadcrumb navigation -->
      <!-- Dropdown menu -->
      <div class="dropdown text-end">
        <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <img [src]="selectedLanguage === 'en' ? 'assets/flags/united-kingdom.png' : 'assets/flags/republic-of-macedonia.png'" alt="mdo" width="32" height="32" class="rounded-circle" />
        </a>
        <ul class="dropdown-menu text-small">
          <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" (click)="selectLang('mk')">MK</a></li>
          <li>
            <hr class="dropdown-divider"/>
          </li>
          <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" (click)="selectLang('en')">EN</a></li>
        </ul>
      </div>
    </div>
  </header>
</div>
<div class="container">
    <h1 class="loggin-title">{{'registerTitle' | translate}}</h1>

    <div class="button-row">
      <button mat-raised-button class="full-width-button" routerLink="first-timer/terms-and-conditions">{{'registerFirstTimer' | translate}}</button>
    </div>

    <div class="button-row">
        <button mat-raised-button class="full-width-button" routerLink="coming-back-user/terms-and-conditions">{{'registerComingBackUser' | translate}}</button>
      </div>
</div>
