import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResourcesAddNewDialogComponent } from './resources-add-new-dialog/resources-add-new-dialog.component';
import { ResourcesAdminControllerService, ResourcesDTO } from 'projects/swagger-api/src';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { LoadingService } from '../loading-spinner/loading.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent {

  displayedColumns: string[] = ['name', 'description', 'image', 'url', 'actions'];
  loading:boolean = false;
  dataSource: MatTableDataSource<ResourcesDTO>;

  constructor(public dialog: MatDialog,
    private resourcesController: ResourcesAdminControllerService,
    private loadingService: LoadingService) {}

    openDialog(isEdit: boolean = false, resource?: ResourcesDTO): void {
      const dialogRef = this.dialog.open(ResourcesAddNewDialogComponent, {
        data: { resource, isEdit }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getAllResources();
        }
      });
    }


  ngOnInit(){

    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.getAllResources();
  }
  

  getAllResources(){
    this.resourcesController.getAllResources1().subscribe(
      (data: ResourcesDTO[]) => {
        // console.log(data);
        const mappedData = data.map(resources => ({
          id: resources.id,
          name: resources.name,
          description: resources.description,
          image: resources.image,
          url: resources.url,
          }));

      this.dataSource = new MatTableDataSource(mappedData);
      this.dataSource.data.sort((a, b) => a.id.localeCompare(b.id));       
      },
      error => {
        // console.error('Error fetching organizations:', error);
      }
    );
  }

  deleteResource(id: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent);
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.resourcesController.deleteResources(id).subscribe(
          (response: boolean) => {
            if (response) {
              // console.log('resource deleted successfully');
              this.getAllResources();
            } else {
              // console.error('Error deleting resource.');
            }
          },
          error => {
            // console.error('Error deleting resource:', error);
          }
        );
      }
    });
  }

  editResource(resource: ResourcesDTO) {
    const dialogRef = this.dialog.open(ResourcesAddNewDialogComponent, {
      data: { resource, isEdit: true }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the edited resource in the data source
        const editedIndex = this.dataSource.data.findIndex(item => item.id === result.id);
        if (editedIndex !== -1) {
          this.dataSource.data[editedIndex] = result;
          // Refresh the MatTableDataSource after updating the data
          this.dataSource.data = [...this.dataSource.data];
        }
      }
    });
  }
}
