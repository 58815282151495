  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <div class="flex-container">
    <div class="container-xs px-3">
      <div class="row justify-content-end">
        <div class="col col-auto">
          <button mat-raised-button color="warn" (click)="openDialog()">
            {{ 'AddnewResource' | translate }}
          </button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col pt-4">
                  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                      <ng-container matColumnDef="name">
                          <mat-header-cell *matHeaderCellDef class="col-3"> Name </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="col-3"> {{element.name}} </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="description">
                          <mat-header-cell *matHeaderCellDef class="col-2"> Description </mat-header-cell>
                          <mat-cell *matCellDef="let element" class="col-2"> {{element.description}} </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="image">
                            <mat-header-cell *matHeaderCellDef class="col-3"> Image </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="col-3">
                              <img [src]="element.image" alt="Image" style="max-width: 100px; max-height: 100px;">
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="url">
                            <mat-header-cell *matHeaderCellDef class="col-2"> Url </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="col-2"> {{element.url}} </mat-cell>
                          </ng-container>
                        
                          <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef class="justify-content-center col-2 p-0"> Actions </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-2">
                              <!-- <button mat-raised-button color="warn" class="full-width-button" (click)="editApplicationStatus(element.id)" [disabled]="element.status === 'DECLINED' || element.status === 'APPROVED'">
                                  {{ "changeStatus" | translate }}
                              </button>
                              <div class="px-2">   </div> -->
                              <button mat-raised-button class="full-width-button" color="warn"[matMenuTriggerFor]="menu">
                                  <div class="d-flex justify-content-center">
                                  <mat-icon>more_vert</mat-icon>
                                  </div>
                              </button>
                              <mat-menu #menu="matMenu">
                                  <div class="d-flex flex-column justify-content-between">

                                  <div class="d-flex justify-content-center p-2">
                                      <button mat-raised-button color="warn" class="full-width-button" (click)="deleteResource(element.id)">
                                      <!-- {{ "changeStatus" | translate }} -->
                                      <div class="d-flex justify-content-center align-items-center">
                                          <mat-icon>delete</mat-icon>
                                      </div>
                                      
                                      </button>                      </div>

                                  <!-- <div class="d-flex justify-content-center p-2">
                                      <button mat-raised-button color="warn" class="w-100">Export</button>
                                  </div> -->

                                  <div class="d-flex justify-content-center p-2">
                                      <button mat-raised-button class="full-width-button" color="warn"  (click)="editResource(element)">
                                      <!-- {{ "Details" | translate }} -->
                                      <div class="d-flex justify-content-center align-items-center">
                                          <mat-icon>edit</mat-icon>
                                      </div>
                                      </button>
                                  </div>

                                  
                                  </div>            
                              </mat-menu>
                              </mat-cell>
                          </ng-container>
            
                    <!-- Header row -->
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            
                    <!-- Table rows -->
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>

        </div>
      </div>
    </div>
  </div>

