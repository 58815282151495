import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LangService } from 'src/app/lang.service';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm: FormGroup;
  selectedLanguage: string;
  submitClicked: boolean = false;
  loginErrorMessage1: boolean = false;
  loginErrorMessage2: boolean = false;
  loading: boolean = false;


  constructor(private router: Router,
    private langService: LangService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cookieService: CookieService,
    private translate: TranslateService,
  ) { }


  ngOnInit(): void {
    this.selectedLanguage = this.langService.getSelectedLanguage();

    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    }

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  selectLang(lang: string) {
    this.langService.selectLang(lang);
    this.selectedLanguage = lang;
  }

  onEnterKey(): void {
    this.login();
  }


  login() {
    this.submitClicked = true;
    if (this.loginForm.valid) {
      this.loading = true;
      const email = this.loginForm.get('email').value;
      const password = this.loginForm.get('password').value;

      this.authService.login(email, password).subscribe(
        (user) => {
          this.loading = false;
          // Implement role-based routing logic based on userRole
          if (user.role === 'ADMIN') {
            this.router.navigate(['/admin-panel/registrations']);
            // console.log("admin logged in");
          } else if (user.role === 'USER') {
            this.router.navigate(['/admin-panel']);
            // console.log("user logged in");
          } else if (user.role === 'ORGANISATION_ADMIN') {
            this.router.navigate(['/admin-panel/registrations']);
            // console.log("organization logged in");
          } else {
            // Handle other roles or unknown roles
          }
        },
        (error) => {
          if (error.status === 409) {
            this.loginErrorMessage1 = true;
            // this.loginErrorMessage = '409 message received';
          } else {
            // this.loginError = true;
            this.loginErrorMessage2 = true;
          }
          this.loading = false;
        }
      );
    }
  }
}


