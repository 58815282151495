import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationAdminControllerService, ApplicationDTO } from 'projects/swagger-api/src';
import { POSITIONS } from 'src/app/positions-values/positions';

interface Genders {
  value: string;
  viewValue: string;
}
interface Boroughs {
  value: string;
  viewValue: string;
}

interface Languages{
  value: string;
  viewValue: string;
}
interface Incomes{
  value: string;
  viewValue: string;
}
interface Numbers {
  value: number;
  viewValue: string;
}
interface Choices{
  value: string;
  viewValue: string;
}
interface MuseumVisits{
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-applications-details-dialog',
  templateUrl: './applications-details-dialog.component.html',
  styleUrls: ['./applications-details-dialog.component.css']
})
export class ApplicationsDetailsDialogComponent {
  positions = POSITIONS;
  detailsForm: FormGroup;
  private positionTitleTranslation: any;
  loading: boolean = false;
  changesMade: boolean = false;
  isFormSubmitted: boolean = false;
  birthYears: string[] = [];
  canSubmit: boolean = true;


  constructor(
    public dialogRef: MatDialogRef<ApplicationsDetailsDialogComponent>,
    private translateService: TranslateService,
    private adminUpdateFormService: ApplicationAdminControllerService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  genders: Genders[] = [
    { value: 'Female', viewValue: 'Female' },
    { value: 'Male', viewValue: 'Male' },
    { value: 'Non-Binary', viewValue: 'Non-Binary' },
    { value: 'Nonconforming', viewValue: 'Nonconforming' },
    { value: 'Prefer not to answer', viewValue: 'PreferNotToAnswer' },
    { value: 'Other Gender Identities', viewValue: 'OtherGenderIdentities' },
  ];

  boroughs: Boroughs[] = [
    { value: 'Bronx', viewValue: 'Bronx' },
    { value: 'Manhattan', viewValue: 'Manhattan' },
    { value: 'Brooklyn', viewValue: 'Brooklyn' },
    { value: 'Queens', viewValue: 'Queens' },
    { value: 'Staten Island', viewValue: 'StatenIsland' },
  ];
  languages: Languages[] = [
    { value: 'Arabic', viewValue: 'language1' },
    { value: 'Bengali', viewValue: 'language2' },
    { value: 'Cantonese', viewValue: 'language3' },
    { value: 'English', viewValue: 'language4' },
    { value: 'French', viewValue: 'language5' },
    { value: 'Haitian Creole', viewValue: 'language6' },
    { value: 'Hebrew', viewValue: 'language7' },
    { value: 'Korean', viewValue: 'language8' },
    { value: 'Mandarin', viewValue: 'language9' },
    { value: 'Spanish', viewValue: 'language10' },
    { value: 'Russian', viewValue: 'language11' },
    { value: 'Urdu', viewValue: 'language12' },
    { value: 'Yiddish', viewValue: 'language13' },
    { value: 'Other', viewValue: 'other' },
  ];
  incomes: Incomes[] = [
    { value: 'Less than $40,000', viewValue: 'LessThan40000' },
    { value: '$40,000 -$49,999', viewValue: '$40,000-$49,000' },
    { value: '$50,000-$59,999', viewValue: '$50,000-$59,000' },
    { value: '$60,000-$69,999', viewValue: '$60,000-$69,000' },
    { value: '$70,000 -$79,000', viewValue: '$70,000-$79,000' },
    { value: '$80,000 - $89,000', viewValue: '$80,000-$89,000' },
    { value: '$90,000 - $99,000', viewValue: '$90,000-$99,000' },
    { value: '$100,000 - $150,000', viewValue: '$100,000-$150,000' },
    { value: '$150,000 or more', viewValue: '150000orMore' }
  ];
  numbers: Numbers[] = [
    { value: 0, viewValue: '0' },
    { value: 1, viewValue: '1' },
    { value: 2, viewValue: '2' },
    { value: 3, viewValue: '3' },
  ];
  museumVisits: MuseumVisits[] = [
    { value: "0", viewValue: 'TimesVisitedMuseum0' },
    { value: "1 time", viewValue: 'TimesVisitedMuseum1' },
    { value: "2 times", viewValue: 'TimesVisitedMuseum2' },
    { value: "3 or more times", viewValue: 'TimesVisitedMuseum3' },
  ];

  choices: Choices[] = [
    { value: 'YES', viewValue: 'Yes' },
    { value: 'NO', viewValue: 'No' },
    { value: 'MAYBE', viewValue: 'Maybe' },
  ];

  ngOnInit(){
    const newData = this.data.element;

    for (let year = 1940; year <= 2005; year++) {
      this.birthYears.push(`${year}-01-01T12:00:00`);
    }
    // console.log("form value raw",newData);

    this.initForm();
    
    // console.log("form value after initForm", this.detailsForm.value);

    const haveChildrenControl = this.detailsForm.get('family.adults.0.haveChildren');
    const initialChildFirstName = this.detailsForm.get('family.children.0.firstName');

    if(this.detailsForm.get('family.adults.0.gender')){
      this.detailsForm.get('family.adults.0.gender').valueChanges.subscribe((value)=>{
        if(value !== 'Other Gender Identities'){
          this.detailsForm.get('family.adults.0.genderCustom').setValue(null);
        }
      })
    }

    if(this.detailsForm.get('family.adults.1.gender')){
      this.detailsForm.get('family.adults.1.gender').valueChanges.subscribe((value)=>{
        if(value !== 'Other Gender Identities'){
          this.detailsForm.get('family.adults.1.genderCustom').setValue(null);
        }
      })
    }
    



    if (haveChildrenControl.value === false) {
      this.detailsForm.get('family.children.0.firstName').setValidators(null);
      this.detailsForm.get('family.children.0.firstName').setValue(null);
      this.detailsForm.get('family.children.0.lastName').setValidators(null);
      this.detailsForm.get('family.children.0.lastName').setValue(null);
      this.detailsForm.get('family.children.0.gradeProgram').setValidators(null);
      this.detailsForm.get('family.children.0.gradeProgram').setValue(null);
      this.detailsForm.get('family.children.0.classroom').setValidators(null);
      this.detailsForm.get('family.children.0.classroom').setValue(null);
      this.detailsForm.get('family.children.0.teacher').setValidators(null);
      this.detailsForm.get('family.children.0.teacher').setValue(null);
      this.detailsForm.get('family.numOfChildren').setValidators(null);
      this.detailsForm.get('family.numOfChildren').setValue(0);

    } else if(haveChildrenControl.value === true){

      this.detailsForm.get('family.children.0.firstName').setValidators([Validators.required]);
      this.detailsForm.get('family.children.0.lastName').setValidators([Validators.required]);
      this.detailsForm.get('family.children.0.gradeProgram').setValidators([Validators.required]);
      this.detailsForm.get('family.children.0.classroom').setValidators([Validators.required]);
      this.detailsForm.get('family.children.0.teacher').setValidators([Validators.required]);
      this.detailsForm.get('family.numOfChildren').setValidators([Validators.required]);
    }

    if (haveChildrenControl) {
      haveChildrenControl.valueChanges.subscribe((value) => {
        // console.log('Value changed:', value);

        if (!value) {
          this.detailsForm.get('family.children.0.firstName').setValidators(null);
          this.detailsForm.get('family.children.0.firstName').setValue(null);
          this.detailsForm.get('family.children.0.lastName').setValidators(null);
          this.detailsForm.get('family.children.0.lastName').setValue(null);
          this.detailsForm.get('family.children.0.gradeProgram').setValidators(null);
          this.detailsForm.get('family.children.0.gradeProgram').setValue(null);
          this.detailsForm.get('family.children.0.classroom').setValidators(null);
          this.detailsForm.get('family.children.0.classroom').setValue(null);
          this.detailsForm.get('family.children.0.teacher').setValidators(null);
          this.detailsForm.get('family.children.0.teacher').setValue(null);
          this.detailsForm.get('family.numOfChildren').setValidators(null);
          this.detailsForm.get('family.numOfChildren').setValue(0);

        } if(value){
          this.detailsForm.get('family.children.0.firstName').setValidators([Validators.required]);
          this.detailsForm.get('family.children.0.firstName').setValue(null);
          this.detailsForm.get('family.children.0.lastName').setValidators([Validators.required]);
          this.detailsForm.get('family.children.0.lastName').setValue(null);
          this.detailsForm.get('family.children.0.gradeProgram').setValidators([Validators.required]);
          this.detailsForm.get('family.children.0.gradeProgram').setValue(null);
          this.detailsForm.get('family.children.0.classroom').setValidators([Validators.required]);
          this.detailsForm.get('family.children.0.classroom').setValue(null);
          this.detailsForm.get('family.children.0.teacher').setValidators([Validators.required]);
          this.detailsForm.get('family.children.0.teacher').setValue(null);
          this.detailsForm.get('family.numOfChildren').setValidators([Validators.required]);
          this.detailsForm.get('family.numOfChildren').setValue(null);
        }
      });
    }
    this.detailsForm.get('family.adults.0.positionTitle').valueChanges.subscribe((positionTitle) => {
      if (positionTitle !== 'other') {
        this.detailsForm.get('family.adults.0.stuffPositionOther').setValue(null);
      }
    });
    // console.log("form value", this.detailsForm.value);



    const isMemberAtSchoolCenterControl = this.detailsForm.get('family.adults.0.isMemberAtSchoolCenter');
    if (isMemberAtSchoolCenterControl) {
      isMemberAtSchoolCenterControl.valueChanges.subscribe((value) => {
        if (!value) {
          const haveChildrenControl = this.detailsForm.get('family.adults.0.haveChildren');
          const assignedCoolCultureCulturalLiaisonControl = this.detailsForm.get('family.adults.0.assignedCoolCultureCulturalLiaison');
          const positionTitleControl = this.detailsForm.get('family.adults.0.positionTitle');
          
          const childForm = this.detailsForm.get('family.children.0');
          // Reset the values and set them as untouched
          assignedCoolCultureCulturalLiaisonControl.setValue(false);
          assignedCoolCultureCulturalLiaisonControl.clearValidators();
          assignedCoolCultureCulturalLiaisonControl.removeValidators([Validators.required]);
          assignedCoolCultureCulturalLiaisonControl.updateValueAndValidity();

          haveChildrenControl.setValue(true);

          positionTitleControl.setValue(null);
          positionTitleControl.clearValidators();
          positionTitleControl.removeValidators([Validators.required]);
          positionTitleControl.updateValueAndValidity();
          assignedCoolCultureCulturalLiaisonControl.markAsUntouched();
          positionTitleControl.markAsUntouched();

          childForm.get('firstName').setValue('');
          childForm.get('firstName').setValidators([Validators.required]);
          childForm.get('firstName').updateValueAndValidity();
          childForm.get('lastName').setValue('');
          childForm.get('lastName').setValidators([Validators.required]);
          childForm.get('lastName').updateValueAndValidity();
          childForm.get('gradeProgram').setValue('');
          childForm.get('gradeProgram').setValidators([Validators.required]);
          childForm.get('gradeProgram').updateValueAndValidity();
          childForm.get('classroom').setValue('');
          childForm.get('classroom').setValidators([Validators.required]);
          childForm.get('classroom').updateValueAndValidity();
          childForm.get('teacher').setValue('');
          childForm.get('teacher').setValidators([Validators.required]);
          childForm.get('teacher').updateValueAndValidity();
          // childForm.get('primaryLanguageSpokenByParent').setValue(null);
          childForm.get('primaryLanguageSpokenByParent').setValidators([Validators.required]);
          childForm.get('primaryLanguageSpokenByParent').updateValueAndValidity();
          // this.registrationForm.get('family.numOfChildren').setValue(null);
          this.detailsForm.get('family.numOfChildren').setValue(null);
          this.detailsForm.get('family.numOfChildren').setValidators([Validators.required]);
          this.detailsForm.get('family.numOfChildren').updateValueAndValidity();

        } if(value){
          const haveChildrenControl = this.detailsForm.get('family.adults.0.haveChildren');
          const positionTitleControl = this.detailsForm.get('family.adults.0.positionTitle');
          const assignedCoolCultureCulturalLiaisonControl = this.detailsForm.get('family.adults.0.assignedCoolCultureCulturalLiaison');

          // haveChildrenControl.setValue(null);

          positionTitleControl.setValue(null);
          positionTitleControl.setValidators([Validators.required]);
          positionTitleControl.updateValueAndValidity();

          assignedCoolCultureCulturalLiaisonControl.setValue(null);
          assignedCoolCultureCulturalLiaisonControl.setValidators([Validators.required]);
          assignedCoolCultureCulturalLiaisonControl.updateValueAndValidity();
        }
      });
    }



    this.translateService.get([
      'education1',
      'education2',
      'education3',
      'education4',
      'education5',
      'education6',
      'education7',
      'other',
      'position1',
      'position2',
      'position3',
      'position4',
      'position5',
      'position6',
      'position7',
    ]).subscribe((translations) => {

      this.positionTitleTranslation = {
        position1: translations['position1'],
        position2: translations['position2'],
        position3: translations['position3'],
        position4: translations['position4'],
        position5: translations['position5'],
        position6: translations['position6'],
        position7: translations['position7'],
      };
    });
    
  }

  childEthnicityCheckboxCheckedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const racialControls = [
        'racialAmericanIndianOrAlaskanNative',
        'racialAsian',
        'racialBlackOrAfricanAmerican',
        'racialLatinoOrLatinx',
        'racialNativeAmericanOrPacificIslander',
        'racialWhite',
        'racialPreferNotToAnswer',
      ];
  
      const anyRacialChecked = racialControls.some((controlName) => control.get(`family.${controlName}`).value === true);
  
      if (anyRacialChecked) {
        return null; // Valid: At least one checkbox is checked
      } else {
        return { childEthnicityNotChecked: true }; // Invalid: None of the checkboxes are checked
      }
    };
  }

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailPattern = /^[a-zA-Z0-9+]+@.+[.].+[a-zA-Z]{1,}$/;
      const valid = emailPattern.test(control.value);
      return valid ? null : { 'invalidEmail': { value: control.value } };
    };
  }

  childLanguageCheckboxCheckedValidator() {
    return (formGroup: FormGroup) => {
      const languages = [
        'otherLanguageArabic',
        'otherLanguageBengali',
        'otherLanguageCantonese',
        'otherLanguageEnglish',
        'otherLanguageFrench',
        'otherLanguageHaitianCreole',
        'otherLanguageHebrew',
        'otherLanguageKorean',
        'otherLanguageMandarin',
        'otherLanguageSpanish',
        'otherLanguageRussian',
        'otherLanguageUrdu',
        'otherLanguageYiddish',
        'otherLanguageOther',
      ];
  
      const atLeastOneLanguageSelected = languages.some((language) => formGroup.get(language).value === true);
  
      return atLeastOneLanguageSelected ? null : { atLeastOneLanguageRequired: true };
    };
  }

  
  initForm(): void{
    
    this.detailsForm = this.formBuilder.group({

      firstName: [this.data.element.family.adults[0].firstName],
      lastName: [this.data.element.family.adults[0].lastName],
      email: [this.data.element.family.adults[0].email],
      occupation: [this.data.element.occupation],
      familySize: [this.data.element.familySize],
      organisationName: [this.data.element.organisationName],
      opportunityId: [this.data.element.opportunityId],
      status: [this.data.element.status],
      language: [this.data.element.language],
      organisationId: [this.data.element.organisationId],
      family: this.formBuilder.group({
        numOfChildren: [this.data.element.family.numOfChildren],
        isVisitedEvent: [this.data.element.family.isVisitedEvent],
        timesVisitedEvent: [this.data.element.family.timesVisitedEvent],
        highestLevelEducation: [this.data.element.family.highestLevelEducation],
        currentHouseholdIncomeRange: [this.data.element.family.currentHouseholdIncomeRange],
        familyName: [this.data.element.family.familyName],
        isInterestedInDigitalCard: [this.data.element.family.isInterestedInDigitalCard],
        racialAmericanIndianOrAlaskanNative: [this.data.element.family.racialAmericanIndianOrAlaskanNative],
        racialAsian: [this.data.element.family.racialAsian],
        racialBlackOrAfricanAmerican: [this.data.element.family.racialBlackOrAfricanAmerican],
        racialLatinoOrLatinx: [this.data.element.family.racialLatinoOrLatinx],
        racialNativeAmericanOrPacificIslander: [this.data.element.family.racialNativeAmericanOrPacificIslander],
        racialWhite: [this.data.element.family.racialWhite],
        racialPreferNotToAnswer: [this.data.element.family.racialPreferNotToAnswer],
        visitedYear2017: [this.data.element.family.visitedYear2017],
        visitedYear2018: [this.data.element.family.visitedYear2018],
        visitedYear2019: [this.data.element.family.visitedYear2019],
        visitedYear2020: [this.data.element.family.visitedYear2020],
        visitedYear2021: [this.data.element.family.visitedYear2021],
        visitedYear2022: [this.data.element.family.visitedYear2022],
        visitedYear2023: [this.data.element.family.visitedYear2023],
        visitedYear2024: [this.data.element.family.visitedYear2024],
        visitedYear2025: [this.data.element.family.visitedYear2025],

        children: this.formBuilder.array([this.createChildForm()]),
        adults: this.formBuilder.array([
          this.createAdultForm(0),
          this.createAdultForm(1),
        ]),
      }, { validators: [familyValidator]}),
    }, { validators: [this.childEthnicityCheckboxCheckedValidator()]});


    this.detailsForm.get('status').value !== 'UNDER_REVIEW' ? this.detailsForm.disable() : this.detailsForm.enable();

    // console.log(this.data.element.status);
    
    if(this.data.element.status !== 'UNDER_REVIEW'){
      this.canSubmit = false;
    }
    
    function familyValidator(): ValidatorFn {
      return (formGroup: FormGroup): ValidationErrors | null => {
        const isVisitedEvent = formGroup.get('isVisitedEvent')?.value;
        const errors = formGroup.errors || {};
    
        if (!isVisitedEvent) {
          // If isVisitedEvent is false, remove the visitedYearCheckboxCheckedValidator error
          delete errors['noYearSelected'];
        }
    
        const checkboxFields = [
          'visitedYear2017',
          'visitedYear2018',
          'visitedYear2019',
          'visitedYear2020',
          'visitedYear2021',
          'visitedYear2022',
          'visitedYear2023',
        ];
    
        const isAtLeastOneChecked = checkboxFields.some((fieldName) =>
          formGroup.get(`${fieldName}`)?.value === true
        );
    
        if (isVisitedEvent && !isAtLeastOneChecked) {
          // If isVisitedEvent is true but no year is selected, add the noYearSelected error
          errors['noYearSelected'] = true;
        } else {
          // If at least one year is selected, remove the noYearSelected error
          delete errors['noYearSelected'];
        }
    
        return Object.keys(errors).length === 0 ? null : errors;
      };
    }
    // Add the familyValidator to the family FormGroup
    const familyFormGroup = this.detailsForm.get('family');
    if (familyFormGroup) {
      familyFormGroup.setValidators([familyValidator()]);
      familyFormGroup.updateValueAndValidity();
    }
    
    // Subscribe to changes in isVisitedEventControl to trigger the validation and update the visited years and timesVisitedEvent
    const isVisitedEventControl = this.detailsForm.get('family.isVisitedEvent');
    const timesVisitedEventControl = this.detailsForm.get('family.timesVisitedEvent');
    
    if (isVisitedEventControl && timesVisitedEventControl) {
      isVisitedEventControl.valueChanges.subscribe((value) => {
        if (!value) {
          // If isVisitedEvent is false, clear the validators for year checkboxes
          const familyFormGroup = this.detailsForm.get('family');
          if (familyFormGroup) {
            familyFormGroup.clearValidators();
            familyFormGroup.updateValueAndValidity();
          }
    
          // Set all visited years to false
          const checkboxFields = [
            'visitedYear2017',
            'visitedYear2018',
            'visitedYear2019',
            'visitedYear2020',
            'visitedYear2021',
            'visitedYear2022',
            'visitedYear2023',
          ];
    
          for (const fieldName of checkboxFields) {
            this.detailsForm.get(`family.${fieldName}`).setValue(false);
          }
    
          // Clear and reset validators for timesVisitedEvent
          timesVisitedEventControl.clearValidators();
          timesVisitedEventControl.setValue(null);
          timesVisitedEventControl.updateValueAndValidity();
        } else {
          // If isVisitedEvent is true, reapply the familyValidator
          const familyFormGroup = this.detailsForm.get('family');
          if (familyFormGroup) {
            familyFormGroup.setValidators([familyValidator()]);
            familyFormGroup.updateValueAndValidity();
          }
    
          // Set validators for timesVisitedEvent
          timesVisitedEventControl.setValidators([Validators.required]);
          timesVisitedEventControl.updateValueAndValidity();
        }
      });
    }
    // const haveChildrenControl = this.detailsForm.get('family.adults.0.haveChildren');
    // if (haveChildrenControl) {
    //   haveChildrenControl.valueChanges.subscribe((value) => {
    //     const childrenFormArray = this.detailsForm.get('family.children') as FormArray;
    
    //     if (value === false) {
    //       // If "No" is selected, remove the child form if it exists
    //       if (childrenFormArray.length > 0) {
    //         childrenFormArray.clear();
    //       }
    //     } else {
    //       // If "Yes" is selected, add one child form if it doesn't exist
    //       if (childrenFormArray.length === 0) {
    //         childrenFormArray.push(this.createChildForm());
    //       }
    //     }
    //   });
    // }

    

    
const adult0 = this.adultsForms.at(0) as FormGroup;
const adult1 = this.adultsForms.at(1) as FormGroup;
const fieldsToWatch = ['firstName', 'lastName', 'birthdate', 'gender', 'email', 'mobilePhone', 'boroughOfResidence'];

fieldsToWatch.forEach(fieldName => {
  adult0.get(fieldName).valueChanges.subscribe(() => {
    updateFamilySize();
  });

  adult1.get(fieldName).valueChanges.subscribe(() => {
    updateFamilySize();
  });
});

// Listen for changes in numOfChildren
const familyNumOfChildren = this.detailsForm.get('family.numOfChildren');
familyNumOfChildren.valueChanges.subscribe(() => {
  updateFamilySize();
});

// Function to update familySize
const updateFamilySize = () => {
  const numOfChildrenValue = familyNumOfChildren.value || 0;

  const familySizeControl = this.detailsForm.get('familySize');

  const isAdult0Filled = fieldsToWatch.every(field => {
    const value = adult0.get(field).value;
    return value !== '' && value !== null;
  });

  const isAdult1Filled = fieldsToWatch.every(field => {
    const value = adult1.get(field).value;
    return value !== '' && value !== null;
  });

  const newFamilySize = (isAdult0Filled ? 1 : 0) + (isAdult1Filled ? 1 : 0) + numOfChildrenValue;

  familySizeControl.setValue(newFamilySize);
};
  }

  

  createChildForm() {
    const firstChild = this.data.element.family.children[0];

    return this.formBuilder.group({
      firstName: [firstChild.firstName, Validators.required],
      lastName: [firstChild.lastName, Validators.required],
      gradeProgram: [firstChild.gradeProgram, Validators.required],
      classroom: [firstChild.classroom, Validators.required],
      teacher: [firstChild.teacher, Validators.required],
      primaryLanguageSpokenByParent: [firstChild.primaryLanguageSpokenByParent, Validators.required],
      otherLanguageArabic: [firstChild.otherLanguageArabic],
      otherLanguageBengali: [firstChild.otherLanguageBengali],
      otherLanguageCantonese: [firstChild.otherLanguageCantonese],
      otherLanguageEnglish: [firstChild.otherLanguageEnglish],
      otherLanguageFrench: [firstChild.otherLanguageFrench],
      otherLanguageHaitianCreole: [firstChild.otherLanguageHaitianCreole],
      otherLanguageHebrew: [firstChild.otherLanguageHebrew],
      otherLanguageKorean: [firstChild.otherLanguageKorean],
      otherLanguageMandarin: [firstChild.otherLanguageMandarin],
      otherLanguageSpanish: [firstChild.otherLanguageSpanish],
      otherLanguageRussian: [firstChild.otherLanguageRussian],
      otherLanguageUrdu: [firstChild.otherLanguageUrdu],
      otherLanguageYiddish: [firstChild.otherLanguageYiddish],
      otherLanguageOther: [firstChild.otherLanguageOther],
    }, { validators: this.childLanguageCheckboxCheckedValidator() });  
  }

  createAdultForm(index: number) {
    const firstAdult = this.data.element.family.adults[0] || {};
    const secondAdult = this.data.element.family.adults[1] || {};
    const adultData = this.data.element.family.adults[index] || {};
  
    const birthDate = adultData.birthdate ? (adultData.birthdate as unknown as string).slice(0, 4) + '-01-01T12:00:00' : null;
    const FormatedYear = birthDate ? (birthDate.slice(0, 4) + '-01-01T12:00:00') : null;
    
  
    return this.formBuilder.group({
      id: [index === 0 ? firstAdult.id : secondAdult.id],
      isMemberAtSchoolCenter: [index === 0 ? (firstAdult.isMemberAtSchoolCenter || false) : false],
      haveChildren: [index === 0 ? (firstAdult.haveChildren || false) : false],
      assignedCoolCultureCulturalLiaison: [index === 0 ? (firstAdult.assignedCoolCultureCulturalLiaison || false) : false],
      positionTitle: [index === 0 ? this.getValue(firstAdult.positionTitle) : null],
      firstName: [adultData.firstName || null],
      lastName: [adultData.lastName || null],
      birthdate: [FormatedYear || null],
      gender: [adultData.gender || null],
      email: [adultData.email || null],
      mobilePhone: [adultData.mobilePhone || null],
      boroughOfResidence: [adultData.boroughOfResidence || null],
      stuffPositionOther: [index === 0 ? (adultData.stuffPositionOther || null) : null],
      genderCustom: [adultData.gender === 'Other Gender Identities' ? (adultData.genderCustom || null) : null]
    });
  }
  



  get childrenForms() {
    return this.detailsForm.get('family.children') as FormArray;
  }

  get adultsForms() {
    return this.detailsForm.get('family.adults') as FormArray;
  }

  


  closeDialog(): void {
    this.dialogRef.close(this.changesMade);
  }

  getValue(positionValue: string){

    if(positionValue === 'Principals Assistant Principals and Centers Director'){
      return 'Principals, Assistant Principals, and Center’s Director'
    } else if(positionValue === 'Budget bookkeeping Managers'){
      return 'Budget/bookkeeping Managers';
    } else if(positionValue === '3K UPK Kindergarten Classroom Teachers Teaching Assistants and Paraprofessionals'){
      return '3K, UPK, Kindergarten Classroom Teachers, Teaching Assistants and Paraprofessionals';
    } else if(positionValue === '3K UPK Kindergarten Art Teachers Cluster Teachers and SEITs'){
      return '3K, UPK, Kindergarten Art Teachers, Cluster Teachers and SEITs';
    } else if(positionValue === '3K, UPK, Kindergarten Social Workers and Guidance Counselors'){
      return '3K, UPK, Kindergarten Social Workers and Guidance Counselors';
    }

    else {
      return positionValue;
    }
    
  }
  
  saveDialog() {
    this.loading = true;
    this.isFormSubmitted = true;
    this.changesMade = true;

        this.detailsForm.patchValue({
            firstName: this.detailsForm.get('family.adults.0.firstName').value,
            lastName: this.detailsForm.get('family.adults.0.lastName').value,
            email: this.detailsForm.get('family.adults.0.email').value,
            // isMemberAtSchoolCenter: this.detailsForm.get('family.adults.0.isMemberAtSchoolCenter').value
    });
    // console.log("patched first name",this.detailsForm.get('firstName').value);
    // console.log("patched last name",this.detailsForm.get('lastName').value);
    // console.log("patched email",this.detailsForm.get('email').value);

    // Check if the form is valid
    if (this.detailsForm.valid) {
      const updatedDataWithoutElement = { ...this.detailsForm.value };
      updatedDataWithoutElement.id = this.data.element.id;
  
      // console.log("Updated data", updatedDataWithoutElement);
  
      this.adminUpdateFormService.updateApplication1(updatedDataWithoutElement, updatedDataWithoutElement.id).subscribe(
        (response) => {
          // console.log('Updated successfully', response);
          this.loading = false;
          this.cdRef.detectChanges();
          this.closeDialog();
        },
        (error) => {
          // console.error('Error updating data', error);
          this.loading = false;
        }
      );
    } else {
      // console.error('Form is not valid');
      this.loading = false;
    }
  }
  
  


  getLanguageDisplayName(key: string): string {
    const languageOrder = [
      'otherLanguageArabic',
      'otherLanguageBengali',
      'otherLanguageCantonese',
      'otherLanguageEnglish',
      'otherLanguageFrench',
      'otherLanguageHaitianCreole',
      'otherLanguageHebrew',
      'otherLanguageKorean',
      'otherLanguageMandarin',
      'otherLanguageSpanish',
      'otherLanguageRussian',
      'otherLanguageUrdu',
      'otherLanguageYiddish',
      'otherLanguageOther'
    ];
  
    const index = languageOrder.indexOf(key);
    if (index !== -1) {
      if (index === languageOrder.length - 1) {
        // Check if any "otherLanguage" option is selected
        const isOtherLanguageSelected = languageOrder.some((languageKey) =>
          this.data.element.family.children[0][languageKey]
        );
        if (isOtherLanguageSelected) {
          return this.translateService.instant('other'); // Fetch the translated name for "other"
        } else {
          return null; // Return an empty string if no "otherLanguage" option is selected
        }
      } else {
        const displayKey = 'language' + (index + 1);
        return this.translateService.instant(displayKey); // Fetch the translated name
      }
    } else {
      return null;
    }
  }
  getSelectedLanguages(): string[] {
    const otherLanguagesSpokenByParent = this.data.element.family.children[0];
    const selectedLanguages = Object.keys(otherLanguagesSpokenByParent)
      .filter((key) => key !== 'primaryLanguageSpokenByParent' && otherLanguagesSpokenByParent[key])
      .map((key) => this.getLanguageDisplayName(key))
      .filter((language) => language !== null); // Filter out null values
    return selectedLanguages;
  }


  getPositionTranslation(positionTitle: string): string {
    const positionTitleTranslations = {
      "Principals Assistant Principals and Centers Director": "position1",
      "Early Childhood Educators and Assistants": "position2",
      "Parent Coordinators": "position3",
      "Budget/bookkeeping Managers": "position4",
      "3K UPK Kindergarten Classroom Teachers Teaching Assistants and Paraprofessionals": "position5",
      "3K UPK Kindergarten Art Teachers Cluster Teachers and SEITs": "position6",
      "3K UPK Kindergarten Social Workers and Guidance Counselors": "position7"
    };
    return positionTitleTranslations[positionTitle] || positionTitle; // Return the translation key or the original value
  }

  getHighestLevelEducationTranslation(highestLevelEducation: string): string {
    const highestLevelEducationTranslations = {
      "Less than 9th grade": "education1",
      "9th to 12th grade, no diploma": "education2",
      "High school graduate (incl. GED or alternative credential)": "education3",
      "Some college": "education4",
      "Associate's degree (i.e AA, AS)": "education5",
      "Bachelor's Degree (i.e BA, BS)": "education6",
      "Graduate or professional degree (i.e MA, MS, Ph.D, JD)": "education7",
      "Other": "other"
    };
    return highestLevelEducationTranslations[highestLevelEducation] || highestLevelEducation; // Return the translation key or the original value
  }

  getTimesVisitedEventTranslation(timesVisitedEvent: string): string {
    const TimesVisitedTranslations = {
      "0": "TimesVisitedMuseum0",
      "1 time": "TimesVisitedMuseum1",
      "2 times": "TimesVisitedMuseum2",
      "3 or more times": "TimesVisitedMuseum3"
    };
    return TimesVisitedTranslations[timesVisitedEvent] || timesVisitedEvent; // Return the translation key or the original value
  }

  getCurrentHouseholdIncomeRange(currentHouseholdIncomeRange: string): string {
    const highestLevelEducationTranslations = {
      "Less than $40,000": "LessThan40000",
      "$40,000 -$49,999": "$40,000-$49,000",
      "$50,000-$59,999": "$50,000-$59,000",
      "$60,000-$69,999": "$60,000-$69,000",
      "$70,000 -$79,000": "$70,000-$79,000",
      "$80,000 - $89,000": "$80,000-$89,000",
      "$90,000 - $99,000": "$90,000-$99,000",
      "$100,000 - $150,000": "$100,000-$150,000",
      "$150,000 or more": "150000orMore"

    };
    return highestLevelEducationTranslations[currentHouseholdIncomeRange] || currentHouseholdIncomeRange; // Return the translation key or the original value
  }

  getisInterestedInDigitalCardTranslation(isInterestedInDigitalCard: string): string {
    const isInterestedTranslations = {
      "YES": "Yes",
      "NO": "No",
      "MAYBE": "Maybe"
    };
    return isInterestedTranslations[isInterestedInDigitalCard] || isInterestedInDigitalCard; // Return the translation key or the original value
  }


  getBoroughTranslation(boroughOfResidence: string): string {
    const boroughTranslations = {
      "Bronx": "Bronx",
      "Manhattan": "Manhattan",
      "Brooklyn": "Brooklyn",
      "Queens": "Queens",
      "Staten Island": "StatenIsland"
    };
    return boroughTranslations[boroughOfResidence] || boroughOfResidence; // Return the translation key or the original value
  }

  getPrimaryLanguageTranslation(primaryLanguageSpokenByParentTranslation: string): string {
    const primaryLanguageTranslations = {
      "Arabic": "language1",
      "Bengali": "language2",
      "Cantonese": "language3",
      "English": "language4",
      "French": "language5",
      "Haitian Creole": "language6",
      "Hebrew": "language7",
      "Korean": "language8",
      "Mandarin": "language9",
      "Spanish": "language10",
      "Russian": "language11",
      "Urdu": "language12",
      "Yiddish": "language13",
      "Other": "other"
    };
    return primaryLanguageTranslations[primaryLanguageSpokenByParentTranslation] || primaryLanguageSpokenByParentTranslation; // Return the translation key or the original value
  }

  getSelectedEthnicities(): string[] {
    const ethnicities = this.data.element.family;
    const selectedEthnicities = [];

    const ethnicityTranslations = {
      racialAmericanIndianOrAlaskanNative: 'ethnicity1',
      racialAsian: 'ethnicity2',
      racialBlackOrAfricanAmerican: 'ethnicity3',
      racialLatinoOrLatinx: 'ethnicity4',
      racialNativeAmericanOrPacificIslander: 'ethnicity5',
      racialWhite: 'ethnicity6',
      racialPreferNotToAnswer: 'ethnicity7',
    };
  
    for (const key in ethnicities) {
      if (ethnicities[key] && ethnicityTranslations[key]) {
        selectedEthnicities.push(this.translateService.instant(ethnicityTranslations[key]));
      }
    }
  
    return selectedEthnicities;
  }
  
}
