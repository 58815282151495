import { Component } from '@angular/core';

@Component({
  selector: 'app-mark-as-printed-confirmation-dialog',
  templateUrl: './mark-as-printed-confirmation-dialog.component.html',
  styleUrls: ['./mark-as-printed-confirmation-dialog.component.css']
})
export class MarkAsPrintedConfirmationDialogComponent {

}
