import { Injectable, VERSION } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment.prod';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private version: string;

  constructor(private cookieService: CookieService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addRequestData(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            const newAccessToken = localStorage.getItem('accessToken');
            if (newAccessToken) {
              const clonedRequest = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${newAccessToken}`,
                  'x-platform': 'web',

                },
                // withCredentials: false,
              });
              return next.handle(clonedRequest);
            }
          }
        }
        return throwError(error);
      })
    );
  }

  private addRequestData(request: HttpRequest<unknown>) {
    let data = {
      setParams: {},
      headers: request.headers,
    };
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken')
    if (accessToken) {
      data.headers = request.headers.set('Authorization', 'Bearer ' + accessToken)
        .set('x-platform', 'web')
        .set('x-version', environment.appVersion);
      // data.setParams = { withCredentials: false }


    } else {
      data.headers = request.headers.set('x-platform', 'web')
        .set('x-version', environment.appVersion);
    }
    return request.clone(data);
  }


  // intercept(
  //     request: HttpRequest<any>,
  //     next: HttpHandler
  //   ): Observable<HttpEvent<any>> {
  //     const accessToken = localStorage.getItem('accessToken');
  //     const refreshToken = localStorage.getItem('refreshToken');


  //     // Clone the request and add authorization headers if access token exists
  //     if (accessToken) {
  //       let clonedRequest = request.clone({
  //         setHeaders: {
  //           Authorization: `Bearer ${accessToken}`,
  //           'x-platform': 'web',
  //         },
  //         withCredentials: false,
  //       });

  //       // // Optionally, add refresh token header if refresh token exists
  //       // if (refreshToken) {
  //       //   clonedRequest = clonedRequest.clone({
  //       //     setHeaders: {
  //       //       'x-refresh-token': refreshToken,
  //       //     },
  //       //   });
  //       // }

  //       return next.handle(clonedRequest);
  //     }

  //     return next.handle(request).pipe(
  //       catchError((error: HttpErrorResponse) => {
  //         if (error.status === 401) {

  //           // console.log('Unauthorized request. Redirecting to login page...');

  //           const newAccessToken = localStorage.getItem('accessToken');

  //           if (newAccessToken) {
  //             const clonedRequest = request.clone({
  //               setHeaders: {
  //                 Authorization: `Bearer ${newAccessToken}`,
  //                 'x-platform': 'web',

  //               },
  //               withCredentials: false,
  //             });
  //             return next.handle(clonedRequest);
  //           }
  //         }
  //         return throwError(error);
  //       })
  //     );
  //   }
}




