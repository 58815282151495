/**
 * COOL CULTURE API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 
 import { Observable }                                        from 'rxjs';
 
 import { environment } from 'src/environments/environment';
import { BASE_PATH, Configuration, OpportunityStatusResponse, OrganisationDTO, PageApplicationDTO } from 'projects/swagger-api/src';
import { CustomHttpUrlEncodingCodec } from 'projects/swagger-api/src/encoder';
 
 
 @Injectable()
 export class CustomApplicationAdminControllerService {
 
     protected basePath = environment.apiBaseUrl;
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
     /**
      * 
      * 
      * @param id 
      * @param fileName 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public downloadFile3(id: string, fileName: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
     public downloadFile3(id: string, fileName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
     public downloadFile3(id: string, fileName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
     public downloadFile3(id: string, fileName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling downloadFile3.');
         }
 
         if (fileName === null || fileName === undefined) {
             throw new Error('Required parameter fileName was null or undefined when calling downloadFile3.');
         }
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (id !== undefined && id !== null) {
             queryParameters = queryParameters.set('id', <any>id);
         }
         if (fileName !== undefined && fileName !== null) {
             queryParameters = queryParameters.set('fileName', <any>fileName);
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (bearerAuth) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             '*/*'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/v1/mgmt/applications/download-file`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress,
                 responseType: 'blob' as 'json'
             }
         );
     }





         /**
     * 
     * 
     * @param opportunityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mergePOPackageDocuments(opportunityId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public mergePOPackageDocuments(opportunityId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public mergePOPackageDocuments(opportunityId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public mergePOPackageDocuments(opportunityId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (opportunityId === null || opportunityId === undefined) {
            throw new Error('Required parameter opportunityId was null or undefined when calling mergePOPackageDocuments.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (opportunityId !== undefined && opportunityId !== null) {
            queryParameters = queryParameters.set('opportunityId', <any>opportunityId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/v1/mgmt/applications/po-package`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }


       /**
     * 
     * 
     * @param opportunityId 
     * @param poEmail 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
       public sendPOPackageEmail(opportunityId: string, poEmail: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
       public sendPOPackageEmail(opportunityId: string, poEmail: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
       public sendPOPackageEmail(opportunityId: string, poEmail: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
       public sendPOPackageEmail(opportunityId: string, poEmail: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
   
           if (opportunityId === null || opportunityId === undefined) {
               throw new Error('Required parameter opportunityId was null or undefined when calling sendPOPackageEmail.');
           }
   
           if (poEmail === null || poEmail === undefined) {
               throw new Error('Required parameter poEmail was null or undefined when calling sendPOPackageEmail.');
           }
   
           let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
           if (opportunityId !== undefined && opportunityId !== null) {
               queryParameters = queryParameters.set('opportunityId', <any>opportunityId);
           }
           if (poEmail !== undefined && poEmail !== null) {
               queryParameters = queryParameters.set('poEmail', <any>poEmail);
           }
   
           let headers = this.defaultHeaders;
   
           // authentication (bearerAuth) required
           if (this.configuration.accessToken) {
               const accessToken = typeof this.configuration.accessToken === 'function'
                   ? this.configuration.accessToken()
                   : this.configuration.accessToken;
               headers = headers.set('Authorization', 'Bearer ' + accessToken);
           }
           // to determine the Accept header
           let httpHeaderAccepts: string[] = [
               '*/*'
           ];
           const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
           if (httpHeaderAcceptSelected != undefined) {
               headers = headers.set('Accept', httpHeaderAcceptSelected);
           }
   
           // to determine the Content-Type header
           const consumes: string[] = [
           ];
   
           return this.httpClient.request<string>('get',`${this.basePath}/api/v1/mgmt/applications/po-package-email`,
               {
                   params: queryParameters,
                   withCredentials: this.configuration.withCredentials,
                   headers: headers,
                   observe: observe,
                   reportProgress: reportProgress,
               }
           );
       }
   

 
 
 }
 