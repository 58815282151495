<div class="flex-container">
  <div class="container-xs px-3">
    <ng-container *ngIf="this.userRole === 'ORGANISATION_ADMIN'">
    <div class="d-flex col gap-2 pb-2">
      <div class="d-flex align-items-center">
        <span>Your registration link is: <a href="https://registration.coolculture.org?code={{this.selectedOpportunityId}}" target="_blank" (keydown.enter)="openLink($event)">https://registration.coolculture.org?code={{this.selectedOpportunityId}}</a></span>
      </div>
      <div class="d-flex justify-content-center align-items-center copy_content px-2">
        <mat-icon (click)="copyLink()">content_copy</mat-icon>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="userRole === 'ORGANISATION_ADMIN'">
    <div class="d-flex justify-content-center">
      <mat-stepper class="col-12" labelPosition="bottom" style="overflow: hidden;" #stepper [selectedIndex]="selectedIndex" [orientation]="stepperOrientation">
        
        <mat-step [completed]="true">
          <ng-template matStepLabel>
            Application Status<br>
            <label [style.color]="opportunityStatus?.enrolmentApplicationDecision === 'Eligible' ? 'green' : 'red'" for="">
              {{opportunityStatus?.enrolmentApplicationDecision}}
            </label>          
          </ng-template>
          <!-- <p>Viewing content for step 1.</p> -->
        </mat-step>
        
        <mat-step [completed]="true">
          <ng-template matStepLabel>
            Partnership Agreement Status<br>
            <label [style.color]="opportunityStatus?.partnershipAgreementAccepted ? 'green' : 'red'" for="">
              {{opportunityStatus?.partnershipAgreementAccepted ? 'Accepted' : 'Not Accepted'}}
            </label>
            <br>

            <ng-container *ngFor="let document of opportunityStatus?.partnershipAgreementDocuments">
              <label class="pt-2 custom-selectable" for=""(click)="downloadFile(document)">{{ document.title }}</label><br>
            </ng-container>
            <!-- <div>

              <input type="file" class="pt-2" id="actual-btn" (change)="onFileSelectedPartnershipAgreement($event)">

            </div> -->

            <input type="file" class="file-input pt-2" (change)="onFileSelectedPartnershipAgreement($event)" #fileUpload1 hidden>

            <div class="file-upload pt-2 d-flex justify-content-center align-items-center">

              <div>
                <button mat-mini-fab color="primary" class="upload-btn"
                (click)="fileUpload1.click()">
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>

              <div class="d-flex justify-content-center ps-2">
                {{this.fileNamePartnershipAgreement || "No file uploaded yet."}}
              </div>

            </div>


          </ng-template>
          <!-- <p>Viewing content for step 2.</p> -->
        </mat-step>
        
        
        <mat-step [completed]="true" *ngIf="opportunityStatus?.paymentMethod !== 'Scholarship'">
          <ng-template matStepLabel>
            Invoice<br>
            <!-- <label [style.color]="opportunityStatus?.invoicePaymentStatus?.paidAmount >= opportunityStatus?.invoicePaymentStatus?.totalAmount ? 'green' : 'red'" for="">
              {{opportunityStatus?.invoicePaymentStatus?.paidAmount >= opportunityStatus?.invoicePaymentStatus?.totalAmount ? 'Paid' : 'Unpaid'}}
              (Paid amount:{{opportunityStatus?.invoicePaymentStatus?.paidAmount}}, Total amount:{{opportunityStatus?.invoicePaymentStatus?.totalAmount}})
            </label> -->
            <label *ngIf="opportunityStatus?.paymentInvoiceDocument && opportunityStatus?.paymentInvoiceDocument.id && opportunityStatus?.paymentInvoiceDocument.title" 
            for="" 
            class="pt-1 custom-selectable" 
            (click)="downloadFile(opportunityStatus?.paymentInvoiceDocument)">
            Download
     </label>
          </ng-template>
          <!-- <p>Viewing content for step 3.</p> -->
        </mat-step>

        <mat-step [completed]="true" *ngIf="opportunityStatus?.paymentMethod !== 'Scholarship'">
          <ng-template matStepLabel matStepperIcon="home">
            Payment Status<br>
            <!-- <label [style.color]="opportunityStatus?.partnershipAgreementAccepted ? 'green' : 'red'" for="">
              {{opportunityStatus?.partnershipAgreementAccepted ? 'Accepted' : 'Not Accepted'}}
            </label> -->

            <div>
              <label [style.color]="opportunityStatus?.invoicePaymentStatus?.paidAmount >= opportunityStatus?.invoicePaymentStatus?.totalAmount ? 'green' : 'red'" for="">
                {{opportunityStatus?.invoicePaymentStatus?.paidAmount >= opportunityStatus?.invoicePaymentStatus?.totalAmount ? 'Paid' : 'Unpaid'}}
                <br>
                Paid amount:{{opportunityStatus?.invoicePaymentStatus?.paidAmount}}<br> Payment due: {{ opportunityStatus?.invoicePaymentStatus?.totalAmount - opportunityStatus?.invoicePaymentStatus?.paidAmount }}

              </label>
            </div>

            <!-- <div>


              <input type="file" id="actual-btn" hidden>
              <label id="btn-label" for="actual-btn">Select file</label>

            </div> -->

          </ng-template>
          <!-- <p>Viewing content for step 2.</p> -->
        </mat-step>

        <mat-step [completed]="true" *ngIf="opportunityStatus?.paymentMethod !== 'Scholarship'">
          <ng-template matStepLabel>
            Purchase Order Status<br>
            <!-- <label [style.color]="opportunityStatus?.invoicePaymentStatus?.paidAmount >= opportunityStatus?.invoicePaymentStatus?.totalAmount ? 'green' : 'red'" for="">
              {{opportunityStatus?.invoicePaymentStatus?.paidAmount >= opportunityStatus?.invoicePaymentStatus?.totalAmount ? 'Paid' : 'Unpaid'}}
              (Paid amount:{{opportunityStatus?.invoicePaymentStatus?.paidAmount}}, Total amount:{{opportunityStatus?.invoicePaymentStatus?.totalAmount}})
            </label> -->
            <label class="text-start">
              <!-- <strong>Email:</strong> {{opportunityStatus?.poPayableEmail}}<br> -->
              <strong>Certified:</strong> {{ opportunityStatus?.poCertified ? 'Yes' : 'No' }}
              <br>
              <!-- <strong>Number:</strong> {{opportunityStatus?.poNumber}} -->
            </label>

            <!-- <div *ngIf="isNullOrEmpty(opportunityStatus?.poPayableEmail) && isNullOrEmpty(opportunityStatus?.poNumber)"> -->
              
              <!-- <div>
              <input class="d-flex pt-2" type="file" id="actual-btn" (change)="onFileSelectedPurchaseOrder($event)">
            </div> -->

            <input type="file" class="file-input pt-2" (change)="onFileSelectedPurchaseOrder($event)" #fileUpload2 hidden>

            <div class="file-upload pt-2 d-flex justify-content-center align-items-center">

              <div>
                <button mat-mini-fab color="primary" class="upload-btn"
                (click)="fileUpload2.click()">
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>

              <div class="d-flex justify-content-center ps-2">
                {{this.fileNamePurchaseOrder || "No file uploaded yet."}}
              </div>

            </div>

          </ng-template>
          <!-- <p>Viewing content for step 3.</p> -->
        </mat-step>

        <!-- <mat-step [completed]="true">
          <ng-template matStepLabel>
            Card Delivery Status<br>


          </ng-template>
        </mat-step> -->
        
        
      </mat-stepper>
    </div>

    <div>

<!-- Your component's template -->


    </div>

  </ng-container>

  
  
  
  


    <div class="row justify-content-evenly justify-content-lg-start">
      <app-loading-spinner *ngIf="loading"></app-loading-spinner>
      <div class="col-lg-3 col-md-6 pb-4" *ngIf="this.userRole !== 'ORGANISATION_ADMIN'">
        <h3 class="fw-bold" style="white-space: nowrap;">{{ 'whichOrg' | translate }}</h3>

        <!-- <mat-form-field class="col-12" appearance="outline">
          <mat-label>{{ 'select' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedOpportunityId" (ngModelChange)="onOrganizationChange()">
            <mat-option [value]="null">All</mat-option>
            <ng-container *ngFor="let organization of retrievedOrganizations">
                <mat-option [value]="organization.opportunityId">{{ organization.organisationName }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field class="col-12" appearance="outline">
          <input matInput 
          (ngModelChange)="onOrganizationChange($event)" 
          [(ngModel)]="selectedValues" name="Organizations" 
          [matAutocomplete]="OrganizationAutoComplete">

           <mat-autocomplete #OrganizationAutoComplete="matAutocomplete" [displayWith]="displayProperty">
            <!-- <mat-option [value]="{ organisationId: null, opportunityId: null, organisationName: 'All' }">All</mat-option> -->
            <mat-option *ngFor="let organization of InputCopyValue" [value]="organization">
                {{ organization.organisationName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      </div>
      <div class="col-lg-3 col-md-6 pb-4">
        <h3 class="fw-bold" style="white-space: nowrap;">{{ 'whichStatus' | translate }}</h3>
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>{{ 'select' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedStatus" (ngModelChange)="onStatusChange()">
            <mat-option *ngFor="let status of statusOptions" [value]="status">{{ status }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div class="col-lg-3 col-md-6 pb-4">
        <h3 class="fw-bold" style="white-space: nowrap;">{{ 'search' | translate }}</h3>
        <mat-form-field class="col-12" appearance="outline">
          <input matInput placeholder="Search" [(ngModel)]="searchText">
        </mat-form-field>
      </div>

      <div class="col-lg-3 col-md-6 pb-4">
        <h3 class="fw-bold custom-hidden" style="white-space: nowrap;">{{ 'search' | translate }}</h3>
        <button mat-raised-button color="warn" class="col-12 col-md-4 col-lg-4 custom-button-size"(click)="onSearch()">Search</button>
      </div>

      <!-- <div class="col-lg-3 col-md-6 justify-content-lg-start  justify-content-between d-flex">
        <div class="">
          <h3 class="fw-bold" style="white-space: nowrap;">{{ 'search' | translate }}</h3>
        <mat-form-field class="col-12" appearance="outline">
          <input matInput placeholder="Search" [(ngModel)]="searchText">
        </mat-form-field>
        </div>
          <div class="d-flex justify-content-center align-items-center custom-padding px-4">
            <button mat-raised-button color="warn" class="custom-button-size"(click)="onSearch()">Search</button>
          </div>
      </div> -->
      
    </div>

      <div class="row justify-content-center">
        <div class="col pt-4 pb-2">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="adult">
              <mat-header-cell *matHeaderCellDef class="p-3 justify-content-start col-2 col-lg-1">Adult</mat-header-cell>
              <mat-cell *matCellDef="let element" class="p-3 justify-content-start col-2 col-lg-1">
                <div *ngIf="!element.family">
                  {{element.firstName}} {{element.lastName}}
                </div> 
                <div *ngIf="element.family">
                  {{element.family?.adults[0]?.firstName}} {{element.family?.adults[0]?.lastName}}
                </div>
              </mat-cell>
            </ng-container>


              <ng-container matColumnDef="childName">
                <mat-header-cell *matHeaderCellDef class="p-3 justify-content-start col-2 col-lg-1">
                  <label class="pe-2 custom-pointer" (click)="toggleSortByName()">Child name</label>
                  <i *ngIf="this.initialOrderName" class="fa-solid fa-arrows-up-down"></i>
                  <i *ngIf="this.sortOrder === 'ASC' && this.selectedLabelName" class="fa-solid fa-arrow-up"></i>
                  <i *ngIf="this.sortOrder === 'DESC' && this.selectedLabelName" class="fa-solid fa-arrow-down"></i>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="p-3 justify-content-start col-2 col-lg-1"> {{element.family?.children[0]?.firstName}} {{element.family?.children[0]?.lastName}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="grade">
                <mat-header-cell *matHeaderCellDef class="p-3 justify-content-start col-2 col-lg-1"> 
                  <label class="pe-2 custom-pointer" (click)="toggleSortByClass()">Grade</label>
                  <i *ngIf="this.initialOrderClass" class="fa-solid fa-arrows-up-down"></i>
                  <i *ngIf="this.sortOrder === 'ASC' && this.selectedLabelClass" class="fa-solid fa-arrow-up"></i>
                  <i *ngIf="this.sortOrder === 'DESC' && this.selectedLabelClass" class="fa-solid fa-arrow-down"></i>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="p-3 justify-content-start col-2 col-lg-1"> {{element.family?.children[0]?.gradeProgram}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="teacherName">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-1 col-lg-1"> Teacher name </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-1 col-lg-1"> {{element.family?.children[0]?.teacher}} </mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="justify-content-start col-1 col-lg-2"> Email </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="justify-content-start col-1 col-lg-2"> {{element.email}} </mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="organization">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="justify-content-start col-1 col-lg-1"> Organization </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="justify-content-start col-1 col-lg-1"> {{element.organisationName}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="staffMember">
                <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-2 col-lg-1">Staff</mat-header-cell>
                <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-2 col-lg-1"> {{element.family?.adults[0]?.isMemberAtSchoolCenter ? 'Yes': 'No'}} </mat-cell>
              </ng-container>
  
              <ng-container matColumnDef="printedFamilyName">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-1 col-lg-1"> Printed family name </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-1 col-lg-1"> {{element.family?.familyName}}</mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="createdDate">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="justify-content-start col-1 col-lg-1"> 
                  <label class="pe-2 custom-pointer" (click)="toggleSortByDate()">Created Date</label>
                  <i *ngIf="this.initialOrderDate" class="fa-solid fa-arrows-up-down"></i>
                  <i *ngIf="this.sortOrder === 'ASC' && this.selectedLabelDate" class="fa-solid fa-arrow-up"></i>
                  <i *ngIf="this.sortOrder === 'DESC' && this.selectedLabelDate" class="fa-solid fa-arrow-down"></i>
                 </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="justify-content-start col-1 col-lg-1"> {{element.createdDate | date:'dd-MM-yyyy HH:mm' }} </mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-2 col-lg-1"> Status </mat-header-cell>
                <mat-cell *matCellDef="let element" class="p-0 p-sm-3 justify-content-center col-2 col-lg-1">
                  <!-- {{ element.status }}  -->
                  <div class="circle-icon" [ngStyle]="{'background-color': (element.status === 'UNDER_REVIEW' ? 'gray' : (element.status === 'DECLINED' ? 'red' : 'green'))}">
                    <mat-icon matTooltip="{{ element.status === 'UNDER_REVIEW' ? 'Under review' : (element.status === 'APPROVED' ? 'Approved' : 'Declined') }}"></mat-icon>
                </div>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center px-4 col-2 col-lg-1"> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-2 col-lg-1">
                  <!-- <button mat-raised-button color="warn" class="full-width-button" (click)="editApplicationStatus(element.id)" [disabled]="element.status === 'DECLINED' || element.status === 'APPROVED'">
                    {{ "changeStatus" | translate }}
                  </button>
                  <div class="px-2">   </div> -->
                  <button mat-raised-button class="" color="warn"[matMenuTriggerFor]="menu" *ngIf="element.familyPassType !== 'Loaner'">
                    <div class="d-flex justify-content-center">
                      <mat-icon>more_vert</mat-icon>
                    </div>
                  </button>
                  <mat-menu #menu="matMenu">
                    <div class="d-flex flex-column justify-content-between">

                      <!-- <div class="d-flex justify-content-center p-2" *ngIf="!element.isPrinted" matTooltip="Changing registration status is temporarily disabled. Please try again later."> -->
                        <div class="d-flex justify-content-center p-2" *ngIf="!element.isPrinted">
                        <button mat-raised-button color="warn" class="col-12" (click)="editApplicationStatus(element.id)" [disabled]="element.status === 'DECLINED' || element.status === 'APPROVED'">
                          <!-- <button mat-raised-button color="warn" class="full-width-button" (click)="editApplicationStatus(element.id)" disabled> -->

                          <!-- {{ "changeStatus" | translate }} -->
                          <div class="d-flex justify-content-center align-items-center">
                            Change Status
                          </div>
                          
                        </button>                      
                      </div>

                      <!-- <div class="d-flex justify-content-center p-2">
                        <button mat-raised-button color="warn" class="w-100">Export</button>
                      </div> -->

                      <div class="d-flex justify-content-center p-2">
                        <button mat-raised-button class="col-12" color="warn"  (click)="details(element)">
                          <!-- {{ "Details" | translate }} -->
                          <div class="d-flex justify-content-center align-items-center">
                            Edit Details
                          </div>
                        </button>
                      </div>

                      
                    </div>            
                  </mat-menu>
                </mat-cell>
              </ng-container>
              
          
            <!-- Header row -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          
            <!-- Table rows -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator class="mat-elevation-z8" #paginator [pageSize]="[50]" [pageSizeOptions]="[25, 50, 100]" [pageSize]="paginator.pageSize" [pageIndex]="paginator.pageIndex" [length]="paginator.length" (page)="onPageChange($event)"></mat-paginator>

        </div>
      </div>
    </div>
  </div>

