<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container main-container">
	<h3 mat-dialog-title class="title">{{ 'BUTTON.' + data.action | translate }} {{ 'CAMPAIGNS.CAMPAIGN' | translate }}</h3>

	<mat-dialog-content class="mat-typography py-2">
		<form *ngIf="data.action === 'CREATE' || data.action === 'UPDATE'" [formGroup]="formGroup">
			<mat-form-field appearance="outline" class="w-100 pb-3">
				<mat-label>{{ 'CAMPAIGNS.NAME' | translate }}</mat-label>
				<input matInput formControlName="name" />
				<mat-error *ngIf="formGroup.get('name').hasError('required')">{{ 'CAMPAIGNS.NAME' | translate }} {{'COMMON.REQUIRED_PREFIX' | translate}}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" class="w-100 pb-3">
				<mat-label>{{ 'CAMPAIGNS.ID' | translate }}</mat-label>
				<input matInput formControlName="campaignId" />
				<mat-error *ngIf="formGroup.get('campaignId').hasError('required')">{{ 'CAMPAIGNS.ID' | translate }} {{'COMMON.REQUIRED_PREFIX' | translate}}</mat-error>
			</mat-form-field>


      <mat-button-toggle-group id="campaign-activator" name="active" aria-label="activate campaign" formControlName="active" color="warn">
        <mat-button-toggle [value]="false">{{ "COMMON.INACTIVE" | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="true">{{ "COMMON.ACTIVE" | translate }}</mat-button-toggle>
      </mat-button-toggle-group>

		</form>

    <div *ngIf="data.action === 'REMOVE'">
      <h4>{{ 'COMMON.DELETE_CONFIRMATION_MESSAGE' | translate }} {{ 'CAMPAIGNS.CAMPAIGN' | translate }}</h4>
    </div>

	</mat-dialog-content>
	<mat-dialog-actions [align]="'end'">
		<button mat-button (click)="action('CLOSE')">{{ 'Cancel' | translate }}</button>
		<button mat-flat-button color="warn" cdkFocusInitial (click)="action('SAVE')" [disabled]="formGroup.invalid">{{ 'BUTTON.' + data.action | translate }} {{ 'CAMPAIGNS.CAMPAIGN' | translate }}</button>
	</mat-dialog-actions>
</div>
