import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/application/first-timer/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent {

  confirmationResult: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: string
  ) {}

  onConfirmClick(result: boolean): void {
    this.confirmationResult = result;
    this.dialogRef.close(this.confirmationResult);
  }}
