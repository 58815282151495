import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LangService } from 'src/app/lang.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {

  selectedLanguage: string;

  constructor(private cookieService: CookieService, private langService: LangService,) {}


  ngOnInit(): void {
    this.selectedLanguage = this.langService.getSelectedLanguage();
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    }
  }

  selectLang(lang: string) {
    this.langService.selectLang(lang);
    this.selectedLanguage = lang;
}
  
}