<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="flex-container">
    <div class="container-xs px-3">
      <!-- <div class="row justify-content-end">
        <div class="col col-auto">
          <button class="btn btn-primary">
            {{ 'AddnewMember' | translate }}
          </button>
        </div>
      </div> -->

      <div class="row justify-content-evenly justify-content-lg-start">
        <div class="col-lg-3 col-md-6 pb-4">
          <h3 class="fw-bold" style="white-space: nowrap;">{{ 'search' | translate }}</h3>
          <mat-form-field class="col-12" appearance="outline">
            <input matInput placeholder="Search" [(ngModel)]="searchText">
          </mat-form-field>
        </div>

        <div class="col-lg-3 col-md-6">
          <h3 class="fw-bold custom-hidden" style="white-space: nowrap;">{{ 'search' | translate }}</h3>
          <button mat-raised-button color="warn" class="col-3 custom-button-size"(click)="onSearch()">Search</button>
        </div>

    </div>

      <div class="row justify-content-center">
        <div class="col pt-4">
                  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                        <ng-container matColumnDef="firstName">
                          <mat-header-cell *matHeaderCellDef> 
                            <label class="pe-2 custom-pointer" (click)="toggleSortByFirstName()">First Name</label>
                            <i *ngIf="this.initialOrderName" class="fa-solid fa-arrows-up-down"></i>
                            <i *ngIf="this.sortOrder === 'ASC' && this.selectedLabelFirstName" class="fa-solid fa-arrow-up"></i>
                            <i *ngIf="this.sortOrder === 'DESC' && this.selectedLabelFirstName" class="fa-solid fa-arrow-down"></i> 
                          </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="lastName">
                          <mat-header-cell *matHeaderCellDef> 
                            <label class="pe-2 custom-pointer" (click)="toggleSortByLastName()">Last Name</label>
                            <i *ngIf="this.initialOrderLastName" class="fa-solid fa-arrows-up-down"></i> 
                            <i *ngIf="this.sortOrder === 'ASC' && this.selectedLabelLastName" class="fa-solid fa-arrow-up"></i>
                            <i *ngIf="this.sortOrder === 'DESC' && this.selectedLabelLastName" class="fa-solid fa-arrow-down"></i> 
                          </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="createdDate">
                          <mat-header-cell *matHeaderCellDef> 
                            <label class="pe-2 custom-pointer" (click)="toggleSortByDate()">Created Date</label>
                            <i *ngIf="this.initialOrderDate" class="fa-solid fa-arrows-up-down"></i> 
                            <i *ngIf="this.sortOrder === 'ASC' && this.selectedLabelDate" class="fa-solid fa-arrow-up"></i>
                            <i *ngIf="this.sortOrder === 'DESC' && this.selectedLabelDate" class="fa-solid fa-arrow-down"></i> 
                          </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.createdDate | date:'dd-MM-yyyy HH:mm' }} </mat-cell>
                      </ng-container>

                    <!-- Header row -->
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            
                    <!-- Table rows -->
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                  <mat-paginator class="mat-elevation-z8" #paginator 
                  [pageSizeOptions]="[10,25, 50, 100]" 
                  [pageSize]="paginator.pageSize" 
                  [pageIndex]="paginator.pageIndex" 
                  [length]="paginator.length" 
                  (page)="onPageChange($event)"></mat-paginator>

        </div>
      </div>
    </div>
  </div>




