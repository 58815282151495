    <div class="col-lg-10 col-md-9 col-sm-8 p-0" hidden>
    <header class="p-3 mb-3 border-bottom">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-3">

        <div class="dropdown text-end">
          <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <img [src]="selectedLanguage === 'en' ? 'assets/flags/united-kingdom.png' : 'assets/flags/republic-of-macedonia.png'" alt="mdo" width="32" height="32" class="rounded-circle" />
          </a>
          <ul class="dropdown-menu text-small">
            <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" (click)="selectLang('mk')">MK</a></li>
            <li>
              <hr class="dropdown-divider"/>
            </li>
            <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" (click)="selectLang('en')">EN</a></li>
          </ul>
        </div>
      </div>
    </header>
  </div>

  <app-loading-spinner *ngIf="loading"></app-loading-spinner>

  <div class="logo-image">
    <img src="https://www.coolculture.org/wp-content/uploads/2022/01/logo.png" alt="Logo" width="198" height="76" />
  </div>

  <div class="conainer">
    <div class="d-flex justify-content-center image-container">
      <img class="image-style" src="https://www.coolculture.org/wp-content/uploads/2022/03/48086683432_b342f8fe06_o.jpg" alt="Logo" width="100%" height="630" style="object-fit: cover;"/>
        <div class="gradient-overlay"></div>
          <div class="text-container col-lg-4">
            <div class="big-text">Family Pass Registration 2024-2025</div>
            <div class="small-text">Sign up for our Cultural Access Program through your child’s school or child care center to see all that a Cool Culture partnership offers to families.</div>
      </div>
    </div>
  </div>


  <div class="container-fluid d-flex justify-content-center pt-5 pb-5">
    <div class="col-lg-4 col-md-6 justify-content-center">
      <mat-card class="card-size">
        <mat-card-content>

            <div class="container d-flex justify-content-center flex-column text-center">
              <div>
                <h1>{{ 'errorCode' | translate }}</h1>
              </div>
              <div>
                <form #codeForm="ngForm" (ngSubmit)="updateUrl(codeForm)">
                <mat-error *ngIf="submitClicked && loginError" class="pb-4 pt-0">
                {{ 'WrongCode' | translate }}
                </mat-error>
                  <mat-form-field class="field" appearance="outline">
                    <mat-label>{{ 'EnterCode' | translate }}</mat-label>
                    <input
                      id="codeInput"
                      matInput
                      placeholder="Your code"
                      name="code"
                      ngModel
                      required
                    />
                  </mat-form-field>
                  <div>
                    <button mat-raised-button class="full-width-button" type="submit">
                      {{ 'submit' | translate }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <footer class="row row-cols-1 py-5  border-top">

    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/families/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Families</a></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/museum-lists/" class="nav-link p-0 custom-color">Museum Lists</a></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/family-activities/" class="nav-link p-0 custom-color">Family Activities</a></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/family-pass-registration/" class="nav-link p-0 custom-color">Family Pass Enrollment</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/faqs-for-families/" class="nav-link p-0 custom-color">FAQs for Families</a></li>
        <br>
        <li class="nav-item mb-2"><a href="https://coolculture.org/centers-schools/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Centers & Schools</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/enrollment-for-centers/" class="nav-link p-0 custom-color">Enrollment for Centers</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/enrollment-for-schools/" class="nav-link p-0 custom-color">Enrollment for Schools</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/resources-activities/" class="nav-link p-0 custom-color">Resources & Activities</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/faqs-for-educators/" class="nav-link p-0 custom-color">FAQs for Educators</a></li>
      </ul>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <!-- <h5>Section</h5> -->
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><a href="https://coolculture.org/programs-impact/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Programs & Impact</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/health-wellbeing/" class="nav-link p-0 custom-color">Health + Wellbeing</a></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/cultural-partners/" class="nav-link p-0 custom-color">Cultural Partners</a></li>
        <br>
        <li class="nav-item mb-2"><a href="https://coolculture.org/about-us/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">About Us</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/our-team/" class="nav-link p-0 custom-color">Our Team</a></li>
        <li class="nav-item mb-2"><a href="https://coolculture.org/careers/" class="nav-link p-0 custom-color">Careers</a></li>
        <!-- <li class="nav-item mb-2"><a routerLink="/privacy-policy" class="nav-link p-0 custom-color">Privacy policy</a></li> -->

        <br>
        <li class="nav-item mb-2"><a href="https://coolculture.org/get-involved/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Get Involved</a></li>
      </ul>
    </div>

    <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><a class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Cool Culture Mission</a></li>
        <br><br>
        <li class="nav-item mb-2"><p class="nav-link p-0 custom-color" style="box-sizing: border-box;">To amplify the voices of families and strengthen the power of historically marginalized communities through engagement with art and culture, both within cultural institutions and beyond.</p></li>
        <li class="nav-item mb-2">
        <a href="https://www.coolculture.org/donate" class="mat-flat-button mat-primary"><button mat-flat-button color="warn" class="donate-button">Donate</button></a>
        </li>
      </ul>
    </div> -->

    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <!-- <h5>Section</h5> -->
      <ul class="nav flex-column col-10">
        <li class="nav-item mb-2"><a class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Cool Culture Mission</a></li>
        <br>
        <li class="nav-item mb-2"><p class="nav-link p-0 custom-color" style="box-sizing: border-box;">To amplify the voices of families and strengthen the power of historically marginalized communities through engagement with art and culture, both within cultural institutions and beyond.</p></li>
        <li class="nav-item mb-2"><a href="https://www.coolculture.org/donate" class="mat-flat-button mat-primary"><button mat-flat-button color="warn" class="donate-button">Donate</button></a></li>
      </ul>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
      <!-- <h5>Section</h5> -->
      <ul class="nav flex-column col">
        <li class="nav-item mb-2"><a href="mailto:info@coolculture.org" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">{{'info@coolculture.org'}}</a></li>
        <li class="nav-item mb-2"><a href="tel:718.230.4186" class="nav-link p-0 text-white">718.230.4186</a></li>
        <li class="nav-item mb-2"><a class="nav-link p-0 text-white">80 Hanson Place, Suite 605</a></li>
        <li class="nav-item mb-2"><a href="https://www.google.com/maps/place/Professional+building,+80+Hanson+Pl+%23606,+Brooklyn,+NY+11217,+USA/@40.6852617,-73.9766057,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25bb1d27ad1f9:0xbbfec9431b6d2d25!8m2!3d40.6852577!4d-73.974417" class="nav-link p-0 text-white">Brooklyn NY 11217</a></li>
        <br>
        <li class="nav-item mb-2 d-flex gap-2">
          <button mat-mini-fab color="primary" class="fab-button custom-fab-size" (click)="openFacebookPage()">
            <span class="fab fa-facebook d-flex justify-content-center"></span>
          </button>
          <button mat-mini-fab color="accent" class="fab-button custom-fab-size" (click)="openInstagramPage()">
            <i class="fab fa-instagram d-flex justify-content-center"></i>
          </button>
          <button mat-mini-fab color="primary" class="fab-button custom-fab-size" (click)="openTwitterPage()">
            <i class="fab fa-twitter d-flex justify-content-center"></i>
          </button>
          <button mat-mini-fab color="warn" class="fab-button custom-fab-size" (click)="openYoutubePage()">
            <i class="fab fa-youtube d-flex justify-content-center"></i>
          </button>
        </li>
        <br>
        <!-- <p class="nav-item mb-2 d-flex text-white">Website by<a href="https://inellipse.com/" class="nav-link p-0"><img src="https://inellipse.com/wp-content/uploads/2019/01/Inellipse-vector-1.png" alt="" width="80%" height="80%"></a></p> -->
      </ul>
    </div>

    <div class="col-1 p-0"></div>
   <div class="col-10 p-1">
      <p class="text-white">Supported in part by public funds from the National Endowment for the Arts (NEA), the New York State Council on the Arts, the New York City Department of Cultural Affairs in partnership with The City Council, and through generous gifts from Corporations, foundations and individuals.</p>
    </div>
    <div class="col-1 p-0"></div>


    <div class="col-1 p-0"></div>
   <div class="col-10 p-1">
      <p class="text-white">2023 © Cool Culture  <a style="color: red; text-decoration: none;" href="https://www.coolculture.org/privacy-policy/">Privacy Policy</a></p>
    </div>
    <div class="col-1 p-0"></div>
  </footer>


