  <div class="flex-container">
    <div class="container-xs px-3">
      <app-loading-spinner *ngIf="loading"></app-loading-spinner>
      <div class="row justify-content-evenly justify-content-lg-start">
        <app-loading-spinner *ngIf="loading"></app-loading-spinner>

        <div class="flex-column flex-md-row col-lg-8 d-flex gap-3">


          <mat-radio-group class="flex-column flex-lg-row d-flex gap-3" color="warn" aria-label="Select an option" [value]="selectedOption" (change)="onRadioChange($event.value)">
            <mat-radio-button style="white-space: nowrap;" value="Yes">{{ 'paidInvoiceYes' | translate }}</mat-radio-button>
            <mat-radio-button style="white-space: nowrap;" value="No">{{ 'paidInvoiceNo' | translate }}</mat-radio-button>
          </mat-radio-group>


          <div class="d-flex justify-content-center align-items-center">
            <button mat-raised-button color="warn" (click)="exportFiles()" style="white-space: nowrap;">{{'export' | translate}}</button>
          </div>

        </div>

<!--   
        <div class="col-lg-6 col-md-6 d-flex justify-content-end gap-3">
          <div class="d-flex justify-content-center align-items-center">
            <h3 class="fw-bold mb-0" style="white-space: nowrap; font-size: larger;">{{ 'startingNumber' | translate }}:</h3>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <mat-form-field class="col-12" appearance="outline">
            <input matInput type="text" matInput placeholder="Number" [(ngModel)] = "inputNumber"/>
          </mat-form-field>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button mat-raised-button color="warn" (click)="exportFiles()">{{'exportPrintList' | translate}}</button>
          </div>
        </div> -->

      </div>
  
      <div class="row justify-content-center">
        <div class="col pt-4">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-3"> Name </mat-header-cell>
                <mat-cell *matCellDef="let element" class="p-3 justify-content-start col-3"> {{element.name}} </mat-cell>
              </ng-container>
              
              <!-- <ng-container matColumnDef="url">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="justify-content-center"> Url </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="justify-content-start"> {{element.link}} </mat-cell>
              </ng-container> -->

              <ng-container matColumnDef="createdDate">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="justify-content-center col-2"> Created Date </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="justify-content-center col-2"> {{element.createdDate | date:'dd-MM-yyyy HH:mm' }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="batchNumber">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="justify-content-center col-2"> Batch Number </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="justify-content-center col-2"> {{element.batch}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="justify-content-center col-2"> Created By </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="justify-content-center col-2"> {{element.createdBy}} </mat-cell>
              </ng-container>
              
              <!-- <ng-container matColumnDef="intake">
                <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="justify-content-start"> Intake </mat-header-cell>
                <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="justify-content-start"> {{element.organisationName}} </mat-cell>
              </ng-container> -->
              
              
              <ng-container matColumnDef="printed">
                <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-1"> Printed </mat-header-cell>
                <mat-cell *matCellDef="let element" class="p-0 p-sm-3 justify-content-center col-1">
                   <div class="circle-icon" [ngStyle]="{'background-color': (element.disabled === true && element.isPrinted === false ? 'gray' : (element.isPrinted === false ? 'orange' : 'green'))}">
                    <mat-icon matTooltip="{{ element.disabled === true && element.isPrinted === false ? 'Is printed in previous export' : (element.isPrinted === false ? 'Eligible to be printed' : 'Already Printed') }}"></mat-icon>
                <!-- <mat-icon matTooltip="{{ element.disabled === true ? 'Is printed in previous export' : (element.isPrinted === false ? 'Eligible to be printed' : 'Already Printed') }}"></mat-icon> -->
                </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center px-4 col-2"> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-2">
                  <!-- <button mat-raised-button color="warn" class="full-width-button" (click)="editApplicationStatus(element.id)" [disabled]="element.status === 'DECLINED' || element.status === 'APPROVED'">
                    {{ "changeStatus" | translate }}
                  </button>
                  <div class="px-2">   </div> -->
                  <button mat-raised-button class="full-width-button" color="warn"[matMenuTriggerFor]="menu">
                    <div class="d-flex justify-content-center">
                      <mat-icon>more_vert</mat-icon>
                    </div>
                  </button>
                  <mat-menu #menu="matMenu">
                    <div class="d-flex flex-column justify-content-between">

                      <div class="d-flex justify-content-center p-2" *ngIf="!element.isPrinted && !element.disabled">
                        <button mat-raised-button color="warn" class="w-100" (click)="markAsPrinted(element.id)">Mark as Printed</button>                    
                      </div>

                      <!-- <div class="d-flex justify-content-center p-2">
                        <button mat-raised-button color="warn" class="w-100">Export</button>
                      </div> -->

                      <div class="d-flex justify-content-center p-2">
                        <button mat-raised-button color="warn" class="w-100" (click)="downloadPrintList(element.id, element.name)">Download Export</button>                    
                      </div>

                      <div class="d-flex justify-content-center p-2">
                        <button mat-raised-button color="warn" class="w-100" (click)="deletePrintList(element.id)">Delete</button>    
                      </div>
                      
                    </div>            
                  </mat-menu>
                </mat-cell>
              </ng-container>
              
          
            <!-- Header row -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          
            <!-- Table rows -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <!-- <mat-paginator class="pb-1 mat-elevation-z8" #paginator [pageSizeOptions]="[5, 10, 25, 50]" [pageSize]="paginator.pageSize" [pageIndex]="paginator.pageIndex" [length]="paginator.length" (page)="onPageChange($event)"></mat-paginator> -->

        </div>
        
      </div>
    </div>
  </div>
  


  