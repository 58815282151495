import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-loaner-pass',
  templateUrl: './confirmation-loaner-pass.component.html',
  styleUrls: ['./confirmation-loaner-pass.component.css']
})
export class ConfirmationLoanerPassComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

}
