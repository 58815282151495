import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.css']
})
export class SendMailComponent {

  PoNumber: string;

  constructor(
    public dialogRef: MatDialogRef<SendMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log(data.data);
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.data = { ...this.data, clicked: 'yes' };
    this.dialogRef.close(this.data);
  }


  onCorrectPoNumber(): void {

    if(this.data?.data?.PO_Number__c === this.PoNumber){
      this.data = { ...this.data, clicked: 'yes' };
      this.dialogRef.close(this.data);
    } else {
      
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  
}
