<div class="container main-container">
    <h2 mat-dialog-title class="title">
        <ng-container *ngIf="!data?.data?.error && data.data.poPackageSentDate">Package Order Number</ng-container> 

        <ng-container *ngIf="!data?.data?.error && !data.data.poPackageSentDate">Confirmation</ng-container>  
        
        <ng-container *ngIf="data?.data?.error">Error</ng-container>
    </h2>
    <mat-dialog-content class="mat-typography pb-0">
      <div class="container">
        <div class="row gy-3">
          <div class="p-0 pb-2">
            <ng-container *ngIf="!data?.data?.error && data.data.poPackageSentDate">
                <mat-form-field class="col-12 pt-2 pb-1" appearance="outline">
                    <mat-label>PO Number</mat-label>
                    <input matInput [(ngModel)]="PoNumber">
                  </mat-form-field> 
                  <ng-container *ngIf="PoNumber !== undefined && data?.data?.PO_Number__c !== PoNumber && PoNumber !== ''">
                        <p class="custom-p-color">The entered PO number is not correct</p>
                  </ng-container>
            </ng-container>

            <ng-container *ngIf="!data?.data?.error && !data.data.poPackageSentDate">
                <p class="">Are you sure you want to send email?</p>          
            </ng-container>


              <ng-container *ngIf="data?.data?.error">
                <p class="">{{data.data.error}}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" *ngIf="!data?.data?.error && data.data.poPackageSentDate">
        <!-- <button mat-flat-button (click)="onNoClick()">No</button> -->
        <button mat-flat-button (click)="onCorrectPoNumber()" color="warn" [disabled]="data?.data?.PO_Number__c !== PoNumber">Confirm</button>
      </mat-dialog-actions>

    <mat-dialog-actions align="end" *ngIf="!data?.data?.error && !data.data.poPackageSentDate">
      <button mat-flat-button (click)="onNoClick()">No</button>
      <button mat-flat-button (click)="onYesClick()" color="warn">Yes</button>
    </mat-dialog-actions>

    <mat-dialog-actions align="end" *ngIf="data?.data?.error">
        <!-- <button mat-flat-button (click)="onNoClick()">No</button> -->
        <button mat-flat-button (click)="closeDialog()" color="warn">Close</button>
      </mat-dialog-actions>
  </div>