<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Responsive Sidebar</title>
</head>

<body class="">
    <div class="row row-sm-4 h-100">
      <!-- Sidebar -->
      <div class="col-lg-2 col-md-3 col-sm-4 p-0 text-bg-dark">
        <div class="d-flex flex-column flex-shrink-0 p-3 text-bg-dark">
          <a href="/" class="d-flex mb-3 mb-md-0 me-md-auto text-white text-decoration-none justify-content-start">
            <svg class="bi pe-none me-2" width="40" height="32">
            </svg>
            <span class="fs-4">CoolCulture</span>
          </a>
          <hr>
          <ul class="nav nav-pills flex-column mb-auto" *ngIf="userRole !== undefined">
            <li class="nav-item" *ngIf="userRole === 'ADMIN'">
              <a routerLink="dashboard" routerLinkActive="active" class="nav-link text-white">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'dashboard' | translate}}
              </a>
            </li>

            <li class="nav-item" *ngIf="userRole !== 'USER'">
              <a routerLink="registrations" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'registrations' | translate}}
              </a>
            </li>

            <li class="nav-item" *ngIf="userRole === 'ADMIN'">
              <a routerLink="loaner-passes" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'loanerPasses' | translate}}
              </a>
            </li>

            <li *ngIf="userRole === 'ADMIN'">
              <a routerLink="unpaid-invoices" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'unpaidInvoices' | translate}}
              </a>
            </li>

            <li *ngIf="userRole === 'ADMIN'">
              <a routerLink="print-list" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'printList' | translate}}
              </a>
            </li>

            <li *ngIf="userRole !== 'USER'">
              <a routerLink="cards-delivery" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'cardsDelivery' | translate}}
              </a>
            </li>

            <li class="nav-item" *ngIf="userRole === 'ADMIN'">
              <a routerLink="uploaded-documents" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'uploadedDocuments' | translate}}
              </a>
            </li>

            <li *ngIf="userRole === 'ADMIN'">
              <a routerLink="purchase-orders" routerLinkActive="active" class="nav-link text-white justify-content-start">
                {{'purchaseOrders' | translate}}
              </a>
            </li>

            <mat-divider></mat-divider>

            <li class="nav-item" *ngIf="userRole === 'ADMIN'">
              <a routerLink="campaigns" routerLinkActive="active" class="nav-link text-white">
                {{'CAMPAIGNS.TITLE' | translate}}
              </a>
            </li>

            <mat-divider></mat-divider>

             <li *ngIf="userRole !== 'ORGANISATION_ADMIN'">
              <a routerLink="members" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'members' | translate}}
              </a>
            </li>

            <li *ngIf="userRole !== 'ORGANISATION_ADMIN'">
              <a routerLink="places" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'places' | translate}}
              </a>
            </li>

            <li *ngIf="userRole !== 'ORGANISATION_ADMIN'">
              <a routerLink="boroughs" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'boroughs' | translate}}
              </a>
            </li>

            <li *ngIf="userRole !== 'ORGANISATION_ADMIN'">
              <a routerLink="categories" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'categories' | translate}}
              </a>
            </li>

            <li class="nav-item" *ngIf="userRole === 'ADMIN'">
              <a routerLink="notifications" routerLinkActive="active" class="nav-link text-white">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'notifications' | translate}}
              </a>
            </li>

            <mat-divider></mat-divider>

            <li *ngIf="userRole !== 'ORGANISATION_ADMIN'" hidden>
              <a routerLink="resources" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                {{'resources' | translate}}
              </a>
            </li>


            <li *ngIf="userRole !== 'USER'">
              <a href="https://coolculture.my.salesforce.com/sfc/p/#5f000006OYLF/a/5f000000M2RI/EUG8ZHp6Hoj8VG7T5HlqBEt2EVrqZUvwDktQtbZxJMk" target="_blank" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                How to Guide
              </a>
            </li>

            <li *ngIf="userRole !== 'USER'">
              <a routerLink="contact-support" routerLinkActive="active" class="nav-link text-white justify-content-start">
                <!-- <svg class="bi pe-none me-2" width="16" height="16">
                </svg> -->
                Contact Support
              </a>
            </li>

            <!-- <li class="pt-3" *ngIf="userRole !== 'USER'">
                <div class="d-flex justify-content-start help-pointer px-3" [matMenuTriggerFor]="menu">
                  Help
                </div>
              <mat-menu #menu="matMenu" class="custom-mat-menu">
                <div class="d-flex flex-column justify-content-between">

                  <div class="d-flex justify-content-start p-2 help-pointer">
                      <div class="d-flex justify-content-center align-items-center">
                        <a href="https://coolculture.my.salesforce.com/sfc/p/#5f000006OYLF/a/5f000000M2RI/EUG8ZHp6Hoj8VG7T5HlqBEt2EVrqZUvwDktQtbZxJMk" target="_blank">
                          How to Guide
                        </a>
                      </div>

                  </div>

                  <div class="d-flex justify-content-start p-2 help-pointer" (click)="openSupportForm()">
                      <div class="d-flex justify-content-center align-items-center">
                        Form for support or email
                      </div>

                      <div class="d-flex justify-content-center align-items-center">
                        <iframe src="https://forms.monday.com/forms/embed/1cab9600076eac89a25f292dcbe8d05e?r=use1" width="650" height="500" style="border: 0; box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25);">dsad</iframe>
                      </div>

                  </div>


                </div>
              </mat-menu>
            </li> -->

          </ul>
        </div>
      </div>

      <!-- Main content area -->
      <div class="col-lg-10 col-md-9 col-sm-8 p-0">
        <header class="p-3 mb-3 border-bottom">
          <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-3">
            <!-- Breadcrumb navigation -->
            <div class="breadcrumb nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              <ng-container *ngFor="let item of breadcrumbData; let last = last">
                <span class="breadcrumb-item" routerLink="/">{{ item | translate }}</span>
                <span *ngIf="!last" class="breadcrumb-separator">></span>
              </ng-container>
            </div>
            <!-- Dropdown menu -->
          <!-- <div class="dropdown text-end">
            <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <img [src]="selectedLanguage === 'en' ? 'assets/flags/united-kingdom.png' : 'assets/flags/republic-of-macedonia.png'" alt="mdo" width="32" height="32" class="rounded-circle" />
            </a>
            <ul class="dropdown-menu text-small">
              <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" (click)="selectLang('mk')">MK</a></li>
              <li>
                <hr class="dropdown-divider"/>
              </li>
              <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" (click)="selectLang('en')">EN</a></li>
            </ul>
          </div> -->
          <div class="campaign-selector-container">
            <mat-form-field id="campaign-selector" appearance="outline" class="w-100">
              <mat-label>{{ 'CAMPAIGNS.CAMPAIGN' | translate }}</mat-label>
              <mat-select matNativeControl [formControl]="campaignFormControl" (selectionChange)="contextDataService.activeCampaignChange($event)">
                <mat-option *ngFor="let campaign of contextDataService.contextData.campaigns" [value]="campaign">{{ campaign.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="dropdown text-end">
            <!-- <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" class="rounded-circle" />
            </a>
            <ul class="dropdown-menu text-small">
              <li><a class="dropdown-item" href="#">{{'profile' | translate}}</a></li>
              <li>
                <hr class="dropdown-divider"/>
              </li>
              <li> -->
                <!-- <a class="dropdown-item" style="cursor: pointer;" (click)="logout()">{{'sign-out' | translate}}</a> -->
                <button mat-raised-button color="warn" (click)="logout()">{{'sign-out' | translate}}</button>
              <!-- </li>
            </ul> -->
          </div>
          </div>
        </header>
          <!-- Main content goes here -->

          <div class="container-fluid py-3 px-0">
            <!-- Content from your components will go here -->
            <router-outlet></router-outlet>
          </div>
          </div>
      </div>



</body>

<footer class="d-flex flex-wrap justify-content-between align-items-center px-4 m-0 border-top bg-dark ab">

  <p class="col-md-4 mb-0 text-white">2023 © Cool Culture  <a style="color: red; text-decoration: none;" href="https://www.coolculture.org/privacy-policy/">Privacy Policy</a></p>

  <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
    <img src="assets/images/CC_logo_transparent.png" alt="CC logo" class="top-image" />
  </a>

  <ul class="nav col-md-4 justify-content-end">
    <li class="nav-item"><a href="mailto:support@coolculture.org" class="nav-link px-2 text-white">Contact Support</a></li>
    <!-- <li class="nav-item"><a href="#" class="nav-link px-2 text-white">Features</a></li>
    <li class="nav-item"><a href="#" class="nav-link px-2 text-white">Pricing</a></li>
    <li class="nav-item"><a href="#" class="nav-link px-2 text-white">FAQs</a></li>
    <li class="nav-item"><a href="#" class="nav-link px-2 text-white">About</a></li> -->
  </ul>
</footer>

</html>

