<app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <div class="flex-container">
    <div class="container-xs px-3">
      <div class="row justify-content-end">
        <div class="col col-auto">
          <button mat-raised-button color="warn" (click)="openDialog()">
            {{ 'AddnewPlace' | translate }}
          </button>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col pt-4">
                  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef class="col-2"> Name </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="col-2"> {{element.name}} </mat-cell>
                        </ng-container>
                        

                        <!-- <ng-container matColumnDef="image">
                            <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center col-1"> Image </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="d-flex justify-content-center col-1">
                                <img [src]="element.image" alt="Image" style="max-width: 100px; max-height: 100px;">
                            </mat-cell>
                        </ng-container> -->
                        
                        <ng-container matColumnDef="logo">
                            <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center col-1"> Logo </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="d-flex justify-content-center col-1">
                                <img [src]="element.logo" alt="Image" style="max-width: 100px; max-height: 100px;">
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                            <mat-cell class="description-cell d-flex justify-content-start align-items-start" *matCellDef="let element"> {{element.description}} </mat-cell>
                        </ng-container>

                        <!-- <ng-container matColumnDef="latitude">
                            <mat-header-cell *matHeaderCellDef> Latitude </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.latitude}} </mat-cell>
                        </ng-container>
                          
                        <ng-container matColumnDef="longitude">
                            <mat-header-cell *matHeaderCellDef> Longitude </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.longitude}} </mat-cell>
                        </ng-container> -->
                          
                        <ng-container matColumnDef="website">
                            <mat-header-cell *matHeaderCellDef> Url </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.url}} </mat-cell>
                        </ng-container>
                          
                        <!-- <ng-container matColumnDef="phone">
                            <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.phone}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                        </ng-container> -->
                        
                        <ng-container matColumnDef="address">
                            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.address}} </mat-cell>
                        </ng-container>

                        <!-- <ng-container ng-container matColumnDef="averageRating">
                            <mat-header-cell *matHeaderCellDef> AverageRating </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.averageRating}} </mat-cell>
                        </ng-container> -->
                        
                        <!-- <ng-container matColumnDef="city">
                            <mat-header-cell *matHeaderCellDef> City </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
                        </ng-container>

                        <ng-container ng-container matColumnDef="state">
                            <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
                        </ng-container>
                          
                        <ng-container matColumnDef="country">
                            <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.country}} </mat-cell>
                        </ng-container>
                          
                        <ng-container ng-container matColumnDef="workingHours">
                            <mat-header-cell *matHeaderCellDef> WorkingHours </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.workingHours}} </mat-cell>
                        </ng-container>
                           -->
                        <!-- <ng-container matColumnDef="totalVotes">
                            <mat-header-cell *matHeaderCellDef> TotalVotes </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.totalVotes}} </mat-cell>
                        </ng-container> -->

                        <!-- <ng-container ng-container matColumnDef="eventId">
                            <mat-header-cell *matHeaderCellDef> EventId </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.eventId}} </mat-cell>
                        </ng-container> -->
                          
                        <!-- <ng-container matColumnDef="categoryId">
                            <mat-header-cell *matHeaderCellDef> CategoryId </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.categoryName }} </mat-cell>
                        </ng-container>
                          
                        <ng-container ng-container matColumnDef="boroughId">
                            <mat-header-cell *matHeaderCellDef> BoroughId </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.boroughName}} </mat-cell>
                        </ng-container> -->

                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef class="d-flex justify-content-center col-1 p-0"> Actions </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-1">
                                <!-- <button mat-raised-button color="warn" class="full-width-button" (click)="editApplicationStatus(element.id)" [disabled]="element.status === 'DECLINED' || element.status === 'APPROVED'">
                                    {{ "changeStatus" | translate }}
                                </button>
                                <div class="px-2">   </div> -->
                                <button mat-raised-button class="full-width-button" color="warn"[matMenuTriggerFor]="menu">
                                    <div class="d-flex justify-content-center">
                                    <mat-icon>more_vert</mat-icon>
                                    </div>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <div class="d-flex flex-column justify-content-between">

                                    <div class="d-flex justify-content-center p-2">
                                        <button mat-raised-button color="warn" class="full-width-button" (click)="deletePlace(element.id)">
                                        <!-- {{ "changeStatus" | translate }} -->
                                        <div class="d-flex justify-content-center align-items-center">
                                            <mat-icon>delete</mat-icon>
                                        </div>
                                        
                                        </button>                      </div>

                                    <!-- <div class="d-flex justify-content-center p-2">
                                        <button mat-raised-button color="warn" class="w-100">Export</button>
                                    </div> -->

                                    <div class="d-flex justify-content-center p-2">
                                        <button mat-raised-button class="full-width-button" color="warn"  (click)="editPlace(element)">
                                        <!-- {{ "Details" | translate }} -->
                                        <div class="d-flex justify-content-center align-items-center">
                                            <mat-icon>edit</mat-icon>
                                        </div>
                                        </button>
                                    </div>

                                    
                                    </div>            
                                </mat-menu>
                                </mat-cell>
                        </ng-container>
                          
                        
                    <!-- Header row -->
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            
                    <!-- Table rows -->
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>

        </div>
      </div>
    </div>
  </div>

