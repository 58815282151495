<div class="flex-container">
    <div class="container-xs px-3">
      <app-loading-spinner *ngIf="loading"></app-loading-spinner>
      <div class="row justify-content-evenly justify-content-lg-start">

        <div class="col-lg-6 col-md-6 d-flex gap-3">

          <!-- <div class="d-flex justify-content-center align-items-center">
            <h3 class="fw-bold mb-0" style="white-space: nowrap; font-size: larger;">{{ 'currentIntake' | translate }}:</h3>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <mat-form-field class="col-12" appearance="outline">
            <input matInput type="text" [(ngModel)]="userInput" matInput placeholder="Ex. BATCH001"/>
          </mat-form-field>
          </div>
          
          <div class="d-flex justify-content-center align-items-center">
            <button mat-raised-button color="warn" (click)="onUpdate()">{{'update' | translate}}</button>
          </div> -->

        </div>

<!--   
        <div class="col-lg-6 col-md-6 d-flex justify-content-end gap-3">
          <div class="d-flex justify-content-center align-items-center">
            <h3 class="fw-bold mb-0" style="white-space: nowrap; font-size: larger;">{{ 'startingNumber' | translate }}:</h3>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <mat-form-field class="col-12" appearance="outline">
            <input matInput type="text" matInput placeholder="Number" [(ngModel)] = "inputNumber"/>
          </mat-form-field>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button mat-raised-button color="warn" (click)="exportFiles()">{{'exportPrintList' | translate}}</button>
          </div>
        </div> -->

      </div>
  
      <div class="row d-flex justify-content-between">

        <div class="col col-md-6 col-lg-3">

          <mat-card class="card-size">
            <mat-card-content>
              <h3 class="fw-bold mb-1" style="white-space: nowrap;">{{ 'whichOrg' | translate }}</h3>
  
              <div class="pb-4">
                <mat-form-field class="col-12" appearance="outline">
                <input matInput 
                       placeholder="Select"
                       (ngModelChange)="onOrganizationChange($event)" 
                       [(ngModel)]="selectedValues" name="Organizations" 
                       [matAutocomplete]="OrganizationAutoComplete">
              
                 <mat-autocomplete #OrganizationAutoComplete="matAutocomplete" [displayWith]="displayProperty">
                  <!-- <mat-option [value]="{ organisationId: null, opportunityId: null, organisationName: 'All' }">All</mat-option> -->
                  <mat-option *ngFor="let organization of InputCopyValue" [value]="organization">
                      {{ organization.organisationName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              </div>
              
            </mat-card-content>
        </mat-card>
        </div>
        <div class="col col-md-6 col-lg-9 d-flex align-items-center">
            <mat-card class="card-size col-12">
                <mat-card-content>

                  <ng-container *ngIf="!createdLoanerPass">
                    <div class="row">
                      <div class="col pb-4">
                         <h3 class="mb-1 fw-bold">{{'schoolSelect' | translate}}</h3>
                         <mat-form-field class="col-12" appearance="outline">
                          <input matInput
                                 placeholder="Select"
                                 [matAutocomplete]="OrganizationAutoComplete"
                                 [formControl]="loanerPassForm.get('selectedValues')"> <!-- Bind form control for selectedValues -->
                          <mat-autocomplete #OrganizationAutoComplete="matAutocomplete" [displayWith]="displayProperty">
                            <ng-container *ngFor="let organization of InputCopyValue">
                              <ng-container *ngIf="organization.organisationName !== 'All'">
                                <mat-option [value]="organization">
                                  {{ organization.organisationName }}
                                </mat-option>
                              </ng-container>
                            </ng-container>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div class="col pb-4">
                        <h3 class="mb-1 fw-bold">{{'numberOfPasses' | translate}}</h3>
                        <mat-form-field class="example-full-width col-12" appearance="outline">
                          <mat-label>Number</mat-label>
                          <input matInput
                                 type="text"
                                 (input)="onInput($event)"
                                 [formControl]="loanerPassForm.get('selectedNumber')"> <!-- Bind form control for selectedNumber -->
                        </mat-form-field>
                      </div>

                      <div class="col pb-4">
                        <h3 class="mb-1 fw-bold">{{'firstName' | translate}}</h3>
                        <mat-form-field class="example-full-width col-12" appearance="outline">
                          <mat-label>First name</mat-label>
                          <input matInput
                                 type="text"
                                 [formControl]="loanerPassForm.get('firstName')"> <!-- Bind form control for selectedNumber -->
                        </mat-form-field>
                      </div>

                      <div class="col pb-4">
                        <h3 class="mb-1 fw-bold">{{'lastName' | translate}}</h3>
                        <mat-form-field class="example-full-width col-12" appearance="outline">
                          <mat-label>Last name</mat-label>
                          <input matInput
                                 type="text"
                                 [formControl]="loanerPassForm.get('lastName')"> <!-- Bind form control for selectedNumber -->
                        </mat-form-field>
                      </div>

                        <div class="col d-flex justify-content-start align-items-center pt-2">
                          <button mat-raised-button color="warn" (click)="generateLoanerPass()" class="col-12 custom-button-size"
                              [disabled]="loanerPassForm.invalid"> <!-- Disable the button if the form is invalid -->
                              {{'generatePassesButton' | translate}}
                        </button>                         
                      </div>
                  </div>


                  </ng-container>

                  <ng-container *ngIf="createdLoanerPass">
                    <div class="d-flex justify-content-evenly align-items-center">
                      <label class="text-center fs-4">{{createdMessage}}</label>
                      <button class="custom-button-size" (click)="this.resetPage()" mat-raised-button color="warn"><i class="fa-solid fa-arrows-rotate"></i></button>
                    </div>
                  </ng-container>

                </mat-card-content>
            </mat-card>

        </div>
        <!-- <div *ngIf="createdLoanerPass"  class="col col-lg-1 d-flex align-items-center">
          <button class="custom-button-size" (click)="this.resetPage()" mat-raised-button color="warn"><i class="fa-solid fa-arrows-rotate"></i></button>
        </div> -->


        <!-- <div class="col-lg-3 col-md-6 pb-4">
          <h3 class="fw-bold" style="white-space: nowrap;">{{ 'whichStatus' | translate }}</h3>
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>{{ 'select' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedStatus" (ngModelChange)="onStatusChange()">
              <mat-option *ngFor="let status of statusOptions" [value]="status">{{ status }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
  
  
        <!-- <div class="col-lg-3 col-md-6 pb-4">
          <h3 class="fw-bold" style="white-space: nowrap;">{{ 'search' | translate }}</h3>
          <mat-form-field class="col-12" appearance="outline">
            <input matInput placeholder="Search" [(ngModel)]="searchText">
          </mat-form-field>
        </div>
  
        <div class="col-lg-3 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start  custom-padding">
          <button mat-raised-button color="warn" class="col-3 custom-button-size"(click)="onSearch()">Search</button>
        </div> -->
        
      </div>

      <div class="row justify-content-center">
        <div class="col pt-4 pb-2">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="adult">
              <mat-header-cell *matHeaderCellDef class="p-3 justify-content-start col-4 col-lg-4">Adult</mat-header-cell>
              <mat-cell *matCellDef="let element" class="p-3 justify-content-start col-4 col-lg-4">
                <div *ngIf="!element.family">
                  {{element.firstName}} {{element.lastName}}
                </div> 
                <div *ngIf="element.family">
                  {{element.family?.adults[0]?.firstName}} {{element.family?.adults[0]?.lastName}}
                </div>
              </mat-cell>
            </ng-container>
              
              <ng-container matColumnDef="organization">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-4 col-lg-4"> Organization </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-4 col-lg-4"> {{element.organisationName}} </mat-cell>
              </ng-container>

              
              <ng-container matColumnDef="createdDate">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-4 col-lg-4"> 
                  <label class="pe-2 custom-pointer" (click)="toggleSortByDate()">Created Date</label>
                  <i *ngIf="this.initialOrder" class="fa-solid fa-arrows-up-down"></i>
                  <i *ngIf="this.sortOrder === 'ASC' && this.selectedLabelDate" class="fa-solid fa-arrow-up"></i>
                  <i *ngIf="this.sortOrder === 'DESC' && this.selectedLabelDate" class="fa-solid fa-arrow-down"></i>
                 </mat-header-cell>
                <mat-cell *matCellDef="let element"  class="justify-content-start col-4 col-lg-4"> {{element.createdDate | date:'dd-MM-yyyy HH:mm' }} </mat-cell>
              </ng-container>
              
          
            <!-- Header row -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          
            <!-- Table rows -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator class="mat-elevation-z8" #paginator [pageSize]="[50]" [pageSizeOptions]="[25, 50, 100]" [pageSize]="paginator.pageSize" [pageIndex]="paginator.pageIndex" [length]="paginator.length" (page)="onPageChange($event)"></mat-paginator>

        </div>
      </div>

    </div>
  </div>
  


  