import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PageUserDTO, UserAdminControllerService, UserDTO } from 'projects/swagger-api/src';
import { LoadingService } from '../loading-spinner/loading.service';
import { ContextDataService } from '../services/context-data.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnDestroy {
	private onDestroy: Subject<any> = new Subject();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['firstName', 'lastName' , 'email', 'createdDate'];

  dataSource: MatTableDataSource<UserDTO> = new MatTableDataSource<UserDTO>();
  sortBy: string = 'createdDate';
  searchText: string = '';
  loading: boolean = false;
  sortOrder: 'ASC' | 'DESC' = 'DESC';
  selectedLabelDate: boolean;
  selectedLabelFirstName: boolean;
  selectedLabelLastName: boolean;
  initialOrderDate:boolean = true;
  initialOrderName:boolean = true;
  initialOrderLastName:boolean = true;


  constructor(
    public dialog: MatDialog,
     private usersController: UserAdminControllerService,
     private changeDetectorRef: ChangeDetectorRef,
     private loadingService: LoadingService,
    private contextDataService: ContextDataService
     ) {}

  ngOnInit()  {
    this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
			next: response => {
        if (response) {
          this.paginator.pageIndex = 0;
          this.paginator.pageSize = 25;
          this.getAllUsers();
        }
			}
		});

    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });
  }

  ngAfterViewInit() {

    this.changeDetectorRef.detectChanges();

  }

  ngOnDestroy(): void {
		this.onDestroy.next('onDestroy');
	}

  getAllUsers() {
    this.usersController.getAllUsers(
      this.contextDataService.contextData.activeCampaign.campaignId,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.searchText,
      this.sortBy,
      this.sortOrder
    ).subscribe(
      (page: PageUserDTO) => {
        // console.log("this is the page log", page);

        if (page && page.content) {
          this.dataSource.data = page.content;
          this.paginator.length = page.totalElements;
        }
      },
      error => {
        // console.error('Error fetching applications:', error);
      },
      () => {
      }
    );
  // }
}

onPageChange(event: any) {
  this.getAllUsers();
}

toggleSortByDate() {
  this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
  this.selectedLabelDate = true;
  this.selectedLabelFirstName = false;
  this.selectedLabelLastName = false;
  this.initialOrderName = true;
  this.initialOrderDate = false;
  this.initialOrderLastName = true;
  this.sortBy = 'createdDate'
  this.paginator.pageIndex = 0,
  this.getAllUsers();
}

toggleSortByFirstName() {
  this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
  this.selectedLabelDate = false;
  this.selectedLabelFirstName = true;
  this.selectedLabelLastName = false;
  this.initialOrderName = false;
  this.initialOrderDate = true;
  this.initialOrderLastName = true;
  this.sortBy = 'firstName'
  this.paginator.pageIndex = 0,
  this.getAllUsers();
}

toggleSortByLastName() {
  this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
  this.selectedLabelDate = false;
  this.selectedLabelFirstName = false;
  this.selectedLabelLastName = true;
  this.initialOrderName = true;
  this.initialOrderDate = true;
  this.initialOrderLastName = false;
  this.sortBy = 'lastName'
  this.paginator.pageIndex = 0,
  this.getAllUsers();
}

onSearch() {
  this.paginator.pageIndex = 0,
  this.getAllUsers();
}


}
