<!-- 

<div class="col-lg-10 col-md-9 col-sm-8 p-0">
  <header class="p-3 mb-3 border-bottom">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-3">
      <div class="dropdown text-end">
        <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown"
          aria-expanded="false">
          <img [src]="
              selectedLanguage === 'en'
                ? 'assets/flags/united-kingdom.png'
                : 'assets/flags/republic-of-macedonia.png'
            " alt="mdo" width="32" height="32" class="rounded-circle" />
        </a>
        <ul class="dropdown-menu text-small">
          <li>
            <a class="d-flex justify-content-center align-items-center dropdown-item p-0"
              (click)="selectLang('mk')">MK</a>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <a class="d-flex justify-content-center align-items-center dropdown-item p-0"
              (click)="selectLang('en')">EN</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</div> -->
<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container">

  <img src="assets/images/CC_logo_transparent.png" alt="CC logo" class="top-image pb-2" />


  <!-- <h1 class="loggin-title pb-3">{{ 'loginTitle1' | translate }}</h1> -->
  <h4 class="loggin-title-2 pb-0">{{ 'loginTitle2' | translate }}</h4>
  <h4 class="loggin-title-3 pb-3">{{ 'loginTitle3' | translate }}</h4>


  <mat-card class="card-size">
    <!-- <mat-card-title class="d-flex flex-wrap align-items-center justify-content-center pt-3 pb-3 fs-2">Log in</mat-card-title> -->
    <mat-card-content>
      <!-- Use formGroup directive to bind the FormGroup -->
      <form [formGroup]="loginForm" class="loggin-form">
        <mat-form-field class="field pb-4" appearance="outline">
          <mat-label>{{ "EnterEmailOrUsername" | translate }}</mat-label>
          <!-- Use formControlName to associate the control with the input -->
          <input type="email" matInput placeholder="email@example.com" formControlName="email" />
          <mat-error *ngIf="loginForm.get('email').hasError('email') && !loginForm.get('email').hasError('required')">
            {{ "EnterEmailError" | translate }}
          </mat-error>
          <!-- <mat-error *ngIf="loginForm.get('email').hasError('required')">
            Email is <strong>required</strong>
          </mat-error> -->
        </mat-form-field>
  
        <mat-form-field class="field pb-4" appearance="outline">
          <mat-label>{{ "EnterPassword" | translate }}</mat-label>
          <input type="password" matInput placeholder="Password" formControlName="password" (keydown.enter)="onEnterKey()"/>
        </mat-form-field>
      </form>
      <div class="button-row">
        <button mat-raised-button class="full-width-button" (click)="login()">
          {{ "signIn" | translate }}
        </button>
      </div>

      <mat-error *ngIf="submitClicked && loginErrorMessage1" class="pb-2 pt-2">
        <p>You are not eligible to sign in this year! Contact the support clicking on the link below for more information</p>
        </mat-error>

        <mat-error *ngIf="submitClicked && loginErrorMessage2" class="pb-2 pt-2">
          {{ 'invalidLoginCredentials' | translate }}
          </mat-error>
      <!-- <div class="registration">
        <p>
          {{ "loginNotMemberYet" | translate }}
          <a routerLink="/registration">{{ "register" | translate }}</a>
        </p>
      </div> -->
    </mat-card-content>
  </mat-card>
  <label class="pt-4" for="contact-suppport">Having problem signing in? <a href="https://forms.monday.com/forms/1cab9600076eac89a25f292dcbe8d05e?r=use1" target="_blank">Contact support</a></label>
</div>