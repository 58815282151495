<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container main-container">
    <h2 mat-dialog-title class="title">{{ (data?.isEdit ? 'updateResource' : 'resourcesAddNewTitle') | translate }}</h2>
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <div class="container">
          <div class="row gy-3">
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'resourceName' | translate}}</mat-label>
                <input matInput placeholder="{{ 'resourcesAddNewName' | translate }}" formControlName="resourceName">
                <mat-error *ngIf="form.get('resourceName').hasError('required')">Name is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'resourceDescription' | translate}}</mat-label>
                <input matInput placeholder="{{ 'resourcesAddNewDescription' | translate }}" formControlName="resourceDescription">
                <mat-error *ngIf="form.get('resourceDescription').hasError('required')">Description is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>{{'url' | translate}}</mat-label>
                <input matInput placeholder="{{ 'resourcesAddNewUrl' | translate }}" formControlName="resourceUrl">
                <mat-error *ngIf="form.get('resourceUrl').hasError('required')">URL is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-3"></div>
            <div class="col-6">
              <mat-form-field class="full-width-form-field" appearance="outline">
                <mat-label>{{'image' | translate}}</mat-label>
                <mat-toolbar class="d-flex justify-content-center flex-column custom-font">
                  <input class="text-center" matInput formControlName="fileAttr" [hidden]="!this.pictureNameUploaded && form.get('fileAttr').value !== 'Choose An Image'" readonly name="image">
                  <img class="pb-4" [src]="imageToDisplay" [hidden]="this.pictureNameUploaded || form.get('fileAttr').value === 'Choose An Image'"  alt="Image" style="max-width: 300px; max-height: 300px;">
                  <button mat-flat-button color="warn">Browse File</button>
                </mat-toolbar>
                <input type="file" #fileInput id="uploadFile" (change)="uploadFileName($event)" name="uploadImage" multiple="multiple" accept="image/*" />
                <mat-error class="input-error" *ngIf="!selectedImageFileName">Image is required.</mat-error>
              </mat-form-field>
              <div class="error-message" *ngIf="form.get('fileAttr').value === 'Choose An Image'">
                {{ 'FileRequired' | translate }}
              </div>
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog()">{{ 'Cancel' | translate }}</button>
      <button mat-flat-button color="warn" cdkFocusInitial (click)="saveDialog()" [disabled]="form.invalid">{{ (data?.isEdit ? 'update' : 'Add') | translate }}</button>
    </mat-dialog-actions>
  </div>
  