<mat-dialog-content>
    <h1 class="fw-bold d-flex justify-content-center" style="color: black;">{{ "introText1" | translate}}</h1>
    
    <p style="color: black;">
        <br>
        {{ "introText2" | translate}}</p>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="d-flex justify-content-center">
    <button mat-raised-button color="warn" (click)="closeDialog()">{{ 'Continue' | translate }}</button>
    <!-- <button mat-flat-button color="primary" (click)="saveDialog()">{{ 'Confirm' | translate }}</button> -->
  </mat-dialog-actions>

  