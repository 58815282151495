import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Body1, ResourcesAdminControllerService, ResourcesDTO } from 'projects/swagger-api/src';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FileManagementControllerService } from 'projects/swagger-api/src';
import { LoadingService } from 'src/app/loading-spinner/loading.service';

@Component({
  selector: 'app-resources-add-new-dialog',
  templateUrl: './resources-add-new-dialog.component.html',
  styleUrls: ['./resources-add-new-dialog.component.css']
})
export class ResourcesAddNewDialogComponent {

  form: FormGroup;
  selectedImageFileName: string = '';
  imageName: string;
  loading: boolean = false;
  categoryNameError: boolean = false;
  imageToDisplay: string = null;
  pictureNameUploaded: boolean = false;

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose An Icon';

  constructor(
    public dialogRef: MatDialogRef<ResourcesAddNewDialogComponent>, 
    private translate: TranslateService,
    private resourcesService: ResourcesAdminControllerService,
    private formBuilder: FormBuilder,
    private fileManagement: FileManagementControllerService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fileInput = new ElementRef(null);
    this.fileAttr = this.translate.instant('chooseImage');

    this.form = this.formBuilder.group({
      resourceName: [data.resource ? data.resource.name : '', Validators.required],
      resourceDescription: [data.resource ? data.resource.description : '', Validators.required],
      resourceUrl: [data.resource ? data.resource.url : '', Validators.required],
      fileAttr: [data.resource ? data.resource.image : 'Choose An Image', Validators.required]
    });

    this.imageToDisplay = this.form.get('fileAttr').value;
    // console.log('image to display',this.imageToDisplay);
    // console.log('fileattr value',this.form.get('fileAttr').value);
  }

  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });
  }

  uploadFileName(event: any): void{
    const file = (event.target as HTMLInputElement).files?.[0];
    // console.log(file);
    this.form.get('fileAttr').setValue(file.name);
    // console.log(this.form.get('fileAttr').value);

    if(file){
      this.pictureNameUploaded = true;
    }
  }


  uploadFileEvt(event: any): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    // console.log(file);
    // this.form.get('fileAttr').setValue('uploads/'+file.name);
    // console.log(this.form.get('fileAttr').value);  
  
    if (file) {
      this.fileManagement.uploadFileForm('uploads', file)
        .subscribe(
          (response) => {
            // console.log('File uploaded successfully:', response);

            this.form.patchValue({
              fileAttr: response.message
            });
          },
          (error) => {
            console.error('Error uploading file:', error);
            // Handle error as needed
          }
        );
    }
  }
  
  

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveDialog(): void {

    if (this.form.invalid || this.form.get('fileAttr').value === 'Choose An Image') {
      // console.log('Please fill all fields.');
      return;
    }

    const fileInput = document.getElementById('uploadFile') as HTMLInputElement;
    const mockEvent = {
      target: {
        files: [fileInput?.files?.[0]] // Assuming you have only one file input
      }
    };

    this.uploadFileEvt(mockEvent);

    if (this.data.isEdit) {
      const updatedResource = {
        id: this.data.resource.id,
        name: this.form.value.resourceName,
        description: this.form.value.resourceDescription,
        url: this.form.value.resourceUrl,
        image: this.form.value.fileAttr
      };


      if (updatedResource.image.startsWith('https://')){
        updatedResource.image = updatedResource.image.split('/').pop();
      }
  
      this.resourcesService.updateResources(updatedResource, this.data.resource.id).subscribe(
        (response: ResourcesDTO) => {
          // console.log('Resource updated successfully:', response);
          this.dialogRef.close(response);
        },
        error => {
          // console.error('Error updating resource:', error);
        }
      );
    } else

    {
       const newResource: ResourcesDTO = {
        name: this.form.value.resourceName,
        description: this.form.value.resourceDescription,
        url: this.form.value.resourceUrl,
        image: this.form.value.fileAttr
      };

      this.resourcesService.createResource(newResource).subscribe(
        (response: ResourcesDTO) => {
          // console.log('Resource added successfully:', response);
          this.dialogRef.close(response);
        },
        error => {
          // console.error('Error adding resource:', error);
        }
      );
    }
    
     

      // Clear error message
      this.clearError();
  }

  clearError() {
    this.categoryNameError = false;
  }

}
