<div class="flex-container">
    <div class="container-xs px-3">
      <div class="row justify-content-between">
        <app-loading-spinner *ngIf="loading"></app-loading-spinner>
        <div class="col-lg-3">
          <h3 class="fw-bold" style="white-space: nowrap;">{{ 'whichOrg' | translate }}</h3>


          <!-- <mat-form-field class="col-12" appearance="outline">
            <mat-label>{{ 'select' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedValues" (ngModelChange)="onOrganizationChange()">
              <mat-option [value]="{ organisationId: null, opportunityId: null }">All</mat-option>
              <ng-container *ngFor="let organization of retrievedOrganizations">
                  <mat-option [value]="organization">{{ organization.organisationName }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field> -->


          <mat-form-field class="col-12" appearance="outline">
                  <input matInput
                  (ngModelChange)="onOrganizationChange($event)"
                  [(ngModel)]="selectedValues" name="Organizations"
                  [matAutocomplete]="OrganizationAutoComplete">

                   <mat-autocomplete #OrganizationAutoComplete="matAutocomplete" [displayWith]="displayProperty">
                    <!-- <mat-option [value]="{ organisationId: null, opportunityId: null, organisationName: 'All' }">All</mat-option> -->
                    <mat-option *ngFor="let organization of InputCopyValue" [value]="organization">
                        {{ organization.organisationName }}
                    </mat-option>
                </mat-autocomplete>
          </mat-form-field>



        </div>

        <div class="col-lg-6">
            <h3 class="fw-bold" style="white-space: nowrap;">{{ 'dateRange' | translate }}</h3>
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-lg-5 pb-4">
                  <mat-form-field class="col-12" appearance="outline">
                  <mat-label>from</mat-label>
                  <input matInput [matDatepicker]="datepicker1" readonly [(ngModel)]="startDate">
                  <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                  <mat-datepicker-toggle matIconSuffix [for]="datepicker1"></mat-datepicker-toggle>
                  <mat-datepicker #datepicker1></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-lg-5 pb-4">
                    <mat-form-field class="col-12" appearance="outline">
                    <mat-label>to</mat-label>
                    <input matInput [matDatepicker]="datepicker2" readonly [(ngModel)]="endDate">
                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                    <mat-datepicker-toggle matIconSuffix [for]="datepicker2"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker2></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-12 col-lg-2 pb-4 ">
                    <!-- <h3 class="fw-bold custom-hidden" style="white-space: nowrap;">{{ 'search' | translate }}</h3> -->
                    <button mat-raised-button color="warn" class="custom-button-size col-12" (click)="onSearch()">Search</button>
                </div>

              </div>

          </div>

      </div>

      <div class="row p-3 gap-2 gap-md-4 d-flex justify-content-center justify-content-md-start">
        <mat-card class="col col-lg-3 bg-danger text-white">
          <mat-card-content>
            <div class="row">
              <div class="d-flex justify-content-center align-items-center">
                <div class="row">
                  <label class="fw-bold custom-font d-flex justify-content-center align-items-center text-center" for="">Total number of registrations</label>

                  <label class="fw-bold custom-font d-flex justify-content-center align-items-center" for="">{{totalRegistrations}}</label>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="col col-lg-3 bg-danger text-white">
          <mat-card-content>
            <div class="row">
              <div class="d-flex justify-content-center align-items-center">
                <div class="row">
                  <label class="fw-bold custom-font d-flex justify-content-center align-items-center text-center" for="">Total number of children</label>

                  <label class="fw-bold d-flex custom-font justify-content-center align-items-center" for="">{{totalChildren}}</label>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="col col-lg-3 bg-danger text-white">
          <mat-card-content>
            <div class="row">
              <div class="d-flex justify-content-center align-items-center">
                <div class="row">
                  <label class="fw-bold custom-font d-flex justify-content-center align-items-center text-center" for="">Total Loaner Passes</label>

                  <label class="fw-bold d-flex custom-font justify-content-center align-items-center" for="">{{displayLoanerPassesNumber}}</label>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

      </div>

      <div class="row justify-content-evenly pt-4">

        <!-- <div class="col-lg-2">

        </div> -->


        <div class="col-lg-5 p-4">
          <div class="">
            <label class="fw-bold" for="">Staff members vs. Parents/Guardians</label>
          </div>

          <div class="d-flex me-lg-3 justify-content-center align-items-center">
            <apx-chart
            [series]="chartOptions1.series"
            [dataLabels]="chartOptions1.dataLabels"
            [chart]="chartOptions1.chart"
            [labels]="chartOptions1.labels"
            [responsive]="chartOptions1.responsive"
            [noData]="chartOptions1.noData"
          ></apx-chart>
          </div>
        </div>



        <div class="col-lg-5 p-4">
          <div class="">
            <label class="fw-bold" for="">Registrations Status</label>
          </div>

          <div class="d-flex me-lg-1 justify-content-center align-items-center">
            <apx-chart
            [series]="chartOptions2.series"
            [dataLabels]="chartOptions2.dataLabels"
            [chart]="chartOptions2.chart"
            [labels]="chartOptions2.labels"
            [responsive]="chartOptions2.responsive"
            [noData]="chartOptions2.noData"
          ></apx-chart>
          </div>
        </div>


        <!-- <div class="col-lg-1">

        </div> -->
      </div>

      <div class="row justify-content-evenly pt-4">


        <!-- <div class="col-lg-2">

        </div> -->

        <div class="col-lg-5 ms-lg-4 p-4">
          <div class="">
            <label class="fw-bold" for="">Income range</label>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <apx-chart
            [series]="chartOptions3.series"
            [dataLabels]="chartOptions3.dataLabels"
            [chart]="chartOptions3.chart"
            [labels]="chartOptions3.labels"
            [responsive]="chartOptions3.responsive"
            [noData]="chartOptions3.noData"
          ></apx-chart>
          </div>
        </div>


        <div class="col-lg-5 ms-lg-5 p-4">
          <div class="">
            <label class="fw-bold" for="">Race/Ethnicity</label>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <apx-chart
            [series]="chartOptions4.series"
            [dataLabels]="chartOptions4.dataLabels"
            [chart]="chartOptions4.chart"
            [labels]="chartOptions4.labels"
            [responsive]="chartOptions4.responsive"
            [noData]="chartOptions4.noData"
          ></apx-chart>
          </div>
        </div>

        <!-- <div class="col-lg-1">

        </div> -->
      </div>



        <div class="row justify-content-center">
          <div class="col-lg-4 pt-4">
            <div>
              <h1 class="fw-bold d-flex justify-content-center text-center">All School / Organizations registrations</h1>
            </div>
            <mat-table [dataSource]="dataSource1" class="mat-elevation-z8">

                <ng-container matColumnDef="organization">
                  <mat-header-cell *matHeaderCellDef class="p-3 justify-content-start col-3"> Organization </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="p-3 justify-content-start col-3"> {{element.opportunityName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="approved">
                  <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-2"> Approved </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-2"> {{element.approvedRegistrations}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="declined">
                  <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-2"> Declined </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-2"> {{element.declinedRegistrations}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="underReview">
                  <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-2"> Under Review </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-2"> {{element.underReviewRegistrations}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="totalRegistrations">
                  <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-3">
                    <label class="pe-2 custom-pointer" (click)="toggleSortOrder()">Total Registrations</label>
                    <i *ngIf="this.initialOrder" class="fa-solid fa-arrows-up-down"></i>
                    <i *ngIf="this.sortOrder === 'ASC' && !this.initialOrder" class="fa-solid fa-arrow-up"></i>
                    <i *ngIf="this.sortOrder === 'DESC' && !this.initialOrder" class="fa-solid fa-arrow-down"></i>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-3"> {{element.totalRegistrations}}</mat-cell>
                </ng-container>

              <!-- Header row -->
              <mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>

              <!-- Table rows -->
              <mat-row *matRowDef="let row; columns: displayedColumns1;"></mat-row>
            </mat-table>
            <mat-paginator class="mat-elevation-z8 pb-1"
            #paginator1
            [pageSizeOptions]="[10, 25, 50, 100]"
            [pageSize]="paginator1PageSize"
            [pageIndex]="paginator1PageIndex"
            [length]="paginator1TotalLength"
            (page)="onPageChangeFirstTable($event)">
            </mat-paginator>
          </div>

          <div class="col-lg-8 pt-4">
            <div>
              <h1 class="fw-bold d-flex justify-content-center text-center">School / Organizations with no registrations</h1>
            </div>
            <mat-table [dataSource]="dataSource2" class="mat-elevation-z8">

                <ng-container matColumnDef="organization">
                  <mat-header-cell *matHeaderCellDef class="p-3 justify-content-start col-6 col-lg-2"> Organization </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="p-3 justify-content-start col-6 col-lg-2" style="white-space: normal;"> {{element.opportunityName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="emailPrincipal">
                  <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-2"> Principal Email </mat-header-cell>
                  <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-2" style="white-space: normal; word-break: break-all;"> {{element.principalDirectorEmail}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="emailCulturalLiaison">
                  <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-2"> Cultural Liaison Email </mat-header-cell>
                  <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-2" style="white-space: normal; word-break: break-all;">{{element.culturalLiaisonEmail}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="emailBookKeeper">
                  <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-2"> Bookkeeper Email </mat-header-cell>
                  <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-2" style="white-space: normal; overflow-wrap: anywhere;">{{element.bookKeeperEmail}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="emailSubmitter">
                  <mat-header-cell *matHeaderCellDef [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-2"> Submitter Email </mat-header-cell>
                  <mat-cell *matCellDef="let element" [class.hideThis]="isXsScreen" class="p-3 justify-content-start col-2" style="white-space: normal; word-break: break-all;"> {{element.submitterEmailEmail}}</mat-cell>
                </ng-container>


                <!-- <ng-container matColumnDef="code">
                  <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-4"> Code </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-4"> {{element.organisationUniqueId}} </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="link">
                  <mat-header-cell *matHeaderCellDef class="p-3 justify-content-center col-6 col-lg-2"> Link </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="p-3 justify-content-center col-6 col-lg-2">
                    <a [href]="'https://coolculture.lightning.force.com/lightning/r/Opportunity/' + element.opportunityId + '/view'" target="_blank">
                      {{element.opportunityId}}
                    </a>
                  </mat-cell>
                </ng-container>

              <!-- Header row -->
              <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>

              <!-- Table rows -->
              <mat-row *matRowDef="let row; columns: displayedColumns2;"></mat-row>
            </mat-table>
            <mat-paginator class="mat-elevation-z8 pb-1"
              #paginator2
              [pageSizeOptions]="[10, 25, 50, 100]"
              [pageSize]="paginator2PageSize"
              [pageIndex]="paginator2PageIndex"
              [length]="paginator2TotalLength"
              (page)="onPageChangeSecondTable($event)">
            </mat-paginator>
          </div>
        </div>

      </div>
    </div>

