import { Component, OnDestroy, ViewChild } from '@angular/core';
import { LoadingService } from '../loading-spinner/loading.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationLoanerPassComponent } from './confirmation-loaner-pass/confirmation-loaner-pass.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApplicationAdminControllerService, ApplicationDTO, OrganisationDTO, PageApplicationDTO } from 'projects/swagger-api/src';
import { ContextDataService } from '../services/context-data.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-loaner-passes',
  templateUrl: './loaner-passes.component.html',
  styleUrls: ['./loaner-passes.component.css']
})
export class LoanerPassesComponent implements OnDestroy {
  private onDestroy: Subject<any> = new Subject();

  loanerPassForm: FormGroup;
  loading: boolean = false;
  retrievedOrganizations: OrganisationDTO[];
  InputCopyValue: OrganisationDTO[];
  selectedValues: OrganisationDTO = { organisationId: null, opportunityId: null , organisationName: null};
  originalOrganizations: OrganisationDTO[] = [{ organisationName: 'All', organisationId: null, opportunityId: null }];
  createdLoanerPass: boolean = false;
  createdMessage: string;
  selectedOpportunityId: string = null;
  sortOrder: 'ASC' | 'DESC' = 'DESC';
  selectedLabelDate:boolean;
  initialOrder: boolean = true;


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  displayedColumns: string[] = ['adult', 'organization', 'createdDate'];
  dataSource: MatTableDataSource<ApplicationDTO> = new MatTableDataSource<ApplicationDTO>();



  constructor(
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private applicationAdminService: ApplicationAdminControllerService,
    private dialog: MatDialog,
    private adminService: ApplicationAdminControllerService,
    private contextDataService: ContextDataService
    ){}

  ngOnInit(){
    this.initForm();

    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
			next: response => {
        if (response) {
          this.getAllOrganizations();
          this.paginator.pageIndex = 0;
          this.paginator.pageSize = 25;
          this.getAllApplications();
        }
			}
		});

    this.loanerPassForm.get('selectedValues').valueChanges.subscribe(value => {
      this.onOrganizationChangeDisplayValue(value);
    });
  }

  ngOnDestroy(): void {
		this.onDestroy.next('onDestroy');
	}

  initForm(){
    this.loanerPassForm = this.formBuilder.group({
      selectedValues: [null, Validators.required], // Add Validators.required for required validation
      selectedNumber: [null, Validators.required],
      firstName: ['LOANER'],
      lastName: ['PASS']
    })}


    onInput(event: Event): void {
      const inputElement = event.target as HTMLInputElement;
      // Remove non-numeric characters from the input value
      const numericValue = inputElement.value.replace(/\D/g, '');
      // Update the form control value using patchValue
      this.loanerPassForm.patchValue({
        selectedNumber: numericValue
      });
    }



    getAllApplications() {
      // const statusToSend = this.selectedStatus === 'All' ? '' : this.selectedStatus;

      // if (this.paginator) {
        this.adminService.getApplicationsByStatusAndSearch(
          this.contextDataService.contextData.activeCampaign.campaignId,
          '',
          '',
          this.selectedOpportunityId,
          this.paginator.pageIndex,
          this.paginator.pageSize,
          '',
          this.sortOrder,
          'Loaner'
        ).subscribe(
          (page: PageApplicationDTO) => {
            // console.log("this is the page log", page);

            if (page && page.content) {
              this.dataSource.data = page.content;
              this.paginator.length = page.totalElements;
            }
          },
          error => {
            // console.error('Error fetching applications:', error);
          },
          () => {
          }
        );
      // }
    }


    getAllOrganizations(){
      this.applicationAdminService.getUniqueOrganisationIds(this.contextDataService.contextData.activeCampaign.campaignId,).subscribe(
        (response: OrganisationDTO[]) => {
          // console.log(response, "this is the organizations get");

          this.retrievedOrganizations = response;
          this.originalOrganizations = [{ organisationName: 'All', organisationId: null, opportunityId: null }, ...response];

          // console.log(this.originalOrganizations);

          this.originalOrganizations.sort((a, b) => a.organisationName.localeCompare(b.organisationName));
          this.InputCopyValue = this.originalOrganizations;
          },
        error => {
          // console.error('Error fetching organizations:', error);
        },
        () => {
        }
      );
    }


    onOrganizationChangeDisplayValue(newValue: any){

      if (typeof newValue === "string") {
        let filterValue = newValue.toLowerCase();
        this.InputCopyValue = this.originalOrganizations.filter(item => item.organisationName.toLowerCase().includes(filterValue));
      }

    }


    onOrganizationChange(newValue: any){


      this.selectedOpportunityId = newValue.opportunityId;

      // this.selectedValues = newValue;
      // console.log('Selected organization:', newValue);
      // console.log('Selected organization:', this.selectedValues);


      if (typeof newValue === "string") {
        let filterValue = newValue.toLowerCase();
        this.InputCopyValue = this.originalOrganizations.filter(item => item.organisationName.toLowerCase().includes(filterValue));
      }

      else {
        this.paginator.pageIndex = 0;
        this.getAllApplications();
      }
    }


    public displayProperty(organisation: OrganisationDTO): string {
      if (organisation) {
          return organisation.organisationName;
      }
      return '';
  }


  // generateLoanerPass(){
  //   this.applicationAdminService.generateLoanerPasses(this.selectedNumber, this.selectedValues.organisationId, this.selectedValues.opportunityId).subscribe(
  //     (response)=>{
  //       console.log(response);
  //   })
  // }

  generateLoanerPass(): void {
    let selectedNumberValue = this.loanerPassForm.get('selectedNumber').value;
    let selectedOpportunityId = this.loanerPassForm.get('selectedValues').value.opportunityId;
    let selectedOrganisationId = this.loanerPassForm.get('selectedValues').value.organisationId;
    let selectedOrganisationName = this.loanerPassForm.get('selectedValues').value.organisationName;
    let firstName = this.loanerPassForm.get('firstName').value;
    let lastName = this.loanerPassForm.get('lastName').value;



    // console.log(selectedOrganisationId, selectedOpportunityId)

    const confirmationMessage = `Are you sure you want to generate ${selectedNumberValue} loaner passes for ${selectedOrganisationName}?`;
    this.createdMessage = `You have generated ${selectedNumberValue} loaner passes for ${selectedOrganisationName}`;


    const dialogRef = this.dialog.open(ConfirmationLoanerPassComponent, {
      width: '400px', // Set the width of your dialog
      data: { message: confirmationMessage }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // User clicked 'Yes', execute the generation logic
        this.applicationAdminService.generateLoanerPasses(
          selectedNumberValue,
          selectedOrganisationId,
          selectedOpportunityId,
          selectedOrganisationName,
          this.contextDataService.contextData.activeCampaign.campaignId,
          firstName,
          lastName).subscribe(
          (response) => {
            // console.log('generated loaner passes log',response);

            this.createdLoanerPass = true;
            this.getAllApplications();
          }
        );
      } else {
        // User clicked 'No' or closed the dialog, do nothing
      }
    });
  }

  resetPage(): void {
    this.loanerPassForm.get('selectedNumber').reset();
    this.loanerPassForm.get('selectedValues').reset();
    this.createdLoanerPass = false;
  }


  toggleSortByDate() {
    this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    this.selectedLabelDate = true;
    // this.selectedLabelName = false;
    // this.selectedLabelClass = false;
    // this.sortBy = 'createdDate'
    this.initialOrder = false;
    this.paginator.pageIndex = 0,
    this.getAllApplications();
  }


  onPageChange(event: any) {
    this.getAllApplications();
  }



}
