import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BoroughAdminControllerService, BoroughDTO, CategoryAdminControllerService, CategoryDTO, EventAdminControllerService, EventDTO, FileManagementControllerService, ImagesResponse, PlaceAdminControllerService, PlaceDTO } from 'projects/swagger-api/src';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/loading-spinner/loading.service';
import { DeleteConfirmationDialogComponent } from 'src/app/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-places-add-new-dialog',
  templateUrl: './places-add-new-dialog.component.html',
  styleUrls: ['./places-add-new-dialog.component.css']
})
export class PlacesAddNewDialogComponent {
  form: FormGroup;
  selectedLogoFileName: string = 'Choose Logo';
  selectedImageFileName: string = 'Choose Image';
  categoryNameError: boolean = false;
  categories: CategoryDTO[] = [];
  boroughs: BoroughDTO[] = [];
  events: EventDTO[] = [];
  googleMapsLink: string = '';
  isGoogleMapsLinkValid: boolean = true;
  linkNotValid: boolean = false;
  loading: boolean = false;
  addedNewPicture: boolean = false;
  usaStates: string[] = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
    'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
    'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina',
    'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  @ViewChild('fileInputLogo') fileInputLogo: ElementRef;
  @ViewChild('fileInputImage') fileInputImage: ElementRef;
  chooseImage: string = 'Choose An Image';
  chooseLogo: string = 'Choose An Logo';

  imageToDisplay: string = null;
  logoToDisplay: string = null;

  imagesNameUploaded: boolean = false;
  logoNameUploaded: boolean = false;
  noNewUploads: boolean = true;

  something= "something";
  

  constructor(
    public dialogRef: MatDialogRef<PlacesAddNewDialogComponent>,
    public dialog: MatDialog, 
    private translate: TranslateService,
    private categoryService: CategoryAdminControllerService,
    private boroughService: BoroughAdminControllerService,
    private eventService: EventAdminControllerService,
    private formBuilder: FormBuilder,
    private placesService: PlaceAdminControllerService,
    private changeDetectorRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private fileManagement: FileManagementControllerService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {

      // console.log(data);
      
    
    this.fileInputLogo = new ElementRef(null);
    this.fileInputImage = new ElementRef(null);

    this.selectedLogoFileName = this.translate.instant('chooseLogo');
    this.selectedImageFileName = this.translate.instant('chooseImage');

    this.form = this.formBuilder.group({
      placeName: ['', Validators.required],  
      placeDescription: ['', Validators.required],
      placesImages: [['Choose An Image'], Validators.required],
      placesLogo: ['Choose An Logo', Validators.required],
      placeUrl: [''],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      phone: ['', Validators.required],
      email: [''],
      address: ['', Validators.required],
      city: ['United States'],
      state: ['', Validators.required],
      country: ['', Validators.required],
      workingHours: ['', Validators.required],
      selectedCategory: ['', Validators.required],
      selectedBorough: ['', Validators.required],
    });
    
  }

   ngOnInit(){

    // console.log('lenght',this.form.get('placesImages').value[0]);
    
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
      this.changeDetectorRef.detectChanges();  // Manually trigger change detection
    });

    if (this.data?.isEdit && this.data?.placeDTO) {
      // console.log('vo edit')
      // Pre-fill form fields for editing
      const placeDTO = this.data.placeDTO;

      // const imagesValue = placeDTO.images || ['Choose An Image'];

      // console.log('this is the place DTO',placeDTO)
      this.form.patchValue({
        placeName: placeDTO.name,
        placeDescription: placeDTO.description,
        placesImages: placeDTO.images,
        placesLogo: placeDTO.logo,
        placeUrl: placeDTO.url,
        latitude: placeDTO.latitude,
        longitude: placeDTO.longitude,
        phone: placeDTO.phone,
        email: placeDTO.email,
        address: placeDTO.address,
        city: placeDTO.city,
        state: placeDTO.state,
        country: placeDTO.country,
        workingHours: placeDTO.workingHours,
        selectedCategory: placeDTO.categoryId,
        selectedBorough: placeDTO.boroughId,     
      
      });

      // console.log('patched for data', this.form.value);
      

      this.formatPhoneNumber(this.data.placeDTO.phone);


      this.imageToDisplay = placeDTO.images.filter((image:ImagesResponse)=>image.name !== null);
      this.logoToDisplay = placeDTO.logo;

      // console.log(this.imageToDisplay);
      

      if (this.data.isEdit && this.data.placeDTO) {
        // If in edit mode and a borough is provided, construct the googleMapsLink
        this.googleMapsLink = `https://www.google.com/maps?@${this.data.placeDTO.latitude},${this.data.placeDTO.longitude}`;
      }
      // Set the file names for display
      this.selectedLogoFileName = placeDTO.logo;
      this.selectedImageFileName = placeDTO.image;
      
    }

    else {
      this.form = this.formBuilder.group({
        placeName: ['', Validators.required],  
        placeDescription: ['', Validators.required],
        placesImages: [['Choose An Image'], Validators.required],
        placesLogo: ['Choose An Logo', Validators.required],
        placeUrl: [''],
        latitude: ['', Validators.required],
        longitude: ['', Validators.required],
        phone: ['', Validators.required],
        email: [''],
        address: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        country: ['United States'],
        workingHours: ['', Validators.required],
        selectedCategory: ['', Validators.required],
        selectedBorough: ['', Validators.required],
      });
    }
    this.getAllCategories();
    this.getAllBoroughs();
    this.getAllEvents();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  // uploadFileName(event: any, type: 'logo' | 'image'): void{
  //   const file = (event.target as HTMLInputElement).files?.[0];
  //   // console.log(file);
  //   if (type === 'logo')
  //   {
  //     this.form.get('placesLogo').setValue(file.name);
  //     this.logoNameUploaded = true;

  //   } else {
  //     this.form.get('placesImage').setValue(file.name);
  //     this.pictureNameUploaded = true;
  //   }
  //   // console.log(this.form.get('fileAttr').value);
  // }

  // deleteImageByName(index: number): void {
  //   // Ensure the index is within bounds

  //   console.log(index);
    
  //   if (index >= 0 && index < this.imageToDisplay.length) {
  //     const imageNameToDelete = this.imageToDisplay[index];
  
  //     console.log(imageNameToDelete);
      
  //     // Call your deleteFile API with the imageNameToDelete
  //     this.fileManagement.deleteFile(imageNameToDelete).subscribe(
  //       response => {
  //         // Handle success
  //         console.log('File deleted successfully', response);
  
  //         // Clear the deleted image URL
  //         this.imageToDisplay = '';
  //       },
  //       error => {
  //         // Handle error
  //         console.error('Error deleting file', error);
  //       }
  //     );
  //   }
  // }
  
  
  
  
  
  
  
  uploadFileEvt(event: any): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    // console.log(file);
    this.form.get('placesLogo').setValue(file.name);
    this.logoNameUploaded = true;
    // console.log(this.form.get('fileAttr').value);  
  
    if (file) {
      this.fileManagement.uploadFileForm('uploads', file)
        .subscribe(
          (response) => {
            // console.log('File uploaded successfully:', response);

            // this.form.patchValue({
            //   placesLogo: response.message              
            // });
            // console.log(this.form.get('placesLogo').value);

          },
          (error) => {
            console.error('Error uploading file:', error);
            // Handle error as needed
          }
        );
    }
  }

  
  uploadFileEvtMultiple(event: any) {
    this.noNewUploads = false;
    const fileList: FileList = event.target.files;
  
    if (fileList && fileList.length > 0) {
      const files: File[] = Array.from(fileList);
  
      // console.log(files);
  
      // Get the current array of file objects from the form control
      let currentFiles: any[] = this.form.get('placesImages').value ?? [];
      // console.log(currentFiles);
  
      // Create an array to accumulate the new files
      let newFiles: any[] = [];
  
      files.forEach(file => {
        // Check if the file is already in the list
        const isDuplicate = currentFiles.some(existingFile => {
          if (typeof existingFile === 'string') {
            return existingFile === file.name;
          } else if (typeof existingFile === 'object' && existingFile.name) {
            return existingFile.name === file.name;
          }
          return false;
        });
  
        // If it's not a duplicate, add it to the list
        if (!isDuplicate) {
          newFiles.push(file);
        }
      });
  
      // Filter out unwanted files from the currentFiles array
      currentFiles = currentFiles.filter((file) => {
        if (typeof file === 'string') {
          return !file.includes("http") && file !== "Choose An Image";
        } else if (typeof file === 'object' && file.name) {
          return !file.name.includes("http") && file.name !== "Choose An Image";
        } else {
          return true;
        }
      });
  
      // Concatenate the new files with the filtered currentFiles
      const updatedFileObjects: { id: null, name: string }[] = newFiles.map(file => ({ id: null, name: file.name }));
      const updatedFileNames: string[] = [...currentFiles, ...updatedFileObjects.map(file => file.name)];
            
      // console.log('updatedFileObjects', updatedFileObjects);
      // console.log('updatedFileNames', updatedFileNames);
      
      // Concatenate currentFiles with updatedFileObjects
      const allFiles: { id: null, name: string }[] = [...currentFiles, ...updatedFileObjects];
      
      // Update the array of file objects in the form control with allFiles
      this.form.get('placesImages').setValue(allFiles);
      

      
      // console.log(this.form.get('placesImages').value, 'placesImages value');
      

      this.imagesNameUploaded = true;
  
      // Call the file upload service for each new file
      newFiles.forEach(file => {
        this.fileManagement.uploadFileForm('uploads', file).subscribe(
          (response) => {
            this.addedNewPicture = true;
            // Handle response if needed
          },
          (error) => {
            console.error('File upload failed:', error);
            // Handle error as needed
          }
        );
      });
    }
  }
  
  
  
  
  
uploadImages(response: PlaceDTO, imageData: PlaceDTO) {
  // console.log('the response after updating', imageData);

  const recordId = response.id;
  const recordType = 'PLACE';
  let imageNames = imageData.images || [];

  if(this.addedNewPicture == true){

    const uniqueImageNames = [...new Set(imageNames.map(image => image.name))];

    // console.log('uniqueImageNames', uniqueImageNames);
    // console.log('recordId', recordId);
    // console.log('recordType', recordType);


    this.fileManagement.createImages(uniqueImageNames, recordId, recordType).subscribe(
      (images) => {
        // console.log('Images created successfully:', images);
        // Handle success if needed
      },
      (error) => {
        console.error('Error creating images:', error);
        // Handle error as needed
      }
    );  } else{

    }

  // Remove duplicate image names
  
}



  validateGoogleMapsLink() {
    this.isGoogleMapsLinkValid = this.googleMapsLink.trim() !== '';
  }

  extractLatLng() {
    const match = this.googleMapsLink.match(/@(-?\d+\.\d+),(-?\d+\.\d+)/);
    if (match) {
      this.linkNotValid = false;

      const latitude = match[1];
      const longitude = match[2];

      // Update the form controls
      this.form.patchValue({
        latitude: latitude,
        longitude: longitude
      });
    } else{
      this.linkNotValid = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveDialog(): void {
    this.validateGoogleMapsLink();

    this.extractLatLng(); 
  
    const placeData: PlaceDTO = {
      name: this.form.value.placeName.trim(),
      description: this.form.value.placeDescription.trim(),
      images: this.form.value.placesImages.map((imageName: any) => ({id: null, name: imageName.name})),      
      logo: this.form.value.placesLogo,
      url: this.form.value.placeUrl.trim(),
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude,
      phone: this.form.value.phone.replace(/[^\d]/g, '').trim(),
      email: this.form.value.email.trim(),
      address: this.form.value.address.trim(),
      city: this.form.value.city.trim(),
      state: this.form.value.state,
      country: this.form.value.country,
      workingHours: this.form.value.workingHours.trim(),
      categoryId: this.form.value.selectedCategory,
      boroughId: this.form.value.selectedBorough,
      // eventId: this.form.value.selectedEvent,
    };
    // console.log('placeData after mapping',placeData);
    
  
    if (this.data?.isEdit) {
      // Editing an existing place
 
      // console.log('place data',placeData);
      // console.log(this.form.value.placeImages)
      // const editImagesValue = this.data.placeDTO.images;
      // const editedPlace = { ...placeData };
  
      // console.log(editImagesValue);
      
      if (placeData.logo.startsWith('https://')){
        placeData.logo = placeData.logo.split('/').pop();
      }
      // console.log('pred',placeData.images);

      if (placeData.images) {
        // console.log('placesData.images',placeData.images);
        
        placeData.images = placeData.images.map(image => ({
          id: null,
          name: image.name.startsWith('https://') ? image.name.split('/').pop() : image.name
        })) as ImagesResponse[]; // Type assertion to assign the modified array back to ImagesResponse[]
      }
      
        // console.log('posle',placeData)
      

      // console.log('place data before updating',placeData);
      
      this.placesService.updatePlace(placeData, this.data.placeDTO.id).subscribe(
        (response: PlaceDTO) => {
          // console.log('Place updated successfully:', response);
          // const patchedImages;
          this.uploadImages(response,placeData);

          this.dialogRef.close(response);

        },
        error => {
          // console.error('Error updating Place:', error);
        }
      );
    } else {
      // Creating a new place
      this.placesService.createPlace(placeData).subscribe(
        (response: PlaceDTO) => {
          // console.log('Place added successfully:', response);
          this.uploadImages(response,placeData);


          this.dialogRef.close(response);

        },
        error => {
          // console.error('Error adding Place:', error);
        }
      );
    }
  
    // Clear error message
    this.clearError();
  }
  

  clearError() {
    this.categoryNameError = false;
  }


  getAllCategories() {
    this.categoryService.getAllCategories1().subscribe(
      (categories: CategoryDTO[]) => {
        this.categories = categories;
      },
      (error) => {
        // console.error('Error fetching categories:', error);
      }
    );
  }

  getAllBoroughs() {
    this.boroughService.getAllBoroughs1().subscribe(
      (boroughs: BoroughDTO[]) => {
        this.boroughs = boroughs;
      },
      (error) => {
        // console.error('Error fetching boroughs:', error);
      }
    );
  }

  getAllEvents() {
    this.eventService.getAllEvents1().subscribe(
      (events: EventDTO[]) => {
        this.events = events;
      },
      (error) => {
        // console.error('Error fetching Events:', error);
      }
    );
  }

  formatPhoneNumber(value: string): void {
  
    const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    this.form.get('phone').setValue(formattedValue);
    
  }

  // deletePicture(index: number) {
  //   const imageToDelete = this.imageToDisplay[index];
    
  //   // Assuming the API requires a string id, you might need to convert it to a string
  //   // const stringId: string = imageToDelete.id.toString();

  //   console.log(this.imageToDisplay);
    
  
  //   this.fileManagement.deleteImage(imageToDelete['id']).subscribe((response) => {
  //     // Handle the response or any additional logic
  //     // ...
  //     console.log('image deleted', response);

  //     if (Array.isArray(this.imageToDisplay)) {
  //       this.imageToDisplay.splice(index, 1);
  //     }
      
  //   });
  // }

  deletePicture(index: number){
    const imageToDelete = this.imageToDisplay[index];

    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent);
  
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'yes') {
        this.fileManagement.deleteImage(imageToDelete['id']).subscribe((response) => {
          // Handle the response or any additional logic
          // ...
          // console.log('image deleted', response);

          if (Array.isArray(this.imageToDisplay)) {
            this.imageToDisplay.splice(index, 1);
          }
          
        });
      } else {
        // 'No' case: do nothing or handle it as needed
      }
    });
  }

  
  
}
 