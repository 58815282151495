<div class="col-lg-10 col-md-9 col-sm-8 p-0">
  <!-- <header class="p-3 mb-3 border-bottom">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-3">


    </div>
  </header> -->
</div>
<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="logo-image">
  <img src="https://www.coolculture.org/wp-content/uploads/2022/01/logo.png" alt="Logo" width="198" height="76" />
</div>

<div class="conainer">
  <div class="d-flex justify-content-center image-container">
    <img class="image-style" src="https://www.coolculture.org/wp-content/uploads/2022/03/48086683432_b342f8fe06_o.jpg" alt="Logo" width="100%" height="630" style="object-fit: cover;"/>
      <div class="gradient-overlay"></div>
        <!-- <div class="text-container col-lg-4"> -->
        <div class="text-container col-lg-4">
          <div class="big-text">{{ 'familyPassRegistration' | translate}}</div>
          <div class="small-text">{{ 'familyPassRegistrationText' | translate}}</div>
    </div>
  </div>
</div>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container-fluid d-flex justify-content-center pt-4 pb-4 px-lg-5">
  <div class="col-lg-8 col-md-8 col-sm-8">
    <mat-card class="card-size">
      <mat-card-content>

      
        <!-- <ng-template #errorMessage>
          <div class="container-fluid d-flex justify-content-center flex-column text-center">
            <div>
              <h1>{{ 'errorCode' | translate }}</h1>
            </div>
            <div>
              <form #codeForm="ngForm" (ngSubmit)="updateUrl(codeForm)">
                <mat-form-field class="field">
                  <mat-label>{{ 'EnterCode' | translate }}</mat-label>
                  <input
                    id="codeInput"
                    matInput
                    placeholder="Your code"
                    name="code"
                    ngModel
                    required
                  />
                </mat-form-field>
                <div class="button-placement">
                  <button mat-raised-button class="full-width-button" type="submit">
                    {{ 'submit' | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
         -->


        <!-- <ng-container *ngIf="isCodeValid; else errorMessage"> -->
        <form [formGroup]="registrationForm">

          <div class="d-flex col">
            <div class="dropdown text-start pt-2 pb-4">
            <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><strong style="font-size: 1.2rem;">{{ 'language' | translate}}</strong></a>
            <ul class="dropdown-menu text-small">
              <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" style="cursor: pointer;" (click)="selectLang('en')">EN</a></li>
              <li>
                <hr class="dropdown-divider"/>
              </li>
              <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" style="cursor: pointer;" (click)="selectLang('es')">ES</a></li>
              <li>
                <hr class="dropdown-divider"/>
              </li>
              <li><a class="d-flex justify-content-center align-items-center dropdown-item p-0" style="cursor: pointer;" (click)="selectLang('cn')">CN</a></li>
            </ul>
          </div>
          <div class="d-flex align-items-center px-2">
            <p><strong style="font-size: 1.2rem;">{{ registrationForm.get('language').value}}</strong></p>
          </div>
            </div>
          
        <mat-card-title>{{'registerFirstTimerTitle' | translate}}</mat-card-title>
        <p>{{'registerFirstTimerInfo' | translate}}</p>
    
<!--     
        <h3 class="pt-5 mb-1 fw-bold">{{'registerSchoolOrCenter' | translate}}</h3>
        <mat-form-field class="col-12">
            <mat-label>{{'select' | translate}}</mat-label>
            <mat-select (selectionChange)="onTypeSelectionChange($event)" formControlName="registerSchoolOrCenter">
              <mat-option value="school">{{'school' | translate}}</mat-option>
              <mat-option value="center">{{'center' | translate}}</mat-option>
            </mat-select>
          </mat-form-field> -->

        <!-- <h3 class="pt-3 mb-1 fw-bold">{{'registerFor' | translate}}</h3>
        <mat-form-field class="col-12" appearance="outline">
            <input matInput hidden>
            <span class="grayed-out-text">Example BATCH001</span>
        </mat-form-field> -->
    
        <h2 class="pt-4 fw-bold d-flex justify-content-center">{{ 'SchoolCenterRegistrationQuestions' | translate}}</h2>

        <h3 class="pt-3 mb-1 fw-bold">{{'registerWhichSchool' | translate}}</h3>
        <mat-form-field class="col-12" appearance="outline">
            <!-- <mat-label>{{'select' | translate}}</mat-label> -->
            <!-- <mat-select formControlName="organisationId">
              <mat-option *ngFor="let org of filteredOrganizations" [value]="org.id">
                {{ org.name }}
              </mat-option>
            </mat-select> -->
            <input matInput formControlName="organisationId" hidden>
            <span *ngIf="filteredOrganizations.length > 0" class="grayed-out-text">{{ filteredOrganizations[0].Name || filteredOrganizations[0].organisationName }}</span>
          </mat-form-field>

          


          <h3 class="pt-2 mb-1 fw-bold">{{'confirmSchool' | translate}}</h3>
          <mat-error *ngIf="isFormSubmitted && registrationForm.get('confirmSchool').hasError('required')">
            {{ 'MustBeTrue' | translate }}
          </mat-error>
          <mat-radio-group color="warn" aria-label="Select an option" formControlName="confirmSchool">
            <mat-radio-button [value]="true">{{ 'yes' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false">{{ 'no' | translate }}</mat-radio-button>
          </mat-radio-group>

          
          <div formGroupName="family">
          <div formArrayName="adults">
          <div [formGroupName]="0">

          
          <h3 class="pt-5 mb-1 fw-bold">{{'areYouStaffMember' | translate}}</h3>
          <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.adults.0.isMemberAtSchoolCenter').hasError('required')">
            {{ 'MustSelectOption' | translate }}
          </mat-error>
          <mat-radio-group color="warn" aria-label="Select an option" formControlName="isMemberAtSchoolCenter">
            <mat-radio-button [value]="true">{{ 'yes' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false">{{ 'no' | translate }}</mat-radio-button>
          </mat-radio-group>
    
          <ng-container *ngIf="registrationForm.get('family.adults.0.isMemberAtSchoolCenter').value === true">
          <h3 class="pt-3 mb-1 fw-bold">{{'doYouHaveChildren' | translate}}</h3>
          <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.adults.0.haveChildren').hasError('required')">
            {{ 'MustSelectOption' | translate }}
          </mat-error>
          <mat-radio-group color="warn" aria-label="Select an option" formControlName="haveChildren">
            <mat-radio-button [value]="true">{{'yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false">{{'no' | translate}}</mat-radio-button>
          </mat-radio-group>
    
          <h3 class="pt-3 mb-1 fw-bold">{{'assignedLiaison' | translate}}</h3>
          <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.adults.0.assignedCoolCultureCulturalLiaison').hasError('required')">
            {{ 'MustSelectOption' | translate }}
          </mat-error>
          <mat-radio-group color="warn" aria-label="Select an option" formControlName="assignedCoolCultureCulturalLiaison">
            <mat-radio-button [value]="true">{{'yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false">{{'no' | translate}}</mat-radio-button>
          </mat-radio-group>
    
          <p class="pt-3">{{'staffQuestionsText1' | translate}}</p>

          <p>{{'staffQuestionsText2' | translate}}</p>
          <h3 class="pt-3 mb-1 fw-bold">{{'yourPositionOrTitle' | translate}}</h3>
          <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.adults.0.positionTitle').hasError('required')">
            {{ 'MustSelectOption' | translate }}
          </mat-error>
          <mat-radio-group color="warn" aria-label="Select an option" class="d-flex flex-column" formControlName="positionTitle">
            <mat-radio-button *ngFor="let position of positions" [value]="position.value">{{ position.translationKey | translate }}</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="registrationForm.get('family.adults.0.positionTitle').value === 'Other'">
              <p class="pt-4">{{'OtherPosition' | translate}}</p>
              <mat-form-field class="non-resizable col-12" appearance="outline">
              <input matInput maxlength="40" formControlName="stuffPositionOther">
            </mat-form-field>
            </div>
            
          </ng-container>
    
  
        <!-- Caregiver/parent-1 -->

        <h2 class="pt-4 fw-bold d-flex justify-content-center">{{'adultCaregiverQuestions' | translate}}</h2>
        <p class="word-wrap">{{'adultCaregiverQuestionsText' | translate}}</p>
        <p class="word-wrap pt-4">{{'adultCaregiverQuestionsText2' | translate}}</p>

        <h2 class="pt-5 fw-bold d-flex justify-content-center">{{'caregiver1' | translate}}</h2>
        <h3 class="mb-1 fw-bold">{{'Name' | translate}}</h3>
             
          <div class="row">
            <div class="col">
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>{{'FirstName' | translate}}</mat-label>
                <input matInput formControlName="firstName">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>{{'LastName' | translate}}</mat-label>
                <input matInput formControlName="lastName">
              </mat-form-field>
            </div>
          </div>

          <div class="row">

            <!-- <div class="col">
              <h3 class="mb-1 fw-bold">{{'birthdate' | translate}}</h3>
              <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'chooseDate' | translate}}</mat-label>
              <input matInput [matDatepicker]="datepicker1" formControlName="birthdate" readonly (click)="datepicker1.open()">
              <mat-datepicker-toggle matIconSuffix [for]="datepicker1"></mat-datepicker-toggle>
              <mat-datepicker #datepicker1></mat-datepicker>
              </mat-form-field>
            </div> -->

              <div class="col">
                <h3 class="mb-1 fw-bold">{{ 'birthdate' | translate }}</h3>
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>{{ 'select' | translate }}</mat-label>
                  <mat-select formControlName="birthdate" placeholder="{{ 'select' | translate }}">
                    <mat-option *ngFor="let year of birthYears" [value]="year">{{ year | slice:0:4  }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            
            
             
            <div class="col">
              <h3 class="mb-1 fw-bold">{{'gender' | translate}}</h3>
              <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'select' | translate}}</mat-label>
              <mat-select formControlName="gender">
              <mat-option *ngFor="let gender of genders" [value]="gender.value">
                  {{gender.viewValue | translate}}
              </mat-option>
              </mat-select>
              </mat-form-field>
            </div>  
          </div>

          <ng-container *ngIf="registrationForm.get('family.adults.0.gender').value === 'Other Gender Identities'">
            <h3 class="mb-1 fw-bold">{{'GenderInfo' | translate}}</h3>
          <mat-form-field class="non-resizable col-12" appearance="outline">
            <textarea matInput formControlName="genderCustom"></textarea>
          </mat-form-field>
          </ng-container>
          
    
          <h3 class="mb-1 fw-bold">{{'EmailAddress' | translate}}</h3>
              <mat-form-field class="field" appearance="outline">
              <mat-label>{{'EnterEmail' | translate}}</mat-label>
              <input matInput formControlName="email" placeholder="email@example.com">
              <mat-error *ngIf="registrationForm.get('email').hasError('required')">
                {{ 'EnterEmailError' | translate }}
              </mat-error>
              <mat-error *ngIf="registrationForm.get('email').hasError('email') && !registrationForm.get('email').hasError('required')">
                {{ 'EmailRequiredError' | translate }}
              </mat-error>
            </mat-form-field>
            <p class="pb-3">{{'AdultQuestionsTextInfo' | translate}}</p>
    
            <div class="row">
              <div class="col">
                 <h3 class="mb-1 fw-bold">{{'boroughResidence' | translate}}</h3>
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>{{'select' | translate}}</mat-label>
                    <mat-select formControlName="boroughOfResidence">
                      <mat-option *ngFor="let borough of boroughs" [value]="borough.value">
                        {{borough.viewValue | translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
              <div class="col">
                <h3 class="mb-1 fw-bold">{{'mobilePhone' | translate}}</h3>
                  <mat-form-field class="example-full-width col-12" appearance="outline">
                    <mat-label></mat-label>
                    <input matInput maxlength="40" 
                    formControlName="mobilePhone"       
                    (input)="formatPhoneNumber($event.target.value, 0)"
                    >
                  </mat-form-field>
              </div>
            </div>
          </div>
  
            <ng-container *ngIf="showOptional">

            <h3 class="pt-5 mb-1 fw-bold">{{ 'secondAdultOptional' | translate }}</h3>
          <mat-radio-group color="warn" aria-label="Select an option" [value]="selectedOption" (change)="onRadioChange($event)">
            <mat-radio-button [value]="true">{{ 'yes' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false">{{ 'no' | translate }}</mat-radio-button>
          </mat-radio-group>
          
            </ng-container>

          
          
          <ng-container *ngIf="secondAdultExists">

          <!-- Caregiver/parent-2 -->

        <ng-container *ngIf="selectedOption === true">

          <h2 class="pt-1 fw-bold d-flex justify-content-center">{{'caregiver2' | translate}}</h2>
          <p>{{'AdultCaregiver2Info' | translate}}</p>
          <h3 class="mb-1 fw-bold">{{'Name' | translate}}</h3>
          <div [formGroupName]="1">     
          <div class="row">
            <div class="col">
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>{{'FirstName' | translate}}</mat-label>
                <input matInput formControlName="firstName">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>{{'LastName' | translate}}</mat-label>
                <input matInput formControlName="lastName">
              </mat-form-field>
            </div>
          </div>
    
          <div class="row">
  
            <div class="col">
              <h3 class="mb-1 fw-bold">{{ 'birthdate' | translate }}</h3>
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>{{ 'chooseDate' | translate }}</mat-label>
                <mat-select formControlName="birthdate" placeholder="{{ 'select' | translate }}">
                  <mat-option *ngFor="let year of birthYears" [value]="year">{{ year | slice:0:4  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
           
            <div class="col">
              <h3 class="mb-1 fw-bold">{{'gender' | translate}}</h3>
              <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'select' | translate}}</mat-label>
              <mat-select formControlName="gender">
                <mat-option *ngFor="let gender of genders" [value]="gender.value">
                  {{gender.viewValue | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            </div>   
          </div>
  
          <ng-container *ngIf="registrationForm.get('family.adults.1.gender').value === 'Other Gender Identities'">
            <h3 class="mb-1 fw-bold">{{'GenderInfo' | translate}}</h3>
          <mat-form-field class="non-resizable col-12" appearance="outline">
            <textarea matInput formControlName="genderCustom"></textarea>
          </mat-form-field>
          </ng-container>
            
    
          <h3 class="mb-1 fw-bold">{{'EmailAddress' | translate}}</h3>
              <mat-form-field class="field" appearance="outline">
              <mat-label>{{'EnterEmail' | translate}}</mat-label>
              <input type="email" matInput formControlName="email" placeholder="email@example.com">
              <mat-error *ngIf="registrationForm.get('email').hasError('required')">
                {{ 'EnterEmailError' | translate }}
              </mat-error>
              <mat-error *ngIf="registrationForm.get('email').hasError('email') && !registrationForm.get('email').hasError('required')">
                {{ 'EmailRequiredError' | translate }}
              </mat-error>
            </mat-form-field>
  
            <p class="pb-3">{{'AdultQuestionsTextInfo' | translate}}</p>
  
            
            <div class="row">
              <div class="col">
                 <h3 class="mb-1 fw-bold">{{'boroughResidence' | translate}}</h3>
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label>{{'select' | translate}}</mat-label>
                    <mat-select formControlName="boroughOfResidence">
                      <mat-option *ngFor="let borough of boroughs" [value]="borough.value">
                        {{borough.viewValue | translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
              <div class="col">
                <h3 class="mb-1 fw-bold">{{'mobilePhone' | translate}}</h3>
                  <mat-form-field class="col-12" appearance="outline">
                    <mat-label></mat-label>
                    <input matInput maxlength="40" 
                    formControlName="mobilePhone"
                    (input)="formatPhoneNumber($event.target.value, 1)"
                    >
                  </mat-form-field>
              </div>
            </div>
          </div> 

        </ng-container>
      </ng-container>
   

      </div>


      <div formArrayName="children">
      <div *ngFor="let childForm of childrenForms.controls; let i = index" [formGroupName]="i">

        <ng-container *ngIf="this.registrationForm.get('family.adults.0.haveChildren').value === true || registrationForm.get('family.adults.0.haveChildren').value === null">
          <h2 class="pt-5 fw-bold d-flex justify-content-center">{{'childQuestions' | translate}}</h2>
        <p class="word-wrap">{{'childQuestionsDescription' | translate}}</p>
        <p class="word-wrap">{{'childQuestionsDescription2' | translate}}</p>
        <h3 class="mb-1 fw-bold">{{'childName' | translate}}</h3>

        <div class="row">
          <div class="col">
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'ChildFirstName' | translate}}</mat-label>
              <input matInput formControlName="firstName">
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'ChildLastName' | translate}}</mat-label>
              <input matInput formControlName="lastName">
            </mat-form-field>
          </div>
        </div>

          <div class="col">
            <h3 class="mb-1 fw-bold">{{'childGradeProgram' | translate}}</h3>
          <mat-form-field class="col-12" appearance="outline">
            <mat-label></mat-label>
            <input matInput formControlName="gradeProgram">
          </mat-form-field>
          <p>{{'childGradeProgramExamples' | translate}}</p>
          </div>
          
          <div class="col">
            <h3 class="mb-1 fw-bold">{{'childClassroom' | translate}}</h3>
          <mat-form-field class="col-12" appearance="outline">
            <mat-label></mat-label>
            <input matInput formControlName="classroom">
          </mat-form-field>
          <p>{{'childClassroomExplained' | translate}}</p>
          </div>
          <div class="col">
             <h3 class="mb-1 fw-bold">{{'childTeacher' | translate}}</h3>
          <mat-form-field class="col-12" appearance="outline">
            <mat-label></mat-label>
            <input matInput formControlName="teacher">
          </mat-form-field>
          <p>{{'childClassroomExplained' | translate}}</p>
          </div>
        </ng-container>  
        </div>
      </div>

        <ng-container *ngIf="registrationForm.get('family.adults.0.haveChildren').value === true || registrationForm.get('family.adults.0.haveChildren').value === null">
          <div class="col">
                <h3 class="mb-1 fw-bold">{{'numberChildren' | translate}}</h3>
            <mat-form-field class="col-12" appearance="outline">
                <mat-label>{{'select' | translate}}</mat-label>
                <mat-select formControlName="numOfChildren">
                  <mat-option *ngFor="let number of numbers" [value]="number.value">
                    {{number.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </ng-container>
      
            <h2 class="pt-5 fw-bold d-flex justify-content-center">{{'printFamilyName' | translate}}</h2>

            <p>{{'printFamilyNameText1' | translate}}</p>
            
            <div class="d-flex justify-content-center">
              <img src="assets/images/FamilyPassCard.png" alt="" class="card-image">
            </div>
              

            <p>{{'printFamilyNameText2' | translate}}</p>

            <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'printFamilyName' | translate}}</mat-label>
              <input matInput maxlength="100" formControlName="familyName">
            </mat-form-field>

            <p>{{'printFamilyNameText3' | translate}}</p>

            <p>{{'printFamilyNameText4' | translate}}</p>

            <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'select' | translate}}</mat-label>
              <mat-select formControlName="isInterestedInDigitalCard">
                <mat-option *ngFor="let choice of choices" [value]="choice.value">
                  {{choice.viewValue | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            
            <h2 class="pt-5 fw-bold d-flex justify-content-center">{{'museumExperience' | translate}}</h2>
            <p class="word-wrap">{{'museumExperience0' | translate}}</p>
            <h3 class="mb-1 mt-3 fw-bold">{{'museumExperience1' | translate}}</h3>
            <mat-radio-group color="warn" aria-label="Select an option" formControlName="isVisitedEvent">
              <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.isVisitedEvent').hasError('required')">
                {{ 'MustSelectOption' | translate }}
              </mat-error>
              <mat-radio-button [value]="true">{{'yes' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false">{{'no' | translate}}</mat-radio-button>
            </mat-radio-group>
  
            <ng-container *ngIf="registrationForm.get('family.isVisitedEvent').value === true">
            <h3 class="mb-1 mt-2 fw-bold">{{'museumExperience2' | translate}}</h3>
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'select' | translate}}</mat-label>
              <mat-select formControlName="timesVisitedEvent">
                <mat-option *ngFor="let number of museumVisits" [value]="number.value">
                  {{number.viewValue | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
  
            <h3 class="mb-1 fw-bold">{{'museumExperience3' | translate}}</h3>
              <!-- <mat-label>{{'select' | translate}}</mat-label> -->
              <mat-error *ngIf="isFormSubmitted && registrationForm.get('family').hasError('noYearSelected')">
                {{ 'MustSelectOption' | translate }}
              </mat-error>
              <section class="d-flex flex-column">
                <mat-checkbox color="warn" formControlName="visitedYear2017">2017</mat-checkbox>
                <mat-checkbox color="warn" formControlName="visitedYear2018">2018</mat-checkbox>
                <mat-checkbox color="warn" formControlName="visitedYear2019">2019</mat-checkbox>
                <mat-checkbox color="warn" formControlName="visitedYear2020">2020</mat-checkbox>
                <mat-checkbox color="warn" formControlName="visitedYear2021">2021</mat-checkbox>
                <mat-checkbox color="warn" formControlName="visitedYear2022">2022</mat-checkbox>
                <mat-checkbox color="warn" formControlName="visitedYear2023">2023</mat-checkbox>
                <mat-checkbox hidden color="warn" formControlName="visitedYear2024">2024</mat-checkbox>
                <mat-checkbox hidden color="warn" formControlName="visitedYear2025">2025</mat-checkbox>
              </section>
            </ng-container>
            <h2 class="pt-5 pb-4 fw-bold d-flex justify-content-center">{{'demographicInformation' | translate}}</h2>
            <p>{{'demographicInformation1' | translate}}</p>
            <div formArrayName="children">
              <div *ngFor="let childForm of childrenForms.controls; let i = index" [formGroupName]="i">

            <h3 class="mb-1 fw-bold">{{'mainLanguageSpokenAtHome' | translate}}</h3>
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'select' | translate}}</mat-label>
              <mat-select formControlName="primaryLanguageSpokenByParent">
                <mat-option *ngFor="let language of languages" [value]="language.value">
                  {{language.viewValue | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
  
            <h3 class="mb-1 fw-bold">{{'otherLanguageSpokenAtHome' | translate}}</h3>
            <div>
              <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.children.0').hasError('atLeastOneLanguageRequired')">
                {{ 'MustSelectOption' | translate }}
              </mat-error>
              <section class="d-flex flex-column">
                <mat-checkbox formControlName="otherLanguageArabic" color="warn">{{ 'language1' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageBengali" color="warn">{{ 'language2' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageCantonese" color="warn">{{ 'language3' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageEnglish" color="warn">{{ 'language4' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageFrench" color="warn">{{ 'language5' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageHaitianCreole" color="warn">{{ 'language6' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageHebrew" color="warn">{{ 'language7' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageKorean" color="warn">{{ 'language8' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageMandarin" color="warn">{{ 'language9' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageSpanish" color="warn">{{ 'language10' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageRussian" color="warn">{{ 'language11' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageUrdu" color="warn">{{ 'language12' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageYiddish" color="warn">{{ 'language13' | translate }}</mat-checkbox>
                <mat-checkbox formControlName="otherLanguageOther" color="warn" >{{ 'other' | translate }}</mat-checkbox>

              </section>
              
              <!-- Add more checkboxes for other languages -->
            </div>

            
          </div>
          </div>


            <h3 class="pt-3 fw-bold">{{'ethnicBackgroundChild' | translate}}</h3>
            <mat-error *ngIf="isFormSubmitted && registrationForm.hasError('childEthnicityNotChecked')">
              {{ 'MustSelectOption' | translate }}
            </mat-error>
                <section class="d-flex flex-column">
                  <mat-checkbox color="warn" formControlName="racialAmericanIndianOrAlaskanNative">{{'ethnicity1' | translate}}</mat-checkbox>
                  <mat-checkbox color="warn" formControlName="racialAsian">{{'ethnicity2' | translate}}</mat-checkbox>
                  <mat-checkbox color="warn" formControlName="racialBlackOrAfricanAmerican">{{'ethnicity3' | translate}}</mat-checkbox>
                  <mat-checkbox color="warn" formControlName="racialLatinoOrLatinx">{{'ethnicity4' | translate}}</mat-checkbox>
                  <mat-checkbox color="warn" formControlName="racialNativeAmericanOrPacificIslander">{{'ethnicity5' | translate}}</mat-checkbox>
                  <mat-checkbox color="warn" formControlName="racialWhite">{{'ethnicity6' | translate}}</mat-checkbox>
                  <mat-checkbox color="warn" formControlName="racialPreferNotToAnswer">{{'ethnicity7' | translate}}</mat-checkbox>
                </section>

            <h3 class="pt-3 fw-bold">{{'highestEducation' | translate}}</h3>
            <mat-radio-group color="warn" aria-label="Select an option" class="d-flex flex-column" formControlName="highestLevelEducation">
              <mat-error *ngIf="isFormSubmitted && registrationForm.get('family.highestLevelEducation').hasError('required')">
                {{ 'MustSelectOption' | translate }}
              </mat-error>
              <mat-radio-button value="Less than 9th grade">{{'education1' | translate}}</mat-radio-button>
              <mat-radio-button value="9th to 12th grade, no diploma">{{'education2' | translate}}</mat-radio-button>
              <mat-radio-button value="High school graduate (incl. GED or alternative credential)">{{'education3' | translate}}</mat-radio-button>
              <mat-radio-button value="Some college">{{'education4' | translate}}</mat-radio-button>
              <mat-radio-button value="Associate's degree (i.e AA, AS)">{{'education5' | translate}}</mat-radio-button>
              <mat-radio-button value="Bachelor's Degree (i.e BA, BS)">{{'education6' | translate}}</mat-radio-button>
              <mat-radio-button value="Graduate or professional degree (i.e MA, MS, Ph.D, JD)">{{'education7' | translate}}</mat-radio-button>
              <mat-radio-button value="Other">{{'other' | translate}}</mat-radio-button>
            </mat-radio-group>
  
            <h3 class="mb-1 pt-3 fw-bold">{{'currentHouseholdIncome' | translate}}</h3>
            <p>{{'currentHouseholdIncome1' | translate}}</p>
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>{{'select' | translate}}</mat-label>
              <mat-select formControlName="currentHouseholdIncomeRange">
                <mat-option *ngFor="let income of incomes" [value]="income.value">
                  {{income.viewValue | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <h1 class="pt-5 fw-bold">{{'consentAndRequirement' | translate}}</h1>
        <div>
            <p>{{'consentAndRequirementText1' | translate}}</p>
            
            <p class="ms-3">{{'consentAndRequirementText1a' | translate}}</p>
            <ul>
              <p>{{'consentAndRequirementText1b' | translate}}</p>
              <li class="ms-4">{{'consentAndRequirementText1c' | translate}}</li>
              <li class="ms-4">{{'consentAndRequirementText1d' | translate}}</li>
              <li class="ms-5">{{'consentAndRequirementText1e' | translate}} <a href="https://www1.nyc.gov/site/idnyc/card/how-to-apply.page">{{'here' | translate}}</a>. {{'consentAndRequirementText1f' | translate}}</li>
              <li class="ms-4">{{'consentAndRequirementText1g' | translate}}</li>
              <li class="ms-4">{{'consentAndRequirementText1h' | translate}}</li>
              <li class="ms-4">{{'consentAndRequirementText1i' | translate}}</li>
            </ul>
        </div>
            <mat-radio-group color="warn" aria-label="Select an option" formControlName = "consentAndRequirement1">
              <mat-error *ngIf="isFormSubmitted && registrationForm.get('consentAndRequirement1').hasError('required')">
                {{ 'mustAgreeToApply' | translate }}
              </mat-error>
              <mat-radio-button [value]="true">{{'iAgree' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false">{{'iDoNotAgree' | translate}}</mat-radio-button>
            </mat-radio-group>
  
            <p class="pt-4 mb-0">{{'consentAndRequirementText2' | translate}}</p>
            <mat-radio-group color="warn" aria-label="Select an option" formControlName = "consentAndRequirement2">
              <mat-error *ngIf="isFormSubmitted && registrationForm.get('consentAndRequirement2').hasError('required')">
                {{ 'mustAgreeToApply' | translate }}
              </mat-error>
              <mat-radio-button [value]="true">{{'iAgree' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false">{{'iDoNotAgree' | translate}}</mat-radio-button>
            </mat-radio-group>
  
            <p class="pt-4 mb-0">{{'consentAndRequirementText3' | translate}}</p>
            <mat-radio-group color="warn" aria-label="Select an option" formControlName = "consentAndRequirement3">
              <mat-error *ngIf="isFormSubmitted && registrationForm.get('consentAndRequirement3').hasError('required')">
                {{ 'mustAgreeToApply' | translate }}
              </mat-error>
              <mat-radio-button [value]="true">{{'iAgree' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false">{{'iDoNotAgree' | translate}}</mat-radio-button>
              </mat-radio-group>
  
            <p class="pt-4 mb-0">{{'consentAndRequirementText4' | translate}}</p>
            <mat-radio-group color="warn" aria-label="Select an option" formControlName = "consentAndRequirement4">
              <mat-error *ngIf="isFormSubmitted && registrationForm.get('consentAndRequirement4').hasError('required')">
                {{ 'mustAgreeToApply' | translate }}
              </mat-error>
              <mat-radio-button [value]="true">{{'iAgree' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false">{{'iDoNotAgree' | translate}}</mat-radio-button>
            </mat-radio-group>

            <p class="pt-4 mb-0">{{'Questions' | translate}}</p>
            <p class="pt-4 mb-0">{{'Questions1' | translate}}</p>
            <p class="pt-4 mb-0">{{'Questions2' | translate}}</p>
    
          <div class="button-row pt-4">
            <button mat-raised-button class="full-width-button" (click)="openConfirmationDialog()">{{'submit' | translate}}</button>
            <!-- <button mat-raised-button class="full-width-button"  (click)="onSubmit()">{{'submit' | translate}}</button> -->

          </div>
        </form>
      <!-- </ng-container> -->
      </mat-card-content>
    </mat-card>  
  </div>
</div>

<footer class="row row-cols-1 py-5  border-top">
  
  <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
    <ul class="nav flex-column">
      <li class="nav-item mb-2"><a href="https://www.coolculture.org/families/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Families</a></li>
      <li class="nav-item mb-2"><a href="https://www.coolculture.org/museum-lists/" class="nav-link p-0 custom-color">Museum Lists</a></li>
      <li class="nav-item mb-2"><a href="https://www.coolculture.org/family-activities/" class="nav-link p-0 custom-color">Family Activities</a></li>
      <li class="nav-item mb-2"><a href="https://www.coolculture.org/family-pass-registration/" class="nav-link p-0 custom-color">Family Pass Enrollment</a></li>
      <li class="nav-item mb-2"><a href="https://coolculture.org/faqs-for-families/" class="nav-link p-0 custom-color">FAQs for Families</a></li>
      <br>
      <li class="nav-item mb-2"><a href="https://coolculture.org/centers-schools/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Centers & Schools</a></li>
      <li class="nav-item mb-2"><a href="https://coolculture.org/enrollment-for-centers/" class="nav-link p-0 custom-color">Enrollment for Centers</a></li>
      <li class="nav-item mb-2"><a href="https://coolculture.org/enrollment-for-schools/" class="nav-link p-0 custom-color">Enrollment for Schools</a></li>
      <li class="nav-item mb-2"><a href="https://coolculture.org/resources-activities/" class="nav-link p-0 custom-color">Resources & Activities</a></li>
      <li class="nav-item mb-2"><a href="https://coolculture.org/faqs-for-educators/" class="nav-link p-0 custom-color">FAQs for Educators</a></li>
    </ul>
  </div>

  <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
    <!-- <h5>Section</h5> -->
    <ul class="nav flex-column">
      <li class="nav-item mb-2"><a href="https://coolculture.org/programs-impact/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Programs & Impact</a></li>
      <li class="nav-item mb-2"><a href="https://coolculture.org/health-wellbeing/" class="nav-link p-0 custom-color">Health + Wellbeing</a></li>
      <li class="nav-item mb-2"><a href="https://www.coolculture.org/cultural-partners/" class="nav-link p-0 custom-color">Cultural Partners</a></li>
      <br>
      <li class="nav-item mb-2"><a href="https://coolculture.org/about-us/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">About Us</a></li>
      <li class="nav-item mb-2"><a href="https://coolculture.org/our-team/" class="nav-link p-0 custom-color">Our Team</a></li>
      <li class="nav-item mb-2"><a href="https://coolculture.org/careers/" class="nav-link p-0 custom-color">Careers</a></li>
      <!-- <li class="nav-item mb-2"><a routerLink="/privacy-policy" class="nav-link p-0 custom-color">Privacy policy</a></li> -->

      <br>
      <li class="nav-item mb-2"><a href="https://coolculture.org/get-involved/" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Get Involved</a></li>
    </ul>
  </div>

  <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
    <ul class="nav flex-column">
      <li class="nav-item mb-2"><a class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Cool Culture Mission</a></li>
      <br><br>
      <li class="nav-item mb-2"><p class="nav-link p-0 custom-color" style="box-sizing: border-box;">To amplify the voices of families and strengthen the power of historically marginalized communities through engagement with art and culture, both within cultural institutions and beyond.</p></li>
      <li class="nav-item mb-2">
      <a href="https://www.coolculture.org/donate" class="mat-flat-button mat-primary"><button mat-flat-button color="warn" class="donate-button">Donate</button></a>
      </li>
    </ul>
  </div> -->

  <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
    <!-- <h5>Section</h5> -->
    <ul class="nav flex-column col-10">
      <li class="nav-item mb-2"><a class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">Cool Culture Mission</a></li>
      <br>
      <li class="nav-item mb-2"><p class="nav-link p-0 custom-color" style="box-sizing: border-box;">To amplify the voices of families and strengthen the power of historically marginalized communities through engagement with art and culture, both within cultural institutions and beyond.</p></li>
      <li class="nav-item mb-2"><a href="https://www.coolculture.org/donate" class="mat-flat-button mat-primary"><button mat-flat-button color="warn" class="donate-button">Donate</button></a></li>
    </ul>
  </div>

  <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 d-flex justify-content-center">
    <!-- <h5>Section</h5> -->
    <ul class="nav flex-column col">
      <a href="mailto:info&#64;coolculture.org" class="nav-link p-0 text-white font-weight-bold" style="font-size: 18px;">info&#64;coolculture.org</a>
      <li class="nav-item mb-2"><a href="tel:718.230.4186" class="nav-link p-0 text-white">718.230.4186</a></li>
      <li class="nav-item mb-2"><a class="nav-link p-0 text-white">80 Hanson Place, Suite 605</a></li>
      <li class="nav-item mb-2"><a href="https://www.google.com/maps/place/Professional+building,+80+Hanson+Pl+%23606,+Brooklyn,+NY+11217,+USA/@40.6852617,-73.9766057,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25bb1d27ad1f9:0xbbfec9431b6d2d25!8m2!3d40.6852577!4d-73.974417" class="nav-link p-0 text-white">Brooklyn NY 11217</a></li>
      <br>
      <li class="nav-item mb-2 d-flex gap-2">
        <button mat-mini-fab color="primary" class="fab-button custom-fab-size" (click)="openFacebookPage()">
          <span class="fab fa-facebook d-flex justify-content-center"></span>
        </button>        
        <button mat-mini-fab color="accent" class="fab-button custom-fab-size" (click)="openInstagramPage()">
          <i class="fab fa-instagram d-flex justify-content-center"></i>
        </button>
        <button mat-mini-fab color="primary" class="fab-button custom-fab-size" (click)="openTwitterPage()">
          <i class="fab fa-twitter d-flex justify-content-center"></i>
        </button>
        <button mat-mini-fab color="warn" class="fab-button custom-fab-size" (click)="openYoutubePage()">
          <i class="fab fa-youtube d-flex justify-content-center"></i>
        </button>
      </li>
      <br>
      <!-- <p class="nav-item mb-2 d-flex text-white">Website by<a href="https://inellipse.com/" class="nav-link p-0"><img src="https://inellipse.com/wp-content/uploads/2019/01/Inellipse-vector-1.png" alt="" width="80%" height="80%"></a></p> -->
    </ul>
  </div>

  <div class="col-1 p-0"></div>
 <div class="col-10 p-1">
    <p class="text-white">Supported in part by public funds from the National Endowment for the Arts (NEA), the New York State Council on the Arts, the New York City Department of Cultural Affairs in partnership with The City Council, and through generous gifts from Corporations, foundations and individuals.</p>
  </div> 
  <div class="col-1 p-0"></div>

  
  <div class="col-1 p-0"></div>
 <div class="col-10 p-1">
    <p class="text-white">2023 © Cool Culture  <a style="color: red; text-decoration: none;" href="https://www.coolculture.org/privacy-policy/">Privacy Policy</a></p>
  </div> 
  <div class="col-1 p-0"></div>
</footer>


