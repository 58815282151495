import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignDTO } from 'projects/swagger-api/src';
import { CampaignDialogComponent } from './campaign-dialog/campaign-dialog.component';
import { take } from 'rxjs/internal/operators/take';
import { ContextDataService } from '../services/context-data.service';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from '../loading-spinner/loading.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnDestroy {
  private onDestroy: Subject<any> = new Subject();

  displayedColumns: string[] = ['name', 'id','active', 'actions'];
  dataSource: MatTableDataSource<CampaignDTO>;

  loading:boolean = false;

  campaigns: CampaignDTO[] = this.contextDataService.contextData.campaigns;

  constructor(
    public dialog: MatDialog,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    public contextDataService: ContextDataService
   ) {

    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
      this.changeDetectorRef.detectChanges();  // Manually trigger change detection
    });


    this.dataSource = new MatTableDataSource(this.campaigns);

    this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
      next: () => {
        this.campaigns = this.contextDataService.contextData.campaigns;
        this.dataSource.data = this.campaigns;
      }
    });
   }

  ngOnDestroy(): void {
    this.onDestroy.next('onDestroy');
  }



  openDialog(action: 'CREATE' | 'UPDATE' | 'REMOVE', item?: any) {
    const dialogRef = this.dialog.open(CampaignDialogComponent, {
			data: {
        action,
        item
      },
		});

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.contextDataService.getAllCampaigns();
      }
    });
  }
}
