import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  supportLanguage = ['en','es','cn'];
  cookieKey = 'selectedLanguage';

  constructor(private translateService: TranslateService, private cookieService: CookieService) {
    this.translateService.addLangs(this.supportLanguage);
    this.translateService.setDefaultLang('en');

    const storedLanguage = localStorage.getItem(this.cookieKey);
    const selectedLanguage = this.supportLanguage.includes(storedLanguage) ? storedLanguage : 'en'; // Set 'en' as the default language if no language is stored or if the stored language is not supported
    this.translateService.use(selectedLanguage);
  }

  selectLang(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem(this.cookieKey, lang);
  }

  getSelectedLanguage(): string {
    const storedLanguage = localStorage.getItem(this.cookieKey);
    return this.supportLanguage.includes(storedLanguage) ? storedLanguage : 'en';
  }
}
