import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationDTO, ConfigurationDTO, ExportFilesControllerService, PagePrintListDTO, PrintListControllerService, PrintListDTO } from 'projects/swagger-api/src';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ExportConfirmationDialogComponent } from '../export-confirmation-dialog/export-confirmation-dialog.component';
import { MarkAsPrintedConfirmationDialogComponent } from '../mark-as-printed-confirmation-dialog/mark-as-printed-confirmation-dialog.component';
import { CustomPrintListControllerService } from 'custom-api/customPrintListController.service';
import { LoadingService } from '../loading-spinner/loading.service';
import { ConfigurationAdminControllerService } from 'projects/swagger-api/src';
import { ContextDataService } from '../services/context-data.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-print-list',
  templateUrl: './print-list.component.html',
  styleUrls: ['./print-list.component.css']
})
export class PrintListComponent implements OnDestroy {
	private onDestroy: Subject<any> = new Subject();

  userInput: string = '';
  displayedColumns: string[] = ['name', 'createdDate', 'createdBy','batchNumber', 'printed', 'actions'];
  dataSource: MatTableDataSource<PrintListDTO> = new MatTableDataSource<PrintListDTO>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedOption: string = 'Yes';


  // inputNumber: number;
  // currentBatch: string = 'BATCH001';
  // newBatch: string;
  // printingData: ConfigurationDTO;
  loading: boolean = false;

  constructor(
    private exportFilesController: ExportFilesControllerService,
    private adminPrintListService: PrintListControllerService,
    private exportFilesService: ExportFilesControllerService,
    private changeDetectorRef: ChangeDetectorRef,
    private configurationAdminController: ConfigurationAdminControllerService,
    private customPrintListController: CustomPrintListControllerService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private contextDataService: ContextDataService
){}


  ngOnInit(){

    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.contextDataService.contextDataLoaded.pipe(takeUntil(this.onDestroy)).subscribe({
			next: response => {
        if (response) {
          this.getAllPrintLists();
          this.activePrintingBatch();
        }
			}
		});
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
		this.onDestroy.next('onDestroy');
	}

  getAllPrintLists(page: number = 0, size: number = 1000){
    this.adminPrintListService.getAllPrintLists(
      this.contextDataService.contextData.activeCampaign.campaignId,
      page, size).subscribe(
      (page: PagePrintListDTO) => {
        // console.log("this is the print lists", page);

        if (page && page.content) {
          const filteredData = page.content.filter(item => !(item.disabled === true && item.isPrinted === false));

          this.dataSource.data = filteredData;
          // this.paginator.length = filteredData.length;
        }
      },
      error => {
        // console.error('Error fetching print lists:', error);
      },
      () => {
      }
    );
  }


  onPageChange(event: any) {
    const pageNumber = event.pageIndex;
    const pageSize = event.pageSize;
    this.getAllPrintLists(pageNumber, pageSize);
  }

  deletePrintList(id: string): void {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        // console.log(id);
        this.customPrintListController._delete(id).subscribe(
          response => {
            // console.log('its deleted', response);
            this.getAllPrintLists();
          },
          error => {
            // console.error('Error deleting:', error);
          }
        );
      }
    });
  }

  downloadPrintList(id: string, fileName: string){
     this.customPrintListController.downloadFile(id).subscribe(
      response => {
        // console.log(response);
        const blobUrl = URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = fileName;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
        // alert('Exported file successfully downloaded');
      },
      error => {
        // console.error(error);
      },
        () => {
        }
    );
  }


  markAsPrinted(id: string): void {
    const dialogRef = this.dialog.open(MarkAsPrintedConfirmationDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // User clicked Yes, proceed with marking as printed
        this.exportFilesService.markAsPrinted(id).subscribe(
          response => {
            // console.log('Marked as Printed:', response);
            this.getAllPrintLists();
          },
          error => {
            // console.error('Error marking as printed:', error);
          }
        );
      } else {
        // User clicked No, do nothing
      }
    });
  }


  exportFiles() {
    const dialogRef = this.dialog.open(ExportConfirmationDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // User clicked Yes, proceed with export
        this.exportFilesService.exportFamilyPasses(
          this.contextDataService.contextData.activeCampaign.campaignId, this.selectedOption).subscribe(
          (data) => {
            this.getAllPrintLists();
            // console.log('Export successful!', data);
          },
          (error) => {
            // Handle errors here
            // console.error('Export failed!', error);
          }
        );
      } else {
        // User clicked No, do nothing
      }
    });
  }

  activePrintingBatch(){
    this.configurationAdminController.findFirstConfigurationByActivePrintingBatch1().subscribe(
      (configData) => {
        // this.printingData = data;
        // console.log('hello',configData);

        this.getAllPrintLists();
        // console.log('First Batch taken successful!', data);
      },
      (error) => {
        // Handle errors here
        // console.error('first batch taking failed!', error);
      }
    );
  }

  onRadioChange(event: string){
    this.selectedOption = event;
  }

}
