import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbData: string[] = [];

  getBreadcrumbData(): string[] {
    return this.breadcrumbData;
  }

  updateBreadcrumb(route: string): void {
    const segments = route.split('/').filter(segment => segment !== '');
    if (segments[1] === 'dashboard') {
      this.breadcrumbData = ['Dashboard'];
    } else if (segments[1] === 'print-list') {
      this.breadcrumbData = ['Dashboard', 'Print List'];    
    } else if (segments[1] === 'loaner-passes') {
      this.breadcrumbData = ['Dashboard', 'Loaner Passes'];
    } else if (segments[1] === 'unpaid-invoices') {
      this.breadcrumbData = ['Dashboard', 'Unpaid Invoices'];    
    } else if (segments[1] === 'cards-delivery') {
      this.breadcrumbData = ['Dashboard', 'Cards Delivery'];    
    } else if (segments[1] === 'contact-support') {
      this.breadcrumbData = ['Dashboard', 'Contact support']; 
    } else if (segments[1] === 'uploaded-documents') {
      this.breadcrumbData = ['Dashboard', 'Uploaded Documents']; 
    }else if (segments[1] === 'purchase-orders') {
      this.breadcrumbData = ['Dashboard', 'Purchase Orders']; 
    }else if (segments.length > 0) {
      this.breadcrumbData = ['Dashboard', segments[segments.length - 1]];
    } else {
      this.breadcrumbData = ['Dashboard'];
    }
  }
  
}