import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { FirstTimerComponent } from './application/first-timer/first-timer.component';
import { TermsAndConditionsComponent } from './application/terms-and-conditions/terms-and-conditions.component';
import { ComingBackUserComponent } from './application/coming-back-user/coming-back-user.component';
import { WelcomeBackComponent } from './application/welcome-back/welcome-back.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ApplicationsComponent } from './applications/applications.component';
import { BoroughsComponent } from './boroughs/boroughs.component';
import { CategoriesComponent } from './categories/categories.component';
import { MembersComponent } from './members/members.component';
import { PlacesComponent } from './places/places.component';
import { ResourcesComponent } from './resources/resources.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthRedirectGuard } from './auth/auth-redirect.guard';
import { CodeGuard } from './auth/code.guard';
import { InvalidCodeComponent } from './invalid-code/invalid-code.component';
import { SuccessPageComponent } from './application/first-timer/success-page/success-page.component';
import { PrintListComponent } from './print-list/print-list.component';
import { PrivacyPolicyComponent } from './application/privacy-policy/privacy-policy.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IframeComponent } from './admin-panel/iframe/iframe.component';
import { LoanerPassesComponent } from './loaner-passes/loaner-passes.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UnpaidInvoicesComponent } from './unpaid-invoices/unpaid-invoices.component';
import { CardsDeliveryComponent } from './cards-delivery/cards-delivery.component';
import { UploadedDocumentsComponent } from './uploaded-documents/uploaded-documents.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { CampaignsComponent } from './campaigns/campaigns.component';

const routes: Routes = [

  // { path: '', redirectTo: 'enter-code', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthRedirectGuard], },
  // { path: 'registration', component: RegistrationComponent, pathMatch: 'full' },
  // { path: 'registration/first-timer/terms-and-conditions', component: TermsAndConditionsComponent, pathMatch: 'full' },
  { path: '', component: FirstTimerComponent, pathMatch: 'full', canActivate: [CodeGuard] },
  // { path: 'registration/coming-back-user/terms-and-conditions', component: TermsAndConditionsComponent, pathMatch: 'full' },
  // { path: 'registration/coming-back-user', component: ComingBackUserComponent, pathMatch: 'full' },
  { path: 'enter-code', component: InvalidCodeComponent, pathMatch: 'full' },
  { path: 'success-page', component: SuccessPageComponent, pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
  // { path: 'registration/coming-back-user/welcome-back', component: WelcomeBackComponent, pathMatch: 'full' },
  {
    path: 'admin-panel',
    component: AdminPanelComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent , pathMatch: 'full' },
      { path: 'campaigns', component: CampaignsComponent },
      { path: 'registrations', component: ApplicationsComponent },
      { path: 'boroughs', component: BoroughsComponent, pathMatch: 'full' },
      { path: 'categories', component: CategoriesComponent, pathMatch: 'full' },
      { path: 'members', component: MembersComponent, pathMatch: 'full' },
      { path: 'places', component: PlacesComponent, pathMatch: 'full' },
      { path: 'resources', component: ResourcesComponent , pathMatch: 'full' },
      { path: 'print-list', component: PrintListComponent , pathMatch: 'full' },
      { path: 'contact-support', component: IframeComponent , pathMatch: 'full' },
      { path: 'loaner-passes', component: LoanerPassesComponent , pathMatch: 'full' },
      { path: 'notifications', component: NotificationsComponent , pathMatch: 'full' },
      { path: 'unpaid-invoices', component: UnpaidInvoicesComponent , pathMatch: 'full' },
      { path: 'cards-delivery', component: CardsDeliveryComponent , pathMatch: 'full' },
      { path: 'uploaded-documents', component: UploadedDocumentsComponent , pathMatch: 'full' },
      { path: 'purchase-orders', component: PurchaseOrdersComponent , pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
