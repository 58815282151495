<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container main-container">
    <h3 mat-dialog-title class="title">{{ (data?.isEdit ? 'updatePlace' : 'AddnewPlaceTitle') | translate }}</h3>
    
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <div class="container">
          <div class="row gy-3">

            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'placesAddNewCategory' | translate }}</mat-label>
                <mat-select formControlName="selectedCategory">
                  <mat-option *ngFor="let category of categories" [value]="category.id">
                    {{ category.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('selectedCategory').hasError('required')">Category is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'placesAddNewBorough' | translate }}</mat-label>
                <mat-select formControlName="selectedBorough">
                  <mat-option *ngFor="let borough of boroughs" [value]="borough.id">
                    {{ borough.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('selectedBorough').hasError('required')">Borough is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <!-- <h3 class="mb-1 fw-bold">{{'Name' | translate}}</h3> -->
              <mat-form-field appearance="outline">
                <mat-label>{{'placeName' | translate}}</mat-label>
                <input matInput placeholder="{{ 'placesAddNewName' | translate }}" formControlName="placeName">
                <mat-error *ngIf="form.get('placeName').hasError('required')">Name is required.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>{{'placeDescription' | translate}}</mat-label>
                <!-- <input matInput placeholder="{{ 'placesAddNewDescription' | translate }}" formControlName="placeDescription"> -->
                <textarea matInput cdkTextareaAutosize placeholder="{{ 'placesAddNewDescription' | translate }}" formControlName="placeDescription"></textarea>
                <mat-error *ngIf="form.get('placeDescription').hasError('required')">Description is required.</mat-error>
              </mat-form-field>
            </div>


            <div class="col-12">
              <mat-form-field class="full-width-form-field" appearance="outline">
                  <mat-label>{{ 'image' | translate }}</mat-label>
          
                  <mat-toolbar class="d-flex justify-content-start flex-row custom-font">
                    
                      <!-- <div *ngIf="form.get('placesImages').value?.length > 0">
                          <div *ngFor="let imageName of form.get('placesImages').value">
                              <div>{{ imageName }}</div>
                          </div>
                      </div> -->


                      <div *ngIf="!this.data?.isEdit && noNewUploads == true">
                        <div >
                            <div>Choose An Image</div>
                        </div>
                    </div>

                    <div *ngIf="noNewUploads == false">
                      <div *ngFor="let image of form.get('placesImages').value">
                        <div>
                          {{image.name}}
                        </div>
                      </div>
                    </div>
                    

                      <!-- <input class="text-center" matInput formControlName="placesImages"
                          [hidden]="!this.imagesNameUploaded && form.get('placesImages').value?.length > 0"
                          readonly name="image"> -->
                          <div class="hide-element p-0 m-0">
                            <input class="text-center" matInput 
                            formControlName="placesImages" 
                            readonly name="image">
                          </div>
                      <!-- <img class="pb-4" [src]="imageToDisplay"
                          [hidden]="this.imagesNameUploaded || form.get('placesImages').value ?? [0] === 'Choose An Image'" alt="Image"
                          style="max-width: 300px; max-height: 300px;"> -->
                        <ng-container *ngIf="noNewUploads == true">
                          <div *ngFor="let imageUrl of imageToDisplay let i = index"  class="d-flex flex-column p-0 m-0">

                            <button mat-icon-button color="warn" class="delete-button uptop" (click)="deletePicture(i)">
                              <i class="fas fa-trash"></i>
                            </button>

                            <img class="pb-4 d-grid gap-3 px-3" [src]="imageUrl.name" alt="Image" 
                            style="max-width: 200px; max-height: 200px;">
<!-- 
                            <label mat-icon-button color="warn" class="delete-button uptop" (click)="deleteImageByName(i)">
                              <mat-icon>close</mat-icon>
                            </label> -->

                          </div>
                        </ng-container>

                  </mat-toolbar>
                  <div class="d-flex justify-content-center">
                    <button mat-flat-button color="warn">Browse File</button>
                  </div>

                  <input type="file" #fileInputImage id="uploadImage" (change)="uploadFileEvtMultiple($event)"
                      name="uploadImage" multiple="multiple" accept="image/*" />
              </mat-form-field>
              <div class="error-message" *ngIf="form.get('placesImages').value?.length === 1 && form.get('placesImages').value[0] === 'Choose An Image'">
                {{ 'FileRequired' | translate }}
            </div>
          </div>
          


              
              <div class="col-12">
                <mat-form-field class="full-width-form-field" appearance="outline">
                  <mat-label>{{'logo' | translate}}</mat-label>

                  <mat-toolbar class="d-flex justify-content-center flex-column custom-font">
                    <input class="text-center" matInput formControlName="placesLogo" [hidden]="!this.logoNameUploaded && form.get('placesLogo').value !== 'Choose An Logo'" readonly name="image">
                    <img class="pb-4" [src]="logoToDisplay" [hidden]="this.logoNameUploaded || form.get('placesLogo').value === 'Choose An Logo'"  alt="Image" style="max-width: 200px; max-height: 200px;">
                  </mat-toolbar>
                  <input type="file" #fileInputLogo id="uploadLogo" (change)="uploadFileEvt($event)" name="uploadImage" multiple="multiple" accept="image/*" />
                  <!-- <mat-error class="input-error" *ngIf="!selectedImageFileName">Logo is required.</mat-error> -->
                  <div class="d-flex justify-content-center">
                    <button mat-flat-button color="warn">Browse File</button>
                  </div>
                </mat-form-field>


                <div class="error-message" *ngIf="form.get('placesLogo').value === 'Choose An Logo'">
                  {{ 'FileRequired' | translate }}
                </div>
              </div>
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>{{'websiteOptional' | translate}}</mat-label>
                <input matInput placeholder="{{ 'placesAddNewUrl' | translate }}" formControlName="placeUrl">
                <mat-error *ngIf="form.get('placeUrl').hasError('required')">URL is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'phone' | translate}}</mat-label>
              <input matInput placeholder="{{ 'placesAddNewPhone' | translate }}" formControlName="phone" (input)="formatPhoneNumber($event.target.value)">
              <mat-error *ngIf="form.get('phone').hasError('required')">Phone is required.</mat-error>
              </mat-form-field>
          </div>

            <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{'emailPlaces' | translate}}</mat-label>
                <input matInput placeholder="{{ 'placesAddNewEmail' | translate }}" formControlName="email">
                <mat-error *ngIf="form.get('email').hasError('required')">Email is required.</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{'address' | translate}}</mat-label>
                <input matInput placeholder="{{ 'placesAddNewAddress' | translate }}" formControlName="address">
                <mat-error *ngIf="form.get('address').hasError('required')">Address is required.</mat-error>
                </mat-form-field>
            </div>
            <!-- <div class="col-6">
                <mat-form-field>
                <input matInput placeholder="{{ 'placesAddNewAverageRating' | translate }}" formControlName="averageRating">
                <mat-error *ngIf="form.get('averageRating').hasError('required')">Average Rating is required.</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                <input matInput placeholder="{{ 'placesAddNewTotalVotes' | translate }}" formControlName="totalVotes">
                <mat-error *ngIf="form.get('totalVotes').hasError('required')">Total Votes is required.</mat-error>
                </mat-form-field>
            </div> -->
            <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{'city' | translate}}</mat-label>
                <input matInput placeholder="{{ 'placesAddNewCity' | translate }}" formControlName="city">
                <mat-error *ngIf="form.get('city').hasError('required')">City is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'state' | translate }}</mat-label>
                <mat-select placeholder="{{ 'placesAddNewState' | translate }}" formControlName="state">
                  <mat-option *ngFor="let state of usaStates" [value]="state">{{ state }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('state').hasError('required')">State is required.</mat-error>
              </mat-form-field>
            </div>

            <div class="col-6" hidden>
                <mat-form-field appearance="outline">
                  <mat-label>{{'country' | translate}}</mat-label>
                <input matInput placeholder="{{ 'placesAddNewCountry' | translate }}" formControlName="country">
                <mat-error *ngIf="form.get('country').hasError('required')">Country is required.</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{'workingHours' | translate}}</mat-label>
                <input matInput placeholder="{{ 'placesAddNewWorkingHours' | translate }}" formControlName="workingHours">
                <mat-error *ngIf="form.get('workingHours').hasError('required')">WorkingHours is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 custom-removed-wrapper">
              <mat-form-field appearance="outline">
                <mat-label>{{'googleMapsLink' | translate}}</mat-label>
                <input matInput placeholder="{{ 'BoroughAddNewGoogleMapsLink' | translate }}" [(ngModel)]="googleMapsLink" [ngModelOptions]="{standalone: true}">
                <!-- <mat-error *ngIf="form.get('latitude').hasError('required') && form.get('longitude').hasError('required')">Latitude is required</mat-error>              -->
              </mat-form-field>
              <div *ngIf="!isGoogleMapsLinkValid && !googleMapsLink" style="color: red; font-size: 12px;">
                <label for="" class="ps-3">Google Maps link is required</label>
              </div>
              <div *ngIf="linkNotValid && googleMapsLink != ''" style="color: red; font-size: 12px;">
                <label for="" class="ps-3">Google Maps link is not valid</label>
              </div>
              <label for="" class="text-break text-wrap pt-3">
                <p class="pt-2 mb-0">Example google maps URL link:</p>
                {{ 'https://www.google.com/maps/place/Cool+Culture/@40.6852501,-73.9769903,17z/data=!3m1!4b1!4m6!3m5!1s0x89c25bb1cdc3f925:0x29ada2fcc54eee6f!8m2!3d40.6852461!4d-73.9744154!16s%2Fg%2F1tmpfvxh?entry=ttu' }}
              </label>            
            </div>


              <!-- <div class="col-6">
                <mat-form-field>
                  <mat-label>{{ 'placesAddNewEvent' | translate }}</mat-label>
                  <mat-select formControlName="selectedEvent">
                    <mat-option *ngFor="let event of events" [value]="event.id">
                      {{ event.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('selectedEvent').hasError('required')">Event is required.</mat-error>
                </mat-form-field>
              </div> -->
  
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog()">{{ 'Cancel' | translate }}</button>
      <button mat-flat-button color="warn" cdkFocusInitial (click)="saveDialog()">{{ (data?.isEdit ? 'update' : 'Add') | translate }}</button>
    </mat-dialog-actions>
  </div>
  
  




