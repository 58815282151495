import { Component } from '@angular/core';
import { BoroughsAddNewDialogComponent } from './boroughs-add-new-dialog/boroughs-add-new-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BoroughAdminControllerService, BoroughDTO } from 'projects/swagger-api/src';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { BoroughErrorDialogComponent } from './borough-error-dialog/borough-error-dialog.component';
import { LoadingService } from '../loading-spinner/loading.service';

@Component({
  selector: 'app-boroughs',
  templateUrl: './boroughs.component.html',
  styleUrls: ['./boroughs.component.css']
})
export class BoroughsComponent {
  
  loading:boolean = false;
  displayedColumns: string[] = ['name', 'image', 'latitude', 'longitude', 'mapZoom', 'actions'];
  dataSource: MatTableDataSource<BoroughDTO>;

  constructor(public dialog: MatDialog, private boroughsController: BoroughAdminControllerService, private loadingService: LoadingService) {}

  openDialog(isEdit: boolean = false, borough?: BoroughDTO): void {
    const dialogRef = this.dialog.open(BoroughsAddNewDialogComponent, {
      data: { borough, isEdit }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllBoroughs();
      }
    });
  }

  ngOnInit()  {
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });
    this.getAllBoroughs();
  }

  getAllBoroughs() {
    this.boroughsController.getAllBoroughs1().subscribe(
      (data: BoroughDTO[]) => {
        // console.log(data);
        const mappedData = data.map(boroughs => ({
            id: boroughs.id,
            name: boroughs.name,
            latitude: boroughs.latitude,
            longitude: boroughs.longitude,
            mapZoom: boroughs.mapZoom,
            image: boroughs.image
          }));

      this.dataSource = new MatTableDataSource(mappedData);
      this.dataSource.data.sort((a, b) => a.id.localeCompare(b.id));
      },
      error => {
        // console.error('Error fetching boroughs:', error);
      }
    );
  }

  deleteBorough(id: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent);
  
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'yes') { // Check if the user clicked 'yes'
        this.boroughsController.deleteBorough(id).subscribe(
          (response: boolean) => {
            if (response) {
              // console.log('Borough deleted successfully');
              this.getAllBoroughs();
            } else {
              // The response is false, but we only want to show the conflict dialog for a 409 status
              this.handleDeleteError(409);
            }
          },
          (error: HttpErrorResponse) => {
            // Handle other errors here
            console.error('Error deleting borough:', error);
            // Check for 409 Conflict status
            if (error.status === 409) {
              this.handleDeleteError(409);
            }
          }
        );
      }
    });
  }
  
  // Show conflict dialog method
  private handleDeleteError(statusCode: number) {
    if (statusCode === 409) {
      const dialogRef = this.dialog.open(BoroughErrorDialogComponent, {
        width: '400px', // Set the width here
        data: {
          message: 'Can not delete because a place is created using this borough'
        }
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        // Handle dialog close if needed
      });
    }
  }
  


  editBorough(borough: BoroughDTO) {
    const dialogRef = this.dialog.open(BoroughsAddNewDialogComponent, {
      data: { borough, isEdit: true }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the edited borough in the data source
        const editedIndex = this.dataSource.data.findIndex(item => item.id === result.id);
        if (editedIndex !== -1) {
          this.dataSource.data[editedIndex] = result;
          // Refresh the MatTableDataSource after updating the data
          this.dataSource.data = [...this.dataSource.data];
        }
      }
    });
  }
  
}
