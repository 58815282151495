import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoryAdminControllerService, CategoryDTO, FileManagementControllerService } from 'projects/swagger-api/src';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/loading-spinner/loading.service';

@Component({
  selector: 'app-categories-add-new-dialog',
  templateUrl: './categories-add-new-dialog.component.html',
  styleUrls: ['./categories-add-new-dialog.component.css']
})
export class CategoriesAddNewDialogComponent {

  selectedImageFileName: string = 'Choose Image';
  categoryNameError: boolean = false;
  form: FormGroup;
  formSubmitted: boolean = false;
  loading:boolean = false;
  imageToDisplay: string = null;
  pictureNameUploaded: boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose An Icon';
 
  
  constructor(
    public dialogRef: MatDialogRef<CategoriesAddNewDialogComponent>, 
    private translate: TranslateService,
    private categoriesController: CategoryAdminControllerService,
    private formBuilder: FormBuilder,
    private fileManagement: FileManagementControllerService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.fileInput = new ElementRef(null);
    this.fileAttr = this.translate.instant('chooseIcon');

    // console.log(data);
    this.form = this.formBuilder.group({
      categoryName: [data?.category ? data.category.name: '', Validators.required],
      fileAttr: [data?.category ? data.category.icon: 'Choose An Icon']
    });

    this.imageToDisplay = this.form.get('fileAttr').value;
    // console.log('image to display',this.imageToDisplay);
    
  }


  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });
  }

  uploadFileName(event: any): void{
    const file = (event.target as HTMLInputElement).files?.[0];
    // console.log(file);
    this.form.get('fileAttr').setValue(file.name);
    // console.log(this.form.get('fileAttr').value);
    // console.log(this.imageToDisplay);

    if(file){
      this.pictureNameUploaded = true;
    }
    
  }


  uploadFileEvt(event: any): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    // console.log(file);
    // this.form.get('fileAttr').setValue('uploads/'+file.name);
    // console.log(this.form.get('fileAttr').value);  
  
    if (file) {
      this.fileManagement.uploadFileForm('uploads', file)
        .subscribe(
          (response) => {
            // console.log('File uploaded successfully:', response);

            this.form.patchValue({
              fileAttr: response.message
            });
          },
          (error) => {
            console.error('Error uploading file:', error);
            // Handle error as needed
          }
        );
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


  saveDialog() {
    this.formSubmitted = true;
    if (this.form.invalid || this.form.get('fileAttr').value === 'Choose An Icon') {
      // console.log('Please provide both a category name and select an icon before adding the category.');
      return;
    }

    const fileInput = document.getElementById('uploadFile') as HTMLInputElement;
    const mockEvent = {
      target: {
        files: [fileInput?.files?.[0]] // Assuming you have only one file input
      }
    };

    this.uploadFileEvt(mockEvent);

    if (this.data?.isEdit) {
      const updatedCategory = {
        id: this.data.category.id,
        name: this.form.value.categoryName,
        icon: this.form.value.fileAttr,
      };
      
      if (updatedCategory.icon.startsWith('https://')){
        updatedCategory.icon = updatedCategory.icon.split('/').pop();
      }
  
      this.categoriesController.updateCategory(updatedCategory, this.data.category.id).subscribe(
        (response: CategoryDTO) => {
          // console.log('Category updated successfully:', response);
          this.dialogRef.close(response);
        },
        error => {
          // console.error('Error updating Category:', error);
        }
      );
    } else {

        const newCategory: CategoryDTO = {
        name: this.form.value.categoryName.trim(),
        icon: this.form.value.fileAttr
      };
    
      this.categoriesController.createCategory(newCategory).subscribe(
        (response: CategoryDTO) => {
          // console.log('Category added successfully:', response);
          this.dialogRef.close(response);
        },
        error => {
          // console.error('Error adding category:', error);
        }
      );
    }
  
    // Clear error message
    this.clearError();
  }
  
  clearError() {
    this.categoryNameError = false;
  }
  
  
}
