<div class="flex-container">
    <div class="container-xs px-3">
      <app-loading-spinner *ngIf="loading"></app-loading-spinner>

        <!-- <div class="row">
            <mat-card class="card-size">
                <mat-card-content>

                  <div class="row">
                    <div class="pb-4 col-3">
                    <h3 class="fw-bold" style="white-space: nowrap;">{{ 'notificationType' | translate }}</h3>
                    <mat-form-field class="col-12" appearance="outline">
                        <mat-label>Select a type</mat-label>
                        <mat-select [(ngModel)]="selectedType" name="selectedType">
                          <mat-option *ngFor="let option of options" [value]="option.name">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field> 
                  </div>

                  <div class="pb-4 col-3">
                    <h3 class="fw-bold" style="white-space: nowrap;">{{ 'message' | translate }}</h3>
                    <mat-form-field class="col-12" appearance="outline">
                      <input matInput placeholder="Search">
                    </mat-form-field>
                    </div>

                    <ng-container *ngIf="selectedType === 'Place'">
                        <div class="pb-4 col-3">
                            <h3 class="fw-bold" style="white-space: nowrap;">Places</h3>
                            <mat-form-field class="col-12" appearance="outline">
                                <mat-label>Select a type</mat-label>
                                <mat-select [(ngModel)]="selectedType" name="selectedType">
                                  <mat-option *ngFor="let option of options" [value]="option.name">
                                    {{ option.name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field> 
                        </div>
                      </ng-container>
    
                      <ng-container *ngIf="selectedType === 'Link'">
                        <div class="pb-4 col-3">
                            <h3 class="fw-bold" style="white-space: nowrap;">Link</h3>
                            <mat-form-field class="col-12" appearance="outline">
                              <input matInput placeholder="Link">
                            </mat-form-field>
                        </div>
                      </ng-container>
                      
                  </div>
                  
                  
                  
                  
                </mat-card-content>
            </mat-card>
        </div> -->

  
    <form [formGroup]="notificationForm">
        <div>

            <div class="col">
                <mat-card>
                    <mat-card-content>
                        <div class="row">
                      <div class="pb-4 col col-xl-2">
                        <h3 class="fw-bold" style="white-space: nowrap;">{{ 'notificationType' | translate }}</h3>
                        <mat-form-field class="col-12" appearance="outline">
                            <mat-label>Select a type</mat-label>
                            <mat-select name="selectedType" formControlName="notificationType">
                              <mat-option *ngFor="let option of options" [value]="option.value">
                                {{ option.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field> 
                      </div>

                      <ng-container *ngIf="notificationForm.get('notificationType').value === 'PLACE'">
                        <div class="pb-4 col-12 col-xl-2">
                            <h3 class="fw-bold" style="white-space: nowrap;">Places</h3>
                            <mat-form-field class="col-12" appearance="outline">
                                <mat-label>Select a type</mat-label>
                                <mat-select name="selectedType" formControlName = "dataId">
                                  <mat-option *ngFor="let place of places" [value]="place.id">
                                    {{ place.name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field> 
                        </div>
                      </ng-container>
    
                      <ng-container *ngIf="notificationForm.get('notificationType').value === 'LINK'">
                        <div class="pb-4 col-12 col-xl-2">
                            <h3 class="fw-bold" style="white-space: nowrap;">Link</h3>
                            <mat-form-field class="col-12" appearance="outline">
                              <input matInput placeholder="Link" formControlName="link">
                            </mat-form-field>
                        </div>
                      </ng-container>

                      <div class="pb-4 col-12 col-xl-3">
                        <h3 class="fw-bold" style="white-space: nowrap;">{{ 'title' | translate }}</h3>
                        <mat-form-field class="col-12" appearance="outline">
                          <textarea matInput cdkTextareaAutosize placeholder="Title" formControlName="title"></textarea>
                        </mat-form-field>
                        </div>
    
                      <div class="pb-4 col-12 col-xl">
                        <h3 class="fw-bold" style="white-space: nowrap;">{{ 'message' | translate }}</h3>
                        <mat-form-field class="col-12" appearance="outline">
                          <textarea matInput cdkTextareaAutosize placeholder="Message" formControlName="message"></textarea>
                        </mat-form-field>
                        </div>
    

                          <div class="pb-4 col col-xl-1">
                            <h3 class="fw-bold custom-hidden" style="white-space: nowrap;">{{ 'search' | translate }}</h3>
                            <button mat-raised-button color="warn" class="custom-button-size col-12" (click)="onSend()">Send</button>
                        </div>
                          
                      
                      
                      
                      </div>
                    </mat-card-content>
                </mat-card>
    
            </div>
            
          </div>
    </form>      
        <!-- <div class="col-lg-3 col-md-6 pb-4">

          <mat-card class="card-size">
            <mat-card-content>
              <h3 class="fw-bold mb-1" style="white-space: nowrap;">{{ 'whichOrg' | translate }}</h3>
  
              <div class="pb-4">
                <mat-form-field class="col-12" appearance="outline">
                <input matInput 
                       placeholder="Select">
              
                 <mat-autocomplete #OrganizationAutoComplete="matAutocomplete">
                  <mat-option *ngFor="let option of options" [value]="option.name">
                      {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              </div>
              
            </mat-card-content>
        </mat-card>
        </div> -->


      <div class="row justify-content-center">
        <div class="col pt-4 pb-2">
          <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="notificationType">
              <mat-header-cell *matHeaderCellDef class="justify-content-start col-3 col-lg-3"> Notification Type</mat-header-cell>
              <mat-cell *matCellDef="let element" class="justify-content-start col-3 col-lg-3">{{element.notificationType}}</mat-cell>
            </ng-container>
              
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-3 col-lg-3"> Title </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-3 col-lg-3"> {{element.title}} </mat-cell>
              </ng-container>

              
              <ng-container matColumnDef="message">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-3 col-lg-3"> Message </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-3 col-lg-3"> {{element.message}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="link">
                <mat-header-cell *matHeaderCellDef class="justify-content-start col-3 col-lg-3"> Link </mat-header-cell>
                <mat-cell *matCellDef="let element" class="justify-content-start col-3 col-lg-3"> {{element.link}} </mat-cell>
              </ng-container>
              
          
            <!-- Header row -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          
            <!-- Table rows -->
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator class="mat-elevation-z8" #paginator [pageSize]="[25]" [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="paginator.pageSize" [pageIndex]="paginator.pageIndex" [length]="paginator.length" (page)="onPageChange()"></mat-paginator>

        </div>
      </div>

  </div>
  


  