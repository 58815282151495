import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlacesAddNewDialogComponent } from './places-add-new-dialog/places-add-new-dialog.component';
import { PlaceAdminControllerService, PlaceDTO, CategoryAdminControllerService, BoroughAdminControllerService, CategoryDTO, BoroughDTO } from 'projects/swagger-api/src';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { LoadingService } from '../loading-spinner/loading.service';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css'],
})

export class PlacesComponent {

  displayedColumns: string[] = ['name', 'logo','description', 'website', 'address', 'actions'];
  dataSource: MatTableDataSource<PlaceDTO>;
  loading:boolean = false;

  constructor(
    public dialog: MatDialog, 
    private placesController: PlaceAdminControllerService, 
    private loadingService: LoadingService,  
    private changeDetectorRef: ChangeDetectorRef,
    private categoryController: CategoryAdminControllerService,
    private boroughController: BoroughAdminControllerService) {}



  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
      this.changeDetectorRef.detectChanges();  // Manually trigger change detection
    });
    this.getAllPlaces();
  }
  
  
  openDialog(): void {
    const dialogRef = this.dialog.open(PlacesAddNewDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllPlaces();
      }
    });
  }



  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  getAllPlaces(){
    this.placesController.getPlaces1().subscribe(
      (data: PlaceDTO[]) => {
        // console.log(data);

        // const categoryObservables = data.map((places) =>
        //   this.categoryController.getCategoryById(places.categoryId)
        // );

        // const boroughObservables = data.map((places) =>
        //   this.boroughController.getBoroughById(places.boroughId)
        // );

        // Use forkJoin to combine observables and wait for all of them to complete
        // forkJoin([forkJoin(categoryObservables), forkJoin(boroughObservables)]).subscribe(
        //   ([categoryNames, boroughNames]: [CategoryDTO[], BoroughDTO[]]) => {
            const mappedData = data.map((places, index) => ({
          
          id: places.id,
          name: places.name,
          description: places.description,
          images: places.images,
          logo: places.logo,
          latitude: places.latitude,
          longitude: places.longitude,
          url: places.url,
          phone: places.phone,
          email: places.email,
          address: places.address,
          averageRating: places.averageRating,
          city: places.city,
          state: places.state,
          country: places.country,
          workingHours: places.workingHours,
          totalVotes: places.totalVotes,
          categoryId: places.categoryId,
          boroughId: places.boroughId,
          // categoryName: categoryNames[index]?.name || 'N/A',
          // boroughName: boroughNames[index]?.name || 'N/A',
        }));

      this.dataSource = new MatTableDataSource(mappedData);
    // });
  },
    error => {
      console.error('Error fetching places:', error);
    }
  );
}

  deletePlace(id: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent);
  
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'yes') {
        this.placesController.deletePlace(id).subscribe(
          (response: boolean) => {
            if (response) {
              this.getAllPlaces();
            } else {
            }
          },
          error => {
            console.error('Error in dialog afterClosed:', error);
          }
        );
      } else {
        // 'No' case: do nothing or handle it as needed
      }
    });
    
  }

  editPlace(placeDTO: PlaceDTO) {
    const dialogRef = this.dialog.open(PlacesAddNewDialogComponent, {
      data: { placeDTO, isEdit: true }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllPlaces();
      }
    });
  }
}