import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/lang.service';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.css']
})
export class SuccessPageComponent implements OnInit{

  selectedLanguage: string;

  constructor(
    private langService: LangService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.langService.getSelectedLanguage();

    this.translateService.use(this.selectedLanguage);
  }


  openFacebookPage(): void {
    window.open('https://www.facebook.com/CoolCulture/', '_blank');
  }
  openInstagramPage(): void {
    window.open('https://www.instagram.com/coolculture/', '_blank');
  }
  openTwitterPage(): void {
    window.open('https://twitter.com/coolculture', '_blank');
  }
  openYoutubePage(): void {
    window.open('https://www.youtube.com/channel/UCMFLyEf4U6-ARLff5kQkaqw', '_blank');
  }

}
