import { Component } from '@angular/core';
import { CategoriesAddNewDialogComponent } from './categories-add-new-dialog/categories-add-new-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CategoryAdminControllerService, CategoryDTO} from 'projects/swagger-api/src';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { CategoriesErrorDialogComponent } from './categories-error-dialog/categories-error-dialog.component';
import { LoadingService } from '../loading-spinner/loading.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent {
  displayedColumns: string[] = ['name', 'icon', 'actions'];
  dataSource: MatTableDataSource<CategoryDTO>;
  loading: boolean = false;

  constructor(
    public dialog: MatDialog, 
    private categoriesController: CategoryAdminControllerService,
    private loadingService: LoadingService) {}


  ngOnInit()  {

    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });
    
    this.getAllCategories();
  }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(CategoriesAddNewDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        debugger
        this.getAllCategories();
      }
    });
  }

  getAllCategories() {
    this.categoriesController.getAllCategories1().subscribe(
      (data: CategoryDTO[]) => {
        // console.log(data);
        const mappedData = data.map(category => ({
            id: category.id,
            name: category.name,
            icon: category.icon
          }));

      this.dataSource = new MatTableDataSource(mappedData);
      this.dataSource.data.sort((a, b) => a.id.localeCompare(b.id));    
      },
      error => {
        // console.error('Error fetching category:', error);
      }
    );
  }

  deleteCategory(id: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent);
  
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'yes') {
        this.categoriesController.deleteCategory(id).subscribe(
          (response: boolean) => {
            if (response) {
              // console.log('Category deleted successfully');
              this.getAllCategories();
            } else {
              // The response is false, but we only want to show the conflict dialog for a 409 status
              this.handleDeleteError(409);
            }
          },
          (error: HttpErrorResponse) => {
            // Handle other errors here
            console.error('Error deleting category:', error);
            // Check for 409 Conflict status
            if (error.status === 409) {
              this.handleDeleteError(409);
            }
          }
        );
      }
    });
  }
  
  // Show conflict dialog method
  private handleDeleteError(statusCode: number) {
    if (statusCode === 409) {
      const dialogRef = this.dialog.open(CategoriesErrorDialogComponent, {
        width: '400px', // Set the width here
        data: {
          message: 'Can not delete because a place is created using this category'
        }
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        // Handle dialog close if needed
      });
    }
  }
 
  
  editCategory(category: CategoryDTO) {
    const dialogRef = this.dialog.open(CategoriesAddNewDialogComponent, {
      data: { category, isEdit: true }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the edited category in the data source
        const editedIndex = this.dataSource.data.findIndex(item => item.id === result.id);
        if (editedIndex !== -1) {
          this.dataSource.data[editedIndex] = result;
          // Refresh the MatTableDataSource after updating the data
          this.dataSource.data = [...this.dataSource.data];
        }
      }
    });
  }
}
