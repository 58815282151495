import { Injectable } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { CampaignAdminControllerService, CampaignDTO } from 'projects/swagger-api/src';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { Subject } from 'rxjs/internal/Subject';

interface ContextData {
  campaigns: CampaignDTO[],
  activeCampaign: CampaignDTO;
}

@Injectable({
  providedIn: 'root'
})

export class ContextDataService {

  public contextData: ContextData;
  public contextDataLoaded: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private campaignAdminApi: CampaignAdminControllerService
  ) {
    this.contextData = {
      activeCampaign: null,
      campaigns: []
    }
  }

  public activeCampaignChange(event: MatSelectChange) {
    this.contextData.activeCampaign = event.value;
    this.contextDataLoaded.next('active changed');
  }

  public getAllCampaigns() {
    this.campaignAdminApi.getAllCampaigns().pipe(take(1)).subscribe({
      next: response => {
        this.contextData.campaigns = response;
        if (response.length) {
          const activeCampaign = response.find(c => c.active);
          this.contextData.activeCampaign = activeCampaign ? activeCampaign : response[0];
        }
        this.contextDataLoaded.next('data loaded');
      }
    });
  }
}
