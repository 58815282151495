import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatIconAnchor, MatIconButton } from '@angular/material/button';
import { RegistrationComponent } from './auth/registration/registration.component';
import { FirstTimerComponent } from './application/first-timer/first-timer.component';
import { ComingBackUserComponent } from './application/coming-back-user/coming-back-user.component';
import { TermsAndConditionsComponent } from './application/terms-and-conditions/terms-and-conditions.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { WelcomeBackComponent } from './application/welcome-back/welcome-back.component';
import { ApplicationsComponent } from './applications/applications.component';
import { MembersComponent } from './members/members.component';
import { PlacesComponent } from './places/places.component';
import { ResourcesComponent } from './resources/resources.component';
import { CategoriesComponent } from './categories/categories.component';
import { BoroughsComponent } from './boroughs/boroughs.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PlacesAddNewDialogComponent } from './places/places-add-new-dialog/places-add-new-dialog.component';
import { BoroughsAddNewDialogComponent } from './boroughs/boroughs-add-new-dialog/boroughs-add-new-dialog.component';
import { CategoriesAddNewDialogComponent } from './categories/categories-add-new-dialog/categories-add-new-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LangService } from './lang.service';
import { CookieService } from 'ngx-cookie-service';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { ApplicationAdminControllerService, AuthenticationControllerService, BoroughAdminControllerService, CategoryAdminControllerService, EventAdminControllerService, OrganisationControllerService, PlaceAdminControllerService, ResourcesAdminControllerService, SalesforceAdminControllerService, UserAdminControllerService } from 'projects/swagger-api/src';
import { MatTableModule } from '@angular/material/table';
import { AuthInterceptor } from './auth/intrerceptors/admin-panel.interceptor';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InvalidCodeComponent } from './invalid-code/invalid-code.component';
import { ApplicationsEditDialogComponent } from './applications/applications-edit-dialog/applications-edit-dialog.component';
import { ApplicationsDetailsDialogComponent } from './applications/applications-details-dialog/applications-details-dialog.component';
import { WelcomeDialogComponent } from './application/first-timer/welcome-dialog/welcome-dialog.component';
import { ConfirmationDialogComponent } from './application/first-timer/confirmation-dialog/confirmation-dialog.component';
import { SuccessPageComponent } from './application/first-timer/success-page/success-page.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiModule as ApiModule, BASE_PATH as ApiBaseUrl, ApplicationControllerService } from 'projects/swagger-api/src';
import { environment } from 'src/environments/environment';
import { PrintListComponent } from './print-list/print-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { ErrorDialogComponent } from './applications/error-dialog/error-dialog.component';
import { PrivacyPolicyComponent } from './application/privacy-policy/privacy-policy.component';
import { ConfirmDeleteComponent } from './applications/confirm-delete/confirm-delete.component';
import { DashboardComponent } from './dashboard/dashboard.component';
// import { GoogleChartsModule } from 'angular-google-charts';
import { NgxApexchartsModule } from 'ngx-apexcharts';
import { ExportConfirmationDialogComponent } from './export-confirmation-dialog/export-confirmation-dialog.component';
import { MarkAsPrintedConfirmationDialogComponent } from './mark-as-printed-confirmation-dialog/mark-as-printed-confirmation-dialog.component';
import { CustomPrintListControllerService } from 'custom-api/customPrintListController.service';
import { IframeComponent } from './admin-panel/iframe/iframe.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoadingService } from './loading-spinner/loading.service';
import { LoadingInterceptor } from './auth/intrerceptors/loading.intereceptor';
import { LoanerPassesComponent } from './loaner-passes/loaner-passes.component';
import { ConfirmationLoanerPassComponent } from './loaner-passes/confirmation-loaner-pass/confirmation-loaner-pass.component';
import { CategoriesErrorDialogComponent } from './categories/categories-error-dialog/categories-error-dialog.component';
import { BoroughErrorDialogComponent } from './boroughs/borough-error-dialog/borough-error-dialog.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ResourcesAddNewDialogComponent } from './resources/resources-add-new-dialog/resources-add-new-dialog.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UnpaidInvoicesComponent } from './unpaid-invoices/unpaid-invoices.component';
import {MatStepperModule} from '@angular/material/stepper';
import { CustomApplicationAdminControllerService } from 'custom-api/customAdminController.service';
import { CardsDeliveryComponent } from './cards-delivery/cards-delivery.component';
import { DatePipe } from '@angular/common';
import { UploadedDocumentsComponent } from './uploaded-documents/uploaded-documents.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { PurchaseOrdersDialogComponent } from './purchase-orders/purchase-orders-dialog/purchase-orders-dialog.component';
import { SendMailComponent } from './purchase-orders/send-mail/send-mail.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignDialogComponent } from './campaigns/campaign-dialog/campaign-dialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatSnackBarModule} from '@angular/material/snack-bar';






@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    FirstTimerComponent,
    ComingBackUserComponent,
    TermsAndConditionsComponent,
    WelcomeBackComponent,
    AdminPanelComponent,
    ApplicationsComponent,
    MembersComponent,
    PlacesComponent,
    ResourcesComponent,
    CategoriesComponent,
    BoroughsComponent,
    PlacesAddNewDialogComponent,
    BoroughsAddNewDialogComponent,
    CategoriesAddNewDialogComponent,
    ResourcesAddNewDialogComponent,
    DeleteConfirmationDialogComponent,
    InvalidCodeComponent,
    ApplicationsEditDialogComponent,
    ApplicationsDetailsDialogComponent,
    WelcomeDialogComponent,
    ConfirmationDialogComponent,
    SuccessPageComponent,
    LoadingSpinnerComponent,
    PrintListComponent,
    ErrorDialogComponent,
    PrivacyPolicyComponent,
    ConfirmDeleteComponent,
    DashboardComponent,
    ExportConfirmationDialogComponent,
    MarkAsPrintedConfirmationDialogComponent,
    IframeComponent,
    LoanerPassesComponent,
    ConfirmationLoanerPassComponent,
    CategoriesErrorDialogComponent,
    BoroughErrorDialogComponent,
    NotificationsComponent,
    UnpaidInvoicesComponent,
    CardsDeliveryComponent,
    UploadedDocumentsComponent,
    PurchaseOrdersComponent,
    PurchaseOrdersDialogComponent,
    SendMailComponent,
    CampaignsComponent,
    CampaignDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatToolbarModule,
    HttpClientModule,
    MatRadioModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    ApiModule,
    MatInputModule,
    MatTooltipModule,
    MatMenuModule,
    NgxApexchartsModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatSnackBarModule,
    // GoogleChartsModule.forRoot(),
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => { return new TranslateHttpLoader(http, './assets/i18n/', '.json') },
          deps: [HttpClient]
        }
      }
    )
  ],

  providers: [LangService,
    CookieService,
    ApplicationControllerService,
    OrganisationControllerService,
    AuthenticationControllerService,
    ApplicationAdminControllerService,
    OrganisationControllerService,
    CategoryAdminControllerService,
    ResourcesAdminControllerService,
    PlaceAdminControllerService,
    BoroughAdminControllerService,
    EventAdminControllerService,
    UserAdminControllerService,
    SalesforceAdminControllerService,
    CustomPrintListControllerService,
    CustomApplicationAdminControllerService,
    DatePipe,
    [
      LoadingService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true
      }
    ],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: ApiBaseUrl, useValue: environment.apiBaseUrl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
