<div class="container main-container">
    <h2 mat-dialog-title class="title">Confirmation</h2>
    <mat-dialog-content class="mat-typography pb-0">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12">
            <p class="">{{ data.message }}</p>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-flat-button [mat-dialog-close]="false">No</button>
      <button mat-flat-button [mat-dialog-close]="true" color="warn">Yes</button>
    </mat-dialog-actions>
  </div>
  