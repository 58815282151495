import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CampaignAdminControllerService, CampaignDTO } from 'projects/swagger-api/src';
import { take } from 'rxjs/internal/operators/take';
import { LoadingService } from 'src/app/loading-spinner/loading.service';

interface InputData {
  action: 'CREATE' | 'UPDATE' | 'REMOVE',
  item?: CampaignDTO
}

@Component({
  selector: 'app-campaign-dialog',
  templateUrl: './campaign-dialog.component.html',
  styleUrls: ['./campaign-dialog.component.css']
})
export class CampaignDialogComponent {
  loading = false;
  formGroup: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    public dialogRef: MatDialogRef<CampaignDialogComponent>,
    private campaignAdminApi: CampaignAdminControllerService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {

    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
      this.changeDetectorRef.detectChanges();  // Manually trigger change detection
    });

    this.initializeForm();
  }

  initializeForm() {
    this.formGroup = this.fb.group({
      id: [],
      name: [, Validators.required],
      campaignId: [, Validators.required],
      active: [false]
    });

    if (this.data.action === 'UPDATE' || this.data.action === 'REMOVE') {
      this.formGroup.setValue(this.data.item);
    }
  }

  action(state: 'CLOSE' | 'SAVE') {
    switch(state) {
      case 'SAVE': {
        if (this.data.action === 'CREATE') {
          this.createCampaign();
        } else if (this.data.action === 'UPDATE') {
          this.updateCampaign();
        } else if (this.data.action === 'REMOVE') {
          this.deleteCampaign();
        }
        break;
      }
      case 'CLOSE': {
        this.closeDialog();
        break;
      }
    }
  }

  closeDialog(result?: string) {
    this.dialogRef.close(result);
  }

  private createCampaign() {
    this.campaignAdminApi.createCampaign(this.formGroup.value).pipe(take(1)).subscribe({
      next: () => {
        this.closeDialog('created');
      }
    })
  }

  private updateCampaign() {
    this.campaignAdminApi.updateCampaign(this.formGroup.value, this.data.item.id).pipe(take(1)).subscribe({
      next: () => {
        this.closeDialog('updated');
      },
      error: () => {
        this.snackBar.open(this.translate.instant('CAMPAIGNS.DEACTIVATE_CAMPAIGN_ERROR'), '', {
          duration: 5000,
          verticalPosition: 'top'
        });
      }
    })
  }

  private deleteCampaign() {
    this.campaignAdminApi.deleteCampaign(this.data.item.id).pipe(take(1)).subscribe({
      next: () => {
        this.closeDialog('removed');
      }
    })
  }
}
