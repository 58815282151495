import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ApplicationAdminControllerService } from './api/applicationAdminController.service';
import { ApplicationControllerService } from './api/applicationController.service';
import { AuthenticationControllerService } from './api/authenticationController.service';
import { BoroughAdminControllerService } from './api/boroughAdminController.service';
import { BoroughControllerService } from './api/boroughController.service';
import { CampaignAdminControllerService } from './api/campaignAdminController.service';
import { CategoryAdminControllerService } from './api/categoryAdminController.service';
import { CategoryControllerService } from './api/categoryController.service';
import { ConfigurationAdminControllerService } from './api/configurationAdminController.service';
import { ConfigurationControllerService } from './api/configurationController.service';
import { DashboardAdminControllerService } from './api/dashboardAdminController.service';
import { EventAdminControllerService } from './api/eventAdminController.service';
import { EventControllerService } from './api/eventController.service';
import { ExportFilesControllerService } from './api/exportFilesController.service';
import { FileManagementControllerService } from './api/fileManagementController.service';
import { ImageUploadControllerService } from './api/imageUploadController.service';
import { NotificationAdminControllerService } from './api/notificationAdminController.service';
import { NotificationControllerService } from './api/notificationController.service';
import { OrganisationControllerService } from './api/organisationController.service';
import { PaymentAdminControllerService } from './api/paymentAdminController.service';
import { PlaceAdminControllerService } from './api/placeAdminController.service';
import { PlaceControllerService } from './api/placeController.service';
import { PrintListControllerService } from './api/printListController.service';
import { ResourcesAdminControllerService } from './api/resourcesAdminController.service';
import { ResourcesControllerService } from './api/resourcesController.service';
import { SalesforceAdminControllerService } from './api/salesforceAdminController.service';
import { UserAdminControllerService } from './api/userAdminController.service';
import { UserControllerService } from './api/userController.service';
import { UserLoginControllerService } from './api/userLoginController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ApplicationAdminControllerService,
    ApplicationControllerService,
    AuthenticationControllerService,
    BoroughAdminControllerService,
    BoroughControllerService,
    CampaignAdminControllerService,
    CategoryAdminControllerService,
    CategoryControllerService,
    ConfigurationAdminControllerService,
    ConfigurationControllerService,
    DashboardAdminControllerService,
    EventAdminControllerService,
    EventControllerService,
    ExportFilesControllerService,
    FileManagementControllerService,
    ImageUploadControllerService,
    NotificationAdminControllerService,
    NotificationControllerService,
    OrganisationControllerService,
    PaymentAdminControllerService,
    PlaceAdminControllerService,
    PlaceControllerService,
    PrintListControllerService,
    ResourcesAdminControllerService,
    ResourcesControllerService,
    SalesforceAdminControllerService,
    UserAdminControllerService,
    UserControllerService,
    UserLoginControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
