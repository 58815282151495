/**
 * COOL CULTURE API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApplicationDTO } from '../model/applicationDTO';
import { OpenAiContentResponse } from '../model/openAiContentResponse';
import { OpportunityStatusResponse } from '../model/opportunityStatusResponse';
import { OrganisationDTO } from '../model/organisationDTO';
import { PageApplicationDTO } from '../model/pageApplicationDTO';
import { SalesforceGeneralDTO } from '../model/salesforceGeneralDTO';
import { UspsTrackingResponse } from '../model/uspsTrackingResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ApplicationAdminControllerService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Creating new application. Request header key pattern, x-platform: web || android || ios
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createApplication1(body: ApplicationDTO, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDTO>;
    public createApplication1(body: ApplicationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDTO>>;
    public createApplication1(body: ApplicationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDTO>>;
    public createApplication1(body: ApplicationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createApplication1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApplicationDTO>('post',`${this.basePath}/api/v1/mgmt/applications/create`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param opportunityId 
     * @param fileName 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContentFileForm(opportunityId: string, fileName: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<SalesforceGeneralDTO>;
    public createContentFileForm(opportunityId: string, fileName: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesforceGeneralDTO>>;
    public createContentFileForm(opportunityId: string, fileName: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesforceGeneralDTO>>;
    public createContentFileForm(opportunityId: string, fileName: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (opportunityId === null || opportunityId === undefined) {
            throw new Error('Required parameter opportunityId was null or undefined when calling createContentFile.');
        }

        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling createContentFile.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (opportunityId !== undefined && opportunityId !== null) {
            queryParameters = queryParameters.set('opportunityId', <any>opportunityId);
        }
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<SalesforceGeneralDTO>('post',`${this.basePath}/api/v1/mgmt/applications/create-content-file`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param fileName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFile3(id: string, fileName: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public downloadFile3(id: string, fileName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public downloadFile3(id: string, fileName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public downloadFile3(id: string, fileName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFile3.');
        }

        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling downloadFile3.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/v1/mgmt/applications/download-file`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param numOfPasses 
     * @param orgId 
     * @param oppId 
     * @param orgName 
     * @param campaignId 
     * @param firstName 
     * @param lastName 
     * @param passType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateLoanerPasses(numOfPasses: number, orgId: string, oppId: string, orgName: string, campaignId: string, firstName?: string, lastName?: string, passType?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public generateLoanerPasses(numOfPasses: number, orgId: string, oppId: string, orgName: string, campaignId: string, firstName?: string, lastName?: string, passType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public generateLoanerPasses(numOfPasses: number, orgId: string, oppId: string, orgName: string, campaignId: string, firstName?: string, lastName?: string, passType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public generateLoanerPasses(numOfPasses: number, orgId: string, oppId: string, orgName: string, campaignId: string, firstName?: string, lastName?: string, passType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numOfPasses === null || numOfPasses === undefined) {
            throw new Error('Required parameter numOfPasses was null or undefined when calling generateLoanerPasses.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling generateLoanerPasses.');
        }

        if (oppId === null || oppId === undefined) {
            throw new Error('Required parameter oppId was null or undefined when calling generateLoanerPasses.');
        }

        if (orgName === null || orgName === undefined) {
            throw new Error('Required parameter orgName was null or undefined when calling generateLoanerPasses.');
        }

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling generateLoanerPasses.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (numOfPasses !== undefined && numOfPasses !== null) {
            queryParameters = queryParameters.set('numOfPasses', <any>numOfPasses);
        }
        if (orgId !== undefined && orgId !== null) {
            queryParameters = queryParameters.set('orgId', <any>orgId);
        }
        if (oppId !== undefined && oppId !== null) {
            queryParameters = queryParameters.set('oppId', <any>oppId);
        }
        if (orgName !== undefined && orgName !== null) {
            queryParameters = queryParameters.set('orgName', <any>orgName);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (passType !== undefined && passType !== null) {
            queryParameters = queryParameters.set('passType', <any>passType);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/v1/mgmt/applications/generate-sf-loaner-passes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param numOfPasses 
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateLoanerPassesBulk(numOfPasses: number, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public generateLoanerPassesBulk(numOfPasses: number, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public generateLoanerPassesBulk(numOfPasses: number, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public generateLoanerPassesBulk(numOfPasses: number, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numOfPasses === null || numOfPasses === undefined) {
            throw new Error('Required parameter numOfPasses was null or undefined when calling generateLoanerPassesBulk.');
        }

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling generateLoanerPassesBulk.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (numOfPasses !== undefined && numOfPasses !== null) {
            queryParameters = queryParameters.set('numOfPasses', <any>numOfPasses);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/v1/mgmt/applications/loaner-passes/bulk`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param status 
     * @param searchQuery 
     * @param opportunityId 
     * @param page 
     * @param size 
     * @param orderBy 
     * @param sortDirection 
     * @param passType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApplicationsByStatusAndSearch(campaignId: string, status?: string, searchQuery?: string, opportunityId?: string, page?: number, size?: number, orderBy?: string, sortDirection?: string, passType?: string, observe?: 'body', reportProgress?: boolean): Observable<PageApplicationDTO>;
    public getApplicationsByStatusAndSearch(campaignId: string, status?: string, searchQuery?: string, opportunityId?: string, page?: number, size?: number, orderBy?: string, sortDirection?: string, passType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageApplicationDTO>>;
    public getApplicationsByStatusAndSearch(campaignId: string, status?: string, searchQuery?: string, opportunityId?: string, page?: number, size?: number, orderBy?: string, sortDirection?: string, passType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageApplicationDTO>>;
    public getApplicationsByStatusAndSearch(campaignId: string, status?: string, searchQuery?: string, opportunityId?: string, page?: number, size?: number, orderBy?: string, sortDirection?: string, passType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getApplicationsByStatusAndSearch.');
        }









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (searchQuery !== undefined && searchQuery !== null) {
            queryParameters = queryParameters.set('searchQuery', <any>searchQuery);
        }
        if (opportunityId !== undefined && opportunityId !== null) {
            queryParameters = queryParameters.set('opportunityId', <any>opportunityId);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (orderBy !== undefined && orderBy !== null) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('sortDirection', <any>sortDirection);
        }
        if (passType !== undefined && passType !== null) {
            queryParameters = queryParameters.set('passType', <any>passType);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageApplicationDTO>('get',`${this.basePath}/api/v1/mgmt/applications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param paymentId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOpenAIDataForm(paymentId: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<OpenAiContentResponse>;
    public getOpenAIDataForm(paymentId: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpenAiContentResponse>>;
    public getOpenAIDataForm(paymentId: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpenAiContentResponse>>;
    public getOpenAIDataForm(paymentId: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling getOpenAIData.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paymentId !== undefined && paymentId !== null) {
            queryParameters = queryParameters.set('paymentId', <any>paymentId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<OpenAiContentResponse>('post',`${this.basePath}/api/v1/mgmt/applications/generate-data-from-pdf`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUniqueOrganisationIds(campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationDTO>>;
    public getUniqueOrganisationIds(campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationDTO>>>;
    public getUniqueOrganisationIds(campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationDTO>>>;
    public getUniqueOrganisationIds(campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getUniqueOrganisationIds.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrganisationDTO>>('get',`${this.basePath}/api/v1/mgmt/applications/organisation/info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param opportunityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mergePOPackageDocuments(opportunityId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mergePOPackageDocuments(opportunityId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mergePOPackageDocuments(opportunityId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mergePOPackageDocuments(opportunityId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (opportunityId === null || opportunityId === undefined) {
            throw new Error('Required parameter opportunityId was null or undefined when calling mergePOPackageDocuments.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (opportunityId !== undefined && opportunityId !== null) {
            queryParameters = queryParameters.set('opportunityId', <any>opportunityId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v1/mgmt/applications/po-package`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public opportunityStatus(observe?: 'body', reportProgress?: boolean): Observable<OpportunityStatusResponse>;
    public opportunityStatus(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunityStatusResponse>>;
    public opportunityStatus(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunityStatusResponse>>;
    public opportunityStatus(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OpportunityStatusResponse>('get',`${this.basePath}/api/v1/mgmt/applications/opportunity-status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveUspsTrackingDetails(observe?: 'body', reportProgress?: boolean): Observable<Array<UspsTrackingResponse>>;
    public retrieveUspsTrackingDetails(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UspsTrackingResponse>>>;
    public retrieveUspsTrackingDetails(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UspsTrackingResponse>>>;
    public retrieveUspsTrackingDetails(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UspsTrackingResponse>>('get',`${this.basePath}/api/v1/mgmt/applications/tracking-number-details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param opportunityId 
     * @param poEmail 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendPOPackageEmail(opportunityId: string, poEmail: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public sendPOPackageEmail(opportunityId: string, poEmail: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public sendPOPackageEmail(opportunityId: string, poEmail: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public sendPOPackageEmail(opportunityId: string, poEmail: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (opportunityId === null || opportunityId === undefined) {
            throw new Error('Required parameter opportunityId was null or undefined when calling sendPOPackageEmail.');
        }

        if (poEmail === null || poEmail === undefined) {
            throw new Error('Required parameter poEmail was null or undefined when calling sendPOPackageEmail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (opportunityId !== undefined && opportunityId !== null) {
            queryParameters = queryParameters.set('opportunityId', <any>opportunityId);
        }
        if (poEmail !== undefined && poEmail !== null) {
            queryParameters = queryParameters.set('poEmail', <any>poEmail);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/v1/mgmt/applications/po-package-email`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public showApplication1(id: string, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDTO>;
    public showApplication1(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDTO>>;
    public showApplication1(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDTO>>;
    public showApplication1(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling showApplication1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ApplicationDTO>('get',`${this.basePath}/api/v1/mgmt/applications/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateApplication1(body: ApplicationDTO, id: string, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDTO>;
    public updateApplication1(body: ApplicationDTO, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDTO>>;
    public updateApplication1(body: ApplicationDTO, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDTO>>;
    public updateApplication1(body: ApplicationDTO, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateApplication1.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateApplication1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApplicationDTO>('put',`${this.basePath}/api/v1/mgmt/applications/update/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param newStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateApplicationStatus(applicationId: string, newStatus: string, observe?: 'body', reportProgress?: boolean): Observable<ApplicationDTO>;
    public updateApplicationStatus(applicationId: string, newStatus: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationDTO>>;
    public updateApplicationStatus(applicationId: string, newStatus: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationDTO>>;
    public updateApplicationStatus(applicationId: string, newStatus: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling updateApplicationStatus.');
        }

        if (newStatus === null || newStatus === undefined) {
            throw new Error('Required parameter newStatus was null or undefined when calling updateApplicationStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newStatus !== undefined && newStatus !== null) {
            queryParameters = queryParameters.set('newStatus', <any>newStatus);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ApplicationDTO>('put',`${this.basePath}/api/v1/mgmt/applications/${encodeURIComponent(String(applicationId))}/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
