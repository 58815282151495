import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationResponseDTO, RefreshTokenDTO } from 'projects/swagger-api/src';
import jwtDecode from 'jwt-decode';
import { AuthenticationControllerService } from 'projects/swagger-api/src';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationControllerService,
    private cookieService: CookieService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      const accessTokenExpiration = this.getAccessTokenExpiration(accessToken);

      if (accessTokenExpiration && accessTokenExpiration <= Date.now() + 60000) {
        this.refreshToken();
      }

      return true; // Allow access
    } else {
      this.router.navigate(['/login']);
      return false; // Redirect to login
    }
  }

  getAccessTokenExpiration(accessToken: string): number | null {
    try {
      const decodedToken: any = jwtDecode(accessToken);
      if (decodedToken && decodedToken.exp) {
        return decodedToken.exp * 1000;
      }
    } catch (error) {
      // console.error('Error decoding access token:', error);
    }
    return null;
  }

  refreshToken(): void {
    const refreshTokenDto: RefreshTokenDTO = {
      refreshToken: localStorage.getItem('refreshToken')
    };
  
    this.authenticationService.refreshToken(refreshTokenDto, 'body').subscribe(
      (response: AuthenticationResponseDTO) => {
        const accessToken = response.accessToken;
        const refreshToken = response.refreshToken;

        // localStorage.removeItem('accessToken');
        // localStorage.removeItem('refreshToken');
  
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
      },
      (error) => {

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.clear();
        // console.error('Token refresh error:', error);
        this.router.navigate(['/login']);
      }
    );
  }
  
}
