import { Component } from '@angular/core';
import { LoadingService } from '../loading-spinner/loading.service';
import { ApplicationAdminControllerService, ExportFilesControllerService, UserAdminControllerService, UserDTO } from 'projects/swagger-api/src';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cards-delivery',
  templateUrl: './cards-delivery.component.html',
  styleUrls: ['./cards-delivery.component.css']
})
export class CardsDeliveryComponent {

  loading: boolean = false;
  deliveryResponseFiltered:any = [];
  deliveryResponse$: Observable<any>;
  showAdditionalDetails: boolean[] = [];
  showing: boolean = false;
  userRole: string;
  uploadedFile: boolean = false;
  fileName: string;


  constructor(
    private loadingService: LoadingService, 
    private applicationAdminService: ApplicationAdminControllerService,
    private datePipe: DatePipe,
    private userAdminController: UserAdminControllerService,
    private exportFilesController: ExportFilesControllerService){}

  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });

    this.getUserRole();
  
  }

  getUserRole(){
    this.userAdminController.getLoggedInUser1().subscribe(
      (user: UserDTO) => {
        // console.log(user.role);
        this.userRole = user.role;
        if(this.userRole === 'ORGANISATION_ADMIN'){
          this.deliveryResponse$ = this.applicationAdminService.retrieveUspsTrackingDetails();
    
          this.deliveryResponse$.subscribe(response => {

            const uniqueDeliveryResponse = this.filterDuplicates(response, 'trackingNumber');            
            this.deliveryResponseFiltered = uniqueDeliveryResponse;
            // console.log('Delivery Response:', response);
          });
        }
        
      },
      (error) => {
        // console.error('Error fetching user information:', error);
      }
    );
  }

  filterDuplicates(array: any[], propertyName: string): any[] {
    return array.filter((item, index, self) =>
      index === self.findIndex((t) => t[propertyName] === item[propertyName])
    );
  }

  formatTimestamp(timestamp: string): string {
    // Remove any extra characters or whitespace and correct the timestamp
    const correctedTimestamp = timestamp.replace(/[^0-9T:-]/g, '').replace(/T:/g, 'T');
  
    try {
      // Attempt to format the cleaned timestamp
      const formattedTimestamp = this.datePipe.transform(new Date(correctedTimestamp), 'yyyy MM/dd HH:mm');
      
      // Check if the formatted timestamp is valid
      if (formattedTimestamp && formattedTimestamp !== 'Invalid Date') {
        return formattedTimestamp;
      } else {
        // Log the error or handle it as needed
        // console.error(`Invalid date format: ${timestamp}`);
        return timestamp;
      }
    } catch (error) {
      // Log the error or handle it as needed
      // console.error(`Error formatting date: ${error}`);
      return timestamp;
    }
  }

  onFileSelected(event:any){

    const file = event.target.files[0];
    // console.log('this is the file', file);

    this.fileName = file.name;
  
    if (file) {
    
      this.exportFilesController.updateTrackingNumbersForm(file)
        .subscribe(response => {
          // Handle the response from the server
          // console.log('File uploaded successfully:', response);
          this.uploadedFile = true;
        }, error => {
          // Handle errors
          // console.error('Error uploading file:', error);
        });
    }
  }
  
  
  
  


  toggleAdditionalDetails(index: number) {
    this.showAdditionalDetails[index] = !this.showAdditionalDetails[index];
    this.showing = true;
  }

  // getOpportunityStatus(){    
  //   this.applicationAdminService.opportunityStatus().subscribe(response => {
  //     console.log('opportunity status',response); 
  //     this.deliveryResponse = response;
  //   });
  // }
}
