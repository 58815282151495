/**
 * COOL CULTURE API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApplicationRacialDto } from '../model/applicationRacialDto';
import { DashboardApplicationStatus } from '../model/dashboardApplicationStatus';
import { DashboardStaffApplication } from '../model/dashboardStaffApplication';
import { PageDashboardApplication } from '../model/pageDashboardApplication';
import { PageDashboardOrganisationsWithNoApplications } from '../model/pageDashboardOrganisationsWithNoApplications';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DashboardAdminControllerService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param campaignId 
     * @param startDate 
     * @param endDate 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public childrenNumberInDateRangeByOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public childrenNumberInDateRangeByOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public childrenNumberInDateRangeByOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public childrenNumberInDateRangeByOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling childrenNumberInDateRangeByOrgId.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (orgId !== undefined && orgId !== null) {
            queryParameters = queryParameters.set('orgId', <any>orgId);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/api/v1/mgmt/dashboard/children-number`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param startDate 
     * @param endDate 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countFamiliesByIncomeRange(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: number; }>;
    public countFamiliesByIncomeRange(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: number; }>>;
    public countFamiliesByIncomeRange(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: number; }>>;
    public countFamiliesByIncomeRange(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling countFamiliesByIncomeRange.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (orgId !== undefined && orgId !== null) {
            queryParameters = queryParameters.set('orgId', <any>orgId);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<{ [key: string]: number; }>('get',`${this.basePath}/api/v1/mgmt/dashboard/income-range`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param startDate 
     * @param endDate 
     * @param orgId 
     * @param page 
     * @param size 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboardApplicationsInDateRangeAndOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, page?: number, size?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<PageDashboardApplication>;
    public getDashboardApplicationsInDateRangeAndOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, page?: number, size?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDashboardApplication>>;
    public getDashboardApplicationsInDateRangeAndOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, page?: number, size?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDashboardApplication>>;
    public getDashboardApplicationsInDateRangeAndOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, page?: number, size?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getDashboardApplicationsInDateRangeAndOrgId.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (orgId !== undefined && orgId !== null) {
            queryParameters = queryParameters.set('orgId', <any>orgId);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageDashboardApplication>('get',`${this.basePath}/api/v1/mgmt/dashboard/org-date-range`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param orgId 
     * @param page 
     * @param size 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboardOrganisationsWithoutApplications(campaignId: string, orgId?: string, page?: number, size?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<PageDashboardOrganisationsWithNoApplications>;
    public getDashboardOrganisationsWithoutApplications(campaignId: string, orgId?: string, page?: number, size?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDashboardOrganisationsWithNoApplications>>;
    public getDashboardOrganisationsWithoutApplications(campaignId: string, orgId?: string, page?: number, size?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDashboardOrganisationsWithNoApplications>>;
    public getDashboardOrganisationsWithoutApplications(campaignId: string, orgId?: string, page?: number, size?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getDashboardOrganisationsWithoutApplications.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orgId !== undefined && orgId !== null) {
            queryParameters = queryParameters.set('orgId', <any>orgId);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageDashboardOrganisationsWithNoApplications>('get',`${this.basePath}/api/v1/mgmt/dashboard/empty-organisations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param startDate 
     * @param endDate 
     * @param oppId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboardStaffApplicationsNumber(campaignId: string, startDate?: Date, endDate?: Date, oppId?: string, observe?: 'body', reportProgress?: boolean): Observable<DashboardStaffApplication>;
    public getDashboardStaffApplicationsNumber(campaignId: string, startDate?: Date, endDate?: Date, oppId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardStaffApplication>>;
    public getDashboardStaffApplicationsNumber(campaignId: string, startDate?: Date, endDate?: Date, oppId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardStaffApplication>>;
    public getDashboardStaffApplicationsNumber(campaignId: string, startDate?: Date, endDate?: Date, oppId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getDashboardStaffApplicationsNumber.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (oppId !== undefined && oppId !== null) {
            queryParameters = queryParameters.set('oppId', <any>oppId);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DashboardStaffApplication>('get',`${this.basePath}/api/v1/mgmt/dashboard/org-staff`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param startDate 
     * @param endDate 
     * @param oppId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboardStaffApplicationsStatuses(campaignId: string, startDate?: Date, endDate?: Date, oppId?: string, observe?: 'body', reportProgress?: boolean): Observable<DashboardApplicationStatus>;
    public getDashboardStaffApplicationsStatuses(campaignId: string, startDate?: Date, endDate?: Date, oppId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardApplicationStatus>>;
    public getDashboardStaffApplicationsStatuses(campaignId: string, startDate?: Date, endDate?: Date, oppId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardApplicationStatus>>;
    public getDashboardStaffApplicationsStatuses(campaignId: string, startDate?: Date, endDate?: Date, oppId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getDashboardStaffApplicationsStatuses.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (oppId !== undefined && oppId !== null) {
            queryParameters = queryParameters.set('oppId', <any>oppId);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DashboardApplicationStatus>('get',`${this.basePath}/api/v1/mgmt/dashboard/org-application-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param startDate 
     * @param endDate 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoanerPassesNumberInDateRangeAndOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getLoanerPassesNumberInDateRangeAndOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getLoanerPassesNumberInDateRangeAndOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getLoanerPassesNumberInDateRangeAndOrgId(campaignId: string, startDate?: Date, endDate?: Date, orgId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getLoanerPassesNumberInDateRangeAndOrgId.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (orgId !== undefined && orgId !== null) {
            queryParameters = queryParameters.set('orgId', <any>orgId);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/api/v1/mgmt/dashboard/loaner-passes-number`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param organisationId 
     * @param from 
     * @param to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRacialRegistrations(campaignId: string, organisationId?: string, from?: Date, to?: Date, observe?: 'body', reportProgress?: boolean): Observable<ApplicationRacialDto>;
    public getRacialRegistrations(campaignId: string, organisationId?: string, from?: Date, to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApplicationRacialDto>>;
    public getRacialRegistrations(campaignId: string, organisationId?: string, from?: Date, to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApplicationRacialDto>>;
    public getRacialRegistrations(campaignId: string, organisationId?: string, from?: Date, to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getRacialRegistrations.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (organisationId !== undefined && organisationId !== null) {
            queryParameters = queryParameters.set('organisationId', <any>organisationId);
        }
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to.toISOString());
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ApplicationRacialDto>('get',`${this.basePath}/api/v1/mgmt/dashboard/total-racial`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
