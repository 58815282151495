import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationAdminControllerService } from 'projects/swagger-api/src';

@Component({
  selector: 'app-applications-edit-dialog',
  templateUrl: './applications-edit-dialog.component.html',
  styleUrls: ['./applications-edit-dialog.component.css']
})
export class ApplicationsEditDialogComponent {

  selectedStatus: string;
  filteredStatusOptions: string[];
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ApplicationsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeStatusController: ApplicationAdminControllerService
  ) {
    // Set the selected status to the current status of the application
    this.selectedStatus = data.currentStatus;

    this.filteredStatusOptions = data.statusOptions.filter(status => status !== 'All' && status !== 'UNDER_REVIEW');

  }


  ngOnInit(){
    
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  saveDialog(): void {
    this.loading = true;
    // this.changeStatusController.updateApplicationStatus(this.data.applicationId, this.selectedStatus).subscribe((response) => {

    // })
    // this.loading = false;
    // console.log(this.data);
    this.dialogRef.close(this.selectedStatus);

  }
}
