import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AdultDTO,
  ApplicationControllerService,
  ApplicationDTO,
  ChildDTO,
  FamilyDTO,
  OrganisationControllerService,
} from 'projects/swagger-api/src';
import { CookieService } from 'ngx-cookie-service';
import { LangService } from 'src/app/lang.service';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { skip, take } from 'rxjs';
import { POSITIONS } from 'src/app/positions-values/positions';
import { MatRadioChange } from '@angular/material/radio';

interface Genders {
  value: string;
  viewValue: string;
}
interface Numbers {
  value: number;
  viewValue: string;
}
interface MuseumVisits {
  value: string;
  viewValue: string;
}
interface Boroughs {
  value: string;
  viewValue: string;
}
interface Languages {
  value: string;
  viewValue: string;
}
interface Incomes {
  value: string;
  viewValue: string;
}

interface Choices {
  value: string;
  viewValue: string;
}
interface Organization {
  id?: string;
  name?: string;
  address?: string;
  website?: string;
  ccOrganisationCode?: string;
  organisationType?: string;
}

@Component({
  selector: 'app-first-timer',
  templateUrl: './first-timer.component.html',
  styleUrls: ['./first-timer.component.css'],
})
export class FirstTimerComponent {
  positions = POSITIONS;
  isFormSubmitted = false;
  selectedLanguage: string;
  isStaffMember: boolean = false;
  registrationForm: FormGroup;
  filteredOrganizations: Organization[] = [];
  organizations: Organization[] = [];
  opportunityId: string;
  loading: boolean = false;
  // initialChildFirstName:  string;
  birthYears: string[] = [];
  selectedOption: boolean = true;
  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];



  genders: Genders[] = [
    { value: 'Female', viewValue: 'Female' },
    { value: 'Male', viewValue: 'Male' },
    { value: 'Non-Binary', viewValue: 'Non-Binary' },
    { value: 'Nonconforming', viewValue: 'Nonconforming' },
    { value: 'Prefer not to answer', viewValue: 'PreferNotToAnswer' },
    { value: 'Other Gender Identities', viewValue: 'OtherGenderIdentities' },
  ];
  numbers: Numbers[] = [
    { value: 0, viewValue: '0' },
    { value: 1, viewValue: '1' },
    { value: 2, viewValue: '2' },
    { value: 3, viewValue: '3' },
  ];
  museumVisits: MuseumVisits[] = [
    { value: "0", viewValue: 'TimesVisitedMuseum0' },
    { value: "1 time", viewValue: 'TimesVisitedMuseum1' },
    { value: "2 times", viewValue: 'TimesVisitedMuseum2' },
    { value: "3 or more times", viewValue: 'TimesVisitedMuseum3' },
  ];
  boroughs: Boroughs[] = [
    { value: 'Bronx', viewValue: 'Bronx' },
    { value: 'Manhattan', viewValue: 'Manhattan' },
    { value: 'Brooklyn', viewValue: 'Brooklyn' },
    { value: 'Queens', viewValue: 'Queens' },
    { value: 'Staten Island', viewValue: 'StatenIsland' },
  ];
  languages: Languages[] = [
    { value: 'Arabic', viewValue: 'language1' },
    { value: 'Bengali', viewValue: 'language2' },
    { value: 'Cantonese', viewValue: 'language3' },
    { value: 'English', viewValue: 'language4' },
    { value: 'French', viewValue: 'language5' },
    { value: 'Haitian Creole', viewValue: 'language6' },
    { value: 'Hebrew', viewValue: 'language7' },
    { value: 'Korean', viewValue: 'language8' },
    { value: 'Mandarin', viewValue: 'language9' },
    { value: 'Spanish', viewValue: 'language10' },
    { value: 'Russian', viewValue: 'language11' },
    { value: 'Urdu', viewValue: 'language12' },
    { value: 'Yiddish', viewValue: 'language13' },
    { value: 'Other', viewValue: 'other' },
  ];
  incomes: Incomes[] = [
    { value: 'Less than $40,000', viewValue: 'LessThan40000' },
    { value: '$40,000 -$49,999', viewValue: '$40,000-$49,000' },
    { value: '$50,000-$59,999', viewValue: '$50,000-$59,000' },
    { value: '$60,000-$69,999', viewValue: '$60,000-$69,000' },
    { value: '$70,000 -$79,000', viewValue: '$70,000-$79,000' },
    { value: '$80,000 - $89,000', viewValue: '$80,000-$89,000' },
    { value: '$90,000 - $99,000', viewValue: '$90,000-$99,000' },
    { value: '$100,000 - $150,000', viewValue: '$100,000-$150,000' },
    { value: '$150,000 or more', viewValue: '150000orMore' }
  ];

  choices: Choices[] = [
    { value: 'YES', viewValue: 'Yes' },
    { value: 'NO', viewValue: 'No' },
    { value: 'MAYBE', viewValue: 'Maybe' },
  ]
  
  private highestLevelEducationTranslations: any;
  private positionTitleTranslation: any;
  isCodeValid: boolean = false;
  languageForTheForm: string = "EN";
  secondAdultExists: boolean = true;
  isEditMode: boolean = this.route.snapshot.queryParams['id'];
  showOptional: boolean = true;
  // isEditMode: boolean = false;

  constructor(
    private cookieService: CookieService,
    private langService: LangService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private applicationController: ApplicationControllerService,
    private organizationService: OrganisationControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  
  ngOnInit(): void {
    for (let year = 1940; year <= 2005; year++) {
      this.birthYears.push(`${year}-01-01T12:00:00`);
    }
   
    this.selectedLanguage = this.langService.getSelectedLanguage();
    const storedLanguage = localStorage.getItem('selectedLanguage');

    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
      this.languageForTheForm = storedLanguage.toUpperCase();      
    }

    // this.route.queryParams.subscribe((params) => {
    //   if (params.id) {
    //     this.isEditMode = true;
    //   } else {
    //     this.isEditMode = false;
    //   }
    // });

    // console.log("this is the params id", this.isEditMode);
    
    this.openWelcomeDialog();
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      const code = params['code'];
      const id = params['id'];      

      if (code) {
        this.organizationService.getOrganisationByCode(code).pipe(take(1)).subscribe(
          (organizationDTO: any) => {
            if (organizationDTO.records && organizationDTO.records.length > 0) {
              const organization = organizationDTO.records[0];
            
              // console.log('Retrieved organization:', organization);
              this.registrationForm.get('organisationId').setValue(organization.Account_ID_18__c);
              this.registrationForm.get('organisationName').setValue(organization.Name);
              this.filteredOrganizations = [organization];
              // this.secondAdultExists === true;              

            } else {
              // console.error('No matching organization found.');
            }
          },
          (error) => {
            // console.error('Error retrieving organization:', error);
          }
        );
      } else {
        // console.log('No code parameter found in the URL.');
      }

      if (id) {
        this.loading = true;
        this.showOptional = false;
        this.applicationController.showApplication(id).pipe(take(1)).subscribe(
          (response: ApplicationDTO) => {
            // console.log('Application Response:', response);
            this.registrationForm.get('organisationName').setValue(response.organisationName);
            this.filteredOrganizations = [response];
            this.updateFormWithResponseData(response);
            if(!this.registrationForm.get('family.adults.1.email').value && !this.registrationForm.get('family.adults.1.firstName').value){
              this.secondAdultExists = false;
              this.onRadioChange({ value: false } as MatRadioChange); // Manually trigger the radio change event with value false
            }

            // this.initialChildFirstName = response.family.children[0].firstName;            
            this.loading = false;
          },
          (error) => {
            // console.error('Error fetching application details:', error);
          }
        );
      } else {
        // console.log('No Id parameter found in the URL.');
      }
    });

    this.initForm();

    
    if(this.selectedOption === true){
      this.registrationForm.get('family.adults.1.firstName').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.firstName').updateValueAndValidity();

      this.registrationForm.get('family.adults.1.lastName').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.lastName').updateValueAndValidity();

      this.registrationForm.get('family.adults.1.birthdate').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.birthdate').updateValueAndValidity();

      this.registrationForm.get('family.adults.1.gender').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.gender').updateValueAndValidity();

      this.registrationForm.get('family.adults.1.email').setValidators([Validators.required, this.customEmailValidator()]);
      this.registrationForm.get('family.adults.1.email').updateValueAndValidity();

      this.registrationForm.get('family.adults.1.boroughOfResidence').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.boroughOfResidence').updateValueAndValidity();

      this.registrationForm.get('family.adults.1.mobilePhone').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.mobilePhone').updateValueAndValidity();

    }
  
    const haveChildrenControl = this.registrationForm.get('family.adults.0.haveChildren');
    const isStaffMember = this.registrationForm.get('family.adults.0.isMemberAtSchoolCenter');
    // console.log(this.registrationForm.get('family.adults.0.isMemberAtSchoolCenter'));
    
    // console.log(initialChildFirstName);
    
// if(isStaffMember){
//   isStaffMember.valueChanges.subscribe((value)=>{
//     if (value){

//     }
//   })
// }
    if (haveChildrenControl) {
      haveChildrenControl.valueChanges.subscribe((value) => {
        if (!value) {
          // If 'haveChildren' is false, set 'firstName' and 'lastName' to "null"
          this.registrationForm.get('family.children.0.firstName').setValidators(null);
          this.registrationForm.get('family.children.0.firstName').setValue(null);
          this.registrationForm.get('family.children.0.lastName').setValidators(null);
          this.registrationForm.get('family.children.0.lastName').setValue(null);
          this.registrationForm.get('family.children.0.gradeProgram').setValidators(null);
          this.registrationForm.get('family.children.0.gradeProgram').setValue(null);
          this.registrationForm.get('family.children.0.classroom').setValidators(null);
          this.registrationForm.get('family.children.0.classroom').setValue(null);
          this.registrationForm.get('family.children.0.teacher').setValidators(null);
          this.registrationForm.get('family.children.0.teacher').setValue(null);
          this.registrationForm.get('family.numOfChildren').setValidators(null);
          this.registrationForm.get('family.numOfChildren').setValue(0);

        } if(value){
          this.registrationForm.get('family.children.0.firstName').setValidators([Validators.required]);
          // this.registrationForm.get('family.children.0.firstName').setValue(null);
          this.registrationForm.get('family.children.0.lastName').setValidators([Validators.required]);
          // this.registrationForm.get('family.children.0.lastName').setValue(null);
          this.registrationForm.get('family.children.0.gradeProgram').setValidators([Validators.required]);
          // this.registrationForm.get('family.children.0.gradeProgram').setValue(null);
          this.registrationForm.get('family.children.0.classroom').setValidators([Validators.required]);
          // this.registrationForm.get('family.children.0.classroom').setValue(null);
          this.registrationForm.get('family.children.0.teacher').setValidators([Validators.required]);
          // this.registrationForm.get('family.children.0.teacher').setValue(null);
          this.registrationForm.get('family.numOfChildren').setValidators([Validators.required]);
          // this.registrationForm.get('family.numOfChildren').setValue(null);
        }
      });
    }

    this.translateService.get([
      'education1',
      'education2',
      'education3',
      'education4',
      'education5',
      'education6',
      'education7',
      'other',
      'position1',
      'position2',
      'position3',
      'position4',
      'position5',
      'position6',
      'position7',
    ]).subscribe((translations) => {

      this.positionTitleTranslation = {
        position1: translations['position1'],
        position2: translations['position2'],
        position3: translations['position3'],
        position4: translations['position4'],
        position5: translations['position5'],
        position6: translations['position6'],
        position7: translations['position7'],
      };
    });

  }

  

  updateFormWithResponseData(responseData: ApplicationDTO) {
    this.registrationForm.patchValue({
      firstName: responseData.firstName,
      lastName: responseData.lastName,
      email: responseData.email,
      occupation: responseData.occupation,
      familySize: responseData.familySize,
      status: responseData.status,
      organisationId: responseData.organisationId,
      opportunityId: responseData.opportunityId,
      language: this.selectLang(responseData.language.toLocaleLowerCase())
    });
    this.registrationForm.get('language').setValue(responseData.language);
    // Update the family array controls
    const familyArray = this.registrationForm.get('family') as FormGroup;
    const childrenArray = familyArray.get('children') as FormArray;
    const adultsArray = familyArray.get('adults') as FormArray;
  
    if (responseData.family) {
      familyArray.patchValue({
        numOfChildren: responseData.family.numOfChildren,
        isVisitedEvent: responseData.family.isVisitedEvent,
        timesVisitedEvent: responseData.family.timesVisitedEvent,
        highestLevelEducation: responseData.family.highestLevelEducation,
        currentHouseholdIncomeRange: responseData.family.currentHouseholdIncomeRange,
        familyName: responseData.family.familyName,
        isInterestedInDigitalCard: responseData.family.isInterestedInDigitalCard,
        racialAmericanIndianOrAlaskanNative: responseData.family.racialAmericanIndianOrAlaskanNative,
        racialAsian: responseData.family.racialAsian,
        racialBlackOrAfricanAmerican: responseData.family.racialBlackOrAfricanAmerican,
        racialLatinoOrLatinx: responseData.family.racialLatinoOrLatinx,
        racialNativeAmericanOrPacificIslander: responseData.family.racialNativeAmericanOrPacificIslander,
        racialWhite: responseData.family.racialWhite,
        racialPreferNotToAnswer: responseData.family.racialPreferNotToAnswer,
        visitedYear2017: responseData.family.visitedYear2017,
        visitedYear2018: responseData.family.visitedYear2018,
        visitedYear2019: responseData.family.visitedYear2019,
        visitedYear2020: responseData.family.visitedYear2020,
        visitedYear2021: responseData.family.visitedYear2021,
        visitedYear2022: responseData.family.visitedYear2022,
        visitedYear2023: responseData.family.visitedYear2023,
        visitedYear2024: responseData.family.visitedYear2024,
        visitedYear2025: responseData.family.visitedYear2025,
      });
  
      if (responseData.family.children) {
        for (let i = 0; i < responseData.family.children.length; i++) {
          // const mappedOtherLanguageSpoken = this.mapOtherLanguageSpoken(responseData.family.children[i].otherLanguagesSpokenByParent)
          if (i < childrenArray.length) {
            childrenArray.at(i).patchValue({
              firstName: responseData.family.children[i].firstName,
              lastName: responseData.family.children[i].lastName,
              gradeProgram: responseData.family.children[i].gradeProgram,
              classroom: responseData.family.children[i].classroom,
              teacher: responseData.family.children[i].teacher,
              primaryLanguageSpokenByParent: responseData.family.children[i].primaryLanguageSpokenByParent,
              otherLanguageArabic: responseData.family.children[i].otherLanguageArabic,
              otherLanguageBengali: responseData.family.children[i].otherLanguageBengali,
              otherLanguageCantonese: responseData.family.children[i].otherLanguageCantonese,
              otherLanguageEnglish: responseData.family.children[i].otherLanguageEnglish,
              otherLanguageFrench: responseData.family.children[i].otherLanguageFrench,
              otherLanguageHaitianCreole: responseData.family.children[i].otherLanguageHaitianCreole,
              otherLanguageHebrew: responseData.family.children[i].otherLanguageHebrew,
              otherLanguageKorean: responseData.family.children[i].otherLanguageKorean,
              otherLanguageMandarin: responseData.family.children[i].otherLanguageMandarin,
              otherLanguageSpanish: responseData.family.children[i].otherLanguageSpanish,
              otherLanguageRussian: responseData.family.children[i].otherLanguageRussian,
              otherLanguageUrdu: responseData.family.children[i].otherLanguageUrdu,
              otherLanguageYiddish: responseData.family.children[i].otherLanguageYiddish,
              otherLanguageOther: responseData.family.children[i].otherLanguageOther

            });
          }
        }
      }
      
      if (responseData.family.adults) {
        for (let i = 0; i < responseData.family.adults.length; i++) {
          if (i < adultsArray.length) {
            const birthDate = (responseData.family.adults[i].birthdate as unknown as string);
            const FormatedYear = birthDate.slice(0,4) + '-01-01T12:00:00' ;
            // console.log('the formated year',FormatedYear);
            

            adultsArray.at(i).patchValue({
              id: responseData.family.adults[i].id,
              isMemberAtSchoolCenter: responseData.family.adults[0].isMemberAtSchoolCenter,
              haveChildren: responseData.family.adults[0].haveChildren,
              assignedCoolCultureCulturalLiaison: responseData.family.adults[0].assignedCoolCultureCulturalLiaison,
              positionTitle: responseData.family.adults[0].positionTitle,
              stuffPositionOther: responseData.family.adults[0].stuffPositionOther,
              firstName: responseData.family.adults[i].firstName,
              lastName: responseData.family.adults[i].lastName,
              birthdate: FormatedYear,
              gender: responseData.family.adults[i].gender,
              email: responseData.family.adults[i].email,
              mobilePhone: responseData.family.adults[i].mobilePhone,
              boroughOfResidence: responseData.family.adults[i].boroughOfResidence,
              genderCustom: responseData.family.adults[i].genderCustom
            });

            this.formatPhoneNumber(responseData.family.adults[i].mobilePhone, i);

          }
        }
      }
    }
  }

  openWelcomeDialog(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      if(code){
        const dialogRef = this.dialog.open(WelcomeDialogComponent, {
          // width: '60%',
          // height: '60%'
        });
      }
    });
  }


  updateUrl(form: HTMLFormElement): void {
    if (form.valid) {
      const enteredCode = form.value.code;
      
      // Check if the entered code exists in organizations
      const matchingOrganization = this.organizations.find(
        org => org.ccOrganisationCode === enteredCode
      );

      if (matchingOrganization) {
        // Navigate to the new URL with the code parameter
        const fullUrl = `/?code=${enteredCode}`; // Construct the full URL
        this.router.navigateByUrl(fullUrl)
        .then(() => {
          // console.log('Navigation successful');
        })
        .catch(error => {
          // console.error('Navigation failed:', error);
        })
        .catch(error => {
          // console.error('Navigation failed:', error);
        });
      } else {
        // console.error('Invalid code entered');
      }
    }
  }


  initForm(): void {
    this.registrationForm = this.formBuilder.group({
      consentAndRequirement1: [false, [Validators.requiredTrue]],
      consentAndRequirement2: [false, [Validators.requiredTrue]],
      consentAndRequirement3: [false, [Validators.requiredTrue]],
      consentAndRequirement4: [false, [Validators.requiredTrue]],
      confirmSchool: [null, [Validators.requiredTrue]],
      firstName: [''],
      lastName: [''],
      email: [''],
      occupation: [''],
      familySize: [0],
      organisationName: [''],
      opportunityId: [''],
      status: ['UNDER_REVIEW'],
      language: [this.languageForTheForm],
      organisationId: [{value: ''}, Validators.required],
      family: this.formBuilder.group({
        numOfChildren: [null, Validators.required],
        isVisitedEvent: [null, Validators.required],
        timesVisitedEvent: [null, Validators.required],
        highestLevelEducation: ['', Validators.required],
        currentHouseholdIncomeRange: ['', Validators.required],
        familyName: ['', Validators.required],
        isInterestedInDigitalCard: ['', Validators.required],
        racialAmericanIndianOrAlaskanNative: [false],
        racialAsian: [false],
        racialBlackOrAfricanAmerican: [false],
        racialLatinoOrLatinx: [false],
        racialNativeAmericanOrPacificIslander: [false],
        racialWhite: [false],
        racialPreferNotToAnswer: [false],
        
        visitedYear2017: [false],
        visitedYear2018: [false],
        visitedYear2019: [false],
        visitedYear2020: [false],
        visitedYear2021: [false],
        visitedYear2022: [false],
        visitedYear2023: [false],
        visitedYear2024: [false],
        visitedYear2025: [false],

        children: this.formBuilder.array([this.createChildForm()]),
        adults: this.formBuilder.array([
          this.createAdultForm(0),
          this.createAdultForm(1),
        ]),
      }, { validators: [familyValidator]}),
    }, { validators: [this.childEthnicityCheckboxCheckedValidator()]});



    const isMemberAtSchoolCenterControl = this.registrationForm.get('family.adults.0.isMemberAtSchoolCenter');
    if (isMemberAtSchoolCenterControl) {
      isMemberAtSchoolCenterControl.valueChanges.subscribe((value) => {
        if (!value) {
          const haveChildrenControl = this.registrationForm.get('family.adults.0.haveChildren');
          const assignedCoolCultureCulturalLiaisonControl = this.registrationForm.get('family.adults.0.assignedCoolCultureCulturalLiaison');
          const positionTitleControl = this.registrationForm.get('family.adults.0.positionTitle');
          
          const childForm = this.registrationForm.get('family.children.0');
          // Reset the values and set them as untouched
          assignedCoolCultureCulturalLiaisonControl.setValue(false);
          assignedCoolCultureCulturalLiaisonControl.clearValidators();
          assignedCoolCultureCulturalLiaisonControl.removeValidators([Validators.required]);
          assignedCoolCultureCulturalLiaisonControl.updateValueAndValidity();

          haveChildrenControl.setValue(true);

          positionTitleControl.setValue(null);
          positionTitleControl.clearValidators();
          positionTitleControl.removeValidators([Validators.required]);
          positionTitleControl.updateValueAndValidity();
          assignedCoolCultureCulturalLiaisonControl.markAsUntouched();
          positionTitleControl.markAsUntouched();

          childForm.get('firstName').setValidators([Validators.required]);
          childForm.get('firstName').updateValueAndValidity();
          childForm.get('lastName').setValidators([Validators.required]);
          childForm.get('lastName').updateValueAndValidity();
          childForm.get('gradeProgram').setValidators([Validators.required]);
          childForm.get('gradeProgram').updateValueAndValidity();
          childForm.get('classroom').setValidators([Validators.required]);
          childForm.get('classroom').updateValueAndValidity();
          childForm.get('teacher').setValidators([Validators.required]);
          childForm.get('teacher').updateValueAndValidity();
          childForm.get('primaryLanguageSpokenByParent').setValidators([Validators.required]);
          childForm.get('primaryLanguageSpokenByParent').updateValueAndValidity();
          // this.registrationForm.get('family.numOfChildren').setValue(null);
          this.registrationForm.get('family.numOfChildren').setValidators([Validators.required]);
          this.registrationForm.get('family.numOfChildren').updateValueAndValidity();

        } if(value){
          const haveChildrenControl = this.registrationForm.get('family.adults.0.haveChildren');
          const positionTitleControl = this.registrationForm.get('family.adults.0.positionTitle');
          const assignedCoolCultureCulturalLiaisonControl = this.registrationForm.get('family.adults.0.assignedCoolCultureCulturalLiaison');

          // haveChildrenControl.setValue(null);

          positionTitleControl.setValue(null);
          positionTitleControl.setValidators([Validators.required]);
          positionTitleControl.updateValueAndValidity();

          assignedCoolCultureCulturalLiaisonControl.setValue(null);
          assignedCoolCultureCulturalLiaisonControl.setValidators([Validators.required]);
          assignedCoolCultureCulturalLiaisonControl.updateValueAndValidity();


        }
      });
    }



function familyValidator(): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const isVisitedEvent = formGroup.get('isVisitedEvent')?.value;
    const errors = formGroup.errors || {};

    if (!isVisitedEvent) {
      // If isVisitedEvent is false, remove the visitedYearCheckboxCheckedValidator error
      delete errors['noYearSelected'];
    }

    const checkboxFields = [
      'visitedYear2017',
      'visitedYear2018',
      'visitedYear2019',
      'visitedYear2020',
      'visitedYear2021',
      'visitedYear2022',
      'visitedYear2023',
    ];

    const isAtLeastOneChecked = checkboxFields.some((fieldName) =>
      formGroup.get(`${fieldName}`)?.value === true
    );

    if (isVisitedEvent && !isAtLeastOneChecked) {
      // If isVisitedEvent is true but no year is selected, add the noYearSelected error
      errors['noYearSelected'] = true;
    } else {
      // If at least one year is selected, remove the noYearSelected error
      delete errors['noYearSelected'];
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };
}
// Add the familyValidator to the family FormGroup
const familyFormGroup = this.registrationForm.get('family');
if (familyFormGroup) {
  familyFormGroup.setValidators([familyValidator()]);
  familyFormGroup.updateValueAndValidity();
}

// Subscribe to changes in isVisitedEventControl to trigger the validation and update the visited years and timesVisitedEvent
const isVisitedEventControl = this.registrationForm.get('family.isVisitedEvent');
const timesVisitedEventControl = this.registrationForm.get('family.timesVisitedEvent');

if (isVisitedEventControl && timesVisitedEventControl) {
  isVisitedEventControl.valueChanges.subscribe((value) => {
    if (!value) {
      // If isVisitedEvent is false, clear the validators for year checkboxes
      const familyFormGroup = this.registrationForm.get('family');
      if (familyFormGroup) {
        familyFormGroup.clearValidators();
        familyFormGroup.updateValueAndValidity();
      }

      // Set all visited years to false
      const checkboxFields = [
        'visitedYear2017',
        'visitedYear2018',
        'visitedYear2019',
        'visitedYear2020',
        'visitedYear2021',
        'visitedYear2022',
        'visitedYear2023',
      ];

      for (const fieldName of checkboxFields) {
        this.registrationForm.get(`family.${fieldName}`).setValue(false);
      }

      // Clear and reset validators for timesVisitedEvent
      timesVisitedEventControl.clearValidators();
      timesVisitedEventControl.setValue(null);
      timesVisitedEventControl.updateValueAndValidity();
    } else {
      // If isVisitedEvent is true, reapply the familyValidator
      const familyFormGroup = this.registrationForm.get('family');
      if (familyFormGroup) {
        familyFormGroup.setValidators([familyValidator()]);
        familyFormGroup.updateValueAndValidity();
      }

      // Set validators for timesVisitedEvent
      timesVisitedEventControl.setValidators([Validators.required]);
      timesVisitedEventControl.updateValueAndValidity();
    }
  });
}



// Assuming you have the FormGroup already created
// Listen for changes in the specified fields of adults[0] and adults[1]

const adult0 = this.adultsForms.at(0) as FormGroup;
const adult1 = this.adultsForms.at(1) as FormGroup;
const fieldsToWatch = ['firstName', 'lastName', 'birthdate', 'gender', 'email', 'mobilePhone', 'boroughOfResidence'];

fieldsToWatch.forEach(fieldName => {
  adult0.get(fieldName).valueChanges.subscribe(() => {
    updateFamilySize();
  });

  adult1.get(fieldName).valueChanges.subscribe(() => {
    updateFamilySize();
  });
});

// Listen for changes in numOfChildren
const familyNumOfChildren = this.registrationForm.get('family.numOfChildren');
familyNumOfChildren.valueChanges.subscribe(() => {
  updateFamilySize();
});

// Function to update familySize
const updateFamilySize = () => {
  const numOfChildrenValue = familyNumOfChildren.value || 0;

  const familySizeControl = this.registrationForm.get('familySize');

  const isAdult0Filled = fieldsToWatch.every(field => {
    const value = adult0.get(field).value;
    return value !== '' && value !== null;
  });

  const isAdult1Filled = fieldsToWatch.every(field => {
    const value = adult1.get(field).value;
    return value !== '' && value !== null;
  });

  const newFamilySize = (isAdult0Filled ? 1 : 0) + (isAdult1Filled ? 1 : 0) + numOfChildrenValue;

  familySizeControl.setValue(newFamilySize);
};




    const consentAndRequirementControls = [
      'consentAndRequirement1',
      'consentAndRequirement2',
      'consentAndRequirement3',
      'consentAndRequirement4'
    ];

    for (const controlName of consentAndRequirementControls) {
      const control = this.registrationForm.get(controlName);
      if (control) {
        control.valueChanges.subscribe((value) => {
          if (value === '1') {
            for (const otherControlName of consentAndRequirementControls) {
              if (otherControlName !== controlName) {
                const otherControl = this.registrationForm.get(otherControlName);
                if (otherControl) {
                  otherControl.setErrors(null);
                }
              }
            }
          }
        });
      }
    }
  }
  

   childEthnicityCheckboxCheckedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const racialControls = [
        'racialAmericanIndianOrAlaskanNative',
        'racialAsian',
        'racialBlackOrAfricanAmerican',
        'racialLatinoOrLatinx',
        'racialNativeAmericanOrPacificIslander',
        'racialWhite',
        'racialPreferNotToAnswer',
      ];
  
      const anyRacialChecked = racialControls.some((controlName) => control.get(`family.${controlName}`).value === true);
  
      if (anyRacialChecked) {
        return null; // Valid: At least one checkbox is checked
      } else {
        return { childEthnicityNotChecked: true }; // Invalid: None of the checkboxes are checked
      }
    };
  }

   customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailPattern = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/;
      const valid = emailPattern.test(control.value);
      return valid ? null : { 'invalidEmail': { value: control.value } };
    };
  }
  


  childLanguageCheckboxCheckedValidator() {
    return (formGroup: FormGroup) => {
      const languages = [
        'otherLanguageArabic',
        'otherLanguageBengali',
        'otherLanguageCantonese',
        'otherLanguageEnglish',
        'otherLanguageFrench',
        'otherLanguageHaitianCreole',
        'otherLanguageHebrew',
        'otherLanguageKorean',
        'otherLanguageMandarin',
        'otherLanguageSpanish',
        'otherLanguageRussian',
        'otherLanguageUrdu',
        'otherLanguageYiddish',
        'otherLanguageOther',
      ];
  
      const atLeastOneLanguageSelected = languages.some((language) => formGroup.get(language).value === true);
  
      return atLeastOneLanguageSelected ? null : { atLeastOneLanguageRequired: true };
    };
  }
  

  createChildForm() {
    return this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      gradeProgram: [''],
      classroom: [''],
      teacher: [''],
      primaryLanguageSpokenByParent: [''],
      otherLanguageArabic: [false],
      otherLanguageBengali: [false],
      otherLanguageCantonese: [false],
      otherLanguageEnglish: [false],
      otherLanguageFrench: [false],
      otherLanguageHaitianCreole: [false],
      otherLanguageHebrew: [false],
      otherLanguageKorean: [false],
      otherLanguageMandarin: [false],
      otherLanguageSpanish: [false],
      otherLanguageRussian: [false],
      otherLanguageUrdu: [false],
      otherLanguageYiddish: [false],
      otherLanguageOther: [false],
    }, { validators: this.childLanguageCheckboxCheckedValidator() });  
  }

  createAdultForm(index: number) {
    
    return this.formBuilder.group({
      id:[''],
      isMemberAtSchoolCenter: [null, index === 0 ? Validators.required: []],
      haveChildren: [null , index === 0 ? Validators.required: []],
      assignedCoolCultureCulturalLiaison: [null, index === 0 ? Validators.required: []],
      positionTitle: ['', index === 0 ? Validators.required: []],
      firstName: ['', index === 0 ? Validators.required : []],
      lastName: ['', index === 0 ? Validators.required : []],
      birthdate: ['', index === 0 ? Validators.required : []],
      gender: ['', index === 0 ? Validators.required : []],
      email: ['', index === 0 ? [Validators.required, this.customEmailValidator()] : []],
      mobilePhone: ['', index === 0 ? Validators.required : []],
      boroughOfResidence: ['', index === 0 ? Validators.required : []],
      stuffPositionOther: [''],
      genderCustom: [''],
    });
  }

  get childrenForms() {
    return this.registrationForm.get('family.children') as FormArray;
  }

  get adultsForms() {
    return this.registrationForm.get('family.adults') as FormArray;
  }
  

  selectLang(lang: string) {
    this.langService.selectLang(lang);
    this.selectedLanguage = lang.toUpperCase();
    this.registrationForm.get('language').setValue(this.selectedLanguage);
  }

  onStaffMemberChange(event: any) {
    this.isStaffMember = event.value;
  }

  onRadioChange(event: MatRadioChange) {
    this.selectedOption = event.value;

    // if(){
    //   this.selectedOption = false;             
    // }
    if(this.selectedOption === true){
      this.registrationForm.get('family.adults.1.firstName').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.firstName').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.lastName').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.lastName').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.birthdate').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.birthdate').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.gender').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.gender').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.email').setValidators([Validators.required, this.customEmailValidator()]);
      this.registrationForm.get('family.adults.1.email').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.boroughOfResidence').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.boroughOfResidence').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.mobilePhone').setValidators([Validators.required]);
      this.registrationForm.get('family.adults.1.mobilePhone').updateValueAndValidity();

    } else if(this.selectedOption === false) {
      this.registrationForm.get('family.adults.1.firstName').clearValidators();
      this.registrationForm.get('family.adults.1.firstName').setValue(null);
      this.registrationForm.get('family.adults.1.firstName').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.lastName').clearValidators();
      this.registrationForm.get('family.adults.1.lastName').setValue(null);
      this.registrationForm.get('family.adults.1.lastName').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.birthdate').clearValidators();
      this.registrationForm.get('family.adults.1.birthdate').setValue(null);
      this.registrationForm.get('family.adults.1.birthdate').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.gender').clearValidators();
      this.registrationForm.get('family.adults.1.gender').setValue(null);
      this.registrationForm.get('family.adults.1.gender').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.email').clearValidators();
      this.registrationForm.get('family.adults.1.email').setValue(null);
      this.registrationForm.get('family.adults.1.email').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.boroughOfResidence').clearValidators();
      this.registrationForm.get('family.adults.1.boroughOfResidence').setValue(null);
      this.registrationForm.get('family.adults.1.boroughOfResidence').updateValueAndValidity();
      this.registrationForm.get('family.adults.1.mobilePhone').clearValidators();
      this.registrationForm.get('family.adults.1.mobilePhone').setValue(null);
      this.registrationForm.get('family.adults.1.mobilePhone').updateValueAndValidity();
    }
  }


  
  onSubmit(): void {
    this.isFormSubmitted = true;
    // console.log("Form validity:", this.registrationForm.valid);
    // console.log("Form errors:", this.registrationForm.errors);

    if (this.registrationForm.valid) {
      this.loading = true;
      const formData = this.mapFormData(this.registrationForm.value);
      
      this.applicationController.createApplication(formData).subscribe(
        (response) => {
          this.loading = false;
          // console.log('Form data submitted successfully:', response);
          this.registrationForm.reset();
          this.router.navigate(['success-page']);
        },
        (error) => {
          // console.error('Error submitting form data:', error);
        }
      );
    } else {
      // console.log("Form has validation errors:");
    }
  }

  onEditSubmit(): void {
    this.isFormSubmitted = true;
    // console.log("Form validity:", this.registrationForm.valid);
    // console.log("Form errors:", this.registrationForm.errors);
    
    if (this.registrationForm.valid) {
      const formData = this.mapFormData(this.registrationForm.value);

      this.route.queryParams.subscribe(params => {
        const id = params['id'];

          this.applicationController.updateApplication(formData, id).subscribe(
            (response) => {
              // console.log('Form data submitted successfully:', response);
              this.registrationForm.reset();
              this.router.navigate(['success-page']);
            },
            (error) => {
              console.error('Error submitting form data:', error);
            }
          );  
      });
      
  } else {
    // console.log("Form has validation errors:");
  }
}
 
  private mapFormData(formData: ApplicationDTO): any {
    const firstAdult = formData.family.adults[0];
    const secondAdult = formData.family.adults[1];
    const opportunityIdForCode = this.route.snapshot.queryParamMap.get('code');
    const opportunityIdForId = this.route.snapshot.queryParamMap.get('id');
    return {
      firstName: firstAdult?.firstName?.trim(),
      lastName: firstAdult?.lastName?.trim(), 
      email: firstAdult?.email?.trim(), 
      occupation: formData.occupation,
      familySize: formData.familySize,
      organisationName: formData.organisationName,
      opportunityId: opportunityIdForCode || formData.opportunityId,
      language: formData.language,
      status: formData.status,
      organisationId: formData.organisationId,
      family: {
        numOfChildren: formData.family.numOfChildren,
        isVisitedEvent: formData.family.isVisitedEvent,
        timesVisitedEvent: formData.family.timesVisitedEvent,
        highestLevelEducation: formData.family.highestLevelEducation,
        currentHouseholdIncomeRange:
        formData.family.currentHouseholdIncomeRange,
        familyName:formData?.family?.familyName?.trim(),
        isInterestedInDigitalCard: formData.family.isInterestedInDigitalCard,
        racialAmericanIndianOrAlaskanNative: formData.family.racialAmericanIndianOrAlaskanNative,
        racialAsian: formData.family.racialAsian,
        racialBlackOrAfricanAmerican: formData.family.racialBlackOrAfricanAmerican,
        racialLatinoOrLatinx: formData.family.racialLatinoOrLatinx,
        racialNativeAmericanOrPacificIslander: formData.family.racialNativeAmericanOrPacificIslander,
        racialWhite: formData.family.racialWhite,
        racialPreferNotToAnswer: formData.family.racialPreferNotToAnswer,
        visitedYear2017: formData.family.visitedYear2017,
        visitedYear2018: formData.family.visitedYear2018,
        visitedYear2019: formData.family.visitedYear2019,
        visitedYear2020: formData.family.visitedYear2020,
        visitedYear2021: formData.family.visitedYear2021,
        visitedYear2022: formData.family.visitedYear2022,
        visitedYear2023: formData.family.visitedYear2023,
        visitedYear2024: formData.family.visitedYear2024,
        visitedYear2025: formData.family.visitedYear2025,

          children: formData.family.children.map((child: ChildDTO) => ({
          firstName: child?.firstName?.trim(),
          lastName: child?.lastName?.trim(),
          gradeProgram: child?.gradeProgram?.trim(),
          classroom: child?.classroom?.trim(),
          teacher: child?.teacher?.trim(),
          primaryLanguageSpokenByParent: child.primaryLanguageSpokenByParent,
          otherLanguageArabic: child.otherLanguageArabic,
          otherLanguageBengali: child.otherLanguageBengali,
          otherLanguageCantonese: child.otherLanguageCantonese,
          otherLanguageEnglish: child.otherLanguageEnglish,
          otherLanguageFrench: child.otherLanguageFrench,
          otherLanguageHaitianCreole: child.otherLanguageHaitianCreole,
          otherLanguageHebrew: child.otherLanguageHebrew,
          otherLanguageKorean: child.otherLanguageKorean,
          otherLanguageMandarin: child.otherLanguageMandarin,
          otherLanguageSpanish: child.otherLanguageSpanish,
          otherLanguageRussian: child.otherLanguageRussian,
          otherLanguageUrdu: child.otherLanguageUrdu,
          otherLanguageYiddish: child.otherLanguageYiddish,
          otherLanguageOther: child.otherLanguageOther,

        })),
        adults: formData.family.adults.map((adult: AdultDTO, index: number) => ({
          id: index === 0 ? firstAdult.id : secondAdult.id,
          isMemberAtSchoolCenter: index === 0 ? firstAdult.isMemberAtSchoolCenter : false,
          haveChildren: firstAdult.haveChildren,
          assignedCoolCultureCulturalLiaison: index === 0 ? firstAdult.assignedCoolCultureCulturalLiaison : false,
          positionTitle: index === 0 ? firstAdult.positionTitle : null,
          firstName: adult?.firstName?.trim(),
          lastName: adult?.lastName?.trim(),
          birthdate: adult.birthdate,
          gender: adult.gender,
          email: adult?.email?.trim(),
          mobilePhone: adult.mobilePhone ? adult.mobilePhone.replace(/[^\d]/g, '') : null,
          boroughOfResidence: adult.boroughOfResidence,
          stuffPositionOther: index === 0 ? (firstAdult.positionTitle === 'Other' ? firstAdult.stuffPositionOther : null)?.trim() : null,
          genderCustom: (adult.gender === 'Other Gender Identities' ? adult.genderCustom : null)?.trim(),
        })),
      },
    };
  }

  openConfirmationDialog(): void {
    this.isFormSubmitted = true;
    if(this.registrationForm.valid){
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%',
      height: '80%',
      data: { formData: this.mapFormData(this.registrationForm.value), optionalAdult2: this.selectedOption},
    });
  
    dialogRef.componentInstance.confirm.subscribe(() => {
      this.handleConfirmationDialog('confirm');
      dialogRef.close();
    });

    dialogRef.componentInstance.cancel.subscribe(() => {
      this.handleConfirmationDialog('cancel');
      dialogRef.close();
    });
    }
  }

  handleConfirmationDialog(result: 'confirm' | 'cancel'): void {
    if (result === 'confirm') {
      this.route.queryParams.subscribe(params => {
        const code = params['code'];
        const id = params['id'];
    
        if (code) {
          this.onSubmit();
        } 
        if(id) {
          this.onEditSubmit();
        }
      });
    }
  }

  formatPhoneNumber(value: string, index: number): void {
  
    if (index === 0) {
      const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        this.registrationForm.get('family.adults.0.mobilePhone').setValue(formattedValue);
    }
    else if(index === 1){
      const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        this.registrationForm.get('family.adults.1.mobilePhone').setValue(formattedValue);
    }
  }

  trimSpaces(event: any): void {
    const inputValue: string = event.target.value;
    const trimmedValue: string = inputValue.replace(/^\s+|\s+$/g, '');
    this.registrationForm.get('family.adults.0.firstName').setValue(trimmedValue);
  
  }
  


  openFacebookPage(): void {
    window.open('https://www.facebook.com/CoolCulture/', '_blank');
  }
  openInstagramPage(): void {
    window.open('https://www.instagram.com/coolculture/', '_blank');
  }
  openTwitterPage(): void {
    window.open('https://twitter.com/coolculture', '_blank');
  }
  openYoutubePage(): void {
    window.open('https://www.youtube.com/channel/UCMFLyEf4U6-ARLff5kQkaqw', '_blank');
  }
}
