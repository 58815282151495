import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent {

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  openFacebookPage(): void {
    window.open('https://www.facebook.com/CoolCulture/', '_blank');
  }
  openInstagramPage(): void {
    window.open('https://www.instagram.com/coolculture/', '_blank');
  }
  openTwitterPage(): void {
    window.open('https://twitter.com/coolculture', '_blank');
  }
  openYoutubePage(): void {
    window.open('https://www.youtube.com/channel/UCMFLyEf4U6-ARLff5kQkaqw', '_blank');
  }
}
