import { Component } from '@angular/core';
import { LoadingService } from '../loading-spinner/loading.service';
import { MatTableDataSource } from '@angular/material/table';
import { SalesforceAdminControllerService, SalesforceContentVersion } from 'projects/swagger-api/src';

@Component({
  selector: 'app-uploaded-documents',
  templateUrl: './uploaded-documents.component.html',
  styleUrls: ['./uploaded-documents.component.css']
})
export class UploadedDocumentsComponent {

  loading:boolean = false;

  dataSource: MatTableDataSource<SalesforceContentVersion> = new MatTableDataSource<SalesforceContentVersion>();
  displayedColumns: string[] = ['title' , 'uploadDate', 'link'];

  constructor(private loadingService: LoadingService, private salesForceAdminController: SalesforceAdminControllerService){}

  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });
    this.getContentVersion();
  }


  getContentVersion(){
    this.salesForceAdminController.getSalesforceCreatedFiles().subscribe(
      (response)=>{
        // console.log(response);
        this.dataSource = new MatTableDataSource(response);

    })
  }
}
