import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from 'src/app/loading-spinner/loading.service';
import { BoroughAdminControllerService, BoroughDTO, FileManagementControllerService } from 'projects/swagger-api/src';

@Component({
  selector: 'app-boroughs-add-new-dialog',
  templateUrl: './boroughs-add-new-dialog.component.html',
  styleUrls: ['./boroughs-add-new-dialog.component.css']
})
export class BoroughsAddNewDialogComponent {

  form: FormGroup;
  categoryNameError: boolean = false;
  googleMapsLink: string = '';
  isGoogleMapsLinkValid: boolean = true;
  linkNotValid: boolean = false;
  loading:boolean = false;
  pictureNameUploaded: boolean = false;
  imageToDisplay: string = null;
  formSubmitted: boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef;

  
  constructor(
    public dialogRef: MatDialogRef<BoroughsAddNewDialogComponent>, 
    private formBuilder: FormBuilder, 
    private boroughsController: BoroughAdminControllerService,
    private loadingService: LoadingService,
    private fileManagement: FileManagementControllerService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.form = this.formBuilder.group({
      name: [data.borough ? data.borough.name : '', Validators.required],
      latitude: [data.borough ? data.borough.latitude : '', Validators.required],
      longitude: [data.borough ? data.borough.longitude : '', Validators.required],
      mapZoom: [data.borough ? data.borough.mapZoom : '', Validators.required],
      image: [data.borough ? data.borough.image : 'Choose An Image', Validators.required],
    });

    this.imageToDisplay = this.form.get('image').value;


    if (data.isEdit && data.borough) {
      // If in edit mode and a borough is provided, construct the googleMapsLink
      this.googleMapsLink = `https://www.google.com/maps?@${data.borough.latitude},${data.borough.longitude}`;
    }
  }

  ngOnInit(){
    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.loading = isLoading;
    });
  }


  validateGoogleMapsLink() {
    this.isGoogleMapsLinkValid = this.googleMapsLink.trim() !== '';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveDialog(): void{

    this.validateGoogleMapsLink();
    this.extractLatLng();

    this.formSubmitted = true;
    if (this.form.invalid || this.form.get('image').value === 'Choose An Image') {
      // console.log('Please provide both a category name and select an icon before adding the category.');
      return;
    }

    const fileInput = document.getElementById('uploadFile') as HTMLInputElement;
    const mockEvent = {
      target: {
        files: [fileInput?.files?.[0]] // Assuming you have only one file input
      }
    };

    this.uploadFileEvt(mockEvent);

    if (this.data.isEdit) {
      const updatedBorough = {
        id: this.data.borough.id,
        name: this.form.value.name,
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
        mapZoom: this.form.value.mapZoom,
        image: this.form.value.image
      };

      if (updatedBorough.image.startsWith('https://')){
        updatedBorough.image = updatedBorough.image.split('/').pop();
      }
  
      this.boroughsController.updateBorough(updatedBorough, this.data.borough.id).subscribe(
        (response: BoroughDTO) => {
          // console.log('Borough updated successfully:', response);
          this.dialogRef.close(response);
        },
        error => {
          // console.error('Error updating Borough:', error);
        }
      );
    } else {

      const newBorough: BoroughDTO = {
      name: this.form.value.name.trim(),
      latitude: this.form.value.latitude,
      longitude:this.form.value.longitude,
      mapZoom:this.form.value.mapZoom.trim(),
      image: this.form.value.image
    };
  
    this.boroughsController.createBorough(newBorough).subscribe(
      (response: BoroughDTO) => {
        // console.log('Borough added successfully:', response);
        this.dialogRef.close(response);
      },
      error => {
        // console.error('Error adding borough:', error);
      }
    );
    }
  
    // Clear error message
    this.clearError();
  }
  
  clearError() {
    this.categoryNameError = false;
  }


  extractLatLng() {
    const match = this.googleMapsLink.match(/@(-?\d+\.\d+),(-?\d+\.\d+)/);
    if (match) {
      this.linkNotValid = false;

      const latitude = match[1];
      const longitude = match[2];

      // Update the form controls
      this.form.patchValue({
        latitude: latitude,
        longitude: longitude
      });
    } else{
      this.linkNotValid = true;
    }
  }


  uploadFileName(event: any): void{
    const file = (event.target as HTMLInputElement).files?.[0];
    // console.log(file);
    this.form.get('image').setValue(file.name);
    // console.log(this.form.get('fileAttr').value);
    // console.log(this.imageToDisplay);

    if(file){
      this.pictureNameUploaded = true;
    }
    
  }

  uploadFileEvt(event: any): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    // console.log(file);
    // this.form.get('fileAttr').setValue('uploads/'+file.name);
    // console.log(this.form.get('fileAttr').value);  
  
    if (file) {
      this.fileManagement.uploadFileForm('uploads', file)
        .subscribe(
          (response) => {
            // console.log('File uploaded successfully:', response);

            this.form.patchValue({
              image: response.message
            });
          },
          (error) => {
            console.error('Error uploading file:', error);
            // Handle error as needed
          }
        );
    }
  }
  
}

