import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LangService } from 'src/app/lang.service';

interface Food{
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-coming-back-user',
  templateUrl: './coming-back-user.component.html',
  styleUrls: ['./coming-back-user.component.css']
})
export class ComingBackUserComponent {
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  selectedLanguage: string = 'en';

  constructor(private cookieService: CookieService, private langService: LangService,) {}


foods: Food[] = [
  {value: 'steak-0', viewValue: 'Option 1'},
  {value: 'pizza-1', viewValue: 'Option 2'},
  {value: 'tacos-2', viewValue: 'Option 3'},
];

ngOnInit(): void {
  this.selectedLanguage = this.langService.getSelectedLanguage();
  const storedLanguage = localStorage.getItem('selectedLanguage');
  if (storedLanguage) {
    this.selectedLanguage = storedLanguage;
  }
}

selectLang(lang: string) {
  this.langService.selectLang(lang);
  this.selectedLanguage = lang;
}
}