import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationControllerService, UserAdminControllerService, AuthenticationRequestDTO, AuthenticationResponseDTO} from 'projects/swagger-api/src';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken: string;
  private refreshTokenValue: string;

  constructor(
    private authController: AuthenticationControllerService,
    private userAdminController: UserAdminControllerService,
    private router: Router,
    private cookieService: CookieService

  ) {}

  login(email: string, password: string) {
    const loginData: AuthenticationRequestDTO = { email, password };
    return this.authController.authenticate(loginData).pipe(
      tap((response: AuthenticationResponseDTO) => {
        this.accessToken = response.accessToken;
        this.refreshTokenValue = response.refreshToken;
        localStorage.setItem('accessToken', this.accessToken);
        localStorage.setItem('refreshToken', this.refreshTokenValue);
      }),
      
      switchMap(() => {
        // After successful login, fetch user details using the access token
        return this.userAdminController.getLoggedInUser1();
      }),
    );
  }

  // refreshToken() {
  //   if (!this.refreshTokenValue) {
  //     this.router.navigate(['/login']);
  //     return;
  //   }
  
  //   const refreshRequest: RefreshTokenDTO = {
  //     refreshToken: this.refreshTokenValue
  //   };
  
  //   // Make an API call to refresh the tokens
  //   this.authController.refreshToken(refreshRequest, 'body').subscribe(
  //     (response: AuthenticationResponseDTO) => {
  //       // Update the stored tokens with the new values
  //       this.accessToken = response.accessToken;
  //       this.refreshTokenValue = response.refreshToken;
  //     },
  //     (error) => {
  //       console.error('Error refreshing tokens:', error);
  //     }
  //   );
  // }
  
  
}
