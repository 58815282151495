<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container main-container">
  <h2 mat-dialog-title class="title">{{ (data?.isEdit ? 'updateCategory' : 'CategoryAddnewTitle') | translate }}</h2>

  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div class="container">
        <div class="row gy-3">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{'categoryName' | translate}}</mat-label>
              <input matInput placeholder="{{ 'CategoryAddnewName' | translate }}" formControlName="categoryName">
              <mat-error *ngIf="form.get('categoryName').hasError('required')">Name is required</mat-error>
            </mat-form-field>
            <!-- <div class="error-message" *ngIf="form.controls.categoryName.invalid && form.controls.categoryName.touched">
              Name is required.
            </div> -->
          </div>

          <div class="col-12">
            <mat-form-field class="full-width-form-field" appearance="outline">
              <mat-label>{{'iconCategory' | translate}}</mat-label>
              <mat-toolbar class="d-flex justify-content-center flex-column custom-font">
                <input class="text-center" matInput formControlName="fileAttr" [hidden]="!this.pictureNameUploaded && form.get('fileAttr').value !== 'Choose An Icon'" readonly name="image">
                <img class="pb-4" [src]="imageToDisplay" [hidden]="this.pictureNameUploaded || form.get('fileAttr').value === 'Choose An Icon'"  alt="Image" style="max-width: 300px; max-height: 300px;">
                <button mat-flat-button color="warn">Browse File</button>
              </mat-toolbar>
              <input type="file" #fileInput id="uploadFile" (change)="uploadFileName($event)" name="uploadImage" multiple="multiple" accept="image/*"/>
            </mat-form-field>
            <div class="error-message" *ngIf="form.get('fileAttr').value === 'Choose An Icon'">
              {{ 'FileRequired' | translate }}
            </div>
            
            <!-- <div class="error-message" *ngIf="form.controls.fileAttr.invalid && form.controls.fileAttr.touched">
              Icon is required.
            </div> -->
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="warn" (click)="saveDialog()" [disabled]="form.invalid ">{{ (data?.isEdit ? 'update' : 'Add') | translate }}</button>
  </mat-dialog-actions>
</div>
