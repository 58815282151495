<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container main-container">
    <h2 mat-dialog-title class="title">{{ (data?.isEdit ? 'updateBorough' : 'BoroughAddNewTitle') | translate }}</h2>
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <div class="container">
          <div class="row gy-3">
  
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'boroughName' | translate}}</mat-label>
                <input matInput placeholder="{{ 'BoroughAddNewName' | translate }}" formControlName="name">
                <mat-error *ngIf="form.get('name').hasError('required')">Name is required</mat-error>
              </mat-form-field>
            </div>
  
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'mapZoom' | translate}}</mat-label>
                <input matInput placeholder="{{ 'BoroughAddNewMapZoom' | translate }}" formControlName="mapZoom">
                <mat-error *ngIf="form.get('mapZoom').hasError('required')">Map Zoom is required</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="full-width-form-field" appearance="outline">
                <mat-label>{{'iconCategory' | translate}}</mat-label>
                <mat-toolbar class="d-flex justify-content-center flex-column custom-font">
                  <input class="text-center" matInput formControlName="image" [hidden]="!this.pictureNameUploaded && form.get('image').value !== 'Choose An Image'" readonly name="image">
                  <img class="pb-4" [src]="imageToDisplay" [hidden]="this.pictureNameUploaded || form.get('image').value === 'Choose An Image'"  alt="Image" style="max-width: 300px; max-height: 300px;">
                  <button mat-flat-button color="warn">Browse File</button>
                </mat-toolbar>
                <input type="file" #fileInput id="uploadFile" (change)="uploadFileName($event)" name="uploadImage" multiple="multiple" accept="image/*"/>
              </mat-form-field>
              <div class="error-message" *ngIf="form.get('image').value === 'Choose An Image'">
                {{ 'FileRequired' | translate }}
              </div>
              
              <!-- <div class="error-message" *ngIf="form.controls.fileAttr.invalid && form.controls.fileAttr.touched">
                Icon is required.
              </div> -->
            </div>


                        <!-- ... other code ... -->
            <div class="col-12 custom-removed-wrapper">
              <mat-form-field appearance="outline">
                <mat-label>{{'googleMapsLink' | translate}}</mat-label>
                <input matInput placeholder="{{ 'BoroughAddNewGoogleMapsLink' | translate }}" [(ngModel)]="googleMapsLink" [ngModelOptions]="{standalone: true}">
                <!-- <mat-error *ngIf="form.get('latitude').hasError('required') && form.get('longitude').hasError('required')">Latitude is required</mat-error>              -->
              </mat-form-field>
              <div *ngIf="!isGoogleMapsLinkValid && !googleMapsLink" style="color: red; font-size: 12px;">
                <label for="" class="ps-3">Google Maps link is required</label>
              </div>
              <div *ngIf="linkNotValid && googleMapsLink != ''" style="color: red; font-size: 12px;">
                <label for="" class="ps-3">Google Maps link is not valid</label>
              </div>
              <label for="" class="text-break text-wrap">
                <p class="pt-2 mb-0">Example google maps URL link:</p>
                {{ 'https://www.google.com/maps/place/Cool+Culture/@40.6852501,-73.9769903,17z/data=!3m1!4b1!4m6!3m5!1s0x89c25bb1cdc3f925:0x29ada2fcc54eee6f!8m2!3d40.6852461!4d-73.9744154!16s%2Fg%2F1tmpfvxh?entry=ttu' }}
              </label>            
            </div>
            <!-- ... other code ... -->

  
            <div class="col-6" hidden>
              <mat-form-field appearance="outline">
                <input matInput placeholder="{{ 'BoroughAddNewLatitude' | translate }}" formControlName="latitude">
                <mat-error *ngIf="form.get('latitude').hasError('required')">Latitude is required</mat-error>
              </mat-form-field>
            </div>
  
            <div class="col-6" hidden>
              <mat-form-field appearance="outline">
                <input matInput placeholder="{{ 'BoroughAddNewNameLongitude' | translate }}" formControlName="longitude">
                <mat-error *ngIf="form.get('longitude').hasError('required')">Longitude is required</mat-error>
              </mat-form-field>
            </div>
  
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="closeDialog()">{{ 'Cancel' | translate }}</button>
      <button mat-flat-button color="warn" (click)="saveDialog()">{{ (data?.isEdit ? 'update' : 'Add') | translate }}</button>
    </mat-dialog-actions>
  </div>
  






